import React, { useState, useEffect, useRef } from "react";
import { styled } from "@mui/material/styles";
import { CSVLink } from "react-csv";
import { useParams } from 'react-router-dom';
import { Grid, Box, ThemeProvider } from "@mui/material";
import TopFilterBar from "../../components/reusableComponents/TopFilterBar";
import DesktopTable from "../../components/reusableComponents/DesktopTable";
import AddPesticide from "../../components/advisoryManagement/addPesticideModal";
import AssignPesticide from "../../components/advisoryManagement/assignPesticideModal";
import EditPesticide from "../../components/advisoryManagement/editPesticideModal";
import EditAssignPesticide from "../../components/advisoryManagement/editAssignPesticideModal";
import DeletePesticide from "../../components/advisoryManagement/deletePesticideModal";
import DeletePartnerPesticideMapping from "../../components/advisoryManagement/deletePartnerPesticideModal";
import BulkUploadPesticide from "../../components/advisoryManagement/bulkUploadPesticide";
import BulkUploadPartnerPesticide from "../../components/advisoryManagement/bulkUploadPartnerPesticide";
import { useDispatch, useSelector } from "react-redux";
import { getPesticidesData, getPartnerPesticideMappingData, exportPesticidesData, exportPartnerPesticideMappingData } from "../../features/advisoryManagement/advisoryManagementSlice";
import SnackBars from "../../components/reusableComponents/SnackBar";
import LinearProgress from "@mui/material/LinearProgress";
import { basicTheme } from "../../themes/customTheme";
import PesticideFilterPopOver from "../../components//advisoryManagement/pesticideFilterPopOver";
import PartnerPesticideFilterPopOver from "../../components//advisoryManagement/partnerPesticideFilterPopOver";
import AlertDialog from "../../components/reusableComponents/AlertDialog";
import StateWiseLocationModal from "../../components/location/statewiseLocationModal";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const MainGrid = styled(Grid)({
  flex: "1",
  backgroundColor: "white",
  marginTop: "80px",
});

const pesticideAdminHeadCells = [
  {
    key: "action",
    label: "Actions",
    type: "editDelete",
    sticky:true,
  },
  {
    key: "code",
    label: "Pesticide Code",
    type: "code",
  },
  {
    key: "title",
    label: "Pesticide Title",
    type: "title",
    sort: true,
  },
  // {
  //   key: "price",
  //   label: "Price",
  //   type: "text",
  //   sort: true,
  // },
  {
    key: "description",
    label: "Description",
    type: "text",
  },
  {
    key: "dosage",
    label: "Dosage",
    type: "text",
  },
  {
    key: "crop",
    label: "Crop Name",
    type: "text",
    sort: true,
    capital: true,
  },
  {
    key: "pest_code",
    label: "Pest Name",
    type: "text",
    sort: true,
  },
  {
    key: "title_translations",
    label: "Title Translations",
    type: "title_translations",
  },
  // {
  //   key: "price_translations",
  //   label: "Price Translations",
  //   type: "price_translations",
  // },
  {
    key: "dosage_translations",
    label: "Dosage Translations",
    type: "dosage_translations",
  },
  {
    key: "description_translations",
    label: "Description Translations",
    type: "description_translations",
  },
  {
    key: "is_active",
    label: "Active",
    type: "checkbox",
  },
];

const pesticideHeadCells = [
  {
    key: "code",
    label: "Pesticide Code",
    type: "code",
  },
  {
    key: "title",
    label: "Pesticide Title",
    type: "title",
    sort: true,
  },
  // {
  //   key: "price",
  //   label: "Price",
  //   type: "text",
  //   sort: true,
  // },
  {
    key: "description",
    label: "Description",
    type: "text",
  },
  {
    key: "dosage",
    label: "Dosage",
    type: "text",
  },
  {
    key: "crop",
    label: "Crop Name",
    type: "text",
    sort: true,
    capital: true
  },
  {
    key: "pest_code",
    label: "Pest Name",
    type: "text",
    sort: true,
  },
  {
    key: "title_translations",
    label: "Title Translations",
    type: "title_translations",
  },
  // {
  //   key: "price_translations",
  //   label: "Price Translations",
  //   type: "price_translations",
  // },
  {
    key: "dosage_translations",
    label: "Dosage Translations",
    type: "dosage_translations",
  },
  {
    key: "description_translations",
    label: "Description Translations",
    type: "description_translations",
  },
  {
    key: "is_active",
    label: "Active",
    type: "checkbox",
  },
];

const partnerPesticideHeadCells = [
  {
    key: "action",
    label: "Actions",
    type: "editDelete",
    sticky:true,
  },
  {
    key: "pesticides",
    label: "Pesticide Code",
    type: "partnerPesticide",
  },
  {
    key: "alert",
    label: "Alert Type",
    type: "text",
    sort: true,
    capital: true
  },
  {
    key: "season",
    label: "Season",
    type: "text",
    sort: true,
  },
  {
    key: "crop",
    label: "Crop Name",
    type: "text",
    sort: true,
    capital: true
  },
  {
    key: "state_wise_location",
    label: "State(Villages)",
    type: "location",
    sort: false,
  },
  {
    key: "farming_type",
    label: "Farming Type",
    type: "text",
    sort: true,
    capital: true
  },
  {
    key: "partner",
    label: "Partner",
    type: "text",
    capital:true,
    sort: true,
  },
  {
    key: "pest",
    label: "Pest",
    type: "text",
    sort: true,
  },
  {
    key: "min_days_after_sowing",
    label: "Minimum Days After Sowing",
    type: "text",
  },
  {
    key: "max_days_after_sowing",
    label: "Maximum Days After Sowing",
    type: "text",
  }
]

const listButtonMenu = [
  { name: "Pesticide Advisory", id: 1 },
  { name: "Assign Pesticide", id: 2 }
];

const addButtonMenu = [
  { name: "Add Pesticide", id: 1 },
  { name: "Assign Pesticide", id: 2 },
]

export default function AdvisoryManagement({ }) {
  const dispatch = useDispatch();
  const csvLink = useRef();
  const { type } = useParams();
  const [openAddModal, setopenAddModal] = React.useState(false);
  const [MenuId, setMenuId] = React.useState(1);
  const [offset, setOffset] = useState(0);
  const [page, setPage] = React.useState(0);
  const [sortingType, setSortingType] = useState("ASC");
  const [sortBy, setSortBy] = useState("created_at");
  const [filterBy, setFilterBy] = useState([]);
  const [applyFilter, setApplyFilter] = useState(false);
  const [searchBarData, setSearchBarData] = useState("");
  const [searchBarText, setSeachBarText] = useState("");
  const [exportData, setExportData] = useState("");
  const [exportedFileName, setExportedFileName] = useState("");
  const [isUpload, setIsUpload] = useState(null);
  const [snackBarType, setSnackBarType] = useState("success");
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openEditModal, setopenEditModal] = useState(false);
  const [editData, setEditData] = useState({});
  const [deleteData, setDeleteData] = useState({});
  const [reload, setReload] = useState(false);
  const [apiCall, setApiCall] = useState(false);
  const [openAlertModal, setOpenAlertModal] = useState(false);
  const [openLocationSubModal, setOpenLocationSubModal] = React.useState(false)
  const [openFilterPopOver, setOpenFilterPopOver] = React.useState(null);
  const [selectedPartner, setSelectedPartner] = React.useState(null);
  const [selectedPest, setSelectedPest] = React.useState("");
  const [selectedFarmingType, setSelectedFarmingType] = React.useState("");
  const [selectedPesticides, setSelectedPesticides] = React.useState([]);
  const [clearFilter, setClearFilter] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleShowFilterPopOver = (event) => {
    setOpenFilterPopOver(event.currentTarget);
    setApplyFilter(false);
    setClearFilter(false);
  };
  const handleCloseFilterPopOver = () => {
    setOpenFilterPopOver(null);
  };

  const rowsPerPage = 10;

  const drawerWidth = useSelector(
    (state) => state.appBarSliceReducer.drawerWidth
  )

  const getPesticidesDataResponse = useSelector(
    (state) => state.pesticidesSliceReducer.pesticidesData.data
  );
  const pesticideDataIsSuccess = useSelector(
    (state) => state.pesticidesSliceReducer.pesticidesDataIsSuccess
  );

  const pesticideDataErrorMessage = useSelector(
    (state) => state.pesticidesSliceReducer.pesticidesDataErrorMessage
  );
  const pesticideDataLoading = useSelector(
    (state) => state.pesticidesSliceReducer.pesticidesDataLoading
  );
  const totalPesticideData = useSelector(
    (state) => state.pesticidesSliceReducer.pesticidesData.count
  );

  const getPartnerPesticidesDataResponse = useSelector(
    (state) => state.pesticidesSliceReducer.partnerPesticideMappingData.data
  );
  const partnerPesticideDataIsSuccess = useSelector(
    (state) => state.pesticidesSliceReducer.partnerPesticideMappingDataIsSuccess
  );
  const partnerPesticideDataErrorMessage = useSelector(
    (state) => state.pesticidesSliceReducer.partnerPesticideMappingDataErrorMessage
  );
  const partnerPesticideDataLoading = useSelector(
    (state) => state.pesticidesSliceReducer.partnerPesticideMappingDataLoading
  );

  const totalPartnerPesticideData = useSelector(
    (state) => state.pesticidesSliceReducer.partnerPesticideMappingData.count
  );
  const loggedInUser = JSON.parse(window.localStorage.getItem("loggedInUser"));

  useEffect(()=>{
    if(type === "pesticide")
      {setMenuId(1);setFilterBy([])}
    else if(type === "partner_pesticide"){
      if(loggedInUser.details.partner!==null){
        setFilterBy((oldArray) => [
          ...oldArray,
          { type: "partner", value: [loggedInUser.details.partner.id] },
        ])
      }
      else
        setFilterBy([]);
      setMenuId(2);
    }
     
  },[type])

  useEffect(()=>{
    setPage(0);
    setOffset(0);
  },[searchBarData])
  const exportPesticidesDataResponse = useSelector(
    (state) => state.pesticidesSliceReducer.exportedPesticidesData
  );
  const exportedPartnerPesticidesMappingDataResponse = useSelector(
    (state) => state.pesticidesSliceReducer.exportedPartnerPesticidesMappingData
  );

  useEffect(() => {
    if (clearFilter) {
      setPage(0);
      setOffset(0);
    }
  }, [clearFilter]);

  useEffect(() => {
    if (applyFilter) {
      handleCloseFilterPopOver();
      setPage(0);
      setOffset(0);
    }
  }, [applyFilter]);

  useEffect(() => {
    if (exportData && MenuId === 1) {
      setLoading(true)
      dispatch(
        exportPesticidesData({
            offset: 0,
            limit: totalPesticideData,
            sort_by: sortingType.toUpperCase(),
            sort_type: sortBy,
            search: searchBarData,
            filter: JSON.stringify(filterBy),
        })
      ).then((res) => {
        setLoading(false)
        setExportData(false);
        if(res.error!==undefined){
          setSnackBarMessage("Something went wrong!")
          setSnackBarType("error")
          setShowSnackBar(true)
        }
        else{        
          setExportedFileName(
            "pesticides_data" + ".csv"
          );
          csvLink.current.link.click();
        }
      });
    }
    else if (exportData && MenuId === 2) {
      setLoading(true)
      dispatch(
        exportPartnerPesticideMappingData({
            offset: 0,
            limit: totalPartnerPesticideData,
            sort_by: sortingType.toUpperCase(),
            sort_type: sortBy,
            search: searchBarData,
            filter: JSON.stringify(filterBy),
        })
      ).then((res) => {
        setLoading(false)
        setExportData(false);
        if(res.error!==undefined){
          setSnackBarMessage("Something went wrong!")
          setSnackBarType("error")
          setShowSnackBar(true)
        }
        else{
          setExportedFileName(
            "partner_pesticides_data" + ".csv"
          );
          csvLink.current.link.click();
        }
      });
    }
  }, [exportData]);

  useEffect(() => {
    if ( MenuId === 1 &&
      !pesticideDataIsSuccess && 
      (pesticideDataErrorMessage === undefined ||
       (pesticideDataErrorMessage.message === undefined && 
        pesticideDataErrorMessage.message !== "" ))
       ) {
      setShowSnackBar(true);
      setSnackBarMessage("Something went wrong on server side");
      setSnackBarType("error");
    }
    else if (
      MenuId === 1 &&
      !pesticideDataLoading && 
      !pesticideDataIsSuccess && 
      pesticideDataErrorMessage !== undefined && 
      pesticideDataErrorMessage.message !== undefined &&
      pesticideDataErrorMessage.message !== ""
      ) {
      setShowSnackBar(true);
      setSnackBarMessage(pesticideDataErrorMessage.message.non_field_errors ? pesticideDataErrorMessage.message.non_field_errors[0] : pesticideDataErrorMessage.message);
      setSnackBarType("error");
    }
    else {
      setSnackBarType("success");
    }
  }, [pesticideDataErrorMessage, pesticideDataLoading, pesticideDataIsSuccess])

  useEffect(() => {
    if (
      MenuId === 2 &&
      !partnerPesticideDataIsSuccess && 
      (partnerPesticideDataErrorMessage === undefined ||
       (partnerPesticideDataErrorMessage.message === undefined && 
        partnerPesticideDataErrorMessage.message !== ""))
      ) {
      setShowSnackBar(true);
      setSnackBarMessage("Something went wrong on server side");
      setSnackBarType("error");
    }
    else if (
      MenuId === 2 && 
      !partnerPesticideDataLoading && 
      !partnerPesticideDataIsSuccess && 
      partnerPesticideDataErrorMessage !== undefined &&
      partnerPesticideDataErrorMessage !== undefined && 
      partnerPesticideDataErrorMessage.message !== ""
      ) {
      setShowSnackBar(true);
      setSnackBarMessage(partnerPesticideDataErrorMessage.message.non_field_errors ? partnerPesticideDataErrorMessage.message.non_field_errors[0] : partnerPesticideDataErrorMessage.message);
      setSnackBarType("error");
    }
    else {
      setSnackBarType("success");
    }
  }, [partnerPesticideDataIsSuccess, partnerPesticideDataErrorMessage, partnerPesticideDataLoading])


  useEffect(() => {
    if (snackBarMessage !== "")
      setShowSnackBar(true);
  }, [snackBarMessage])

  useEffect(() => {
    setPage(0);
    setOffset(0);
    setSortBy("created_at");
    setSortingType("ASC");
    setSelectedFarmingType("");
    setSelectedPest("");
    setSelectedPartner(null);
    setSelectedPesticides([]);
    setSeachBarText("");
    setSearchBarData("");
    setShowSnackBar(false);
    setSnackBarMessage("");
    setSnackBarType("success");
  }, [MenuId])
  
  useEffect(() => {
    
    if (MenuId !== 1 && (apiCall || reload))
    {
      setLoading(true)
    
      dispatch(
        getPartnerPesticideMappingData(
          {
            offset: offset,
            limit: rowsPerPage,
            sort_by: sortingType.toUpperCase(),
            sort_type: sortBy,
            search: searchBarData,
            filter: JSON.stringify(filterBy),
            fields: "partner,id,max_days_after_sowing,min_days_after_sowing,season,farming_type,alert,crop,pest,pesticides,location"
          }
        )
      ).then(()=>{
        setReload(false);
        setLoading(false);
        setApiCall(false);
      })
        }
    
  }, [dispatch,reload, apiCall])

  useEffect(() => {
    setApiCall(true);
  }, [
    searchBarData,
    offset,
    sortingType,
    sortBy,
    filterBy,
    MenuId
  ]);

  useEffect(() => {
    if (MenuId === 1 && (apiCall || reload)){
      setLoading(true)
      dispatch(
        getPesticidesData(
          {
            offset: offset,
            limit: rowsPerPage,
            sort_by: sortingType.toUpperCase(),
            sort_type: sortBy,
            search: searchBarData,
            filter: JSON.stringify(filterBy),
            fields: "code,title,dosage,quantity,description,dosage_translations,title_translations,description_translations,farming_type,crop,pest_code,is_active"
          }
        )
      ).then(() => {
        setReload(false);
        setLoading(false)
        setApiCall(false);
      });
    }
  }, [dispatch,reload, apiCall])
  return (
    <MainGrid container id="main" sx={{ marginLeft: drawerWidth + "px", width: "auto" }} spacing={3}>
      <ThemeProvider theme={basicTheme}>
        <Grid item xs={12}>
          <TopFilterBar
            addtext="Add Pesticide"
            setAddOpen={setopenAddModal}
            // showListButton={true}
            // itemButtonMenu={listButtonMenu}
            addButtonMenu={addButtonMenu}
            menuId={MenuId}
            // setMenuId={setMenuId}
            filterType={MenuId}
            applyFilter={applyFilter}
            setApplyFilter={setApplyFilter}
            searchBarData={searchBarData}
            setSearchBarData={setSearchBarData}
            searchBarText={searchBarText}
            setSeachBarText={setSeachBarText}
            handleShowFilterPopOver={handleShowFilterPopOver}
            searchBy = {MenuId ===1 ? "Search By Pesticide Code and Name":"Search By Pesticide Code"}
            isImport={true}
            setIsUpload={setIsUpload}
            setExportData={setExportData}
            loading={loading}
            filterSelected = {(selectedPest!=="" || selectedFarmingType!=="" || selectedPartner!==null || selectedPesticides.length!==0)}
          />
          {MenuId === 1 && openFilterPopOver ? (
            <PesticideFilterPopOver
              handleClose={handleCloseFilterPopOver}
              openFilterPopOver={openFilterPopOver}
              setApplyFilter={setApplyFilter}
              filterBy={filterBy}
              setFilterBy={setFilterBy}
              selectedPest={selectedPest}
              setSelectedPest={setSelectedPest}
              selectedFarmingType={selectedFarmingType}
              setSelectedFarmingType={setSelectedFarmingType}
              clearFilter={clearFilter}
              setClearFilter={setClearFilter}
              MenuId={MenuId}
            />) :
            MenuId === 2 && openFilterPopOver ? (
              <PartnerPesticideFilterPopOver
              handleClose={handleCloseFilterPopOver}
              openFilterPopOver={openFilterPopOver}
              setApplyFilter={setApplyFilter}
              filterBy={filterBy}
              setFilterBy={setFilterBy}
              selectedPest={selectedPest}
              setSelectedPest={setSelectedPest}
              selectedFarmingType={selectedFarmingType}
              setSelectedFarmingType={setSelectedFarmingType}
              selectedPartner={selectedPartner}
              setSelectedPartner={setSelectedPartner}
              selectedPesticides={selectedPesticides}
              setSelectedPesticides={setSelectedPesticides}
              clearFilter={clearFilter}
              setClearFilter={setClearFilter}
              MenuId={MenuId}
              />
            ) : null}

        </Grid>
        <Grid item xs={12}>
          {MenuId === 1 ? (
            <>
              {pesticideDataLoading && !pesticideDataIsSuccess && loading ? (
                <Box>
                  <LinearProgress color="success" />
                </Box>
              ) : (
                !pesticideDataLoading && pesticideDataIsSuccess &&
                <DesktopTable
                  headCells={loggedInUser.details.role === "Wadhwani Admin" ? pesticideAdminHeadCells:pesticideHeadCells}
                  tableResponse={getPesticidesDataResponse}
                  totalCount={totalPesticideData}
                  setOffset={setOffset}
                  rowsPerPage={rowsPerPage}
                  setSortingType={setSortingType}
                  sortingType={sortingType}
                  setSortBy={setSortBy}
                  sortBy={sortBy}
                  setopenEditModal={setopenEditModal}
                  setEditData={setEditData}
                  setOpenDeleteModal={setOpenDeleteModal}
                  setDeleteData={setDeleteData}
                  setPage={setPage}
                  page={page}
                  openEditModal={openEditModal}
                  openDeleteModal={openDeleteModal}
                />
              )}
              {openAddModal && <AddPesticide
                openAddModal={openAddModal}
                handleClose={() => setopenAddModal(false)}
                setSnackBarMessage={setSnackBarMessage}
                setReload={setReload}
              />}
              {isUpload && <BulkUploadPesticide
                isUpload={isUpload}
                handleClose={() => setIsUpload(null)}
                setSnackBarMessage={setSnackBarMessage}
                setSnackBarType={setSnackBarType}
              />}
              {openEditModal &&
                <EditPesticide
                  openAddModal={openEditModal}
                  handleClose={() => setopenEditModal(false)}
                  data={editData}
                  setSnackBarMessage={setSnackBarMessage}
                  setReload = {setReload}
                />}
              {openAlertModal && (
                <AlertDialog
                  handleClose={() => setOpenAlertModal(false)}
                  open={openAlertModal}
                  heading={'Can not delete this pesticide'}
                  text={alertMessage ? alertMessage : 'This pesticide is already assigned to a partner. Please remove it from the partner before deleting it.'}
                />
              )}
              {openDeleteModal && (
                <DeletePesticide
                  handleClose={() => setOpenDeleteModal(false)}
                  open={openDeleteModal}
                  deleteData={deleteData}
                  setSnackBarMessage={setSnackBarMessage}
                  setReload={setReload}
                  setOpenAlertModal={setOpenAlertModal}
                  setAlertMessage={setAlertMessage}
                />
              )}
              {showSnackBar &&
                <SnackBars
                  open={showSnackBar}
                  handleClose={() => setShowSnackBar(false)}
                  severity={snackBarType}
                  message={snackBarMessage}
                  autoHideDuration={6000}
                  vertical="bottom"
                  horizontal="center"
                />}
                <CSVLink
                  data={exportPesticidesDataResponse}
                  filename={exportedFileName}
                  className="hidden"
                  ref={csvLink}
                  target="_blank"
                />
            </>

          ) : (
            <>
             {partnerPesticideDataLoading && !partnerPesticideDataIsSuccess && loading ? (
                <Box>
                  <LinearProgress color="success" />
                </Box>
              ) : (
                !partnerPesticideDataLoading && partnerPesticideDataIsSuccess && !loading &&
              <DesktopTable
                headCells={partnerPesticideHeadCells}
                tableResponse={getPartnerPesticidesDataResponse}
                totalCount={totalPartnerPesticideData}
                setOffset={setOffset}
                rowsPerPage={rowsPerPage}
                setSortingType={setSortingType}
                sortingType={sortingType}
                setSortBy={setSortBy}
                sortBy={sortBy}
                setopenEditModal={setopenEditModal}
                setEditData={setEditData}
                setOpenDeleteModal={setOpenDeleteModal}
                setDeleteData={setDeleteData}
                setPage={setPage}
                page={page}
                openEditModal={openEditModal}
                setOpenLocationSubModal={setOpenLocationSubModal}
                openDeleteModal={openDeleteModal}
              />
              )}
              {openAlertModal && (
                <AlertDialog
                  handleClose={() => setOpenAlertModal(false)}
                  open={openAlertModal}
                  heading={'Can not delete this Partner Pesticide'}
                  text={alertMessage}
                />
              )}
              {openAddModal && <AssignPesticide
                openAddModal={openAddModal}
                handleClose={() => setopenAddModal(false)}
                setSnackBarMessage={setSnackBarMessage}
                setReload={setReload}
              />}
              {isUpload && <BulkUploadPartnerPesticide
                isUpload={isUpload}
                handleClose={() => setIsUpload(null)}
                setSnackBarMessage={setSnackBarMessage}
                setSnackBarType={setSnackBarType}
              />}
              {openEditModal &&
                <EditAssignPesticide
                  openAddModal={openEditModal}
                  handleClose={() => setopenEditModal(false)}
                  data={editData}
                  setSnackBarMessage={setSnackBarMessage}
                  setReload={setReload}
                />}
              {showSnackBar &&
                <SnackBars
                  open={showSnackBar}
                  handleClose={() => setShowSnackBar(false)}
                  severity={snackBarType}
                  message={snackBarMessage}
                  autoHideDuration={6000}
                  vertical="bottom"
                  horizontal="center"
                />}
              {openDeleteModal && (
                <DeletePartnerPesticideMapping
                  handleClose={() => setOpenDeleteModal(false)}
                  open={openDeleteModal}
                  deleteData={deleteData}
                  setSnackBarMessage={setSnackBarMessage}
                  setReload={setReload}
                  setOpenAlertModal={setOpenAlertModal}
                  setAlertMessage={setAlertMessage}
                />
              )}
              {openLocationSubModal && (
                <StateWiseLocationModal
                  openModal={openLocationSubModal}
                  handleClose={() => setOpenLocationSubModal(false)}
                  data={editData.state_wise_location}
                />
              )}
              {showSnackBar &&
                <SnackBars
                  open={showSnackBar}
                  handleClose={() => setShowSnackBar(false)}
                  severity={snackBarType}
                  message={snackBarMessage}
                  autoHideDuration={6000}
                  vertical="bottom"
                  horizontal="center"
                />}
                <CSVLink
                  data={exportedPartnerPesticidesMappingDataResponse}
                  filename={exportedFileName}
                  className="hidden"
                  ref={csvLink}
                  target="_blank"
                />
            </>
          )}
          {loading && (
          <Backdrop
            sx={{
              color: "#fff",
              position:"absolute",
              zIndex: (theme) => theme.zIndex.drawer +1,
            }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        </Grid>
      </ThemeProvider>
    </MainGrid>
  );
}
