import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";

const Centerstyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const closeIconStyle = {
  position: "absolute",
  right: 3,
  cursor: "pointer",
  color: (theme) => theme.palette.grey[600],
};
export default function AlertDialog({
  handleClose = () => {},
  open = false,
  heading = "",
  text = "",
}) {
  return (
    <Dialog
      open={open}
      PaperProps={{
        style: { borderRadius: "20px" },
      }}
    >
      <HighlightOffRoundedIcon sx={closeIconStyle} onClick={handleClose} />
      <DialogTitle sx={{ marginTop: 2 }}>{heading}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" sx={Centerstyle}>
          {text}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={Centerstyle}>
        <Button
          color="secondary"
          variant="contained"
          sx={{ background: "#EB5451", marginLeft: 5 }}
          onClick={handleClose}
          autoFocus
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
