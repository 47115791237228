import React, { useState, useEffect } from "react";
import {
    Button,
    Grid,
    Typography,
    Modal,
    ThemeProvider,
    Checkbox,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { basicTheme } from "../../themes/customTheme";
import LocationDropDown from "../../components/advisoryManagement/partnerPesticideLocationDropdown";
import { removeDuplicates } from "../../utils/index";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import _without from "lodash/without";
import { useDispatch, useSelector } from "react-redux";
import { getPesticideTitleList, getSeasonData, getFarmingTypeData, getCropData, getAlertData, getPestData, addPartnerPesticideMapping, resetAddPartnerPesticide } from "../../features/advisoryManagement/advisoryManagementSlice";
import { getPartnerNames } from "../../features/partner/partnerSlice";
import {
    getStatesData,
} from "../../features/location/locationSlice";
import SnackBars from "../reusableComponents/SnackBar";
import BasicTextfield from "../reusableComponents/BasicTextfield";
import OptionalDropDown from "../reusableComponents/OptionalDropDown";
import AutoCompleteDropdown from "../reusableComponents/AutoCompleteDropDown";
import AutoCompleteMultiDropdown from "../../components/advisoryManagement/AutoCompleteMultiDropDown";
import { defaultAdvisoryPartnerId } from "../../api's/index"


const subGridStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 2,
    width: "65%",
    maxHeight: "80%",
    overflow: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    paddingLeft: 5,
  };

const useStyles = makeStyles(() => ({
    closeBtnStyle: {
        display: "flex",
        justifyContent: "flex-end",
        zoom: "2.2",
        alignItems: "right",
        "&:hover": {
            color: "#081C15",
        },
        cursor: "pointer",
    },
}));

const ButtonStyle = styled(Button)({
    backgroundColor: "#2D6A4F",
    color: "white",
    textTransform: "none",
    borderRadius: "8px",
    fontSize: "16px",
    fontWeight: "600",
    minWidth: "140px",
    maxWidth: "150px",
    height: "54px",
});

const CancelButtonStyle = styled(Button)({
    backgroundColor: "white",
    color: "#2D6A4F",
    textTransform: "none",
    borderRadius: "8px",
    fontSize: "16px",
    fontWeight: "600",
    minWidth: "140px",
    maxWidth: "150px",
    height: "54px",
});

export default function AssignPesticide({
    openAddModal,
    handleClose,
    setSnackBarMessage = () => { },
    setReload = () => { },
}) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [minSowingDate, setMinSowingDate] = useState("");
    const [maxSowingDate, setMaxSowingDate] = useState("");
    const [partnerId, setPartnerId] = React.useState(null);
    const [seasonId, setSeasonId] = React.useState("");
    const [farmingTypeId, setFarmingTypeId] = React.useState("");
    const [cropId, setCropId] = React.useState("");
    const [alertTypeId, setAlertTypeId] = React.useState("");
    const [pestId, setPestId] = React.useState("");
    const [pesticideId, setPesticideId] = React.useState([]);
    const [partnerLocations, setPartnerLocations] = useState([]);
    const [addAnotherLocationDropDown, setAddAnotherLocationDropDown] = useState(1);
    const [addPartnerPesticideSnackBar, setAddPartnerPesticideSnackBar] = useState(false);
    const [addPartnerPesticideSnackBarMessage, setAddPartnerPesticideSnackBarMessage] = useState("");
    const [snackBarType, setSnackBarType] = useState("success");
    const loggedInUser = JSON.parse(window.localStorage.getItem("loggedInUser"));
    const basicError = {
        minSowingDate: "",
        maxSowingDate: "",
        partnerId: "",
        seasonId: "",
        farmingTypeId: "",
        cropId: "",
        alertTypeId: "",
        pestId: "",
        pesticideId: [],
    };
    const [errors, setErrors] = useState(basicError);
    const [loading, setLoading] = useState(false);
    const [location, setLocation] = useState([
        {
            state: null,
            district: null,
            taluka: null,
            villages: [],
        }
    ]);

    const partnerNamesData = useSelector(
        (state) => state.partnerSliceReducer.partnerNames.data
    );
    const getStatesResponse = useSelector(
        (state) => state.locationSliceReducer.statesData
    );
    const getCropDataResponse = useSelector(
        (state) => state.pesticidesSliceReducer.cropData
    );
    const getFarmingTypeDataResponse = useSelector(
        (state) => state.pesticidesSliceReducer.farmingTypeData
    );
    const getPesticidesTitleList = useSelector(
        (state) => state.pesticidesSliceReducer.pesticideTitleList
    );
    const getSeasonsDataResponse = useSelector(
        (state) => state.pesticidesSliceReducer.seasonData
    );
    const getAlertDataResponse = useSelector(
        (state) => state.pesticidesSliceReducer.alertData
    );
    const getPestDataResponse = useSelector(
        (state) => state.pesticidesSliceReducer.pestData
    );
    const addPartnerPesticideMappingApiResponse = useSelector(
        (state) => state.pesticidesSliceReducer.addPartnerPesticideMappingApiResponse.message
    );
    const addPartnerPesticideMappingErrorMessage = useSelector(
        (state) => state.pesticidesSliceReducer.addPartnerPesticideMappingErrorMessage.message
    );
    const addPartnerPesticideMappingIsSuccess = useSelector(
        (state) => state.pesticidesSliceReducer.addPartnerPesticideMappingIsSuccess
    );
    useEffect(() => {
        if (partnerId !== null) {
            setPartnerLocations(partnerNamesData.find((item) => item.id === partnerId.id).location)
            setLocation([
                {
                    state: null,
                    district: null,
                    taluka: null,
                    villages: [],
                }
            ])
        }
    }, [partnerId])
    useEffect(() => {
        dispatch(resetAddPartnerPesticide());
        if(getStatesResponse[0].id==="")
            dispatch(getStatesData());
    }, []);

    const getPestList = ()=>{
        if(getPestDataResponse[0].code===""){
            setLoading(true);
            dispatch(getPestData({ fields: "code,name" })).then(() => setLoading(false));
        }

    }

    const getAlertList = ()=>{
        if(getAlertDataResponse[0].name===""){
            setLoading(true);
            dispatch(getAlertData({ fields: "name" })).then(() => setLoading(false));
        }
    }

    const getPesticideTitleData = ()=>{
        if(getPesticidesTitleList[0].code===""){
            setLoading(true);
            dispatch(getPesticideTitleList({ fields: "code" })).then(() => setLoading(false));

        }
    }

    const getPartnerList = ()=>{
        var newFilters = []
        if(loggedInUser.details.partner!==null)
          newFilters.push({type:"id",value:[loggedInUser.details.partner.id]})
        newFilters.push({ type: "is_active", value: [true] })
        if(partnerNamesData[0].id===""){
          setLoading(true);
          dispatch(
            getPartnerNames({
              fields: ["name", "id", "location"].toString(),
              filters: JSON.stringify(newFilters),
            })
          ).then(() => setLoading(false));
        }
      }

      const getCropList = ()=>{
        if(getCropDataResponse[0].name===""){
          setLoading(true);
          dispatch(getCropData({ fields: "name" })).then(() => setLoading(false));
        }
      }
    
      const getSeasonList = ()=>{
        if(getSeasonsDataResponse[0].code===""){
           setLoading(true);
           dispatch(getSeasonData({ fields: "code,name,year" })).then(() => setLoading(false));
        }
          
      }
    
      const getFarmingTypeList = () =>{
        if(getFarmingTypeDataResponse[0].name===""){
          setLoading(true);
          dispatch(getFarmingTypeData({ fields: "name" })).then(() => setLoading(false));
        }
      }

    const handleCloseByClearIcon = () => {
        setPartnerId(null);
        setSeasonId("");
        setFarmingTypeId("");
        setCropId("");
        setAlertTypeId("");
        setPestId("");
        setPesticideId([]);
        setAddPartnerPesticideSnackBar(false);
        setAddPartnerPesticideSnackBarMessage("");
        setSnackBarType("success");
        setErrors(basicError);
        setLocation([
            {
                state: null,
                district: null,
                taluka: null,
                villages: [],
            }
        ]);
        dispatch(resetAddPartnerPesticide());
        handleClose();
    };

    const handleChangeMinSowingDays = (value) => {
        const reg = /^-?\d+$/;
        if (!reg.test(value))
            setErrors({ ...errors, minSowingDate: "Please enter only numbers" });
        else if (minSowingDate === "") {
            setErrors({ ...errors, minSowingDate: "Min sowing date should be not be empty." });
        }
        else
            setErrors({ ...errors, minSowingDate: "" });
        setMinSowingDate(value)
    }
    const handleChangeMaxSowingDays = (value) => {
        const reg = /^-?\d+$/;
        if (!reg.test(value))
            setErrors({ ...errors, maxSowingDate: "Please enter only numbers" });
        else if (maxSowingDate === "") {
            setErrors({ ...errors, maxSowingDate: "Max sowing date should be not be empty." });
        }
        else
            setErrors({ ...errors, maxSowingDate: "" });
        setMaxSowingDate(value)
    }

    const setLocationForApi = () => {
        var locations = {
            state_ids: [],
            district_ids: [],
            taluka_ids: [],
            village_ids: [],
        };
        if(location[0].villages.length > 0){
        location.forEach((item) => {
            locations["state_ids"].push(item.state.id);
            locations["district_ids"].push(item.district.id);
            locations["taluka_ids"].push(item.taluka.id);
            locations["village_ids"] = locations["village_ids"].concat(item.villages.map((item) => item.id));
        });
        locations["state_ids"] = removeDuplicates(locations["state_ids"]);
        locations["district_ids"] = removeDuplicates(locations["district_ids"]);
        locations["taluka_ids"] = removeDuplicates(locations["taluka_ids"]);
        locations["village_ids"] = removeDuplicates(locations["village_ids"]);
        }
        return locations;
    };

    const handleSave = () => {
        var locations = setLocationForApi();
        if (
            minSowingDate !== "" &&
            maxSowingDate !== "" &&
            partnerId !== null &&
            seasonId !== "" &&
            farmingTypeId !== "" &&
            cropId !== "" &&
            alertTypeId !== "" &&
            pestId !== "" &&
            pesticideId.length !== 0 &&
            partnerId.id != defaultAdvisoryPartnerId ? locations.village_ids.length > 0 : locations = {}
        ) {
            setAddPartnerPesticideSnackBar(false);
            setSnackBarType("success");
            setAddPartnerPesticideSnackBarMessage("");
            setLoading(true);
            const data = {
                min_days_after_sowing: minSowingDate,
                max_days_after_sowing: maxSowingDate,
                partner: partnerId.id,
                season: seasonId,
                farming_type: farmingTypeId,
                alert: alertTypeId,
                crop: cropId,
                pest: pestId,
                pesticides: pesticideId.map((item) => item.code),
                location: locations
            }
            dispatch(addPartnerPesticideMapping(data))
                .then(() => {
                    setLoading(false);
                })
        }
        else {
            setAddPartnerPesticideSnackBar(true);
            setSnackBarType("error");
            setAddPartnerPesticideSnackBarMessage("Please fill all the fields");
        }
    };

    const handleChangeLocation = (newValue, index, type) => {
        var newLocation = [...location];
        if (type === "state") {
            newLocation[index].state = newValue;
            newLocation[index].district = null;
            newLocation[index].taluka = null;
            newLocation[index].villages = [];
            setLocation(newLocation);
        } else if (type === "district") {
            newLocation[index].district = newValue;
            setLocation(newLocation);
            newLocation[index].taluka = null;
            newLocation[index].villages = [];
        } else if (type === "taluka") {
            newLocation[index].taluka = newValue;
            setLocation(newLocation);
            newLocation[index].villages = [];
        } else if (type === "village") {
            newLocation[index].villages = newValue;
            setLocation(newLocation);
        }
    };
    const handleChangeAddAnotherLocationBox = (event) => {
        if (location[addAnotherLocationDropDown - 1].villages.length !== 0) {
            const newLocation = [...location];
            newLocation.push({
                state: "",
                district: "",
                taluka: "",
                villages: [],
            });
            setLocation(newLocation);
            setAddAnotherLocationDropDown((prevIndex) => prevIndex + 1);
        }
    };

    const handleChangeDeleteLocation = (index) => {
        setAddAnotherLocationDropDown((prevIndex) => prevIndex - 1);
        setLocation((names) => names.filter((_, ind) => ind !== index));
    };

    useEffect(() => {
        if (addPartnerPesticideMappingIsSuccess && addPartnerPesticideMappingApiResponse !== "") {
            setSnackBarMessage(addPartnerPesticideMappingApiResponse);
            setReload(true);
            setSnackBarType("success");
            handleClose();
            dispatch(resetAddPartnerPesticide());
        }
        else if (!addPartnerPesticideMappingIsSuccess && addPartnerPesticideMappingErrorMessage !== "") {
            setAddPartnerPesticideSnackBar(true);
            setSnackBarType("error");
            setAddPartnerPesticideSnackBarMessage(addPartnerPesticideMappingErrorMessage);
        }
    }, [addPartnerPesticideMappingIsSuccess, addPartnerPesticideMappingApiResponse, addPartnerPesticideMappingErrorMessage]);


    const handleAddAnotherLocationBox = (index) => {
        return (
            <React.Fragment key={index}>
                <LocationDropDown
                    location={location}
                    handleChangeLocation={handleChangeLocation}
                    handleChangeDeleteLocation={handleChangeDeleteLocation}
                    index={index}
                    getStatesResponse={getStatesResponse}
                    isPartner={true}
                    partnerLocations={partnerLocations}
                />
            </React.Fragment>
        );
    };

    return (
        <div>
            <Modal
                open={openAddModal}
            >
                <Grid container sx={subGridStyle} spacing={1}>
                    <ThemeProvider theme={basicTheme}>
                            <Grid item xs={11}>
                                <Typography variant="h4" style={{ marginBottom: 10 }}>
                                    Assign Pesticide
                                </Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <ClearIcon
                                    className={classes.closeBtnStyle}
                                    onClick={handleCloseByClearIcon}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <Typography sx={{ marginBottom: 1 }}>
                                            Minimum days after sowing
                                        </Typography>
                                        <BasicTextfield
                                            label="Enter Minimum days"
                                            handleChange={handleChangeMinSowingDays}
                                            index={null}
                                            error={errors.minSowingDate !== ""}
                                            helperText={errors.minSowingDate}
                                            required={true}
                                            value={minSowingDate}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Typography sx={{ marginBottom: 1 }}>
                                            Maximum days after sowing
                                        </Typography>
                                        <BasicTextfield
                                            label="Enter Maximum days"
                                            handleChange={handleChangeMaxSowingDays}
                                            index={null}
                                            error={errors.maxSowingDate !== ""}
                                            helperText={errors.maxSowingDate}
                                            required={true}
                                            value={maxSowingDate}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Typography sx={{ marginBottom: 1 }}>Partner</Typography>
                                        <AutoCompleteDropdown
                                            width="auto"
                                            selectedOptions={partnerId}
                                            setSelectedOptions={setPartnerId}
                                            menuList={partnerNamesData}
                                            label={"Select Partner"}
                                            required={true}
                                            onOpen = {getPartnerList}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Typography sx={{ marginBottom: 1 }}>Season</Typography>
                                        <OptionalDropDown
                                            text={"Select Season"}
                                            id={seasonId}
                                            handleChange={(e) => { setSeasonId(e.target.value) }}
                                            menuList={getSeasonsDataResponse}
                                            required={true}
                                            onOpen = {getSeasonList}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Typography sx={{ marginBottom: 1 }}>Farming Type</Typography>
                                        <OptionalDropDown
                                            text={"Select Farming Type"}
                                            id={farmingTypeId}
                                            handleChange={(e) => { setFarmingTypeId(e.target.value) }}
                                            menuList={getFarmingTypeDataResponse}
                                            required={true}
                                            onOpen = {getFarmingTypeList}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Typography sx={{ marginBottom: 1 }}>Crop Name</Typography>
                                        <OptionalDropDown
                                            text={"Select Crop Name"}
                                            id={getCropDataResponse[0].name === "" ? "" :cropId}
                                            handleChange={(e) => { setCropId(e.target.value) }}
                                            menuList={getCropDataResponse}
                                            required={true}
                                            onOpen = {getCropList}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Typography sx={{ marginBottom: 1 }}>
                                            Alert Type
                                        </Typography>
                                        <OptionalDropDown
                                            text={"Select Alert Type"}
                                            id={alertTypeId}
                                            handleChange={(e) => { setAlertTypeId(e.target.value) }}
                                            menuList={getAlertDataResponse}
                                            required={true}
                                            onOpen={getAlertList}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Typography sx={{ marginBottom: 1 }}>
                                            Pest
                                        </Typography>
                                        <OptionalDropDown
                                            text={"Select Pest"}
                                            id={pestId}
                                            handleChange={(e) => { setPestId(e.target.value) }}
                                            menuList={getPestDataResponse}
                                            required={true}
                                            onOpen = {getPestList}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Typography sx={{ marginBottom: 1 }}>
                                            Pesticide Code
                                        </Typography>
                                         <AutoCompleteMultiDropdown
                                            widht={230}
                                            menuList={getPesticidesTitleList}
                                            selectedOptions={pesticideId}
                                            onHighlightChange={(value) => { setPesticideId(value) }}
                                            label='Select Pesticide Code'
                                            required={true}
                                            onOpen={getPesticideTitleData}
                                        />
                                    </Grid>
                                    <Grid item xs={1}></Grid>
                                    <Grid item xs={12}>
                                        <Typography sx={{ marginBottom: 1 }}>Location*</Typography>
                                    </Grid>

                                    {Array.from({ length: addAnotherLocationDropDown }).map((_, index) =>
                                        handleAddAnotherLocationBox(index)
                                    )}
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography
                                    sx={{
                                        marginBottom: 4,
                                        color: "#2D6A4F",
                                        marginTop: 2,
                                        cursor: "pointer",
                                    }}
                                    onClick={(e) => handleChangeAddAnotherLocationBox(e)}
                                >
                                    + Add in another State/District/Taluka
                                </Typography>
                            </Grid>
                            <Grid
                        container
                        item
                        sm={12}
                        spacing={0}
                        sx={{ display: "flex", justifyContent: "center", marginTop: 2, marginBottom: 2 }}
                    >
                        <Grid
                            item
                            xs={12}
                            sm={2.5}
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                // justifyContent: "space-around",
                            }}
                            style={{ backgroundColor: "transparent", boxShadow: "none" }}
                        >
                            <CancelButtonStyle variant="contained" name="cancel" onClick={handleCloseByClearIcon}>
                                Cancel
                            </CancelButtonStyle>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={2.5}
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-around",
                            }}
                        >
                            <ButtonStyle
                                variant="contained"
                                name="add"
                                startIcon={<CheckIcon size="large" />}
                                onClick={handleSave}
                            >
                                Save
                            </ButtonStyle>
                        </Grid>
                        <Grid item xs={12}>
                            {addPartnerPesticideSnackBar && (
                            <SnackBars
                                open={addPartnerPesticideSnackBar}
                                handleClose={() => setAddPartnerPesticideSnackBar(false)}
                                severity={snackBarType}
                                message={addPartnerPesticideSnackBarMessage}
                                autoHideDuration={6000}
                                vertical="bottom"
                                horizontal="center"
                                style = {{ willChange: "transform" }}
                            />
                        )}
                        </Grid>
                    </Grid>
                    </ThemeProvider>
                    {loading && (
                        <Backdrop
                            sx={{
                                color: "#fff",
                                zIndex: (theme) => theme.zIndex.drawer + 1,
                            }}
                            open={loading}
                        >
                            <CircularProgress color="inherit" />
                        </Backdrop>
                    )}
                    
                </Grid>
            </Modal>
        </div>
    );
}
