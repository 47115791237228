export const GET_ROLES_AND_PERMISSIONS_API_RESPONSE = {
    "Partner Onboarding Module": {
        
    }
}
export const GET_EDIT_ROLES_AND_PERMISSIONS_API_RESPONSE = {
        "message": ""
}
export const ERROR_MESSAGE_API_RESPONSE = {
        "message": ""
}
