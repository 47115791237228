import React, { useState, useEffect } from "react";
import { Button, Grid, Typography, Modal, ThemeProvider } from "@mui/material";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { basicTheme } from "../../themes/customTheme";
import Backdrop from "@mui/material/Backdrop";
import LocationDropDown from "./supervisorLocation";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import { getLocationById, removeDuplicates } from "../../utils/index";
import { getStatesData } from "../../features/location/locationSlice";
import { getPartnerNames } from "../../features/partner/partnerSlice";
import {
  editSupervisor,
  resetEditSupervisor,
} from "../../features/supervisorAccess/supervisorAccessSlice";
import SnackBars from "../reusableComponents/SnackBar";
import AutoCompleteDropdown from "../reusableComponents/AutoCompleteDropDown";
import BasicDropDown from "../reusableComponents/BasicDropDown";
import BasicTextfield from "../reusableComponents/BasicTextfield";

const subGridStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
  width: "65%",
  maxHeight: "80%",
  overflow: "auto",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-around",
  paddingLeft: 5,
};

const useStyles = makeStyles(() => ({
  closeBtnStyle: {
    display: "flex",
    justifyContent: "flex-end",
    zoom: "2.2",
    alignItems: "right",
    "&:hover": {
      color: "#081C15",
    },
    cursor: "pointer",
  },
}));

const ButtonStyle = styled(Button)({
  backgroundColor: "#2D6A4F",
  color: "white",
  textTransform: "none",
  borderRadius: "8px",
  fontSize: "16px",
  fontWeight: "600",
  minWidth: "140px",
  maxWidth: "150px",
  height: "54px",
});

const CancelButtonStyle = styled(Button)({
  backgroundColor: "white",
  color: "#2D6A4F",
  textTransform: "none",
  borderRadius: "8px",
  fontSize: "16px",
  fontWeight: "600",
  minWidth: "140px",
  maxWidth: "150px",
  height: "54px",
});

export default function EditSupervisor({
  openModal = false,
  handleClose = () => {},
  data = [],
  setEditData = () => {},
  setSupervisorSnackBarMessage = () => {},
  setReload = () => {},
}) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [supervisorName, setSupervisorName] = useState(
    data.spoc_details.name || ""
  );
  const [supervisorNumber, setSupervisorNumber] = useState(
    data.spoc_details.phone_number || ""
  );
  const [supervisorEmail, setSupervisorEmail] = useState(
    data.spoc_details.email || ""
  );
  const [supervisorRole, setSupervisorRole] = useState(data.role || "");
  const [supervisorPartner, setSupervisorPartner] = useState(
    data.partner || null
  );
  const [partnerStates, setPartnerStates] = useState([]);
  const [partnerDistricts, setPartnerDistricts] = useState([]);
  const [partnerTalukas, setPartnerTalukas] = useState([]);
  const activeStatus = data.is_active
    ? "Active"
    : data.is_active === false
    ? "Inactive"
    : "";
  const [supervisorStatus, setSupervisorStatus] = useState(activeStatus || "");
  const [selectedStateOptions, setSelectedStateOptions] = useState(
    data.role === "State Head" ? data.location_data.data : []
  );
  const [selectedDistrictOptions, setSelectedDistrictOptions] = useState(
    data.role === "District Head" ? data.location_data.data : []
  );
  const [selectedTalukaOptions, setSelectedTalukaOptions] = useState(
    data.role === "Taluka Head" ? data.location_data.data : []
  );
  const [editSupervisorSnackBar, setEditSupervisorSnackBar] = useState(false);
  const [
    editSupervisorSnackBarMessage,
    setEditSupervisorSnackBarMessage,
  ] = useState("");

  const basicError = {
    supervisorName: "",
    email: "",
    phoneNumber: "",
    role: "",
  };
  const [snackBarType, setSnackBarType] = useState("success");
  const [errors, setErrors] = useState(basicError);

  const supervisorStatusList = ["Active", "Inactive"];
  const roleList = ["State Head", "District Head", "Taluka Head", "Government"];
  const [loading, setLoading] = useState(false);

  const loggedInUser = JSON.parse(window.localStorage.getItem("loggedInUser"));

  const editSupervisorApiResponse = useSelector(
    (state) => state.supervisorSliceReducer.editSupervisorApiResponse.message
  );
  const editSupervisorErrorMessage = useSelector(
    (state) => state.supervisorSliceReducer.editSupervisorErrorMessage.message
  );

  const editSupervisorIsSuccess = useSelector(
    (state) => state.supervisorSliceReducer.editSupervisorIsSuccess
  );

  const partnerNamesData = useSelector(
    (state) => state.partnerSliceReducer.partnerNames.data
  );

  useEffect(() => {
    dispatch(resetEditSupervisor());
    dispatch(getStatesData());
    setSupervisorSnackBarMessage("");
    if(partnerNamesData[0].id===""){
      setLoading(true);
      dispatch(
        getPartnerNames({
          fields: ["name", "id", "location"].toString(),
          filters: JSON.stringify([{ type: "is_active", value: [true] }]),
        })
      ).then(() => setLoading(false));
    }
  }, []);

  useEffect(() => {
    if (partnerNamesData[0].id !== "") {
      if (supervisorRole === "State Head") {
        var partnerLocation = partnerNamesData.find(
          (data) => data.id === supervisorPartner.id
        ).location.state_ids;
        partnerLocation = removeDuplicates(partnerLocation);
        getLocationById(partnerLocation).then((states) => {
          setPartnerStates(states.data);
        });
      } else if (supervisorRole === "District Head") {
        var partnerLocation = partnerNamesData.find(
          (data) => data.id === supervisorPartner.id
        ).location.district_ids;
        partnerLocation = removeDuplicates(partnerLocation);
        getLocationById(partnerLocation).then((districts) => {
          setPartnerDistricts(districts.data);
        });
      } else if (supervisorRole === "Taluka Head") {
        var partnerLocation = partnerNamesData.find(
          (data) => data.id === supervisorPartner.id
        ).location.taluka_ids;
        partnerLocation = removeDuplicates(partnerLocation);
        getLocationById(partnerLocation).then((talukas) => {
          setPartnerTalukas(talukas.data);
        });
      }
    }
  }, [partnerNamesData]);

  const handleChangeSupervisorName = (value) => {
    value = value.replace(/^\s+/g, "");
    const ALPHA_NUMERIC_DASH_REGEX = /^[a-zA-Z0-9\s]{1,}[\.]{0,1}[A-Za-z0-9\s]{0,}$/;
    if (value !== "" && !ALPHA_NUMERIC_DASH_REGEX.test(value)) value = "";
    if (value === "")
      setErrors({
        ...errors,
        supervisorName: "Supervisor Name can't be empty ",
      });
    else {
      setErrors({ ...errors, supervisorName: "" });
    }
    setSupervisorName(value);
  };

  const handleChangeSupervisorEmail = (value) => {
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (value.match(mailformat)) {
      setErrors({ ...errors, email: "" });
    } else {
      setErrors({ ...errors, email: "Invalid Email " });
    }
    setSupervisorEmail(value);
  };
  const handleChangeSupervisorStatus = (event) => {
    setSupervisorStatus(event.target.value);
  };
  const handleChangeSupervisorRole = (event) => {
    if (event.target.value !== (null || undefined)) {
      setSelectedStateOptions([]);
      setSelectedDistrictOptions([]);
      setSelectedTalukaOptions([]);
      setSupervisorRole(event.target.value);
      setSupervisorPartner("");
    }
  };
  const handleChangeSupervisorPartner = (value) => {
    setSelectedStateOptions([]);
    setSelectedDistrictOptions([]);
    setSelectedTalukaOptions([]);
    if (supervisorRole === "State Head") {
      var partnerLocation = partnerNamesData.find(
        (data) => data.id === value.id
      ).location.state_ids;
      partnerLocation = removeDuplicates(partnerLocation);
      getLocationById(partnerLocation).then((states) => {
        setPartnerStates(states.data);
      });
    } else if (supervisorRole === "District Head") {
      var partnerLocation = partnerNamesData.find(
        (data) => data.id === value.id
      ).location.district_ids;
      partnerLocation = removeDuplicates(partnerLocation);
      getLocationById(partnerLocation).then((districts) => {
        setPartnerDistricts(districts.data);
      });
    } else if (supervisorRole === "Taluka Head") {
      var partnerLocation = partnerNamesData.find(
        (data) => data.id === value.id
      ).location.taluka_ids;
      partnerLocation = removeDuplicates(partnerLocation);
      getLocationById(partnerLocation).then((talukas) => {
        setPartnerTalukas(talukas.data);
      });
    }
  };

  const handleChangeSupervisorNumber = (phoneNumber) => {
    phoneNumber = phoneNumber.trim();
    const reg = /^[1-9][0-9]*$/;
    if (phoneNumber === "") {
      setErrors({ ...errors, phoneNumber: "Phone number can't be empty " });
    } else if (!reg.test(phoneNumber)) {
      setErrors({
        ...errors,
        phoneNumber: "Phone number should not start with 0 ",
      });
    } else if (phoneNumber.length !== 10) {
      setErrors({ ...errors, phoneNumber: "Phone number should be 10 digits" });
    } else {
      setErrors({ ...errors, phoneNumber: "" });
    }
    setSupervisorNumber(phoneNumber);
  };

  const handleCloseByClearIcon = () => {
    setErrors(basicError);
    setSupervisorName("");
    setSupervisorNumber("");
    setSupervisorEmail("");
    setSupervisorRole("");
    setSupervisorStatus("");
    setEditSupervisorSnackBar(false);
    setSnackBarType("success");
    setEditSupervisorSnackBarMessage("");
    setSupervisorSnackBarMessage("");
    setSelectedStateOptions([]);
    setSelectedDistrictOptions([]);
    setSelectedTalukaOptions([]);
    dispatch(resetEditSupervisor());
    handleClose();
  };

  const setLocationForApi = () => {
    var locations = {
      state_ids: [],
      district_ids: [],
      taluka_ids: [],
      village_ids: [],
    };
    if (supervisorRole === "State Head") {
      selectedStateOptions.forEach((state) =>
        locations["state_ids"].push(state.id)
      );
    }
    if (supervisorRole === "District Head") {
      selectedDistrictOptions.forEach((district) =>
        locations["district_ids"].push(district.id)
      );
    }
    if (supervisorRole === "Taluka Head") {
      selectedTalukaOptions.forEach((taluka) =>
        locations["taluka_ids"].push(taluka.id)
      );
    }
    return locations;
  };

  const handleSaveData = () => {
    dispatch(resetEditSupervisor());
    var locations = setLocationForApi();
    if (
      errors.supervisorName === "" &&
      errors.email === "" &&
      errors.phoneNumber === "" &&
      supervisorName !== "" &&
      supervisorNumber !== "" &&
      supervisorEmail !== "" &&
      ((supervisorRole !== "" && supervisorPartner !== null) ||
        supervisorRole === "Government") &&
      supervisorStatus !== "" &&
      ((supervisorRole === "State Head" && locations.state_ids.length > 0) ||
        (supervisorRole === "District Head" &&
          locations.district_ids.length > 0) ||
        (supervisorRole === "Taluka Head" && locations.taluka_ids.length > 0) ||
        (supervisorRole === "Government" && locations.state_ids.length === 0))
    ) {
      setEditSupervisorSnackBar(false);
      setSnackBarType("success");
      setEditSupervisorSnackBarMessage("");
      var isActive = supervisorStatus === "Active" ? true : false;
      setLoading(true);
      var params = {
        id: data.id,
        name: supervisorName,
        email: supervisorEmail,
        phone_number: supervisorNumber,
        role: supervisorRole,
        location: locations,
        is_active: isActive,
      };
      if (supervisorRole !== "Government") {
        params.partner = supervisorPartner.id;
      }
      dispatch(editSupervisor(params)).then(() => {
        setLoading(false);
      });
    } else {
      setEditSupervisorSnackBar(true);
      setSnackBarType("error");
      setEditSupervisorSnackBarMessage("Please Fill all required values");
    }
  };

  useEffect(() => {
    if (editSupervisorIsSuccess && editSupervisorApiResponse !== "") {
      setSupervisorSnackBarMessage(editSupervisorApiResponse);
      setReload(true);
      // setSnackBarType("success");
      handleClose();
      dispatch(resetEditSupervisor());
    } else if (!editSupervisorIsSuccess && editSupervisorErrorMessage !== "") {
      setEditSupervisorSnackBar(true);
      setSnackBarType("error");
      setEditSupervisorSnackBarMessage(editSupervisorErrorMessage);
    }
  }, [
    editSupervisorIsSuccess,
    editSupervisorErrorMessage,
    editSupervisorApiResponse,
  ]);

  return (
    <Modal
      open={openModal}
    >
      <Grid container sx={subGridStyle} spacing={1}>
        <ThemeProvider theme={basicTheme}>
            <Grid item xs={11}>
              <Typography variant="h4" style={{ marginBottom: 10 }}>
                Edit Supervisor
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <ClearIcon
                className={classes.closeBtnStyle}
                onClick={handleCloseByClearIcon}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ marginBottom: 1 }}>
                    Supervisor Name
                  </Typography>
                  <BasicTextfield
                    label="Enter Supervisor Name"
                    is_phone={false}
                    handleChange={handleChangeSupervisorName}
                    is_disabled={false}
                    index={1}
                    error={errors.supervisorName !== ""}
                    helperText={errors.supervisorName}
                    required={true}
                    value={supervisorName}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography sx={{ marginBottom: 1 }}>
                    Supervisor Mobile Number
                  </Typography>
                  <BasicTextfield
                    label="Enter Supervisor Mobile Number"
                    is_phone={true}
                    handleChange={handleChangeSupervisorNumber}
                    is_disabled={false}
                    index={3}
                    error={errors.phoneNumber !== ""}
                    helperText={errors.phoneNumber}
                    required={true}
                    value={supervisorNumber}
                    number={true}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ marginBottom: 1 }}>
                    Supervisor Email Id
                  </Typography>
                  <BasicTextfield
                    label="Enter Supervisor Email Id"
                    is_phone={false}
                    handleChange={handleChangeSupervisorEmail}
                    is_disabled={false}
                    index={4}
                    error={errors.email !== ""}
                    helperText={errors.email}
                    required={true}
                    value={supervisorEmail}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ marginBottom: 1 }}>
                    Supervisor Status
                  </Typography>
                  <BasicDropDown
                    text="Select Supervisor Status"
                    value={supervisorStatus}
                    handleChange={handleChangeSupervisorStatus}
                    menuList={supervisorStatusList}
                    required={true}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ marginBottom: 1 }}>Role</Typography>
                  <BasicDropDown
                    text="Select Role"
                    value={supervisorRole}
                    handleChange={handleChangeSupervisorRole}
                    menuList={roleList}
                    required={true}
                    disable={loggedInUser.details.role !== "Wadhwani Admin"}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography
                    sx={{
                      marginBottom: 1,
                      color:
                        (supervisorRole === "Government" ||
                          supervisorRole === "") &&
                        "#ACADAD",
                    }}
                  >
                    Partner
                  </Typography>
                  <AutoCompleteDropdown
                    width="auto"
                    menuList={partnerNamesData}
                    selectedOptions={
                      partnerNamesData[0].id !== "" ? supervisorPartner : null
                    }
                    setSelectedOptions={setSupervisorPartner}
                    disable={
                      partnerNamesData[0].id === "" ||
                      supervisorRole === "" ||
                      supervisorRole === "Government" || 
                      loggedInUser.details.role !== "Wadhwani Admin"
                    }
                    label="Select Partner"
                    onHighlightChange={handleChangeSupervisorPartner}
                    required={true}
                  />
                </Grid>
                {supervisorRole !== "" &&
                  supervisorPartner !== null &&
                  supervisorRole !== "Government" && (
                    <React.Fragment>
                      <Grid item xs={12}>
                        <Typography sx={{ marginBottom: 1 }}>
                          Location{" "}
                        </Typography>
                      </Grid>
                      <LocationDropDown
                        role={supervisorRole}
                        menuList={
                          supervisorRole === "State Head"
                            ? partnerStates
                            : supervisorRole === "District Head"
                            ? partnerDistricts
                            : supervisorRole === "Taluka Head"
                            ? partnerTalukas
                            : []
                        }
                        selectedStateOptions={
                          partnerStates.length > 0 ? selectedStateOptions : []
                        }
                        setSelectedStateOptions={setSelectedStateOptions}
                        selectedDistrictOptions={
                          partnerDistricts.length > 0
                            ? selectedDistrictOptions
                            : []
                        }
                        setSelectedDistrictOptions={setSelectedDistrictOptions}
                        selectedTalukaOptions={
                          partnerTalukas.length > 0 ? selectedTalukaOptions : []
                        }
                        setSelectedTalukaOptions={setSelectedTalukaOptions}
                      />
                    </React.Fragment>
                  )}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                item
                sm={12}
                spacing={0}
                sx={{
                  direction: "row",
                  justifyContent: "center",
                  marginTop: 5,
                  marginBottom: 5,
                }}
              >
                <Grid
                  item
                  xs={12}
                  sm={2.5}
                  style={{ backgroundColor: "transparent", boxShadow: "none" }}
                >
                  <CancelButtonStyle
                    variant="contained"
                    name="cancel"
                    onClick={handleClose}
                  >
                    Cancel
                  </CancelButtonStyle>
                </Grid>
                <Grid item xs={12} sm={2.5}>
                  <ButtonStyle
                    variant="contained"
                    name="add"
                    startIcon={<CheckIcon size="large" />}
                    onClick={handleSaveData}
                  >
                    Save
                  </ButtonStyle>
                </Grid>
              </Grid>
            </Grid>
        </ThemeProvider>
        {loading && (
          <Backdrop
            sx={{
              color: "#fff",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}

        {editSupervisorSnackBar && (
          <SnackBars
            open={editSupervisorSnackBar}
            handleClose={() => setEditSupervisorSnackBar(false)}
            severity={snackBarType}
            message={editSupervisorSnackBarMessage}
            autoHideDuration={6000}
            vertical="bottom"
            horizontal="center"
          />
        )}
      </Grid>
    </Modal>
  );
}
