import * as React from "react";
import {
    Grid,
    Typography,
} from "@mui/material";
import { displayEditTextField } from "../../utils/index";
import { useState } from "react";
import BasicTextfield from "../reusableComponents/BasicTextfield";

export default function LanguageAndTranslation({ label, onChange, initialData = null }) {
    const [Trans, setTrans] = useState(initialData ? initialData[label] : {})
    const [PesticideName, setPesticideName] = useState(initialData ? initialData[label]['Pesticide Name'] : "")
    // const [price, setPrice] = useState(initialData ? initialData[label].Price : "")
    const [Dosage, setDosage] = useState(initialData ? initialData[label].Dosage : "")
    const [Description, setDescription] = useState(initialData ? initialData[label].Description : "")

    const handlePesticideNameChange = (value) => {
        const newTrans = { ...Trans }
        newTrans["Pesticide Name"] = value
        setTrans(newTrans)
        onChange(newTrans, label)
        setPesticideName(value)
    }
    // const handlePriceChange = (value) => {
    //     const newTrans = { ...Trans }
    //     newTrans["Price"] = value
    //     setTrans(newTrans)
    //     onChange(newTrans, label)
    //     setPrice(value)
    // }
    const handleDosageChange = (value) => {
        const newTrans = { ...Trans }
        newTrans["Dosage"] = value
        setTrans(newTrans)
        onChange(newTrans, label)
        setDosage(value)
    }
    const handleDescriptionChange = (value) => {
        const newTrans = { ...Trans }
        newTrans["Description"] = value
        setTrans(newTrans)
        onChange(newTrans, label)
        setDescription(value)
    }

    return (
        <Grid container spacing={2} sx={{ marginBottom: '20px', fontWeight: '600' }}>
            <Grid item xs={12} sm={12}> {label} </Grid>
            <Grid container item sm={12} spacing={1}>
                <Grid item xs={12} sm={6}>
                    <Typography sx={{ marginBottom: 1 }}>Pesticide Name</Typography>
                    <BasicTextfield
                        label="Write translation here..."
                        handleChange={handlePesticideNameChange}
                        index={null}
                        value={PesticideName}
                    />
                </Grid>
                {/* <Grid item xs={12} sm={4}>
                    <Typography sx={{ marginBottom: 1 }}>Price</Typography>
                    <BasicTextfield
                        label="Write translation here..."
                        handleChange={handlePriceChange}
                        index={null}
                        value={price}
                    />
                </Grid> */}
                <Grid item xs={12} sm={6}>
                    <Typography sx={{ marginBottom: 1 }}>Dosage</Typography>
                    <BasicTextfield
                        label="Write translation here..."
                        handleChange={handleDosageChange}
                        index={null}
                        value={Dosage}
                    />
                </Grid>
                <Grid container item xs={12} sm={12} spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <Typography sx={{ marginBottom: 1 }}>Description</Typography>
                        <BasicTextfield
                            label="Write translation here..."
                            handleChange={handleDescriptionChange}
                            index={null}
                            value={Description}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}