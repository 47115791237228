import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteLocation,
  resetDeletelocation,
} from "../../features/location/locationSlice";
import { styled } from "@mui/material/styles";
import SnackBars from "../reusableComponents/SnackBar";
const Centerstyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const closeIconStyle = {
  position: "absolute",
  right: 1,
  cursor: "pointer",
  color: (theme) => theme.palette.grey[600],
};
const DeleteButtonStyle = styled(Button)({
  backgroundColor: "#2D6A4F",
  color: "white",
  textTransform: "none",
  borderRadius: "8px",
  fontSize: "18px",
  fontWeight: "600",
  minWidth: "120px",
  maxWidth: "150px",
  marginLeft: 20,
});

const CancelButtonStyle = styled(Button)({
  backgroundColor: "white",
  color: "#2D6A4F",
  textTransform: "none",
  borderRadius: "8px",
  fontSize: "18px",
  fontWeight: "600",
  minWidth: "120px",
  maxWidth: "150px",
  marginRight: 20,
});

export default function DeleteLocation({
  handleClose = () => {},
  open,
  deleteData = {},
  setSnackBarMessage = () => {},
  setReload = () => {},
  setOpenAlertModal = () => {},
}) {
  const dispatch = useDispatch();

  const deleteLocationApiResponse = useSelector(
    (state) => state.locationSliceReducer.deleteLocationApiResponse.message
  );

  const deleteLocationErrorMessage = useSelector(
    (state) => state.locationSliceReducer.deleteLocationErrorMessage.message
  );

  const deleteLocationIsSuccess = useSelector(
    (state) => state.locationSliceReducer.deleteLocationIsSuccess
  );

  useEffect(() => {
    setSnackBarMessage(deleteLocationApiResponse);
  }, [deleteLocationApiResponse]);

  useEffect(() => {
    dispatch(resetDeletelocation());
  }, []);

  useEffect(() => {
    if (deleteLocationIsSuccess && deleteLocationApiResponse !== "") {
      setSnackBarMessage(deleteLocationApiResponse);
      setReload(true);
      handleClose();
      dispatch(resetDeletelocation());
    } else if (!deleteLocationIsSuccess && deleteLocationErrorMessage !== "") {
      setOpenAlertModal(true);
      handleClose();
      dispatch(resetDeletelocation());
    }
  }, [
    deleteLocationIsSuccess,
    deleteLocationErrorMessage,
    deleteLocationApiResponse,
  ]);

  const handleDelete = () => {
    dispatch(
      deleteLocation({
        id: deleteData.id,
        is_deleted: true,
      })
    );
  };

  return (
    <Dialog open={open}>
      <HighlightOffRoundedIcon sx={closeIconStyle} onClick={handleClose} />
      <DialogTitle sx={{ marginTop: 2 }}>
        Are you sure you want to delete this village?
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" sx={Centerstyle}>
          {deleteData.name}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={Centerstyle}>
        <CancelButtonStyle
          variant="contained"
          onClick={handleClose}
          sx={{ marginRight: 5 }}
        >
          No
        </CancelButtonStyle>
        <DeleteButtonStyle
          variant="contained"
          sx={{ background: "#EB5451", marginLeft: 5 }}
          onClick={handleDelete}
          autoFocus
          disabled={deleteLocationErrorMessage !== ""}
        >
          YES, Delete
        </DeleteButtonStyle>
      </DialogActions>
    </Dialog>
  );
}
