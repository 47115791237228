// import * as React from "react";
import React, { useState, useEffect } from "react";
import {
    Button,
    Grid,
    Typography,
    Modal,
    ThemeProvider,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { basicTheme } from "../../themes/customTheme";
import LanguageAndTranslation from "./languageAndTranslation";
import { useDispatch, useSelector } from "react-redux";
import { getPestData, getCropData, getFarmingTypeData, editPesticide, resetEditPesticide } from "../../features/advisoryManagement/advisoryManagementSlice";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import SnackBars from "../reusableComponents/SnackBar";
import BasicTextfield from "../reusableComponents/BasicTextfield";
import OptionalDropDown from "../reusableComponents/OptionalDropDown";

const subGridStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 2,
    width: "65%",
    maxHeight: "80%",
    overflow: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    paddingLeft: 5,
  };

const useStyles = makeStyles(() => ({
    closeBtnStyle: {
        display: "flex",
        justifyContent: "flex-end",
        zoom: "2.2",
        alignItems: "right",
        "&:hover": {
            color: "#081C15",
        },
        cursor: "pointer",
    },
}));

const ButtonStyle = styled(Button)({
    backgroundColor: "#2D6A4F",
    color: "white",
    textTransform: "none",
    borderRadius: "8px",
    fontSize: "16px",
    fontWeight: "600",
    minWidth: "140px",
    maxWidth: "150px",
    height: "54px",
});

const CancelButtonStyle = styled(Button)({
    backgroundColor: "white",
    color: "#2D6A4F",
    textTransform: "none",
    borderRadius: "8px",
    fontSize: "16px",
    fontWeight: "600",
    minWidth: "140px",
    maxWidth: "150px",
    height: "54px",
});

export default function EditPesticide({
    openAddModal,
    handleClose,
    data = {},
    setSnackBarMessage = () => { },
    setReload = () => { },
}) {

    let initialTranslationState = {
        Hindi: {
            "Description": "",
            "Dosage": "",
            "Pesticide Name": ""
            // "Price": ""
        },
        Gujarati: {
            "Description": "",
            "Dosage": "",
            "Pesticide Name": "",
            // "Price": ""
        },
        Marathi: {
            "Description": "",
            "Dosage": "",
            "Pesticide Name": "",
            // "Price": ""
        },
        Tamil: {
            "Description": "",
            "Dosage": "",
            "Pesticide Name": "",
            // "Price": ""
        },
        Telugu: {
            "Description": "",
            "Dosage": "",
            "Pesticide Name": "",
            // "Price": ""
        },
        Kannada: {
            "Description": "",
            "Dosage": "",
            "Pesticide Name": "",
            // "Price": ""
        },
        Punjabi: {
            "Description": "",
            "Dosage": "",
            "Pesticide Name": "",
            // "Price": ""
        },
        Odia: {
            "Description": "",
            "Dosage": "",
            "Pesticide Name": "",
            // "Price": ""
        }
    }
    const dispatch = useDispatch();
    const classes = useStyles();
    const [pesticideCode, setPesticideCode] = React.useState(data.code || "");
    const [pesticideName, setPesticideName] = React.useState(data.title || "");
    // const [price, setPrice] = React.useState(data.price || "");
    const [pestId, setPestId] = React.useState(data.pest_code || "");
    const [cropId, setCropId] = React.useState(data.crop || "");
    const [dosage, setDosage] = React.useState(data.dosage || "");
    const activeStatus = data.is_active ? "Active" : "Inactive";
    const [isActive, setIsActive] = React.useState(activeStatus);
    const [description, setDescription] = React.useState(data.description || "");
    const [farmingTypeId, setFarmingTypeId] = React.useState(data.farming_type || "");
    const [translations, setTranslations] = React.useState(initialTranslationState);
    const [editPesticideSnackBar, setEditPesticideSnackBar] = useState(false);
    const [editPesticideSnackBarMessage, setEditPesticideSnackBarMessage] = useState("");
    const [snackBarType, setSnackBarType] = useState("success");
    const basicError = {
        pesticideCode: "",
        pesticideName: "",
        // price: "",
        quantity: "",
        pestId: "",
        cropId: "",
        dosage: "",
        description: "",
        farmingTypeId: "",
        isActive: "",
    }
    const isActiveList = [
        {name: 'Active'},
        {name: 'Inactive'}
    ]
    const [errors, setErrors] = useState(basicError);
    const [loading, setLoading] = useState(false)

    const handleChangePesticideCode = (value) => {
        value = value.replace(/^\s+/g, "");
        if (value === "")
            setErrors({ ...errors, pesticideCode: "Pesticide Code is required" })
        else {
            setErrors({ ...errors, pesticideCode: "" })
        }
        setPesticideCode(value);
    };
    const handleChangePesticideName = (value) => {
        value = value.replace(/^\s+/g, "");
        if (value === "")
            setErrors({ ...errors, pesticideName: "Pesticide Name is required" })
        else if (value.length > 200)
            setErrors({ ...errors, pesticideName: "Pesticide Name should not be more than 200 characters" })
        else {
            setErrors({ ...errors, pesticideName: "" })
        }
        setPesticideName(value);
    };
    // const handleChangePrice = (value) => {
    //     if (value === "")
    //         setErrors({ ...errors, price: "Price is required" })
    //     else if (value.length > 100)
    //         setErrors({ ...errors, price: "Price should not be more than 100 characters" })
    //     else {
    //         setErrors({ ...errors, price: "" })
    //     }
    //     setPrice(value);
    // };
    const handleChangeDosage = (value) => {
        value = value.replace(/^\s+/g, "");
        if (value === "")
            setErrors({ ...errors, dosage: "Dosage is required" })
        else if (value.length > 200)
            setErrors({ ...errors, dosage: "Dosage should not be more than 200 characters" })
        else {
            setErrors({ ...errors, dosage: "" })
        }
        setDosage(value);
    };
    const handleChangeDescription = (value) => {
        value = value.replace(/^\s+/g, "");
        if (value === "")
            setErrors({ ...errors, description: "Description is required" })
        else if (value.length > 500)
            setErrors({ ...errors, description: "Description should not be more than 500 characters" })
        else {
            setErrors({ ...errors, description: "" })
        }
        setDescription(value);
    };
    const handleChangePest = (event) => {
        setPestId(event.target.value);
    };

    const handleChangeCrop = (event) => {
        setCropId(event.target.value);
    };

    const handleChangeFarmingType = (event) => {
        setFarmingTypeId(event.target.value);
    };
    const handleChangeIsActive = (event) => {
        setIsActive(event.target.value);
    };

    const handleChangeTranslation = (event, key) => {
        setTranslations((prevState) => ({
            ...prevState,
            [key]: event
        }));
    }

    const translationDataFomatter = (data) => {
        const languageList = ["hi", "mr", "gu", "ta", "te", "kn", "pa", "or"]
        const languageCodeMap = {
            "hi": "Hindi",
            "mr": "Marathi",
            "gu": "Gujarati",
            "ta": "Tamil",
            "te": "Telugu",
            "kn": "Kannada",
            "pa": "Punjabi",
            "or": "Odia"
        }
        let languageData = translations
        languageList.forEach((language) => {
            languageData[languageCodeMap[language]] = {}
            data.title_translations.forEach((title) => {
                if (title.language === language) {
                    languageData[languageCodeMap[language]]["Pesticide Name"] = title.translation
                }
            })
            // data.price_translations.forEach((price) => {
            //     if (price.language === language) {
            //         languageData[languageCodeMap[language]]["Price"] = price.translation
            //     }
            // })
            data.dosage_translations.forEach((dosage) => {
                if (dosage.language === language) {
                    languageData[languageCodeMap[language]]["Dosage"] = dosage.translation
                }
            })
            data.description_translations.forEach((description) => {
                if (description.language === language) {
                    languageData[languageCodeMap[language]]["Description"] = description.translation
                }
            })
        })
        setTranslations(languageData)
    }

    const editPesticideData = useSelector(
        (state) => state.pesticidesSliceReducer.editPesticideData
    );
    const editPesticideDataErrorMessage = useSelector(
        (state) => state.pesticidesSliceReducer.editPesticideDataErrorMessage
    );
    const editPesticideDataIsSuccess = useSelector(
        (state) => state.pesticidesSliceReducer.editPesticideDataIsSuccess
    );
    const getPestDataResponse = useSelector(
        (state) => state.pesticidesSliceReducer.pestData
    );
    const getCropDataResponse = useSelector(
        (state) => state.pesticidesSliceReducer.cropData
    );
    const getFarmingTypeDataResponse = useSelector(
        (state) => state.pesticidesSliceReducer.farmingTypeData
    );


    const handleSave = () => {
        if (
            pesticideCode !== "" &&
            pesticideName !== "" &&
            // price !== "" &&
            pestId !== "" &&
            cropId !== "" &&
            dosage !== "" &&
            description !== "" &&
            farmingTypeId !== "" &&
            isActive !== ""
        ) {
            setEditPesticideSnackBar(false);
            setSnackBarType("success");
            setEditPesticideSnackBarMessage("");
            var is_active = isActive === "Active" ? true : false;
            setLoading(true);
            const updated_data = {
                code: pesticideCode,
                title: pesticideName,
                // price: price,
                pest_code: pestId,
                crop: cropId,
                dosage: dosage,
                description: description,
                farming_type: farmingTypeId,
                translations: translations,
                is_active: is_active
            }
            dispatch(editPesticide(updated_data)).then(() => {
                setLoading(false);
            });
        }
        else {
            setEditPesticideSnackBar(true);
            setSnackBarType("error");
            setEditPesticideSnackBarMessage("Please fill all the fields");
        }
    }

    //call translationDataFomatter
    useEffect(() => {
        if (data) {
            translationDataFomatter(data)
        }
    }, [data])

    useEffect(() => {
        dispatch(resetEditPesticide());
        if(getFarmingTypeDataResponse[0].name==="")
            dispatch(getFarmingTypeData({ fields: "name" }))
        if(getCropDataResponse[0].name==="")
            dispatch(getCropData({ fields: "name" }))
        if(getPestDataResponse[0].code===""){
            setLoading(true);
            dispatch(getPestData({ fields: "code,name" })).then(() => setLoading(false));
        }      
    }, [])

    useEffect(() => {
        if (editPesticideDataIsSuccess && editPesticideData.message !== "") {
            setSnackBarMessage(editPesticideData.message);
            setReload(true);
            setSnackBarType("success");
            handleClose();
            dispatch(resetEditPesticide());
        }
        else if (!editPesticideDataIsSuccess && editPesticideDataErrorMessage.message !== "") {
            setEditPesticideSnackBar(true);
            setSnackBarType("error");
            setEditPesticideSnackBarMessage(editPesticideDataErrorMessage.message);
        }
    }, [editPesticideDataIsSuccess, editPesticideDataErrorMessage, editPesticideData])

    const handleCloseByClearIcon = () => {
        setPesticideCode("");
        setPesticideName("");
        // setPrice("");
        setPestId("");
        setCropId("");
        setIsActive("");
        setFarmingTypeId("");
        setDosage("");
        setDescription("");
        setTranslations(initialTranslationState);
        setSnackBarMessage("");
        setEditPesticideSnackBar(false);
        setEditPesticideSnackBarMessage("");
        setSnackBarType("success");
        setErrors(basicError);
        dispatch(resetEditPesticide());
        handleClose();
    }

    return (
        <Modal
            open={openAddModal}
        >
            <Grid container sx={subGridStyle} spacing={1}>
                <ThemeProvider theme={basicTheme}>
                        <Grid item xs={11}>
                            <Typography variant="h4" style={{ marginBottom: 10 }}>
                                Edit Pesticide
                            </Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <ClearIcon
                                className={classes.closeBtnStyle}
                                onClick={handleClose}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <Typography sx={{ marginBottom: 1 }}>Pesticide Code</Typography>
                                    <BasicTextfield
                                        label="Enter Pesticide Code"
                                        handleChange={handleChangePesticideCode}
                                        index={null}
                                        error={errors.pesticideCode !== ""}
                                        helperText={errors.pesticideCode}
                                        required={true}
                                        value={pesticideCode}
                                        is_disabled ={true}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography sx={{ marginBottom: 1 }}>
                                        Pesticide Name
                                    </Typography>
                                    <BasicTextfield
                                        label="Enter Pesticide Name"
                                        handleChange={handleChangePesticideName}
                                        index={null}
                                        error={errors.pesticideName !== ""}
                                        helperText={errors.pesticideName}
                                        required={true}
                                        value={pesticideName}
                                    />
                                </Grid>
                                {/* <Grid item xs={12} sm={6}>
                                    <Typography sx={{ marginBottom: 1 }}>
                                        Price
                                    </Typography>
                                    <BasicTextfield
                                        label="Enter Price"
                                        handleChange={handleChangePrice}
                                        index={null}
                                        error={errors.price !== ""}
                                        helperText={errors.price}
                                        required={true}
                                        value={price}
                                    />
                                </Grid> */}
                                <Grid item xs={12} sm={6}>
                                    <Typography sx={{ marginBottom: 1 }}>Pest Name*</Typography>
                                    <OptionalDropDown
                                        text={"Select Pest Name"}
                                        id={getPestDataResponse[0].code!==""?pestId:""}
                                        handleChange={handleChangePest}
                                        menuList={getPestDataResponse}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography sx={{ marginBottom: 1 }}>Crop Name*</Typography>
                                    <OptionalDropDown
                                        text={"Select Crop Name"}
                                        id={getCropDataResponse[0].name==="" ? "": cropId}
                                        handleChange={handleChangeCrop}
                                        menuList={getCropDataResponse}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography sx={{ marginBottom: 1 }}>Dosage</Typography>
                                    <BasicTextfield
                                        label="Enter Dosage"
                                        handleChange={handleChangeDosage}
                                        index={null}
                                        error={errors.dosage !== ""}
                                        helperText={errors.dosage}
                                        required={true}
                                        value={dosage}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography sx={{ marginBottom: 1 }}>Description</Typography>
                                    <BasicTextfield
                                        label="Write Description here..."
                                        handleChange={handleChangeDescription}
                                        index={null}
                                        error={errors.description !== ""}
                                        helperText={errors.description}
                                        required={true}
                                        value={description}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography sx={{ marginBottom: 1 }}>Farming Type*</Typography>
                                    <OptionalDropDown
                                        text={"Select Farming Type"}
                                        id={getFarmingTypeDataResponse[0].name==="" ? "": farmingTypeId}
                                        handleChange={handleChangeFarmingType}
                                        menuList={getFarmingTypeDataResponse}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography sx={{ marginBottom: 1 }}>Pesticide Status*</Typography>
                                    <OptionalDropDown
                                        text={"Select Pesticide Status"}
                                        id={isActive}
                                        handleChange={handleChangeIsActive}
                                        menuList={isActiveList}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography sx={{ marginBottom: 1 }}>Language & Translations* </Typography>
                                </Grid>
                                <Grid item></Grid>
                                <Grid
                                    item
                                    xs={11}
                                    sx={{
                                        border: 1,
                                        borderColor: "#D3D3D3",
                                        borderRadius: "10px",
                                        marginBottom: 2,
                                    }}
                                >
                                    <LanguageAndTranslation label='Hindi' onChange={handleChangeTranslation} initialData={translations} />
                                    <LanguageAndTranslation label={'Gujarati'} onChange={handleChangeTranslation} initialData={translations} />
                                    <LanguageAndTranslation label={'Marathi'} onChange={handleChangeTranslation} initialData={translations} />
                                    <LanguageAndTranslation label={'Tamil'} onChange={handleChangeTranslation} initialData={translations} />
                                    <LanguageAndTranslation label={'Telugu'} onChange={handleChangeTranslation} initialData={translations} />
                                    <LanguageAndTranslation label={'Kannada'} onChange={handleChangeTranslation} initialData={translations} />
                                    <LanguageAndTranslation label={'Punjabi'} onChange={handleChangeTranslation} initialData={translations} />
                                    <LanguageAndTranslation label={'Odia'} onChange={handleChangeTranslation} initialData={translations} />

                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            item
                            sm={12}
                            spacing={0}
                            sx={{ display: "flex", justifyContent: "center", marginTop: 2, marginBottom: 2 }}
                        >
                            <Grid
                                item
                                xs={12}
                                sm={2.5}
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    // justifyContent: "space-around",
                                }}
                                style={{ backgroundColor: "transparent", boxShadow: "none" }}
                            >
                                <CancelButtonStyle variant="contained" name="cancel" onClick={handleCloseByClearIcon}>
                                    Cancel
                                </CancelButtonStyle>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={2.5}
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-around",
                                }}
                            >
                                <ButtonStyle
                                    variant="contained"
                                    name="add"
                                    startIcon={<CheckIcon size="large" />}
                                    onClick={handleSave}
                                >
                                    Save
                                </ButtonStyle>
                            </Grid>
                            <Grid item xs={12}>
                            {editPesticideSnackBar && (
                                <SnackBars
                                    open={editPesticideSnackBar}
                                    handleClose={() => setEditPesticideSnackBar(false)}
                                    severity={snackBarType}
                                    message={editPesticideSnackBarMessage}
                                    autoHideDuration={6000}
                                    vertical="bottom"
                                    horizontal="center"
                                    style = {{ willChange: "transform" }}
                                />
                             )}
                            </Grid>
                        </Grid>
                </ThemeProvider>
                {loading && (
                    <Backdrop
                        sx={{
                            color: "#fff",
                            zIndex: (theme) => theme.zIndex.drawer + 1,
                        }}
                        open={loading}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                )}
            </Grid>
        </Modal>
    );
}
