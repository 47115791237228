export const GET_PARTNER_COUNT_API_RESPONSE = {
  count: -1,
};

export const GET_COUNT_API_RESPONSE = {
  count: -1,
};

export const GET_PARTNER_BASED_USER_COUNT = {
    name: "",
    lf: -1,
    ew: -1,
    cf: -1

};
export const ERROR_MESSAGE_API_RESPONSE = {
  message: "",
};

export const GET_TOP_COUNTS_API_RESPONSE = [
  {
      "name": "",
      "count": -1,
  }
];

export const GET_SESSION_DATA_API_RESPONSE = {
  count:-1,
  data: [
    {
        "village_name": "",
        "created_at": "",
        "session_status": "",
        "alert_type": "",
        "name": ""
    },
  ]
};

export const GET_APPINSTALL_DATA_API_RESPONSE = {
    count: -1
}

export const GET_APPUSAGE_COUNT_DATA = {
  count: -1,
  data: [
      {
          "name": "",
          "phone_number": "",
          "location": "",
          "auth_user_id": "",
          "user_type": "",
          "partner": ""
      }
    ]
}

export const GET_ALERTS_DATA_API_RESPONSE = {
  count:-1,
  data: [ 
    {
      "village_name": "",
        "created_at": "",
        "alert_type": "",
        "name": "",
        "result_generated_by": ""
    }
  ]
}
