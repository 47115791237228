import React, { useState, useEffect } from "react";
import {
    Button,
    Grid,
    Typography,
    Modal,
    ThemeProvider,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { basicTheme } from "../../themes/customTheme";
import LocationDropDown from "../../components/advisoryManagement/partnerPesticideLocationDropdown";
import { removeDuplicates } from "../../utils/index";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import _without from "lodash/without";
import { useDispatch, useSelector } from "react-redux";
import { getPesticideTitleList, getSeasonData, getFarmingTypeData, getCropData, getAlertData, getPestData, editPartnerPesticideMapping, resetEditPartnerPesticide } from "../../features/advisoryManagement/advisoryManagementSlice";
import {
    getStatesData,
} from "../../features/location/locationSlice";
import { getPartnerNames } from "../../features/partner/partnerSlice";
import SnackBars from "../reusableComponents/SnackBar";
import BasicTextfield from "../reusableComponents/BasicTextfield";
import DisplayMultiSelectDropdown from "../reusableComponents/DisplayMultiSelectDropdown";
import OptionalDropDown from "../reusableComponents/OptionalDropDown";
import AutoCompleteMultiDropdown from "../../components/advisoryManagement/AutoCompleteMultiDropDown";
import { defaultAdvisoryPartnerId } from "../../api's/index"

const subGridStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 2,
    width: "65%",
    maxHeight: "80%",
    overflow: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    paddingLeft: 5,
  };

const useStyles = makeStyles(() => ({
    closeBtnStyle: {
        display: "flex",
        justifyContent: "flex-end",
        zoom: "2.2",
        alignItems: "right",
        "&:hover": {
            color: "#081C15",
        },
        cursor: "pointer",
    },
}));

const ButtonStyle = styled(Button)({
    backgroundColor: "#2D6A4F",
    color: "white",
    textTransform: "none",
    borderRadius: "8px",
    fontSize: "16px",
    fontWeight: "600",
    minWidth: "140px",
    maxWidth: "150px",
    height: "54px",
});

const CancelButtonStyle = styled(Button)({
    backgroundColor: "white",
    color: "#2D6A4F",
    textTransform: "none",
    borderRadius: "8px",
    fontSize: "16px",
    fontWeight: "600",
    minWidth: "140px",
    maxWidth: "150px",
    height: "54px",
});

export default function EditAssignPesticide({
    openAddModal,
    handleClose,
    data = {},
    setEditData = () => { },
    setSnackBarMessage = () => { },
    setReload = () => { },
}) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [minSowingDate, setMinSowingDate] = useState(data.min_days_after_sowing || 0);
    const [maxSowingDate, setMaxSowingDate] = useState(data.max_days_after_sowing || 0);
    const [partnerId, setPartnerId] = React.useState(data.partner != null && data.partner.id != null ? data.partner.id : "" || "");
    const [seasonId, setSeasonId] = React.useState(data.season || "");
    const [farmingTypeId, setFarmingTypeId] = React.useState(data.farming_type || "");
    const [cropId, setCropId] = React.useState(data.crop || "");
    const [alertTypeId, setAlertTypeId] = React.useState(data.alert || "");
    const [pestId, setPestId] = React.useState(data.pest || "");
    const [pesticideId, setPesticideId] = React.useState(data.pesticides.map((item) => {
        return { code: item };
    }) || []);
    const [partnerLocations, setPartnerLocations] = useState([]);
    const [addAnotherLocationDropDown, setAddAnotherLocationDropDown] = useState(
        data.formatted_location ? data.formatted_location.length : 1
    );
    const [editPartnerPesticideSnackBar, setEditPartnerPesticideSnackBar] = useState(false);
    const [editPartnerPesticideSnackBarMessage, setEditPartnerPesticideSnackBarMessage] = useState("");
    const [snackBarType, setSnackBarType] = useState("success");
    const basicError = {
        minSowingDate: "",
        maxSowingDate: "",
        partnerId: "",
        seasonId: "",
        farmingTypeId: "",
        cropId: "",
        alertTypeId: "",
        pestId: "",
        pesticideId: [],
    };
    const [errors, setErrors] = useState(basicError);
    const [loading, setLoading] = useState(false);

    const totalLocationEditableData = data.formatted_location
        ? data.formatted_location.length
        : 0;
    const [location, setLocation] = useState(
        data.formatted_location || [
            {
                state: null,
                district: null,
                taluka: null,
                villages: [],
            }
        ]
    );
    const partnerNamesData = useSelector(
        (state) => state.partnerSliceReducer.partnerNames.data
    );
    const getStatesResponse = useSelector(
        (state) => state.locationSliceReducer.statesData
    );
    const getCropDataResponse = useSelector(
        (state) => state.pesticidesSliceReducer.cropData
    );
    const getFarmingTypeDataResponse = useSelector(
        (state) => state.pesticidesSliceReducer.farmingTypeData
    );
    const getPesticidesDataResponse = useSelector(
        (state) => state.pesticidesSliceReducer.pesticideTitleList
    );
    const getSeasonsDataResponse = useSelector(
        (state) => state.pesticidesSliceReducer.seasonData
    );
    const getAlertDataResponse = useSelector(
        (state) => state.pesticidesSliceReducer.alertData
    );
    const getPestDataResponse = useSelector(
        (state) => state.pesticidesSliceReducer.pestData
    );
    const editPartnerPesticideMappingApiResponse = useSelector(
        (state) => state.pesticidesSliceReducer.editPartnerPesticideMappingApiResponse.message
    );
    const editPartnerPesticideMappingErrorMessage = useSelector(
        (state) => state.pesticidesSliceReducer.editPartnerPesticideMappingErrorMessage.message
    );
    const editPartnerPesticideMappingIsSuccess = useSelector(
        (state) => state.pesticidesSliceReducer.editPartnerPesticideMappingIsSuccess
    );
    useEffect(() => {
        if (partnerNamesData[0].name !== "") {
            setPartnerLocations(partnerNamesData.find((item) => item.id === partnerId).location)
        }
    }, [partnerNamesData])

    useEffect(() => {
        dispatch(resetEditPartnerPesticide());
        if(getStatesResponse[0].id==="")
            dispatch(getStatesData())
        if(partnerNamesData[0].id===""){
            setLoading(true);
            dispatch(
                getPartnerNames({
                fields: ["name", "id", "location"].toString(),
                filters: JSON.stringify([{ type: "is_active", value: [true] }]),
                })
            ).then(() =>{ 
                if(getCropDataResponse[0].name==="")
                    dispatch(getCropData({ fields: "name" })).then(()=>{
                        if(getFarmingTypeDataResponse[0].name==="")
                            dispatch(getFarmingTypeData({ fields: "name" })).then(()=>{
                                if(getPesticidesDataResponse[0].code==="")
                                    dispatch(getPesticideTitleList({ fields: "code" })).then(()=>{
                                        if(getSeasonsDataResponse[0].code==="")
                                            dispatch(getSeasonData({ fields: "code,name,year" })).then(()=>{
                                                if(getAlertDataResponse[0].name==="")
                                                    dispatch(getAlertData({ fields: "name" })).then(()=>{
                                                        if(getPestDataResponse[0].code==="")
                                                            dispatch(getPestData({ fields: "code,name" })).then(()=>{
                                                                setLoading(false);
                                                            })
                                                    })
                                            })
                                    })
                            })
                    })
            
            });
            }
        ;
       
        
        
        
       
        
    }, []);

    const handleCloseByClearIcon = () => {
        setPartnerId("");
        setSeasonId("");
        setFarmingTypeId("");
        setCropId("");
        setAlertTypeId("");
        setPestId("");
        setPesticideId([]);
        setEditPartnerPesticideSnackBar(false);
        setEditPartnerPesticideSnackBarMessage("");
        setSnackBarType("success");
        setErrors(basicError);
        setLocation([
            {
                state: null,
                district: null,
                taluka: null,
                villages: [],
            }
        ]);
        dispatch(resetEditPartnerPesticide());
        handleClose();
    };

    const handleChangeMinSowingDays = (value) => {
        const reg = /^-?\d+$/;
        if (!reg.test(value))
            setErrors({ ...errors, minSowingDate: "Please enter only numbers" });
        else
            setErrors({ ...errors, minSowingDate: "" });
        setMinSowingDate(value)
    }
    const handleChangeMaxSowingDays = (value) => {
        const reg = /^-?\d+$/;
        if (!reg.test(value))
            setErrors({ ...errors, maxSowingDate: "Please enter only numbers" });
        else
            setErrors({ ...errors, maxSowingDate: "" });
        setMaxSowingDate(value)
    }

    const setLocationForApi = () => {
        var locations = {
            state_ids: [],
            district_ids: [],
            taluka_ids: [],
            village_ids: [],
        };
        if(location[0].villages.length > 0){
        location.forEach((item) => {
            locations["state_ids"].push(item.state.id);
            locations["district_ids"].push(item.district.id);
            locations["taluka_ids"].push(item.taluka.id);
            locations["village_ids"] = locations["village_ids"].concat(item.villages.map((item) => item.id));
        });
        locations["state_ids"] = removeDuplicates(locations["state_ids"]);
        locations["district_ids"] = removeDuplicates(locations["district_ids"]);
        locations["taluka_ids"] = removeDuplicates(locations["taluka_ids"]);
        locations["village_ids"] = removeDuplicates(locations["village_ids"]);
    }
        return locations;
    };

    const handleSave = () => {
        var locations = setLocationForApi();
        if (
            partnerId !== "" &&
            seasonId !== "" &&
            farmingTypeId !== "" &&
            cropId !== "" &&
            alertTypeId !== "" &&
            pestId !== "" &&
            pesticideId.length !== 0 &&
            partnerId != defaultAdvisoryPartnerId ? locations.village_ids.length > 0 : locations = {} &&
            partnerId != defaultAdvisoryPartnerId ? location[addAnotherLocationDropDown - 1].villages.length !== 0 : locations = {}
        ) {
            setEditPartnerPesticideSnackBar(false);
            setSnackBarType("success");
            setEditPartnerPesticideSnackBarMessage("");
            setLoading(true);
            dispatch(editPartnerPesticideMapping(
                {
                    id: data.id,
                    min_days_after_sowing: minSowingDate,
                    max_days_after_sowing: maxSowingDate,
                    partner: partnerId,
                    season: seasonId,
                    farming_type: farmingTypeId,
                    alert: alertTypeId,
                    crop: cropId,
                    pest: pestId,
                    pesticides: pesticideId.map((item) => item.code),
                    location: locations
                }
            )).then(() => {
                setLoading(false);
            });
        }
        else {
            setEditPartnerPesticideSnackBar(true);
            setSnackBarType("error");
            setEditPartnerPesticideSnackBarMessage("Please fill all the fields");
        }
    };

    const handleChangeLocation = (newValue, index, type) => {
        var newLocation = [...location];
        if (type === "state") {
            newLocation[index].state = newValue;
            newLocation[index].district = null;
            newLocation[index].taluka = null;
            newLocation[index].villages = [];
            setLocation(newLocation);
        } else if (type === "district") {
            newLocation[index].district = newValue;
            setLocation(newLocation);
            newLocation[index].taluka = null;
            newLocation[index].villages = [];
        } else if (type === "taluka") {
            newLocation[index].taluka = newValue;
            setLocation(newLocation);
            newLocation[index].villages = [];
        } else if (type === "village") {
            newLocation[index].villages = newValue;
            setLocation(newLocation);
        }
    };
    const handleChangeAddAnotherLocationBox = (event) => {
        if (location[addAnotherLocationDropDown - 1].villages.length !== 0) {
            const newLocation = [...location];
            newLocation.push({
                state: null,
                district: null,
                taluka: null,
                villages: [],
            });
            setLocation(newLocation);
            setAddAnotherLocationDropDown((prevIndex) => prevIndex + 1);
        }
    };

    const handleChangeDeleteLocation = (index) => {
        setAddAnotherLocationDropDown((prevIndex) => prevIndex - 1);
        setLocation((names) => names.filter((_, ind) => ind !== index));
    };

    useEffect(() => {
        if (editPartnerPesticideMappingIsSuccess && editPartnerPesticideMappingApiResponse !== "") {
            setSnackBarMessage(editPartnerPesticideMappingApiResponse);
            setReload(true);
            setSnackBarType("success");
            handleClose();
            dispatch(resetEditPartnerPesticide());
        }
        else if (!editPartnerPesticideMappingIsSuccess && editPartnerPesticideMappingErrorMessage !== "") {
            setEditPartnerPesticideSnackBar(true);
            setSnackBarType("error");
            setEditPartnerPesticideSnackBarMessage(editPartnerPesticideMappingErrorMessage);
        }
    }, [editPartnerPesticideMappingIsSuccess, editPartnerPesticideMappingApiResponse, editPartnerPesticideMappingErrorMessage]);


    const handleAddAnotherLocationBox = (index) => {
        return (
            <React.Fragment key={index}>
                <LocationDropDown
                    location={location}
                    handleChangeLocation={handleChangeLocation}
                    handleChangeDeleteLocation={handleChangeDeleteLocation}
                    index={index}
                    getStatesResponse={getStatesResponse}
                    isPartner={true}
                    partnerLocations={partnerLocations}
                    disable={index < totalLocationEditableData || partnerId == defaultAdvisoryPartnerId}
                />
            </React.Fragment>
        );
    };

    return (
        <div>
            <Modal
                open={openAddModal}
            >
                <Grid container sx={subGridStyle} spacing={1}>
                    <ThemeProvider theme={basicTheme}>
                            <Grid item xs={11}>
                                <Typography variant="h4" style={{ marginBottom: 10 }}>
                                    Assign Pesticide
                                </Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <ClearIcon
                                    className={classes.closeBtnStyle}
                                    onClick={handleCloseByClearIcon}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <Typography sx={{ marginBottom: 1 }}>
                                            Minimum days after sowing
                                        </Typography>
                                        <BasicTextfield
                                            label="Enter Minimum days"
                                            handleChange={handleChangeMinSowingDays}
                                            index={null}
                                            error={errors.minSowingDate !== ""}
                                            helperText={errors.minSowingDate}
                                            required={true}
                                            value={minSowingDate}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Typography sx={{ marginBottom: 1 }}>
                                            Maximum days after sowing
                                        </Typography>
                                        <BasicTextfield
                                            label="Enter Maximum days"
                                            handleChange={handleChangeMaxSowingDays}
                                            index={null}
                                            error={errors.maxSowingDate !== ""}
                                            helperText={errors.maxSowingDate}
                                            required={true}
                                            value={maxSowingDate}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Typography sx={{ marginBottom: 1 }}>Partner*</Typography>
                                        <OptionalDropDown
                                            text={"Select Partner"}
                                            id={partnerNamesData[0].id !== "" ? partnerId : ""}
                                            handleChange={(e) => { setPartnerId(e.target.value) }}
                                            menuList={partnerNamesData}
                                            disabled={true}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Typography sx={{ marginBottom: 1 }}>Season*</Typography>
                                        <OptionalDropDown
                                            text={"Select Season"}
                                            id={getSeasonsDataResponse[0].name !== "" ? seasonId : ""}
                                            handleChange={(e) => { setSeasonId(e.target.value) }}
                                            menuList={getSeasonsDataResponse}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Typography sx={{ marginBottom: 1 }}>Farming Type*</Typography>
                                        <OptionalDropDown
                                            text={"Select Farming Type"}
                                            id={getFarmingTypeDataResponse[0].name !== "" ? farmingTypeId : ""}
                                            handleChange={(e) => { setFarmingTypeId(e.target.value) }}
                                            menuList={getFarmingTypeDataResponse}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Typography sx={{ marginBottom: 1 }}>Crop Name*</Typography>
                                        <OptionalDropDown
                                            text={"Select Crop Name"}
                                            id={getCropDataResponse[0].name !== "" ? cropId : ""}
                                            handleChange={(e) => { setCropId(e.target.value) }}
                                            menuList={getCropDataResponse}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Typography sx={{ marginBottom: 1 }}>
                                            Alert Type*
                                        </Typography>
                                        <OptionalDropDown
                                            text={"Select Alert Type"}
                                            id={getAlertDataResponse[0].name !== "" ? alertTypeId : ""}
                                            handleChange={(e) => { setAlertTypeId(e.target.value) }}
                                            menuList={getAlertDataResponse}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Typography sx={{ marginBottom: 1 }}>
                                            Pest*
                                        </Typography>
                                        <OptionalDropDown
                                            text={"Select Pest"}
                                            id={getPestDataResponse[0].name !== "" ? pestId : ""}
                                            handleChange={(e) => { setPestId(e.target.value) }}
                                            menuList={getPestDataResponse}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Typography sx={{ marginBottom: 1 }}>
                                            Pesticide Code*
                                        </Typography>
                                        <AutoCompleteMultiDropdown
                                            widht={230}
                                            menuList={getPesticidesDataResponse}
                                            selectedOptions={getPesticidesDataResponse[0].code ==="" ? []:pesticideId}
                                            onHighlightChange={(value) => { setPesticideId(value) }}
                                            label='Select Pesticide Code'
                                            disable={getPesticidesDataResponse[0].code !=="" ? true : false}
                                            required={true}
                                        />

                                    </Grid>
                                    <Grid item xs={1}></Grid>
                                    <Grid item xs={12}>
                                        <Typography sx={{ marginBottom: 1 }}>Location*</Typography>
                                    </Grid>

                                    {Array.from({ length: addAnotherLocationDropDown }).map((_, index) =>
                                        handleAddAnotherLocationBox(index)
                                    )}
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography
                                    sx={{
                                        marginBottom: 4,
                                        color: "#2D6A4F",
                                        marginTop: 2,
                                        cursor: "pointer",
                                    }}
                                    onClick={(e) => handleChangeAddAnotherLocationBox(e)}
                                >
                                    + Add in another State/District/Taluka
                                </Typography>
                            </Grid>
                            <Grid
                                container
                                item
                                sm={12}
                                spacing={0}
                                sx={{ display: "flex", justifyContent: "center", marginTop: 2, marginBottom: 2 }}
                            >
                                <Grid
                                    item
                                    xs={12}
                                    sm={2.5}
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        // justifyContent: "space-around",
                                    }}
                                    style={{ backgroundColor: "transparent", boxShadow: "none" }}
                                >
                                    <CancelButtonStyle variant="contained" name="cancel" onClick={handleCloseByClearIcon}>
                                        Cancel
                                    </CancelButtonStyle>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={2.5}
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-around",
                                    }}
                                >
                                    <ButtonStyle
                                        variant="contained"
                                        name="add"
                                        startIcon={<CheckIcon size="large" />}
                                        onClick={handleSave}
                                    >
                                        Save
                                    </ButtonStyle>
                                </Grid>
                                <Grid item xs={12}>
                                    {editPartnerPesticideSnackBar && (
                                        <SnackBars
                                            open={editPartnerPesticideSnackBar}
                                            handleClose={() => setEditPartnerPesticideSnackBar(false)}
                                            severity={snackBarType}
                                            message={editPartnerPesticideSnackBarMessage}
                                            autoHideDuration={6000}
                                            vertical="bottom"
                                            horizontal="center"
                                            style = {{ willChange: "transform" }}
                                        />
                                    )}
                                </Grid>
                            </Grid>
                    </ThemeProvider>
                    {loading && (
                        <Backdrop
                            sx={{
                                color: "#fff",
                                zIndex: (theme) => theme.zIndex.drawer + 1,
                            }}
                            open={loading}
                        >
                            <CircularProgress color="inherit" />
                        </Backdrop>
                    )}
                   
                </Grid>
            </Modal>
        </div>
    );
}
