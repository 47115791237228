import React, { useEffect, useState } from "react";
import { Checkbox, TextField, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import _without from "lodash/without";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import Chip from "@mui/material/Chip";
import { createFilterOptions } from '@mui/material/Autocomplete';

const CustomAutocomplete = styled(Autocomplete)({
  "& .MuiInputBase-root.Mui-disabled": {
    backgroundColor: "#f0f0f0",
  },
});

export default function AutoCompleteMultiDropdown({
  width = 250,
  menuList = [],
  selectedOptions = [],
  setSelectedOptions = () => {},
  disable = false,
  label = "",
  onHighlightChange = () => {},
  index = 0,
  type = "",
  required = false,
  addAll = false,
  editAll = false
}) {
  const MenuProps = {
    style: {
      display:
        menuList.length > 0 &&
        menuList !== undefined &&
        menuList !== null &&
        ((menuList[0].name !== undefined && menuList[0].name === "") ||
          (menuList[0].id !== undefined && menuList[0].id === "")) &&
        "none",
    },
  };

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" color="success" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const handleChange = (event, value) => {
    setSelectedOptions(value);
    onHighlightChange(value, index, type, menuList);
    var newSelected = [];
    value.forEach((option) => {
      newSelected.push(option.id);
    });
  };

  return (
    <CustomAutocomplete
      onChange={(e, value) => {
        handleChange(e, value);
      }}
      ListboxProps={MenuProps}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      filterOptions={createFilterOptions({ trim: true })}
      value={selectedOptions}
      multiple
      options={menuList}
      disabled={disable || (menuList.length > 0 && menuList[0].id === "")}
      disableCloseOnSelect
      getOptionLabel={(option) => option.name}
      componentsProps={{
        paper: {
          sx: {
            width: { xl: "260px", lg: "220px", md: "180px", sm: "100px" },
          },
        },
      }}
      renderOption={(props, option, { selected }) => (
        <React.Fragment key={option.id}>
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.name}
          </li>
          <Divider />
        </React.Fragment>
      )}
      renderTags={(value, getTagProps) => {
        const selectedOptions = value.slice(0, 10);
        const allOption = value.find((option) => option.id === "all");
        return (
          <>
            {(allOption || editAll) ? (
              <Chip key={"all"} label="All" variant="outlined" />
            ) : (
              selectedOptions.map((option, index) => (
                <Chip
                  variant="outlined"
                  key={option.id}
                  label={option.name}
                  {...getTagProps({ index })}
                />
              ))
            )}
          </>
        );
      }}
      renderInput={(params) => (
        <TextField {...params} label={label} required={required} />
      )}
      sx={{
        ".MuiOutlinedInput-root": {
          marginBottom: 1,
          borderRadius: 2,
          backgroundColor: "#F5F5F5",
        },
        width: "90%",
      }}
    ></CustomAutocomplete>
  );
}
