import React, { useState, useEffect } from "react";
import {
  Button,
  Grid,
  Typography,
  Modal,
  ThemeProvider,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import CheckIcon from "@mui/icons-material/Check";
import { basicTheme } from "../../themes/customTheme";
import LocationDropDown from "./locationDropdown";
import { addLocation, resetAddlocation } from "../../features/location/locationSlice";
import { useDispatch, useSelector } from "react-redux";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import { ReactComponent as CrossButton } from "../../styles/close-button.svg"
import SnackBars from "../reusableComponents/SnackBar";

const mainGridStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "65%",
  height: "80%",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 4,
  p: 4,
  overflow: "scroll",
};

const useStyles = makeStyles(() => ({
  closeBtnStyle: {
    display: "flex",
    justifyContent: "flex-end",
    zoom: "2.2",
    alignItems: "right",
    "&:hover": {
      color: "#081C15",
    },
    cursor: "pointer",
  },
}));

const ButtonStyle = styled(Button)({
  backgroundColor: "#2D6A4F",
  color: "white",
  textTransform: "none",
  borderRadius: "8px",
  fontSize: "16px",
  fontWeight: "600",
  minWidth: "140px",
  maxWidth: "150px",
  height:"54px",
});


export default function AddVillageModal({ openAddModal, handleClose, setSnackBarMessage = () => { }, setReload = () => { } }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [
    showAnotherLocationDropDown,
    setShowAnotherLocationDropDown,
  ] = useState(false);

  const [villageNames, setVillageNames] = useState([]);
  const [activeStatus, setActiveStatus] = useState([]);
  const [parentId, setParentId] = useState('');
  const [addVillageSnackBar, setAddVillageSnackBar] = useState(false);
  const [addVillageSnackBarMessage, setAddVillageSnackBarMessage] = useState("");
  const [snackBarType, setSnackBarType] = useState("success");
  const [currentIndex, setCurrentIndex] = useState(1);

  const addLocationApiResponse = useSelector(
    (state) => state.locationSliceReducer.addLocationApiResponse.message
  );
  const addLocationErrorMessage = useSelector(
    (state) => state.locationSliceReducer.addLocationErrorMessage.message
  );

  const addLocationIsSuccess = useSelector(
    (state) => state.locationSliceReducer.addLocationIsSuccess
  );

  useEffect(() => {
    dispatch(resetAddlocation())
  }, [])

  const saveData = () => {
    dispatch(resetAddlocation());
    dispatch(addLocation({
      names: villageNames,
      parent_location_id: parentId,
      type: "village",
      is_active: activeStatus,
    }))
  }

  useEffect(() => {
    if (addLocationIsSuccess && addLocationApiResponse !== "") {
      setSnackBarMessage(addLocationApiResponse);
      setReload(true);
      setSnackBarType("success");
      setActiveStatus([]);
      setParentId('');
      setVillageNames([]);
      handleClose();
      dispatch(resetAddlocation())
    }
    else if (addLocationErrorMessage !== undefined && addLocationErrorMessage !== "") {
      setAddVillageSnackBar(true);
      setSnackBarType("error");
      setAddVillageSnackBarMessage(addLocationErrorMessage);
      dispatch(resetAddlocation())
    }
  }, [addLocationIsSuccess, addLocationErrorMessage, addLocationApiResponse])

  const handleCloseByClearIcon = () => {
    handleClose();
    setShowAnotherLocationDropDown(false);
    setActiveStatus([]);
    setParentId('');
    setVillageNames([]);
    setSnackBarMessage("");
    setReload(false);
    setAddVillageSnackBar(false);
    setAddVillageSnackBarMessage("");
    dispatch(resetAddlocation());
  }

  return (
    <Modal
      open={openAddModal}
      style={{ backgroundColor: "transparent", boxShadow: "none" }}
      overlaystyle={{ backgroundColor: "transparent" }}
    >
      <Grid container sx={mainGridStyle} spacing={2}>
        <ThemeProvider theme={basicTheme}>
          <Grid item xs={11}>
            <Typography variant="h4" style={{
              fontFamily: 'Inter',
              fontStyle: "normal",
              fontWeight: "600",
              fontSize: "32px",
              color: "#081C15"
            }}>
              Add Villages
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleCloseByClearIcon}
              edge="start"
            >
              <Icon
                sx={{
                  height: "48px",
                  width: "48px"
                }}
              >
                <CrossButton />
              </Icon>
            </IconButton>
          </Grid>
          <Grid
            item
            xs={12}
            style={{ backgroundColor: "#F5F5F5", borderRadius: "24px" }}
          >
            <LocationDropDown addVillage={true} setVillageNames={setVillageNames} villageNames={villageNames} setParentId={setParentId} setActiveStatus={setActiveStatus} activeStatus={activeStatus}
              setSnackBarType={setSnackBarType} setShowSnackBar={setAddVillageSnackBar} setSnackBarMessage={setAddVillageSnackBarMessage} setCurrentIndex={setCurrentIndex} />
          </Grid>
          <Grid item xs={12}>
            <ButtonStyle
              variant="contained"
              name="add"
              startIcon={<CheckIcon size="large" left="15.95%" right="14.24%" top="25.03%" bottom="22.57%" />}
              onClick={saveData}
              disabled={villageNames.length === 0 || villageNames.length !== currentIndex || (villageNames.includes(''))}
            >
              Save
            </ButtonStyle>
          </Grid>
        </ThemeProvider>
        {addVillageSnackBar &&
          <SnackBars
            open={addVillageSnackBar}
            handleClose={() => setAddVillageSnackBar(false)}
            severity={snackBarType}
            message={addVillageSnackBarMessage}
            autoHideDuration={6000}
            vertical="bottom"
            horizontal="center"
          />}
      </Grid>
    </Modal>
  );
}
