import React, { useState, useEffect } from "react";
import { Grid, Typography, Checkbox } from "@mui/material";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import _without from "lodash/without";
import { getDistrictList, getTalukaList, getVillageList } from "../utils/index";
import AutoCompleteMultiDropdown from "./reusableComponents/AutoCompleteMultiDropDown";
import AutoCompleteDropdown from "./reusableComponents/AutoCompleteDropDown";
export default function LocationDropDown({
  index = 0,
  location,
  handleChangeLocation,
  handleChangeDeleteLocation,
  getStatesResponse = [],
  disable = false,
  addAllVillage = false,
}) {
  const [getDistrictResponse, setGetDistrictResponse] = useState([]);
  const [getTalukaResponse, setGetTalukaResponse] = useState([]);
  const [getVillageResponse, setGetVillageResponse] = useState([]);

  useEffect(() => {
    if (location[index].state !== null) {
      setGetDistrictResponse([]);
      setGetTalukaResponse([]);
      setGetVillageResponse([]);
      getDistrictList(location[index].state.id).then((data) =>
        setGetDistrictResponse(data)
      );
    }
  }, [location[index].state]);

  useEffect(() => {
    if (location[index].district !== null) {
      setGetTalukaResponse([]);
      setGetVillageResponse([]);
      getTalukaList(location[index].district.id).then((data) =>
        setGetTalukaResponse(data)
      );
    }
  }, [location[index].district]);

  useEffect(() => {
    if (location[index].taluka !== null) {
      setGetVillageResponse([]);
      getVillageList(location[index].taluka.id).then((data) =>
        {
          if(addAllVillage){
            var newVillageList = data
            newVillageList.unshift({id:"all",name:"All"});
            setGetVillageResponse(newVillageList)
          }
          else{
            setGetVillageResponse(data)
          }
      }
      );
    }
  }, [location[index].taluka]);

  return (
    <Grid
      item
      xs={11}
      sx={{
        border: 1,
        borderColor: "#D3D3D3",
        borderRadius: "10px",
        margin: 2,
      }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} sm={index !== 0 && !disable ? 2.75 : 3}>
          <Typography sx={{ marginBottom: 1 }}>State</Typography>
          <AutoCompleteDropdown
            width="auto"
            menuList={getStatesResponse}
            selectedOptions={getStatesResponse[0].id === "" ? null :location[index].state}
            disable={disable || getStatesResponse[0].id === ""}
            label="Select State"
            onHighlightChange={handleChangeLocation}
            index={index}
            type="state"
          />
        </Grid>
        <Grid item xs={12} sm={index !== 0 && !disable ? 2.75 : 3}>
          <Typography sx={{ marginBottom: 1 }}>District</Typography>
          <AutoCompleteDropdown
            width="auto"
            menuList={getDistrictResponse}
            selectedOptions={getDistrictResponse.length === 0 ? null :location[index].district}
            disable={disable || getDistrictResponse.length === 0}
            label="Select District"
            onHighlightChange={handleChangeLocation}
            index={index}
            type="district"
          />
        </Grid>
        <Grid item xs={12} sm={index !== 0 && !disable ? 2.75 : 3}>
          <Typography sx={{ marginBottom: 1 }}>Taluka</Typography>
          <AutoCompleteDropdown
            width="auto"
            menuList={getTalukaResponse}
            selectedOptions={getTalukaResponse.length === 0 ? null :location[index].taluka}
            disable={disable || getTalukaResponse.length === 0}
            label="Select Taluka"
            onHighlightChange={handleChangeLocation}
            index={index}
            type="taluka"
          />
        </Grid>

        <Grid item xs={12} sm={index !== 0 && !disable ? 3 : 3}>
          <Typography sx={{ marginBottom: 1 }}>Village</Typography>
          <AutoCompleteMultiDropdown
            width={230}
            menuList={getVillageResponse}
            selectedOptions={getVillageResponse.length === 0 ? [] :location[index].villages}
            onHighlightChange={handleChangeLocation}
            index={index}
            type="village"
            label="Select Villages"
            disable={disable || getVillageResponse.length === 0}
            addAll = {addAllVillage}
            editAll = {getVillageResponse.length===location[index].villages.length}
          />
        </Grid>
        <Grid item xs={12} sm={0.75}>
          {index > 0 && !disable && (
            <DeleteRoundedIcon
              sx={{ cursor: "pointer", marginTop: 5 }}
              onClick={() => handleChangeDeleteLocation(index)}
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
