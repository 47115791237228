import React, { useState, useEffect, useRef } from "react";
import { styled } from "@mui/material/styles";
import { Grid, ThemeProvider, Box } from "@mui/material";
import TopFilterBar from "../../components/reusableComponents/TopFilterBar";
import { basicTheme } from "../../themes/customTheme";
import DesktopTable from "../../components/reusableComponents/DesktopTable";
import { useDispatch, useSelector } from "react-redux";
import {
  getlocationData,
  getFilteredLocationData,
  getExportedData,
} from "../../features/location/locationSlice";
import AddVillageModal from "../../components/location/AddVillageModal";
import { CSVLink } from "react-csv";
import SnackBars from "../../components/reusableComponents/SnackBar";
import EditLocationModal from "../../components/location/EditLocationModal";
import DeleteLocation from "../../components/location/deleteLocationModal";
import FilterPopOver from "../../components/location/FilterPopOver";
import useMediaQuery from "@mui/material/useMediaQuery";
import AlertDialog from "../../components/reusableComponents/AlertDialog";
import LinearProgress from "@mui/material/LinearProgress";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const MainGrid = styled(Grid)({
  flex: "1",
  backgroundColor: "white",
  // [theme.breakpoints.down("sm")]: {
  //   marginTop: "60px",
  // },
  marginTop: "80px",
});

const initialHeadCells = [
  {
    key: "action",
    label: "Actions",
    type: "editDelete",
    sort: false,
    sticky:true
  },
  {
    key: "state",
    label: "State",
    type: "text",
    sort: true,
    capital:true
  },
  {
    key: "district",
    label: "District",
    type: "text",
    sort: true,
    capital:true
  },
  {
    key: "taluka",
    label: "Taluka",
    type: "text",
    sort: true,
    capital:true
  },
  {
    key: "village",
    label: "Village",
    type: "text",
    sort: true,
    capital:true
  },
  {
    key: "is_active",
    label: "Active",
    type: "checkbox",
    sort: true,
  },
];

export default function Location() {
  const dispatch = useDispatch();
  const csvLink = useRef();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [openAddModal, setopenAddModal] = useState(false);
  const [offset, setOffset] = useState(0);
  const [sortingType, setSortingType] = useState("asc");
  const [sortBy, setSortBy] = useState("id");
  const [applyFilter, setApplyFilter] = useState(false);
  const [searchBarData, setSearchBarData] = useState("");
  const [searchBarText, setSeachBarText] = useState("");
  const [exportData, setExportData] = useState("");
  const [exportedFileName, setExportedFileName] = useState("");
  const [snackBarType, setSnackBarType] = useState("success");
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openEditModal, setopenEditModal] = useState(false);
  const [editData, setEditData] = useState({});
  const [deleteData, setDeleteData] = useState({});
  const [reload, setReload] = useState(false);
  const [page, setPage] = React.useState(0);
  const [filterApiCall, setFilterApiCall] = useState(false);
  const [locationIds, setLocationIds] = useState([]);

  const [openFilterPopOver, setOpenFilterPopOver] = React.useState(null);
  const [selectedStatesOptions, setSelectedStatesOptions] = useState([]);
  const [selectedDistrictOptions, setSelectedDistrictOptions] = useState([]);
  const [selectedTalukaOptions, setSelectedTalukaOptions] = useState([]);
  const [clearFilter, setClearFilter] = useState(false);
  const [openAlertModal, setOpenAlertModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleShowFilterPopOver = (event) => {
    setOpenFilterPopOver(event.currentTarget);
    setApplyFilter(false);
    setClearFilter(false);
  };

  const handleCloseFilterPopOver = () => {
    setOpenFilterPopOver(null);
  };

  const rowsPerPage = 10;

  const drawerWidth = useSelector(
    (state) => state.appBarSliceReducer.drawerWidth
  );

  const getLocationDataResponse = useSelector(
    (state) => state.locationSliceReducer.locationData.data
  );

  const locationDataErrorMessage = useSelector(
    (state) => state.locationSliceReducer.locationDataErrorMessage
  );

  const locationDataIsSuccess = useSelector(
    (state) => state.locationSliceReducer.locationDataIsSuccess
  );

  const locationDataLoading = useSelector(
    (state) => state.locationSliceReducer.locationDataLoading
  );

  const totalLocationData = useSelector(
    (state) => state.locationSliceReducer.locationData.count
  );

  const exportedData = useSelector(
    (state) => state.locationSliceReducer.exportedData
  );

  useEffect(() => {
    if (applyFilter === true) {
      var tempLocationIds = []
      if(selectedStatesOptions.length > 0) {
        selectedStatesOptions.forEach((obj)=>{
          tempLocationIds.push(obj.id)
        })
      }
      if(selectedDistrictOptions.length > 0) {
        selectedDistrictOptions.forEach((obj)=>{
          tempLocationIds.push(obj.id)
        })
      }
      if(selectedTalukaOptions.length > 0) {
        selectedTalukaOptions.forEach((obj)=>{
          tempLocationIds.push(obj.id)
        })
      }
      setLocationIds(tempLocationIds)
    }
  }, [applyFilter]);

  useEffect(()=>{
    setPage(0);
    setOffset(0);
  },[searchBarData])

  useEffect(() => {
    if (clearFilter === true) {
      setLocationIds([]);
      setSelectedStatesOptions([]);
      setSelectedDistrictOptions([]);
      setSelectedTalukaOptions([]);
      setPage(0);
      setOffset(0);
    }
  }, [clearFilter]);

  useEffect(() => {
    if (
      !locationDataIsSuccess &&
      (locationDataErrorMessage === undefined ||
        (locationDataErrorMessage.message !== undefined &&
          locationDataErrorMessage.message !== ""))
    ) {
      setShowSnackBar(true);
      setSnackBarMessage("Something went wrong on server side");
      setSnackBarType("error");
    } else if (
      !locationDataLoading &&
      !locationDataIsSuccess &&
      locationDataErrorMessage !== undefined &&
      locationDataErrorMessage.message !== undefined &&
      locationDataErrorMessage.message !== ""
    ) {
      setShowSnackBar(true);
      setSnackBarMessage(locationDataErrorMessage.message);
      setSnackBarType("error");
    }
    // else{
    //   setShowSnackBar(false);
    // }
  }, [locationDataIsSuccess, locationDataErrorMessage, locationDataLoading]);

  useEffect(() => {
    if (snackBarMessage !== "") setShowSnackBar(true);
  }, [snackBarMessage]);

  useEffect(() => {
    if (exportData){
      setLoading(true)
      dispatch(
        getExportedData({
          sort_by: sortingType,
          sort_type: sortBy,
          location_ids: locationIds,
          search: searchBarData,
        })
      ).then((res) => {
        setLoading(false)
        setExportData(false);
        if(res.error!==undefined){
          setSnackBarMessage("Something went wrong!")
          setSnackBarType("error")
          setShowSnackBar(true)
        }
        else{
          setExportedFileName(
            "location_data" + ".csv"
          );
          csvLink.current.link.click();
        }
      });
    }
  }, [exportData]);

  useEffect(() => {
    if (locationIds.length !== 0 || searchBarData !== "") {
      setFilterApiCall(true);
    }
  }, [searchBarData, applyFilter, offset, locationIds, reload,sortingType, sortBy]);

  useEffect(() => {
    if (filterApiCall) {
      setLoading(true)
      dispatch(
        getFilteredLocationData({
          offset: offset,
          limit: rowsPerPage,
          sort_by: sortingType,
          sort_type: sortBy,
          location_ids: locationIds,
          search: searchBarData,
        })
      ).then(() => {
        setReload(false);
        setFilterApiCall(false);
        setLoading(false)
      });
    }
  }, [filterApiCall, reload]);

  useEffect(() => {
    if (locationIds.length === 0 && searchBarData === ""){
      setLoading(true)
      dispatch(
        getlocationData({
          offset: offset,
          limit: rowsPerPage,
          sort_by: sortingType,
          sort_type: sortBy,
        })
      ).then(() => {
        setReload(false);
        setLoading(false)
      });
    }
  }, [offset, sortingType, sortBy, reload, locationIds, searchBarData]);

  useEffect(() => {
    if (applyFilter) {
      handleCloseFilterPopOver();
      setPage(0);
      setOffset(0);
    }
  }, [applyFilter]);

  return (
    <MainGrid
      container
      id="main"
      sx={{ marginLeft: drawerWidth + "px", width: "auto" }}
      spacing={3}
    >
      <ThemeProvider theme={basicTheme}>
        <Grid item xs={12}>
          {!isSmallScreen && (
            <TopFilterBar
              addtext="Add Village"
              setAddOpen={setopenAddModal}
              applyFilter={applyFilter}
              setApplyFilter={setApplyFilter}
              searchBarData={searchBarData}
              setSearchBarData={setSearchBarData}
              searchBarText={searchBarText}
              setSeachBarText={setSeachBarText}
              setExportData={setExportData}
              handleShowFilterPopOver={handleShowFilterPopOver}
              searchBy="Search By Location"
              loading={loading}
              filterSelected = {selectedStatesOptions.length!==0}
            />
          )}
          {openFilterPopOver && !isSmallScreen && (
            <FilterPopOver
              handleClose={handleCloseFilterPopOver}
              openFilterPopOver={openFilterPopOver}
              // setFilterBy={setFilterBy}
              // setFilterIds={setFilterIds}
              setApplyFilter={setApplyFilter}
              clearFilter={clearFilter}
              setClearFilter={setClearFilter}
              selectedStatesOptions={selectedStatesOptions}
              setSelectedStatesOptions={setSelectedStatesOptions}
              selectedDistrictOptions={selectedDistrictOptions}
              setSelectedDistrictOptions={setSelectedDistrictOptions}
              selectedTalukaOptions = {selectedTalukaOptions}
              setSelectedTalukaOptions={setSelectedTalukaOptions}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          {locationDataLoading && !locationDataIsSuccess && loading && (
            <Box>
              <LinearProgress color="success" />
            </Box>
          )}
          {locationDataIsSuccess && !locationDataLoading && !loading &&(
            <DesktopTable
              key={getLocationDataResponse}
              headCells={initialHeadCells}
              tableResponse={getLocationDataResponse}
              totalCount={totalLocationData}
              setOffset={setOffset}
              rowsPerPage={rowsPerPage}
              setSortingType={setSortingType}
              sortingType={sortingType}
              setSortBy={setSortBy}
              sortBy={sortBy}
              setopenEditModal={setopenEditModal}
              setEditData={setEditData}
              setOpenDeleteModal={setOpenDeleteModal}
              setDeleteData={setDeleteData}
              setPage={setPage}
              page={page}
              openEditModal={openEditModal}
              openDeleteModal={openDeleteModal}
            />
          )}
        </Grid>
        {openAddModal && (
          <AddVillageModal
            openAddModal={openAddModal}
            handleClose={() => setopenAddModal(false)}
            setSnackBarMessage={setSnackBarMessage}
            setReload={setReload}
          />
        )}
        <CSVLink
          data={exportedData}
          filename={exportedFileName}
          className="hidden"
          ref={csvLink}
          target="_blank"
        />
        {openEditModal && (
          <EditLocationModal
            openModal={openEditModal}
            handleClose={() => setopenEditModal(false)}
            data={editData}
            setEditData={setEditData}
            setSnackBarMessage={setSnackBarMessage}
            setReload={setReload}
          />
        )}

        {openAlertModal && (
          <AlertDialog
            handleClose={() => setOpenAlertModal(false)}
            open={openAlertModal}
            heading="Can't delete this location "
            text="Partners are mapped with this location.Please unmap partners and then
            try deleting again!"
          />
        )}

        {openDeleteModal && (
          <DeleteLocation
            handleClose={() => setOpenDeleteModal(false)}
            open={openDeleteModal}
            deleteData={deleteData.village}
            setSnackBarMessage={setSnackBarMessage}
            setReload={setReload}
            setOpenAlertModal={setOpenAlertModal}
          />
        )}
        {showSnackBar && (
          <SnackBars
            open={showSnackBar}
            handleClose={() => setShowSnackBar(false)}
            severity={snackBarType}
            message={snackBarMessage}
            autoHideDuration={6000}
            vertical="bottom"
            horizontal="center"
          />
        )}
        {loading && (
          <Backdrop
            sx={{
              color: "#fff",
              position:"absolute",
              zIndex: (theme) => theme.zIndex.drawer +1,
            }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
      </ThemeProvider>
    </MainGrid>
  );
}
