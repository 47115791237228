import {  createTheme } from '@mui/material/styles';
import { green } from '@mui/material/colors';


export const basicTheme = createTheme({
  palette: {
    primary: green,
  },
  
    // shape: {
    //   borderRadius: "1px",
    // },
    // typography: {
    //   button: {
    //     textTransform: "none",
    //   },
    // },
    // overrides: {
    //   MuiSelect: {
    //     root: {
    //       backgroundColor: "white",
    //       padding: "10px",
    //       fontSize: "smaller",
    //     },
    //     select: {
    //       borderTopRightRadius: "4px",
    //       borderBottomRightRadius: "4px",
    //       "&:focus": {
    //         borderTopRightRadius: "4px",
    //         borderBottomRightRadius: "4px",
    //         backgroundColor: "white",
    //       },
    //     },
    //   },
    //   MuiFilledInput: {
    //     underline: {
    //       "&:before": {
    //         borderBottom: "none",
    //       },
    //       "&:after": {
    //         borderBottom: "none",
    //       },
    //     },
    //   },
    //   MuiOutlinedInput: {
    //     root: {
    //       borderRadius: "0px",
    //       backgroundColor: "white",
    //     },
    //     adornedStart: {
    //       paddingLeft: "4px",
    //     },
    //     input: {
    //       padding: "10px",
    //     },
    //   },
    // },
  });
