import {createTheme } from "@mui/material/styles";
import {ThemeProvider} from "@mui/material";

import CircularProgress from "@mui/material/CircularProgress";
import LinearProgress from "@mui/material/LinearProgress";
import PropTypes from "prop-types";
import React from 'react';
import { blue } from '@mui/material/colors';

const theme = createTheme({
    palette: {
      primary: blue,
    },
});

export default function Loader ({ variant = "linear", size }) {
  return (
    variant === 'linear'
    ? <ThemeProvider theme={theme}>
        <LinearProgress/>
    </ThemeProvider>
    : <ThemeProvider theme={theme}>
      <CircularProgress size={size}/>
    </ThemeProvider>
  )
}

Loader.propTypes = {
  variant: PropTypes.oneOf(["linear", "circular"])
};
