import React from "react";
import { AppBar, Toolbar } from "@mui/material";
import { makeStyles } from "@mui/styles";
import logo from "../../styles/images/icons/logo.png"

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  logo: {
      zoom: '10%'
  },
  flex: {
      display: "flex"
  },
  equal: {
      flex: "1"
  },
}));

export default function MenuAppBar () {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <AppBar position="static">
          <Toolbar className={classes.flex}>
            <div className={classes.equal}>
              <img src={logo} alt="logo" className={classes.logo}/>
            </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}
