import * as React from "react";

import { Button, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { makeStyles } from "@mui/styles";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    fontSize: "24px",
    fontWeight: "500",
    color: "#929dad",
    paddingTop: "5px",
    marginTop: "10px",
  },
  dialogueActions: {
    paddingBottom: "25px",
    paddingRight: "20px",
  },
  signOutTypography: {
    fontSize: "36px",
    color: "#151b24",
    fontWeight: "500",
  },
}));

const Centerstyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};


const CancelButtonStyle = styled(Button)({
  backgroundColor: "white",
  color: "#2D6A4F",
  textTransform: "none",
  borderRadius: "8px",
  fontSize: "16px",
  fontWeight: "600",
  minWidth: "120px",
  maxWidth: "150px",
});

const ButtonStyle = styled(Button)({
  backgroundColor: "#2D6A4F",
  color: "white",
  textTransform: "none",
  borderRadius: "12px",
  fontSize: "16px",
  fontWeight: "600",
  minWidth: "120px",
  maxWidth: "200px",
});

export default function BasicDialog({
  open,
  handleClose,
  heading,
  text,
  saveButtonText,
  handleLogout,
}) {
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={handleClose} PaperProps={{ sx: { width: "500px", height: "200px" } }}>
      <DialogTitle >
        <Typography sx={{fontSize:"28px"}}className={classes.signOutTypography}>{heading}</Typography>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          style={{ position: "absolute", top: "0", right: "0" }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography className={classes.dialogContent}>{text}</Typography>
      </DialogContent>
      <DialogActions className={classes.dialogueActions} sx={Centerstyle}>
        <CancelButtonStyle variant="contained" onClick={handleClose}>
          Cancel
        </CancelButtonStyle>
        <ButtonStyle variant="contained" name="signout" onClick={handleLogout}>
          {saveButtonText}
        </ButtonStyle>
      </DialogActions>
    </Dialog>
  );
}
