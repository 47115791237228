import React, { useState, useEffect } from "react";
import {
  Button,
  Grid,
  Typography,
  Modal,
  ThemeProvider,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { basicTheme } from "../../themes/customTheme";
import Backdrop from "@mui/material/Backdrop";
import LocationDropDown from "./UserLocation";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import * as moment from "moment";
import { getEwList } from "../../utils/index";
import { getStatesData } from "../../features/location/locationSlice";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import BasicTextfield from "../reusableComponents/BasicTextfield";
import ReactLoader from 'react-fullpage-custom-loader'
import dayjs from "dayjs";
import {
  getSeasonData,
  getFarmingTypeData,
  getCropData,
} from "../../features/advisoryManagement/advisoryManagementSlice";
import { getPartnerNames } from "../../features/partner/partnerSlice";
import { editUser, resetEditUser } from "../../features/appUsers/appUserSlice";
import SnackBars from "../reusableComponents/SnackBar";
import AutoCompleteDropdown from "../reusableComponents/AutoCompleteDropDown";
import OptionalDropDown from "../reusableComponents/OptionalDropDown";
import BasicDropDown from "../reusableComponents/BasicDropDown";

const subGridStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
  width: "65%",
  maxHeight: "80%",
  overflow: "auto",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-around",
  paddingLeft: 5,
};

const useStyles = makeStyles(() => ({
  closeBtnStyle: {
    display: "flex",
    justifyContent: "flex-end",
    zoom: "2.2",
    alignItems: "right",
    "&:hover": {
      color: "#081C15",
    },
    cursor: "pointer",
  },
}));

const ButtonStyle = styled(Button)({
  backgroundColor: "#2D6A4F",
  color: "white",
  textTransform: "none",
  borderRadius: "8px",
  fontSize: "16px",
  fontWeight: "600",
  minWidth: "140px",
  maxWidth: "150px",
  height: "54px",
});

const CancelButtonStyle = styled(Button)({
  backgroundColor: "white",
  color: "#2D6A4F",
  textTransform: "none",
  borderRadius: "8px",
  fontSize: "16px",
  fontWeight: "600",
  minWidth: "140px",
  maxWidth: "150px",
  height: "54px",
});

export default function EditFarmer({
  openModal,
  handleClose,
  data = [],
  setUserSnackBarMessage = () => {},
  setReload = () => {},
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [season, setSeason] = useState(data.season.code);
  const [farmerPartner, setFarmerPartner] = useState(data.partner);
  const [cropName, setCropName] = useState(data.crop.name);
  const [farmingType, setFarmingType] = useState(data.farming_type);
  const [trapInstalled, setTrapsInstalled] = useState(
    data.farmer_type === "Lead" ? "Yes" : "No"
  );
  const [farmerType, setFarmerType] = useState(data.farmer_type);
  const [farmerName, setFarmerName] = useState(data.name);
  const [farmerNumber, setFarmerNumber] = useState(data.phone_number);
  const [farmerGender, setFarmerGender] = useState(data.gender);
  const [sowingDate, setSowingDate] = useState(
    dayjs(moment(data.sowing_date, "DD/MM/YYYY")).format("YYYY-MM-DD")
  );

  const [formattedSowingDate, setFormattedSowingDate] = useState(
    data.sowing_date
  );

  const [protocol, setProtocol] = useState(data.protocol);
  const [totalLandHolding, setTotalLandHolding] = useState(
    data.total_land_holding || ""
  );
  const [areaUnderCotton, setAreaUnderCotton] = useState(
    data.area_under_cotton || ""
  );
  const activeStatus = data.status
    ? "Active"
    : data.status === false
    ? "Inactive"
    : "";
  const [farmerStatus, setFarmerStatus] = useState(activeStatus || "");
  const [ewName, setEwName] = useState("");
  const [ewNumber, setEwNumber] = useState("");
  const [ewGender, setEwGender] = useState("");
  const [isEwLead, setIsEwLead] = useState(false);
  const [addUserSnackBar, setAddUserSnackBar] = useState(false);
  const [addUserSnackBarMessage, setAddUserSnackBarMessage] = useState("");
  const [ewTotalData, setEwTotalData] = useState([]);
  const [ewList, setEwList] = useState([]);
  const [showEwAdd, setShowEwAdd] = useState(false);
  const [selectedStateOptions, setSelectedStateOptions] = useState(
    data.formatted_location.state
  );
  const [selectedDistrictOptions, setSelectedDistrictOptions] = useState(
    data.formatted_location.district
  );
  const [selectedTalukaOptions, setSelectedTalukaOptions] = useState(
    data.formatted_location.taluka
  );
  const [selectedVillageOptions, setSelectedVillageOptions] = useState(
    data.formatted_location.village
  );
  const [selectedEwOptions, setSelectedEwOptions] = useState(
    data.extension_worker
  );
  const [loading, setLoading] = useState(false);
  const [partnerLocations, setPartnerLocations] = useState({});
  const basicError = {
    farmerName: "",
    phoneNumber: "",
    ewName: "",
    ewPhoneNumber: "",
    areaUnderCotton:""
  };
  const userStatusList = ["Active", "Inactive"];
  const farmerTypeList = ["Lead", "Cascade"];
  const yesNoList = ["Yes", "No"];
  const genderList = ["Male", "Female", "Transgender", "Prefer not to say"];
  const protocolList = ["Weekly Monitoring Protocol", "CICR"];

  const [snackBarType, setSnackBarType] = useState("success");
  const [errors, setErrors] = useState(basicError);
  const loggedInUser = JSON.parse(window.localStorage.getItem("loggedInUser"));

  const editUserApiResponse = useSelector(
    (state) => state.usersSliceReducer.editUserApiResponse.message
  );
  const editUserErrorMessage = useSelector(
    (state) => state.usersSliceReducer.editUserErrorMessage.message
  );

  const editUserIsSuccess = useSelector(
    (state) => state.usersSliceReducer.editUserIsSuccess
  );

  const partnerNamesData = useSelector(
    (state) => state.partnerSliceReducer.partnerNames.data
  );

  const getSeasonsDataResponse = useSelector(
    (state) => state.pesticidesSliceReducer.seasonData
  );

  const getCropDataResponse = useSelector(
    (state) => state.pesticidesSliceReducer.cropData
  );

  const getFarmingTypeDataResponse = useSelector(
    (state) => state.pesticidesSliceReducer.farmingTypeData
  );

  const getStatesResponse = useSelector(
    (state) => state.locationSliceReducer.statesData
  );

  useEffect(() => {
    var newEwList = [];
    ewTotalData.forEach((ew_data) => {
      newEwList.push({
        phone_number: ew_data.phone_number,
        name: ew_data.name,
        id: ew_data.id,
      });
    });

    setEwList(newEwList);
  }, [ewTotalData]);

  useEffect(() => {
    dispatch(resetEditUser());
    // if(getStatesResponse[0].id==="")
    //   dispatch(getStatesData());
    
    if(getSeasonsDataResponse[0].code==="")
    {
      setLoading(true);
      dispatch(getSeasonData({ fields: "code,name,year" })).then(()=>{
      if(getCropDataResponse[0].name==="")
        dispatch(getCropData({ fields: "name" })).then(()=>{
          if(getFarmingTypeDataResponse[0].name==="")
            dispatch(getFarmingTypeData({ fields: "name" })).then(()=>setLoading(false))
        })
    })
    }
    if(partnerNamesData[0].id===""){
      setLoading(true);
      dispatch(
        getPartnerNames({
          fields: ["name", "id", "location"].toString(),
          filters: JSON.stringify([{ type: "is_active", value: [true] }]),
          internal:true
        })
      ).then(() => {
        setLoading(false)
      });
    }
  }, []);

  useEffect(() => {
    getFilteredEw();
  }, [selectedDistrictOptions]);

  useEffect(() => {
    if (farmerPartner !== null && partnerNamesData[0].id !== "") {
      setPartnerLocations(
        partnerNamesData.find((item) => item.id === farmerPartner.id).location
      );
    }
  }, [farmerPartner, partnerNamesData]);

  const getFilteredEw = () => {
    if (
      farmerPartner !== null &&
      selectedStateOptions !== null &&
      selectedDistrictOptions !== null
    ) {
      setLoading(true);
      getEwList({
        filters: JSON.stringify([
          { type: "program_id", value: [farmerPartner.id] },
          { type: "user_type", value: ["ew"] },
          { type: "state", value: [selectedStateOptions.id] },
          { type: "district", value: [selectedDistrictOptions.id] },
        ]),
        offset: 0,
        limit: 200,
      }).then((data) => {
        if (data.data.length === 0) {
          setAddUserSnackBarMessage("No extension worker found");
          setSnackBarType("error");
          setAddUserSnackBar(true);
        } else {
          setAddUserSnackBar(false);
        }
        setEwTotalData(data.data);
        setLoading(false);
      });
    }
  };

  const handleChangeLocation = () => {
    setSelectedEwOptions(null);
    setEwTotalData([]);
  };

  const handleChangeTrapStatus = (event) => {
    setTrapsInstalled(event.target.value);
    if (event.target.value === "Yes") setFarmerType("Lead");
    else setFarmerType("Cascade");
  };

  const handleChangeFarmerType = (event) => {
    setFarmerType(event.target.value);
  };

  const handleChangeProtocol = (event) => {
    setProtocol(event.target.value);
  };

  const handleChangeFarmerGender = (event) => {
    setFarmerGender(event.target.value);
  };

  const handleChangeEwGender = (event) => {
    setEwGender(event.target.value);
  };

  const handleChangeFarmerStatus = (event) => {
    setFarmerStatus(event.target.value);
  };
  const handleChangeFarmerName = (value) => {
    value = value.replace(/^\s+/g, "");
    const ALPHA_NUMERIC_DASH_REGEX = /^[a-zA-Z0-9\s]{1,}[\.]{0,1}[A-Za-z0-9\s]{0,}$/;
    if (value !== "" && !ALPHA_NUMERIC_DASH_REGEX.test(value)) value = "";
    if (value === "")
      setErrors({
        ...errors,
        farmerName: "Farmer Name can't be empty ",
      });
    else {
      setErrors({ ...errors, farmerName: "" });
    }
    setFarmerName(value);
  };
  const handleChangeEwName = (value) => {
    value = value.replace(/^\s+/g, "");
    const ALPHA_NUMERIC_DASH_REGEX = /^[a-zA-Z0-9\s]{1,}[\.]{0,1}[A-Za-z0-9\s]{0,}$/;
    if (value !== "" && !ALPHA_NUMERIC_DASH_REGEX.test(value)) value = "";
    if (value === "")
      setErrors({
        ...errors,
        ewName: "Extension Worker Name can't be empty ",
      });
    else {
      setErrors({ ...errors, ewName: "" });
    }
    setEwName(value);
  };

  const handleChangeFarmerPartner = (event) => {
    setSelectedEwOptions(null);
    setSelectedStateOptions(null);
    setSelectedDistrictOptions(null);
    setSelectedTalukaOptions(null);
    setSelectedVillageOptions(null);
    setEwTotalData([]);
  };

  const onDateChange = (newValue) => {
    var formattedDate = dayjs(newValue).format("DD/MM/YYYY");
    setFormattedSowingDate(formattedDate);
    setSowingDate(newValue);
  };

  const handleChangeFarmerNumber = (phoneNumber) => {
    phoneNumber = phoneNumber.trim();
    const reg = /^[1-9][0-9]*$/;
    if (phoneNumber === "") {
      setErrors({ ...errors, phoneNumber: "Phone number can't be empty " });
    } else if (!reg.test(phoneNumber)) {
      setErrors({
        ...errors,
        phoneNumber: "Phone number should not start with 0 ",
      });
    } else if (phoneNumber.length !== 10) {
      setErrors({ ...errors, phoneNumber: "Phone number should be 10 digits" });
    } else {
      setErrors({ ...errors, phoneNumber: "" });
    }
    setFarmerNumber(phoneNumber);
  };

  const handleChangeTotalLandHolding = (value) => {
    var regex = /^\d*\.?\d{0,2}$/;
    if (value === "" || regex.test(value)) {
      setTotalLandHolding(value);
    }
    if(areaUnderCotton!=="" && value!=="" && Number(areaUnderCotton)>Number(value)){
      setErrors({ ...errors, areaUnderCotton: "Area Under Cotton should not be greater than Total Land Holding" });
    }
    else{
      setErrors({ ...errors, areaUnderCotton: "" });
    }
  };

  const handleChangeAreaUnderCotton = (value) => {
    var regex = /^\d*\.?\d{0,2}$/;
    if (value === "" || regex.test(value)) {
      setAreaUnderCotton(value);
    }
    if(totalLandHolding!=="" && value!=="" && Number(value)>Number(totalLandHolding)){
      setErrors({ ...errors, areaUnderCotton: "Area Under Cotton should not be greater than Total Land Holding" });
    }
    else{
      setErrors({ ...errors, areaUnderCotton: "" });
    }
  };

  const handleChangeEwNumber = (phoneNumber) => {
    phoneNumber = phoneNumber.trim();
    const reg = /^[1-9][0-9]*$/;
    if (phoneNumber === "") {
      setErrors({ ...errors, ewPhoneNumber: "Phone number can't be empty " });
    } else if (!reg.test(phoneNumber)) {
      setErrors({
        ...errors,
        ewPhoneNumber: "Phone number should not start with 0 ",
      });
    } else if (phoneNumber.length !== 10) {
      setErrors({
        ...errors,
        ewPhoneNumber: "Phone number should be 10 digits",
      });
    } else {
      setErrors({ ...errors, ewPhoneNumber: "" });
    }
    setEwNumber(phoneNumber);
  };

  const handleCloseByClearIcon = () => {
    setErrors(basicError);
    setSeason("");
    setCropName("");
    setFarmerPartner("");
    setFarmingType("");
    setTrapsInstalled("");
    setFarmerName("");
    setFarmerNumber("");
    setFarmerGender("");
    setProtocol("");
    setTotalLandHolding("");
    setAreaUnderCotton("");
    setFarmerStatus("");
    setEwGender("");
    setEwName("");
    setEwNumber("");
    setEwList([]);
    setEwTotalData([]);
    setIsEwLead(false);
    setAddUserSnackBar(false);
    setAddUserSnackBarMessage("");
    setShowEwAdd(false);
    setSelectedStateOptions(null);
    setSelectedDistrictOptions(null);
    setSelectedTalukaOptions(null);
    setSelectedVillageOptions(null);
    setSelectedEwOptions(null);
    setPartnerLocations(null);
    setSowingDate(dayjs(new Date()));

    dispatch(resetEditUser());
    handleClose();
  };

  const setLocationForApi = () => {
    var locations = {
      state_ids: [],
      district_ids: [],
      taluka_ids: [],
      village_ids: [],
    };
    if (selectedStateOptions !== null) {
      locations["state_ids"].push(selectedStateOptions.id);
    }
    if (selectedDistrictOptions !== null) {
      locations["district_ids"].push(selectedDistrictOptions.id);
    }
    if (selectedTalukaOptions !== null) {
      locations["taluka_ids"].push(selectedTalukaOptions.id);
    }
    if (selectedVillageOptions !== null) {
      locations["village_ids"].push(selectedVillageOptions.id);
    }

    return locations;
  };

  useEffect(() => {
    if (!showEwAdd && selectedEwOptions !== null) {
      setEwName(selectedEwOptions.name);
      setEwNumber(selectedEwOptions.phone_number);
    }
    else if(!showEwAdd && selectedEwOptions === null)
    {
      setEwName("");
      setEwNumber("");
    }
  }, [selectedEwOptions]);

  const handleChangeAddEw = (value) => {
    setShowEwAdd(value);
    setSelectedEwOptions(null);
    setEwName("");
    setEwNumber("");
    setEwGender("");
  };

  const handleSaveData = () => {
    dispatch(resetEditUser());
    var locations = setLocationForApi();
    if (
      errors.farmerName === "" &&
      errors.phoneNumber === "" &&
      errors.ewName === "" &&
      errors.ewPhoneNumber === "" &&
      errors.areaUnderCotton === "" &&
      farmerName !== "" &&
      farmerNumber !== "" &&
      farmerStatus !== "" &&
      farmingType !== "" &&
      farmerPartner !== null &&
      cropName !== "" &&
      farmerType !== "" &&
      season !== "" &&
      protocol !== "" &&
      formattedSowingDate !== "" &&
      ewName !== "" &&
      ewNumber !== "" &&
      locations.village_ids.length > 0
    ) {
      setAddUserSnackBar(false);
      setSnackBarType("success");
      setAddUserSnackBarMessage("");
      var isActive = farmerStatus === "Active" ? true : false;
      setLoading(true);
      var params = {
        farmer_id: data.id,
        farmer_name: farmerName,
        farmer_phone_number: farmerNumber,
        farmer_type: farmerType,
        farming_type: farmingType,
        farmer_gender: farmerGender,
        total_land_holding: totalLandHolding === "" ? 0 : totalLandHolding,
        area_under_cotton: areaUnderCotton === "" ? 0 : areaUnderCotton,
        season_code: season,
        crop_name: cropName,
        protocol: protocol,
        sowing_date: formattedSowingDate,
        partner_id: farmerPartner.id,
        ew_name: ewName,
        ew_phone_number: ewNumber,
        ew_gender: ewGender,
        is_ew_lead_farmer: isEwLead,
        location: locations,
        is_active: isActive,
        previous_farmer_type:data.farmer_type
      };

      dispatch(editUser(params)).then(() => {
        setLoading(false);
      });
    } else {
      setAddUserSnackBar(true);
      setSnackBarType("error");
      setAddUserSnackBarMessage("Please Fill all required values");
    }
  };

  useEffect(() => {
    if (editUserIsSuccess && editUserApiResponse !== "") {
      setUserSnackBarMessage(editUserApiResponse);
      setReload(true);
      handleClose();
      dispatch(resetEditUser());
    } else if (!editUserIsSuccess && editUserErrorMessage !== "") {
      setAddUserSnackBar(true);
      setSnackBarType("error");
      setAddUserSnackBarMessage(editUserErrorMessage);
    }
  }, [editUserIsSuccess, editUserErrorMessage, editUserApiResponse]);

  return (
    <Modal
      open={openModal}
    >
      <Grid container sx={subGridStyle} spacing={1}>
        <ThemeProvider theme={basicTheme}>
            <Grid item xs={11}>
              <Typography variant="h4" style={{ marginBottom: 10 }}>
                Edit Farmer
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <ClearIcon
                className={classes.closeBtnStyle}
                onClick={handleCloseByClearIcon}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ marginBottom: 1 }}>Farmer Name</Typography>
                  <BasicTextfield
                    label="Enter Farmer Name"
                    is_phone={false}
                    handleChange={handleChangeFarmerName}
                    is_disabled={false}
                    index={1}
                    error={errors.farmerName !== ""}
                    helperText={errors.farmerName}
                    required={true}
                    value={farmerName}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography sx={{ marginBottom: 1 }}>
                    Farmer Mobile Number
                  </Typography>
                  <BasicTextfield
                    label="Enter Farmer Mobile Number"
                    is_phone={true}
                    handleChange={handleChangeFarmerNumber}
                    is_disabled={false}
                    index={2}
                    error={errors.phoneNumber !== ""}
                    helperText={errors.phoneNumber}
                    required={true}
                    value={farmerNumber}
                    number={true}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ marginBottom: 1 }}>
                    Farmer Gender
                  </Typography>
                  <BasicDropDown
                    text="Select Farmer Gender"
                    value={farmerGender}
                    handleChange={handleChangeFarmerGender}
                    menuList={genderList}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ marginBottom: 1 }}>Farming Type</Typography>
                  <OptionalDropDown
                    text="Select Farming Type"
                    id={
                      getFarmingTypeDataResponse[0].name !== ""
                        ? farmingType
                        : ""
                    }
                    handleChange={(e) => {
                      setFarmingType(e.target.value);
                    }}
                    menuList={getFarmingTypeDataResponse}
                    disabled={false}
                    required={true}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography sx={{ marginBottom: 1 }}>
                    Do you have Traps installed in your farm?
                  </Typography>
                  <BasicDropDown
                    text="Select Trap Installed"
                    value={trapInstalled}
                    handleChange={handleChangeTrapStatus}
                    menuList={yesNoList}
                    error={false}
                    required={true}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ marginBottom: 1 }}>Farmer Type</Typography>
                  <BasicDropDown
                    text="Select Farmer Type"
                    value={farmerType}
                    handleChange={handleChangeFarmerType}
                    menuList={farmerTypeList}
                    error={false}
                    required={true}
                    disable={true}
                  />
                </Grid>
                {farmerType === "Lead" && (
                  <React.Fragment>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ marginBottom: 1 }}>
                        Total Land Holding
                      </Typography>
                      <BasicTextfield
                        label="Enter Land Holding"
                        is_phone={false}
                        handleChange={handleChangeTotalLandHolding}
                        is_disabled={false}
                        index={3}
                        required={false}
                        value={totalLandHolding}
                        number={true}
                        unit="Acres/ Hectares"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ marginBottom: 1 }}>
                        Area Under Cotton
                      </Typography>
                      <BasicTextfield
                        label="Enter Area Under Cotton"
                        is_phone={false}
                        handleChange={handleChangeAreaUnderCotton}
                        error={errors.areaUnderCotton !== ""}
                        helperText={errors.areaUnderCotton}
                        is_disabled={false}
                        index={3}
                        required={false}
                        value={areaUnderCotton}
                        number={true}
                        unit="Acres/ Hectares"
                      />
                    </Grid>
                  </React.Fragment>
                )}
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ marginBottom: 1 }}>Season</Typography>
                  <OptionalDropDown
                    text="Select Season"
                    id={getSeasonsDataResponse[0].code !== "" ? season : ""}
                    handleChange={(e) => {
                      setSeason(e.target.value);
                    }}
                    menuList={getSeasonsDataResponse}
                    disabled={false}
                    required={true}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ marginBottom: 1 }}>Crop Name</Typography>
                  <OptionalDropDown
                    text="Select Crop Name"
                    id={getCropDataResponse[0].name !== "" ? cropName : ""}
                    handleChange={(e) => {
                      setCropName(e.target.value);
                    }}
                    menuList={getCropDataResponse}
                    disabled={false}
                    required={true}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ marginBottom: 1 }}>
                    Date of Sowing
                  </Typography>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      inputFormat="DD/MM/YYYY"
                      value={sowingDate}
                      format="DD/MM/YYYY"
                      onChange={onDateChange}
                      renderInput={(params) => (
                        <TextField
                          onKeyDown={(e) => e.preventDefault()}
                          {...params}
                          sx={{
                            marginBottom: 1,
                            borderRadius: 2,
                            width: "90%",
                            backgroundColor: "#F5F5F5",
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ marginBottom: 1 }}>Protocol</Typography>
                  <BasicDropDown
                    text="Select Protocol"
                    value={protocol}
                    handleChange={handleChangeProtocol}
                    menuList={protocolList}
                    error={false}
                    required={true}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ marginBottom: 1 }}>
                    Farmer Status
                  </Typography>
                  <BasicDropDown
                    text="Select Farmer Status"
                    value={farmerStatus}
                    handleChange={handleChangeFarmerStatus}
                    menuList={userStatusList}
                    error={false}
                    required={true}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography
                    sx={{
                      marginBottom: 1,
                    }}
                  >
                    Partner Name
                  </Typography>
                  <AutoCompleteDropdown
                    width="auto"
                    menuList={partnerNamesData}
                    selectedOptions={
                      partnerNamesData[0].id !== "" ? farmerPartner : null
                    }
                    setSelectedOptions={setFarmerPartner}
                    disable={partnerNamesData[0].id === "" || loggedInUser.details.role !== "Wadhwani Admin"}
                    label="Select Partner"
                    onHighlightChange={handleChangeFarmerPartner}
                    required={true}
                  />
                </Grid>

                {farmerPartner !== null && (
                  <React.Fragment>
                    {Object.keys(partnerLocations).length !== 0 &&
                      <>
                      <Grid item xs={12}>
                        <Typography sx={{ marginBottom: 1 }}>
                          Location{" "}
                        </Typography>
                      </Grid>
                      <LocationDropDown
                        stateList={getStatesResponse}
                        partnerLocations={partnerLocations}
                        selectedStateOptions={selectedStateOptions}
                        setSelectedStateOptions={setSelectedStateOptions}
                        selectedDistrictOptions={selectedDistrictOptions}
                        setSelectedDistrictOptions={setSelectedDistrictOptions}
                        selectedTalukaOptions={selectedTalukaOptions}
                        setSelectedTalukaOptions={setSelectedTalukaOptions}
                        selectedVillageOptions={selectedVillageOptions}
                        setSelectedVillageOptions={setSelectedVillageOptions}
                        handleChangeLocation={handleChangeLocation}
                      />
                      </>
                    }
                    <Grid item xs={12} sm={6}>
                      <Typography
                        sx={{
                          marginBottom: 1,
                          color:
                            showEwAdd || ewList.length === 0
                              ? "#ACADAD"
                              : "#444444",
                        }}
                      >
                        Extension Worker
                      </Typography>
                      <AutoCompleteDropdown
                        width="auto"
                        menuList={ewList}
                        selectedOptions={
                          ewList.length !== 0 ? selectedEwOptions : null
                        }
                        setSelectedOptions={setSelectedEwOptions}
                        nameNumber={true}
                        disable={showEwAdd || ewList.length === 0}
                        label="Select Extension Worker"
                        required={true}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControlLabel
                        sx={{
                          color: "#2D6A4F",
                          marginTop: 5,
                          "&.Mui-checked": { color: "#2D6A4F" },
                        }}
                        label="Add Extension Worker"
                        control={
                          !showEwAdd ? (
                            <Checkbox checked={false} />
                          ) : (
                            <Checkbox checked={true} />
                          )
                        }
                        onChange={(e) => handleChangeAddEw(e.target.checked)}
                      />
                    </Grid>
                    {showEwAdd && (
                      <React.Fragment>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ marginBottom: 1 }}>
                            Extension Worker Name
                          </Typography>
                          <BasicTextfield
                            label="Enter Extension Worker Name"
                            is_phone={false}
                            handleChange={handleChangeEwName}
                            is_disabled={false}
                            index={4}
                            error={errors.ewName !== ""}
                            helperText={errors.ewName}
                            required={true}
                            value={ewName}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ marginBottom: 1 }}>
                            Extension Worker Mobile Number
                          </Typography>
                          <BasicTextfield
                            label="Enter Extension Worker Mobile Number"
                            is_phone={true}
                            handleChange={handleChangeEwNumber}
                            is_disabled={false}
                            index={5}
                            error={errors.ewPhoneNumber !== ""}
                            helperText={errors.ewPhoneNumber}
                            required={true}
                            value={ewNumber}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ marginBottom: 1 }}>
                            Extension Worker Gender
                          </Typography>
                          <BasicDropDown
                            text="Select Extension Worker Gender"
                            value={ewGender}
                            handleChange={handleChangeEwGender}
                            menuList={genderList}
                          />
                        </Grid>
                      </React.Fragment>
                    )}
                  </React.Fragment>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                item
                sm={12}
                spacing={0}
                sx={{
                  direction: "row",
                  justifyContent: "center",
                  marginTop: 5,
                  marginBottom: 5,
                }}
              >
                <Grid
                  item
                  xs={12}
                  sm={2.5}
                  style={{ backgroundColor: "transparent", boxShadow: "none" }}
                >
                  <CancelButtonStyle
                    variant="contained"
                    name="cancel"
                    onClick={handleClose}
                  >
                    Cancel
                  </CancelButtonStyle>
                </Grid>
                <Grid item xs={12} sm={2.5}>
                  <ButtonStyle
                    variant="contained"
                    name="add"
                    startIcon={<CheckIcon size="large" />}
                    onClick={handleSaveData}
                  >
                    Save
                  </ButtonStyle>
                </Grid>
                <Grid item xs={12}>
                  {addUserSnackBar && (
                  <SnackBars
                    open={addUserSnackBar}
                    handleClose={() => setAddUserSnackBar(false)}
                    severity={snackBarType}
                    message={addUserSnackBarMessage}
                    autoHideDuration={60000}
                    vertical="bottom"
                    horizontal="center"
                    style = {{ willChange: "transform" }}
                  />
                  )}
                </Grid>
              </Grid>
            </Grid>
            
        </ThemeProvider>
        {loading &&
            <ReactLoader customLoader={<CircularProgress />} width = "100%" height="1500px" sentences = {[]}/>
        }
      </Grid>
    </Modal>
  );
}

