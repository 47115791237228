import React, { useState, useEffect, useRef } from "react";
import { styled } from "@mui/material/styles";
import { Grid, Box, Typography, ThemeProvider, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Badge from "@mui/material/Badge";
import Icon from "@mui/material/Icon";
import { ReactComponent as FilterIcon } from "../../styles/FilterIcon.svg";

const MainGrid = styled(Grid)({
  direction: "row",
  justifyContent: "center",
  alignItems: "center",
});
const GridItemEnd = styled(Grid)({
  flex: "1",
  justifyContent: "flex-end",
  justify: "flex-end",
  alignItems: "center",
  display: "flex",
  // marginRight:"20px"
});

export const CenteredTabs = styled(Tabs)({
  flexContainer: {
    justifyContent: "center",
    alignItems: "center",
  },
});

export default function TopFilterBar({
  setSelectedTabValue = () => {},
  selectedTabValue = () => {},
  totalPending = 0,
  totalCompleted = 0,
}) {
  const handleChange = (event, newValue) => {
    setSelectedTabValue(newValue);
    window.localStorage.setItem("reviewImagesTab", JSON.stringify(newValue));
  };

  return (
    <MainGrid container style={{ paddingLeft: "80px" }}>
      <Grid item xs={8}>
        <CenteredTabs
          value={selectedTabValue}
          onChange={handleChange}
          indicatorColor={""}
          centered
          sx={{
            width: { md: "500px", lg: "600px", xl: "672px" },
            height: "58px",
            borderRadius: "16px",
            backgroundColor: "#F5F5F5",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            "& .Mui-selected": {
              backgroundColor: "#FFFFFF",
              borderRadius: "14px",
              height: "48px",
              marinTop: "10px",
              color: "#2D6A4F",
            },
          }}
        >
          <Tab
            value="pending"
            label={`Pending Images (${totalPending===-1?"-":totalPending})`}
            style={{
              color: selectedTabValue === "pending" ? "#2D6A4F" : "#78828C",
            }}
            sx={{
              fontSize: "20px",
              width: { xl: "330px", lg: "290px", md: "240px" },

              textTransform: "none",
            }}
          />
          <Tab
            value="completed"
            label={`Completed (${totalCompleted===-1?"-":totalCompleted})`}
            style={{
              color: selectedTabValue === "completed" ? "#2D6A4F" : "#78828C",
            }}
            sx={{
              fontSize: "20px",
              width: { xl: "330px", lg: "290px", md: "240px" },
              textTransform: "none",
            }}
          />
        </CenteredTabs>
      </Grid>
      <GridItemEnd item xs={4}>
        {/* <Typography
          variant="h5"
          noWrap
          component="div"
          sx={{
            color: "#78828C",
            fontFamily: "Inter",
            fontSize: "16px",
          }}
        >
          Filter
        </Typography>
        <Badge
          variant="dot"
          invisible={true}
          sx={{
            "& .MuiBadge-badge": {
              color: "red",
              backgroundColor: "red",
            },
          }}
        >
          <Icon
            sx={{
              height: "50px",
              width: "50px",
              color: "#ACADAD",
              stroke: "#F8F8F8",
              marginLeft: 1,
              marginRight: "56px",
            }}
            // onClick={handleShowFilterPopOver}
          >
            <FilterIcon />
          </Icon>
        </Badge> */}
      </GridItemEnd>
    </MainGrid>
  );
}
