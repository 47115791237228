import React, { useState, useEffect } from "react";
import Popover from "@mui/material/Popover";
import { Grid, Button, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles"
import { displayDropdown } from "../../utils/index";
import { useDispatch, useSelector } from "react-redux";
import { getPestData, getCropData, getFarmingTypeData } from "../../features/advisoryManagement/advisoryManagementSlice";
import ClearIcon from "@mui/icons-material/Clear";
import OptionalDropDown from "../reusableComponents/OptionalDropDown";
const StyledGrid = styled(Grid)({
    display: "flex",
    width: "700px",
});

const ClearBtn = styled(Button)({
    backgroundColor: "#FFFFFF",
    color: "#2D6A4F",
    textTransform: "none",
    borderRadius: 10,
    fontSize: "18px",
    fontWeight: "600",
    marginLeft: 20,
    marginBottom: 20,
    marginTop: 30,
    width: "80%",
    height: "60%",
    borderRadius: 10,
    borderColor: "1px solid #2D6A4F",
});
const ApplyBtn = styled(Button)({
    backgroundColor: "#2D6A4F",
    color: "#FFFFFF",
    textTransform: "none",
    borderRadius: 10,
    fontSize: "18px",
    fontWeight: "600",
    marginLeft: 20,
    marginBottom: 20,
    marginTop: 30,
    width: "80%",
    height: "60%",
    borderRadius: 10,
    borderColor: "1px solid #2D6A4F",
});

const useStyles = makeStyles(() => ({
    closeBtnStyle: {
        display: "flex",
        justifyContent: "flex-end",
        zoom: "1.5",
        alignItems: "right",
        "&:hover": {
            color: "#081C15",
        },
        cursor: "pointer",
    },
}));

export default function PesticideFilterPopOver({
    handleClose = () => { },
    openFilterPopOver,
    setApplyFilter = () => { },
    filterBy,
    setFilterBy = () => { },
    selectedPest,
    setSelectedPest = () => { },
    selectedFarmingType,
    setSelectedFarmingType = () => { },
    clearFilter,
    setClearFilter = () => { },
}) {
    // const stateList = [];
    const dispatch = useDispatch();
    const classes = useStyles();
    const open = Boolean(openFilterPopOver);
    const id = open ? "filter-popover" : undefined;
    const [PestId, setPestId] = React.useState(selectedPest);
    const [FarmingTypeId, setFarmingTypeId] = React.useState(selectedFarmingType);

    const handleChangePest = (event) => {
        setPestId(event.target.value);
        setSelectedPest(event.target.value);
    };

    const handleChangeFarmingType = (event) => {
        setFarmingTypeId(event.target.value);
        setSelectedFarmingType(event.target.value);
    };

    const handleApplyFilter = () => {
        setFilterBy([{
            type: "pest_code",
            value: PestId ? [PestId] : []
        },
        {
            type: "farming_type",
            value: FarmingTypeId ? [FarmingTypeId] : []
        }]
        );
        handleClose();
    };

    const getPestDataResponse = useSelector(
        (state) => state.pesticidesSliceReducer.pestData
    );
    useEffect(() => {
        if(getPestDataResponse[0].code==="")
        dispatch(
            getPestData({ fields: "code,name" })
        )
    }, [dispatch])

    const getFarmingTypeDataResponse = useSelector(
        (state) => state.pesticidesSliceReducer.farmingTypeData
    );
    useEffect(() => {
        if(getFarmingTypeDataResponse[0].name==="")
            dispatch(
                getFarmingTypeData({ fields: "name" })
            )
    }, [dispatch])

    useEffect(() => {
        if (clearFilter) {
            setClearFilter(false);
            setFarmingTypeId("");
            setPestId("");
            setFilterBy([]);
            setSelectedFarmingType("");
            setSelectedPest("");
        }
    }, [clearFilter]);

    return (
        <Popover
            id={id}
            open={open}
            anchorEl={openFilterPopOver}
            onClose={handleClose}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
            }}
            sx={{
                '& .MuiPopover-paper': {
                    padding: 2.5,
                    borderRadius: '20px',
                }
            }}
        >
            <StyledGrid container spacing={2}>
                <Grid item xs={11} sx={{ color: '#78828C' }}>
                    <Typography sx={{ fontSixe: "20px", color: "#999DA4" }}>Filter By</Typography>
                </Grid>
                <Grid item xs={1}>
                    <ClearIcon className={classes.closeBtnStyle} onClick={handleClose} />
                </Grid>
                <Grid container item sm={12} sx={{ color: '#5E6060' }}>
                    <Grid item xs={12} sm={6}>
                        <Typography sx={{ fontSixe: "18px", color: "#444444", margin: 1 }}>Pest Name</Typography>
                        <OptionalDropDown
                            text={"Select Pest Name"}
                            id={PestId}
                            handleChange={handleChangePest}
                            menuList={getPestDataResponse}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography sx={{ fontSixe: "18px", color: "#444444", margin: 1 }}>Farming Type</Typography>
                        <OptionalDropDown
                            text={"Select Farming Type"}
                            id={FarmingTypeId}
                            handleChange={handleChangeFarmingType}
                            menuList={getFarmingTypeDataResponse}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={3}>
                    <ClearBtn
                        variant="contained"
                        name="Clear Filter"
                        onClick={() => setClearFilter(true)}
                    >
                        Clear All
                    </ClearBtn>
                </Grid>
                <Grid item xs={3}>
                    <ApplyBtn
                        variant="contained"
                        name="Apply Filter"
                        onClick={handleApplyFilter}
                    >
                        Apply
                    </ApplyBtn>
                </Grid>
            </StyledGrid>
        </Popover>
    );
}
