import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import { useDispatch, useSelector } from "react-redux";
import {
  deletePartner,
  resetDeletePartner,
} from "../../features/partner/partnerSlice";
import { styled } from "@mui/material/styles";

const Centerstyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const closeIconStyle = {
  position: "absolute",
  right: 1,
  cursor: "pointer",
  color: (theme) => theme.palette.grey[600],
};
const DeleteButtonStyle = styled(Button)({
  backgroundColor: "#2D6A4F",
  color: "white",
  textTransform: "none",
  borderRadius: "8px",
  fontSize: "18px",
  fontWeight: "600",
  minWidth: "120px",
  maxWidth: "150px",
  marginLeft: 20,
});

const CancelButtonStyle = styled(Button)({
  backgroundColor: "white",
  color: "#2D6A4F",
  textTransform: "none",
  borderRadius: "8px",
  fontSize: "18px",
  fontWeight: "600",
  minWidth: "120px",
  maxWidth: "150px",
  marginRight: 20,
});

export default function DeletePartner({
  handleClose = () => {},
  open,
  deleteData = {},
  setSnackBarMessage = () => {},
  setReload = () => {},
  setOpenAlertModal = () => {},
}) {
  const dispatch = useDispatch();

  const deletePartnerApiResponse = useSelector(
    (state) => state.partnerSliceReducer.deletePartnerApiResponse.message
  );

  const deletePartnerErrorMessage = useSelector(
    (state) => state.partnerSliceReducer.deletePartnerErrorMessage.message
  );

  const deletePartnerIsSuccess = useSelector(
    (state) => state.partnerSliceReducer.deletePartnerIsSuccess
  );

  useEffect(() => {
    setSnackBarMessage(deletePartnerApiResponse);
  }, [deletePartnerApiResponse]);

  useEffect(() => {
    dispatch(resetDeletePartner());
  }, []);

  useEffect(() => {
    if (deletePartnerIsSuccess && deletePartnerApiResponse !== "") {
      setSnackBarMessage(deletePartnerApiResponse);
      setReload(true);
      handleClose();
      dispatch(resetDeletePartner());
    } else if (!deletePartnerIsSuccess && deletePartnerErrorMessage !== "") {
      setOpenAlertModal(true);
      handleClose();
      dispatch(resetDeletePartner());
    }
  }, [
    deletePartnerIsSuccess,
    deletePartnerErrorMessage,
    deletePartnerApiResponse,
  ]);

  const handleDelete = () => {
    dispatch(
      deletePartner({
        id: deleteData.id,
        is_deleted: true,
        name:deleteData.name,
        spoc_name: deleteData.spoc_details.name,
        spoc_email: deleteData.spoc_details.email,
        spoc_phone_number: deleteData.spoc_details.phone_number,
        is_active: deleteData.is_active,
        protocol: deleteData.protocol,
        location: deleteData.location,
      })
    );
  };

  return (
    <Dialog open={open}>
      <HighlightOffRoundedIcon sx={closeIconStyle} onClick={handleClose} />
      <DialogTitle sx={{ marginTop: 2 }}>
        Are you sure you want to delete this partner?
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" sx={Centerstyle}>
          {deleteData.name}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={Centerstyle}>
        <CancelButtonStyle
          variant="contained"
          onClick={handleClose}
          sx={{ marginRight: 5 }}
        >
          No
        </CancelButtonStyle>
        <DeleteButtonStyle
          variant="contained"
          sx={{ background: "#EB5451", marginLeft: 5 }}
          onClick={handleDelete}
          autoFocus
          disabled={deletePartnerErrorMessage !== ""}
        >
          YES, Delete
        </DeleteButtonStyle>
      </DialogActions>
    </Dialog>
  );
}
