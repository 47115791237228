import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteSupervisor,
  resetDeleteSupervisor,
} from "../../features/supervisorAccess/supervisorAccessSlice";
import { styled } from "@mui/material/styles";

const Centerstyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const closeIconStyle = {
  position: "absolute",
  right: 1,
  cursor: "pointer",
  color: (theme) => theme.palette.grey[600],
};
const DeleteButtonStyle = styled(Button)({
  backgroundColor: "#2D6A4F",
  color: "white",
  textTransform: "none",
  borderRadius: "8px",
  fontSize: "18px",
  fontWeight: "600",
  minWidth: "120px",
  maxWidth: "150px",
  marginLeft: 20,
});

const CancelButtonStyle = styled(Button)({
  backgroundColor: "white",
  color: "#2D6A4F",
  textTransform: "none",
  borderRadius: "8px",
  fontSize: "18px",
  fontWeight: "600",
  minWidth: "120px",
  maxWidth: "150px",
  marginRight: 20,
});

export default function DeleteSupervisor({
  handleClose = () => {},
  open,
  deleteData = {},
  setSnackBarMessage = () => {},
  setReload = () => {},
  setSnackBarType = ()=>{},
}) {
  const dispatch = useDispatch();

  const deleteSupervisorApiResponse = useSelector(
    (state) => state.supervisorSliceReducer.deleteSupervisorApiResponse.message
  );

  const deleteSupervisorErrorMessage = useSelector(
    (state) => state.supervisorSliceReducer.deleteSupervisorErrorMessage.message
  );

  const deleteSupervisorIsSuccess = useSelector(
    (state) => state.supervisorSliceReducer.deleteSupervisorIsSuccess
  );

  useEffect(() => {
    setSnackBarMessage(deleteSupervisorApiResponse);
  }, [deleteSupervisorApiResponse]);

  useEffect(() => {
    dispatch(resetDeleteSupervisor());
  }, []);

  useEffect(() => {
    if (deleteSupervisorIsSuccess && deleteSupervisorApiResponse !== "") {
      setSnackBarMessage(deleteSupervisorApiResponse);
      setReload(true);
      handleClose();
      dispatch(resetDeleteSupervisor());
    }
     else if (!deleteSupervisorIsSuccess && deleteSupervisorErrorMessage !== "") {
      setSnackBarMessage(deleteSupervisorErrorMessage);
      setSnackBarType("error");
      dispatch(resetDeleteSupervisor());
    }
  }, [
    deleteSupervisorIsSuccess,
    deleteSupervisorErrorMessage,
    deleteSupervisorApiResponse,
  ]);

  const handleDelete = () => {
    
    var params = {
      id: deleteData.id,
      is_deleted: true,
      name:deleteData.name,
      email:deleteData.email,
      phone_number: deleteData.phone_number,
      role: deleteData.role,
      location: deleteData.location,
      is_active: deleteData.is_active,
    };
    if (deleteData.role !== "Government") {
      params.partner = deleteData.partner.id;
    }
    dispatch(
      deleteSupervisor(params)
    );
  };

  return (
    <Dialog open={open}>
      <HighlightOffRoundedIcon sx={closeIconStyle} onClick={handleClose} />
      <DialogTitle sx={{ marginTop: 2 }}>
        Are you sure you want to delete this Supervisor?
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" sx={Centerstyle}>
          {deleteData.name}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={Centerstyle}>
        <CancelButtonStyle
          variant="contained"
          onClick={handleClose}
          sx={{ marginRight: 5 }}
        >
          No
        </CancelButtonStyle>
        <DeleteButtonStyle
          variant="contained"
          sx={{ background: "#EB5451", marginLeft: 5 }}
          onClick={handleDelete}
          autoFocus
          disabled={deleteSupervisorErrorMessage !== ""}
        >
          YES, Delete
        </DeleteButtonStyle>
      </DialogActions>
    </Dialog>
  );
}
