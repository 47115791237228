import React, { useState, useEffect, useContext } from "react";
import Popover from "@mui/material/Popover";
import { Grid, Button, Typography, TextField, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import { getStatesData } from "../../features/location/locationSlice";
import MultiSelectDropDownComma from "../reusableComponents/MultiSelectDropDownComma";
import ClearIcon from "@mui/icons-material/Clear";
import { getPartnerNames } from "../../features/partner/partnerSlice";
import AutoCompleteMultiDropdown from "../reusableComponents/AutoCompleteMultiDropDown";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import BasicDropDown from "../reusableComponents/BasicDropDown";
import { Block } from "@mui/icons-material";
import { flexbox } from "caniuse-lite/data/features";
import CommonContext from "../../features/appbar/ContextProvider";

const StyledGrid = styled(Grid)({
  display: "flex",
});

const ClearBtn = styled(Button)({
  backgroundColor: "#FFFFFF",
  color: "#2D6A4F",
  textTransform: "none",
  borderRadius: 10,
  fontSize: "18px",
  fontWeight: "600",
  marginLeft: 20,
  marginBottom: 20,
  marginTop: 30,
  width: "140px",
  height: "50px",
  borderRadius: 10,
  borderColor: "1px solid #2D6A4F",
});
const ApplyBtn = styled(Button)({
  backgroundColor: "#2D6A4F",
  color: "#FFFFFF",
  textTransform: "none",
  borderRadius: 10,
  fontSize: "18px",
  fontWeight: "600",
  marginLeft: 20,
  marginBottom: 20,
  marginTop: 30,
  width: "140px",
  height: "50px",
  borderRadius: 10,
  borderColor: "1px solid #2D6A4F",
});

const useStyles = makeStyles(() => ({
  closeBtnStyle: {
    display: "flex",
    justifyContent: "flex-end",
    zoom: "1.5",
    alignItems: "right",
    "&:hover": {
      color: "#081C15",
    },
    cursor: "pointer",
    marginLeft: 8,
  },
}));

export default function DashboardFilterPopOver({
  handleClose = () => {},
  setApplyFilter = () => {},
  openFilterPopOver,
  startDate = "",
  setStartDate = () => {},
  endDate = "",
  setEndDate = () => {},
  clearFilter = false,
  setClearFilter = () => {},
  selectedStatesOptions = [],
  setSelectedStatesOptions = () => {},
  sessionGeneratedBy = "",
  appUsageBy = false,
  sessionGeneratedInState = "",
  setSessionGeneratedBy = () => {},
  setSessionGeneratedInState = () => {},
  sessionGeneratedByMenu = [],
  selectedPartnersOptions = [],
  setSelectedPartners = () => {},
  selectedProtocols = [],
  setSelectedProtocols = () => {},
  sessionGeneratedInStateMenu = [],
  sessionFilterLabel="",
  stateFilterLabel = ""
}) {
  console.log(sessionGeneratedBy)
  const dispatch = useDispatch();
  const classes = useStyles();


  const open = Boolean(openFilterPopOver);
  const id = open ? "filter-popover" : undefined;
  const [loading, setLoading] = useState(false);
  const { setDashboardFilterSelected } = useContext(CommonContext);

  const handleChangeApplyFilter = () => {
    setDashboardFilterSelected(true);
    setApplyFilter(true);
  };
  const partnerNamesData = useSelector(
    (state) => state.partnerSliceReducer.partnerNames.data
  );
  const [protocolIds, setProtocolIds] = useState(selectedProtocols);
  const protocolData = [
    { id: 1, name: "Weekly Monitoring Protocol" },
    { id: 2, name: "CICR" },
  ];

  const loggedInUser = JSON.parse(window.localStorage.getItem("loggedInUser"));

  const handleChangeClearFilter = () => {
    setDashboardFilterSelected(false);
    setClearFilter(true);
  };

  // useEffect(() => {
   
  // }, [clearFilter]);


  useEffect(() => {
    if (loggedInUser.details.partner !== null)
      setSelectedPartners([loggedInUser.details.partner]);
    if (getStatesResponse[0].id === "") dispatch(getStatesData());
    if (partnerNamesData[0].id === "") {
      setLoading(true);
      dispatch(
        getPartnerNames({
          fields: ["name", "id"].toString(),
          filters: JSON.stringify([{ type: "is_active", value: [true] }]),
          internal:true
        })
      ).then(() => setLoading(false));
    }
  }, []);


  useEffect(() => {
    if (clearFilter){ 
      setStartDate(dayjs().subtract(1, "year"));
      setEndDate(dayjs(new Date()));
      setProtocolIds([]);
      setSelectedPartners([]);
      var tempFilter = [
        { type: "protocol", value: [] },
        { type: "location", value: [] },
        {type:"state",value:[]},
        {type:"program_id",value:[]},
      ];
      // if (loggedInUser.details.partner === null) {
      //   tempFilter.push({ type: "name", value: []});
      // } else {
      //   tempFilter.push({
      //     type: "name",
      //     value: [loggedInUser.details.partner.name],
      //   });
      
      setApplyFilter(tempFilter);
      setSelectedProtocols([]);
    }
  }, [clearFilter]);

  const onStartDateChange = (newValue) => {
    setStartDate(newValue);
  };
  const onEndDateChange = (newValue) => {
    setEndDate(newValue);
  };

  const handleChangeSessionGeneratedBy = (event) => {
    setSessionGeneratedBy(event.target.value);
  };

 

 const handleChangeSessionGeneratedInState = (event) => {
  setSessionGeneratedInState(event.target.value);
 } 

 const getStatesResponse = useSelector(
  (state) => state.locationSliceReducer.statesData
);
  useEffect(() => {
    if(getStatesResponse[0].id==="")
        dispatch(getStatesData());
  }, []);
  return (
   
    <Popover
      id={id}
      open={open}
      anchorEl={openFilterPopOver}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      sx={{
        "& .MuiPopover-paper": {
          padding: 2.5,
          borderRadius: "20px",
        },
      }}
      onClose={handleClose}
    >
      <StyledGrid
        container
        sx={{
          maxWidth: "100%",
          width: {
            xl: sessionGeneratedBy !== "" ? "800px" : "600px",
            lg: sessionGeneratedBy !== "" ? "600px" : "450px",
          },
        }}
      >
        <Grid item xs={11} sx={{ color: "#78828C" }}>
          <Typography sx={{ fontSixe: "20px", color: "#999DA4" }}>
            Filter By
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <ClearIcon className={classes.closeBtnStyle} onClick={handleClose} />
        </Grid>
        <Grid container item sm={12} sx={{ color: "#5E6060" }}></Grid>
        { !appUsageBy && (<Grid item xs={6} md={sessionGeneratedBy!=="" ? 4 : 5}>
          <Typography sx={{ fontSixe: "18px", color: "#444444", margin: 1 }}>
            From
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              maxDate={dayjs(new Date())}
              inputFormat="DD/MM/YYYY"
              value={startDate}
              onChange={onStartDateChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  onKeyDown={(e) => e.preventDefault()}
                  sx={{
                    marginBottom: 1,
                    borderRadius: 2,
                    width: "90%",
                    backgroundColor: "#F5F5F5",
                  }}
                />
              )}
            />
          </LocalizationProvider>
        </Grid>)}
      { !appUsageBy && (  <Grid item xs={6} md={sessionGeneratedBy!=="" ? 4 : 5}>
          <Typography sx={{ fontSixe: "18px", color: "#444444", margin: 1 }}>
            To
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              maxDate={dayjs(new Date())}
              inputFormat="DD/MM/YYYY"
              value={endDate}
              onChange={onEndDateChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  onKeyDown={(e) => e.preventDefault()}
                  sx={{
                    marginBottom: 1,
                    borderRadius: 2,
                    width: "90%",
                    backgroundColor: "#F5F5F5",
                  }}
                />
              )}
            />
          </LocalizationProvider>
        </Grid>)}
        {(sessionGeneratedBy || appUsageBy)&&(
          <Grid item xs={6} md={(appUsageBy)?6:4}>
            <Typography sx={{ fontSixe: "18px", color: "#444444", margin: 1 }}>
              States
            </Typography>
            <AutoCompleteMultiDropdown
              menuList={getStatesResponse}
              selectedOptions={selectedStatesOptions}
              setSelectedOptions={setSelectedStatesOptions}
            />
          </Grid>
        )}

        {(sessionGeneratedBy || appUsageBy)&& (
          <Grid item xs={6} md={(appUsageBy)?6:4}>
            <Typography sx={{ fontSixe: "18px", color: "#444444", margin: 1 }}>
              Partners
            </Typography>
            <AutoCompleteMultiDropdown
             width={230}
            menuList={partnerNamesData}
            selectedOptions={selectedPartnersOptions}
            setSelectedOptions={setSelectedPartners}
            disable={loggedInUser.details.partner !== null}
            />
          </Grid>
        )}
       
        {sessionGeneratedBy && (
          <Grid item xs={6} md={4}>
            <Typography sx={{ fontSixe: "18px", color: "#444444", margin: 1}}>
              {sessionFilterLabel}
            </Typography>
            <BasicDropDown
              text={sessionFilterLabel}
              value={sessionGeneratedBy}
              handleChange={handleChangeSessionGeneratedBy}
              menuList={sessionGeneratedByMenu}
              error={false}
            />
          </Grid>
         
        )}
         <Grid xs={6}></Grid>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            gap: "20px",
          }}
        >
          <ClearBtn
            variant="contained"
            name="Clear Filter"
            onClick={handleChangeClearFilter}
          >
            Clear All
          </ClearBtn>

          <ApplyBtn
            variant="contained"
            name="Apply Filter"
            onClick={handleChangeApplyFilter}
          >
            Apply
          </ApplyBtn>
        </Box>
        {loading && (
          <Backdrop
            sx={{
              color: "#fff",
              position: "absolute",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
      </StyledGrid>
    </Popover>
  );
}
