import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import * as Structures from "../../apiResonseStructres/supervisorModule";
import {getHeaders} from "../../utils/index"
import { supervisorDetailsAPI, exportSupervisorDataAPI } from "../../api's/index";
const initialState = {
  supervisorData: Structures.GET_SUPERVISOR_DETAIL_API_RESPONSE,
  supervisorDataLoading: false,
  supervisorDataIsSuccess: false,
  supervisorDataErrorMessage: Structures.ERROR_MESSAGE_API_RESPONSE,
  supervisorNames: Structures.GET_SUPERVISOR_NAMES_API_RESPONSE,
  supervisorNamesLoading: false,
  supervisorNamesIsSuccess: false,
  supervisorNamesErrorMessage: Structures.ERROR_MESSAGE_API_RESPONSE,
  exportedData: [],
  addSupervisorApiResponse: Structures.ADD_SUPERVISOR_API_RESPONSE,
  addSupervisorErrorMessage: Structures.ERROR_MESSAGE_API_RESPONSE,
  addSupervisorIsSuccess: false,
  addSupervisorIsLoading: false,
  editSupervisorApiResponse: Structures.ADD_SUPERVISOR_API_RESPONSE,
  editSupervisorErrorMessage: Structures.ERROR_MESSAGE_API_RESPONSE,
  editSupervisorIsSuccess: false,
  editSupervisorIsLoading: false,
  deleteSupervisorApiResponse: Structures.ADD_SUPERVISOR_API_RESPONSE,
  deleteSupervisorErrorMessage: Structures.ERROR_MESSAGE_API_RESPONSE,
  deleteSupervisorIsSuccess: false,
};
var qs = require("qs");
const headers = getHeaders();


export const getSupervisorData = createAsyncThunk(
  "supervisor/getSupervisorData",
  async (data, { rejectWithValue }) => {
    try {
      const headers = getHeaders();
      const response = await axios.get(supervisorDetailsAPI, {
        params: data,
        headers: headers
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getSupervisorNames = createAsyncThunk(
  "supervisor/getSupervisorNames",
  async (data, { rejectWithValue }) => {
    try {
      const headers = getHeaders();
      const response = await axios.get(supervisorDetailsAPI, {
        params: data,
        headers: headers
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getExportedData = createAsyncThunk(
  "supervisor/getExportedData",
  async (data, { rejectWithValue }) => {
    try {
      const headers = getHeaders();
      const response = await axios.get(exportSupervisorDataAPI, {
        params: data,
        paramsSerializer: {
          serialize: (params) =>
            qs.stringify(params, { arrayFormat: "repeat" }),
        },
        headers: headers
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addSupervisor = createAsyncThunk(
  "supervisor/addSupervisor",
  async (data, { rejectWithValue }) => {
    try {
      const headers = getHeaders();
      const response = await axios({
        method: "post",
        url: supervisorDetailsAPI,
        data: data,
        headers: headers
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const editSupervisor = createAsyncThunk(
  "supervisor/editsupervisor",
  async (data, { rejectWithValue }) => {
    try {
      const headers = getHeaders();
      const response = await axios({
        method: "put",
        url: supervisorDetailsAPI,
        data: data,
        headers: headers
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteSupervisor = createAsyncThunk(
  "supervisor/deleteSupervisor",
  async (data, { rejectWithValue }) => {
    try {
      const headers = getHeaders();
      const response = await axios({
        method: "put",
        url: supervisorDetailsAPI,
        data: data,
        headers: headers
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const supervisorSlice = createSlice({
  name: "supervisor",
  initialState,
  reducers: {
    resetAddSupervisor(state) {
      Object.assign(
        state.addSupervisorApiResponse,
        Structures.ADD_SUPERVISOR_API_RESPONSE
      );
      Object.assign(
        state.addSupervisorErrorMessage,
        Structures.ERROR_MESSAGE_API_RESPONSE
      );
      Object.assign(state.addSupervisorIsSuccess, false);
      Object.assign(state.addSupervisorIsLoading, false);
    },
    resetEditSupervisor(state) {
      Object.assign(
        state.editSupervisorApiResponse,
        Structures.ADD_SUPERVISOR_API_RESPONSE
      );
      Object.assign(
        state.editSupervisorErrorMessage,
        Structures.ERROR_MESSAGE_API_RESPONSE
      );
      Object.assign(state.editSupervisorIsSuccess, false);
      Object.assign(state.editSupervisorIsLoading, false);
    },
    resetDeleteSupervisor(state) {
      Object.assign(
        state.deleteSupervisorApiResponse,
        Structures.ADD_SUPERVISOR_API_RESPONSE
      );
      Object.assign(
        state.deleteSupervisorErrorMessage,
        Structures.ERROR_MESSAGE_API_RESPONSE
      );
      Object.assign(state.deleteSupervisorIsSuccess, false);
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getSupervisorData.fulfilled, (state, { payload }) => {
        state.supervisorData = payload;
        state.supervisorDataLoading = false;
        state.supervisorDataIsSuccess = true;
      })
      .addCase(getSupervisorData.pending, (state, { payload }) => {
        state.supervisorDataLoading = true;
        state.supervisorDataIsSuccess = false;
      })
      .addCase(getSupervisorData.rejected, (state, { payload }) => {
        state.supervisorDataErrorMessage = payload;
        state.supervisorDataLoading = false;
        state.supervisorDataIsSuccess = false;
      })
      .addCase(getSupervisorNames.fulfilled, (state, { payload }) => {
        state.supervisorNames = payload;
        state.supervisorNamesLoading = false;
        state.supervisorNamesIsSuccess = true;
      })
      .addCase(getSupervisorNames.pending, (state, { payload }) => {
        state.supervisorNamesLoading = true;
        state.supervisorNamesIsSuccess = false;
      })
      .addCase(getSupervisorNames.rejected, (state, { payload }) => {
        state.supervisorNamesErrorMessage = payload;
        state.supervisorNamesLoading = false;
        state.supervisorNamesIsSuccess = false;
      })
      .addCase(getExportedData.fulfilled, (state, { payload }) => {
        state.exportedData = payload;
      })
      .addCase(addSupervisor.fulfilled, (state, { payload }) => {
        state.addSupervisorApiResponse = payload;
        state.addSupervisorIsSuccess = true;
        state.addSupervisorIsLoading = false;
      })
      .addCase(addSupervisor.rejected, (state, { payload }) => {
        state.addSupervisorErrorMessage = payload;
        state.addSupervisorIsSuccess = false;
        state.addSupervisorIsLoading = false;
      })
      .addCase(addSupervisor.pending, (state) => {
        state.addSupervisorIsLoading = true;
        state.addSupervisorIsSuccess = false;
      })
      .addCase(editSupervisor.fulfilled, (state, { payload }) => {
        state.editSupervisorApiResponse = payload;
        state.editSupervisorIsSuccess = true;
      })
      .addCase(editSupervisor.rejected, (state, { payload }) => {
        state.editSupervisorErrorMessage = payload;
        state.editSupervisorIsSuccess = false;
        state.editSupervisorIsLoading = false;
      })
      .addCase(editSupervisor.pending, (state) => {
        state.editSupervisorIsLoading = true;
        state.editSupervisorIsSuccess = false;
      })
      .addCase(deleteSupervisor.fulfilled, (state, { payload }) => {
        state.deleteSupervisorApiResponse = payload;
        state.deleteSupervisorIsSuccess = true;
      })
      .addCase(deleteSupervisor.rejected, (state, { payload }) => {
        state.deleteSupervisorErrorMessage = payload;
        state.deleteSupervisorIsSuccess = false;
      });
  },
});
export const {
  resetAddSupervisor,
  resetEditSupervisor,
  resetDeleteSupervisor,
} = supervisorSlice.actions;
export default supervisorSlice.reducer;
