import React, { useState, useEffect } from "react";
import { Button, Grid, Typography, Modal, ThemeProvider } from "@mui/material";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { basicTheme } from "../../themes/customTheme";
import Backdrop from "@mui/material/Backdrop";
import EWLocationDropDown from "./EwLocation";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import { getStatesData } from "../../features/location/locationSlice";
import BasicTextfield from "../reusableComponents/BasicTextfield";

import { getPartnerNames } from "../../features/partner/partnerSlice";
import { editEw, resetEditEw } from "../../features/appUsers/appUserSlice";
import SnackBars from "../reusableComponents/SnackBar";
import BasicDropDown from "../reusableComponents/BasicDropDown";

const subGridStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
  width: "65%",
  maxHeight: "80%",
  overflow: "auto",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-around",
  paddingLeft: 5,
};

const useStyles = makeStyles(() => ({
  closeBtnStyle: {
    display: "flex",
    justifyContent: "flex-end",
    zoom: "2.2",
    alignItems: "right",
    "&:hover": {
      color: "#081C15",
    },
    cursor: "pointer",
  },
}));

const ButtonStyle = styled(Button)({
  backgroundColor: "#2D6A4F",
  color: "white",
  textTransform: "none",
  borderRadius: "8px",
  fontSize: "16px",
  fontWeight: "600",
  minWidth: "140px",
  maxWidth: "150px",
  height: "54px",
});

const CancelButtonStyle = styled(Button)({
  backgroundColor: "white",
  color: "#2D6A4F",
  textTransform: "none",
  borderRadius: "8px",
  fontSize: "16px",
  fontWeight: "600",
  minWidth: "140px",
  maxWidth: "150px",
  height: "54px",
});

export default function EditEw({
  openModal,
  handleClose,
  data = [],
  setUserSnackBarMessage = () => {},
  setReload = () => {},
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [ewPartner, setEwPartner] = useState(data.partner.id);

  const activeStatus = data.status
    ? "Active"
    : data.status === false
    ? "Inactive"
    : "";
  const [ewStatus, setEwStatus] = useState(activeStatus || "");
  const [ewName, setEwName] = useState(data.name);
  const [ewNumber, setEwNumber] = useState(data.phone_number);
  const [ewGender, setEwGender] = useState(data.gender);
  const [editEwSnackBar, setEditEwSnackBar] = useState(false);
  const [editEwSnackBarMessage, setEditEwSnackBarMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [partnerLocations, setPartnerLocations] = useState([]);

  const basicError = {
    farmerName: "",
    phoneNumber: "",
    ewName: "",
    ewPhoneNumber: "",
  };
  const userStatusList = ["Active", "Inactive"];
  const genderList = ["Male", "Female", "Transgender", "Prefer not to say"];

  const [snackBarType, setSnackBarType] = useState("success");
  const [errors, setErrors] = useState(basicError);
  const [selectedStateOptions, setSelectedStateOptions] = useState(
    data.state_wise_location[0].state || null
  );
  const [selectedDistrictOptions, setSelectedDistrictOptions] = useState([]);

  const editEwApiResponse = useSelector(
    (state) => state.usersSliceReducer.editEwApiResponse.message
  );
  const editEwErrorMessage = useSelector(
    (state) => state.usersSliceReducer.editEwErrorMessage.message
  );

  const editEwIsSuccess = useSelector(
    (state) => state.usersSliceReducer.editEwIsSuccess
  );

  const partnerNamesData = useSelector(
    (state) => state.partnerSliceReducer.partnerNames.data
  );

  const getStatesResponse = useSelector(
    (state) => state.locationSliceReducer.statesData
  );

  useEffect(() => {
    dispatch(resetEditEw());
    dispatch(getStatesData());
    setLoading(true);
    dispatch(
      getPartnerNames({
        fields: ["name", "id", "location"].toString(),
        filters: JSON.stringify([{ type: "is_active", value: [true] }]),
      })
    ).then(() => setLoading(false));
    // filtering out districts
    var districtLocation = [];
    data.state_wise_location[0].locations.forEach((location) => {
      districtLocation.push(location.district);
    });
    setSelectedDistrictOptions(districtLocation);
  }, []);

  useEffect(() => {
    if (ewPartner !== "" && partnerNamesData[0].id !== "") {
      setPartnerLocations(
        partnerNamesData.find((item) => item.id === ewPartner).location
      );
    }
  }, [ewPartner, partnerNamesData]);

  const handleChangeEwName = (value) => {
    value = value.replace(/^\s+/g, "");
    const ALPHA_NUMERIC_DASH_REGEX = /^[a-zA-Z0-9\s]{1,}[\.]{0,1}[A-Za-z0-9\s]{0,}$/;
    if (value !== "" && !ALPHA_NUMERIC_DASH_REGEX.test(value)) value = "";
    if (value === "")
      setErrors({
        ...errors,
        ewName: "Extension Worker Name can't be empty ",
      });
    else {
      setErrors({ ...errors, ewName: "" });
    }
    setEwName(value);
  };

  const handleChangeEwPartner = (event) => {
    if (event.target.value !== (null || undefined)) {
      setEwPartner(event.target.value);
    }
  };

  const handleChangeEwNumber = (phoneNumber) => {
    phoneNumber = phoneNumber.trim();
    const reg = /^[1-9][0-9]*$/;
    if (phoneNumber === "") {
      setErrors({ ...errors, ewPhoneNumber: "Phone number can't be empty " });
    } else if (!reg.test(phoneNumber)) {
      setErrors({
        ...errors,
        ewPhoneNumber: "Phone number should not start with 0 ",
      });
    } else if (phoneNumber.length !== 10) {
      setErrors({
        ...errors,
        ewPhoneNumber: "Phone number should be 10 digits",
      });
    } else {
      setErrors({ ...errors, ewPhoneNumber: "" });
    }
    setEwNumber(phoneNumber);
  };

  const handleChangeEwStatus = (event) => {
    setEwStatus(event.target.value);
  };
  const handleChangeEwGender = (event) => {
    setEwGender(event.target.value);
  };

  const handleCloseByClearIcon = () => {
    setErrors(basicError);
    setEwPartner("");
    setEwGender("");
    setEwName("");
    setEwNumber("");
    setEditEwSnackBar(false);
    setEditEwSnackBarMessage("");
    setPartnerLocations(null);
    setSelectedDistrictOptions([]);
    setSelectedStateOptions(null);
    dispatch(resetEditEw());
    handleClose();
  };

  const setLocationForApi = () => {
    var locations = {
      state_ids: [],
      district_ids: [],
    };
    if (selectedStateOptions !== null) {
      locations["state_ids"].push(selectedStateOptions.id);
    }
    if (selectedDistrictOptions.length > 0) {
      selectedDistrictOptions.forEach((district) => {
        locations["district_ids"].push(district.id);
      });
    }
    return locations;
  };

  const handleSaveData = () => {
    dispatch(resetEditEw());
    var locations = setLocationForApi();
    if (
      ewStatus !== "" &&
      ewPartner !== "" &&
      ewName !== "" &&
      ewNumber !== "" &&
      selectedDistrictOptions.length > 0
    ) {
      setEditEwSnackBar(false);
      setSnackBarType("success");
      setEditEwSnackBarMessage("");
      var isActive = ewStatus === "Active" ? true : false;
      setLoading(true);
      var params = {
        id: data.id,
        name: ewName,
        phone_number: ewNumber,
        gender: ewGender,
        location: locations,
        is_active: isActive,
      };

      dispatch(editEw(params)).then(() => {
        setLoading(false);
      });
    } else {
      setEditEwSnackBar(true);
      setSnackBarType("error");
      setEditEwSnackBarMessage("Please Fill all required values");
    }
  };

  useEffect(() => {
    if (editEwIsSuccess && editEwApiResponse !== "") {
      setUserSnackBarMessage(editEwApiResponse);
      setReload(true);
      handleClose();
      dispatch(resetEditEw());
    } else if (!editEwIsSuccess && editEwErrorMessage !== "") {
      setEditEwSnackBar(true);
      setSnackBarType("error");
      setEditEwSnackBarMessage(editEwErrorMessage);
    }
  }, [editEwIsSuccess, editEwErrorMessage, editEwApiResponse]);

  return (
    <Modal
      open={openModal}
    >
      <Grid container sx={subGridStyle} spacing={1}>
        <ThemeProvider theme={basicTheme}>
            <Grid item xs={11}>
              <Typography variant="h4" style={{ marginBottom: 10 }}>
                Edit Extension Worker
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <ClearIcon
                className={classes.closeBtnStyle}
                onClick={handleCloseByClearIcon}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography
                    sx={{
                      marginBottom: 1,
                      color: "#ACADAD",
                    }}
                  >
                    Partner Name
                  </Typography>
                  <BasicDropDown
                    text="Select Partner"
                    value={partnerNamesData[0].id !== "" ? ewPartner : ""}
                    handleChange={handleChangeEwPartner}
                    menuList={
                      partnerNamesData[0].id !== "" ? partnerNamesData : []
                    }
                    error={false}
                    required={true}
                    disable={true}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ marginBottom: 1 }}>
                    Extension Worker Name
                  </Typography>
                  <BasicTextfield
                    label="Enter Extension Worker Name"
                    is_phone={false}
                    handleChange={handleChangeEwName}
                    is_disabled={false}
                    index={4}
                    error={errors.ewName !== ""}
                    helperText={errors.ewName}
                    required={true}
                    value={ewName}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography sx={{ marginBottom: 1 }}>
                    Extension Worker Mobile Number
                  </Typography>
                  <BasicTextfield
                    label="Enter Extension Worker Mobile Number"
                    is_phone={true}
                    handleChange={handleChangeEwNumber}
                    is_disabled={false}
                    index={5}
                    error={errors.ewPhoneNumber !== ""}
                    helperText={errors.ewPhoneNumber}
                    required={true}
                    value={ewNumber}
                    number={true}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ marginBottom: 1 }}>
                    Extension Worker Status
                  </Typography>
                  <BasicDropDown
                    text="Select Extension Worker Status"
                    value={ewStatus}
                    handleChange={handleChangeEwStatus}
                    menuList={userStatusList}
                    error={false}
                    required={true}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ marginBottom: 1 }}>
                    Extension Worker Gender
                  </Typography>
                  <BasicDropDown
                    text="Select Extension Worker Gender"
                    value={ewGender}
                    handleChange={handleChangeEwGender}
                    menuList={genderList}
                  />
                </Grid>
                {ewPartner !== "" && (
                  <React.Fragment>
                    <Grid item xs={12}>
                      <Typography sx={{ marginBottom: 1 }}>
                        Location{" "}
                      </Typography>
                    </Grid>
                    <EWLocationDropDown
                      getStatesResponse={getStatesResponse}
                      stateList={getStatesResponse}
                      partnerLocations={partnerLocations}
                      selectedStateOptions={selectedStateOptions}
                      setSelectedStateOptions={setSelectedStateOptions}
                      selectedDistrictOptions={selectedDistrictOptions}
                      setSelectedDistrictOptions={setSelectedDistrictOptions}
                    />
                  </React.Fragment>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                item
                sm={12}
                spacing={0}
                sx={{
                  direction: "row",
                  justifyContent: "center",
                  marginTop: 5,
                  marginBottom: 5,
                }}
              >
                <Grid
                  item
                  xs={12}
                  sm={2.5}
                  style={{ backgroundColor: "transparent", boxShadow: "none" }}
                >
                  <CancelButtonStyle
                    variant="contained"
                    name="cancel"
                    onClick={handleClose}
                  >
                    Cancel
                  </CancelButtonStyle>
                </Grid>
                <Grid item xs={12} sm={2.5}>
                  <ButtonStyle
                    variant="contained"
                    name="add"
                    startIcon={<CheckIcon size="large" />}
                    onClick={handleSaveData}
                  >
                    Save
                  </ButtonStyle>
                </Grid>
              </Grid>
            </Grid>
        </ThemeProvider>
        {loading && (
          <Backdrop
            sx={{
              color: "#fff",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        {editEwSnackBar && (
          <SnackBars
            open={editEwSnackBar}
            handleClose={() => setEditEwSnackBar(false)}
            severity={snackBarType}
            message={editEwSnackBarMessage}
            autoHideDuration={6000}
            vertical="bottom"
            horizontal="center"
          />
        )}
      </Grid>
    </Modal>
  );
}
