import React from "react";
import {
    TextField,
    InputAdornment,
  } from "@mui/material";
  import { makeStyles } from "@mui/styles";
  
  const useStyles = makeStyles({
    textfield: {
      '& input[type=number]': {
          '-moz-appearance': 'textfield'
      },
      '& input[type=number]::-webkit-outer-spin-button': {
          '-webkit-appearance': 'none',
          margin: 0
      },
      '& input[type=number]::-webkit-inner-spin-button': {
          '-webkit-appearance': 'none',
          margin: 0
      }
    },
  });
export default function BasicTextfield (
   { label="",
    is_phone = false,
    handleChange =()=>{},
    is_disabled =false,
    index=0,
    error = false,
    helperText = "",
    required = false,
    value = "",
    number = false,
    unit = "",
    borderRadius=2,
  }
  ) {
    const blockInvalidCharNumber = e => {(['e', 'E', '+', '-','.'].includes(e.key) || (e.which === 38 || e.which === 40)) && e.preventDefault()};
    const blockInvalidChar = e => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();
    const classes = useStyles();
    return (
      <TextField
        type={number===true ? "number" :"string"}
        onKeyDown={number===true && unit==="" ? blockInvalidCharNumber:unit!==""? blockInvalidChar:()=>{}}
        label={label}
        key={index}
        disabled={is_disabled}
        required={required}
        error={error}
        helperText={helperText}
        value={value}
        variant="outlined"
        className={classes.textfield}
        sx={{ width: "90%", backgroundColor: "#F5F5F5", borderRadius: borderRadius }}
        inputProps={{
          style: {
            height: "40%",
            borderRadius: borderRadius,
          },
          "aria-label": "Without label",
        }}
        InputProps={{
          startAdornment: is_phone && (
            <InputAdornment position="start">
              <p>+91 | </p>
            </InputAdornment>
          ),
          endAdornment:unit!=="" && (<InputAdornment position="end">{unit}</InputAdornment>)
        }}
        onChange={(e) => handleChange(e.target.value, index)}
      />
    );
  };