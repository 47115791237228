import React, { useEffect } from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import { Checkbox,Button, ListItemIcon } from '@mui/material';
import { borderRadius } from '@mui/system';



const MenuProps = {
  PaperProps: {
    style: {
      width: "168px",
      height:"325px",
      border: "1.5px solid #74C69D",
      boxShadow: "8px 8px 24px rgba(16, 65, 48, 0.12)",
      borderRadius:"16px",
      marginTop:"5px"

    },
  },
};

const ROLES = {
  partner_head: "Partner Head",
  state_head: "State Head",
  district_head: "District Head",
  taluka_head: "Taluka Head",
  government: "Government"
}

const options = [
  'create',
  'view',
  'edit',
  'delete'
];

function capitaliseFirstLetter(str) {
  if  (!str || str==="" || str == undefined){
    return str
  }
    
  let words = str.split(" ");
  for (let i = 0; i < words.length; i++) {
    if (words[i] && words[i].length>0)
      words[i] = words[i][0].toUpperCase() + words[i].substring(1);
  }
  let newStr = words.join(" ");
  return newStr
}

export default function MultipleSelectCheckmarks(
  permission, 
  handleEditPermission, 
  index, 
  role, 
  module, 
  setOpenRolesConfirmationBox, 
  setPermissionViaAlert, 
  discardAlert, 
  setDiscardAlert) {
  const [openMultiSelect, setOpenMultiSelect] = React.useState(false);
  const [givenPermission, setGivenPermission] = React.useState(permission[index][role] ? permission[index][role] : []);
  const isAllSelected =
    options.length > 0 && givenPermission.length === options.length;

  const handleChange = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      setGivenPermission(givenPermission.length === options.length ? [] : options);
      return;
    }
    setGivenPermission(value);
  };
  const handleSavdPermission = () => {
    setOpenMultiSelect(false)
    handleEditPermission({
      module: module,
      role: ROLES[role],
      permission: givenPermission
    })
  }
  const handleCancelPermission = () => {
    if (givenPermission === permission[index][role]) {
      setOpenMultiSelect(false)
      return
    }
    setOpenMultiSelect(false)
    setPermissionViaAlert({
      module: module,
      role: ROLES[role],
      permission: givenPermission
    })
    setOpenRolesConfirmationBox(true)
  }
  useEffect(() => {
    if (discardAlert) {
      setGivenPermission(permission[index][role] ? permission[index][role] : []);
    }
    setDiscardAlert(false)
  }, [discardAlert])


  return (
   
    <FormControl sx={{ m: 1, width: '95%', borderRadius: '30px', borderColor: "#CAEEDC", textAlign: "center" }}>
       
      <Select
        open={openMultiSelect}
        onOpen={() => setOpenMultiSelect(true)}
        onClose={() => handleCancelPermission()}
        labelId="demo-multiple-checkbox-label"
        id="demo-multiple-checkbox"
        multiple
        displayEmpty={true}
        value={givenPermission.map((permission) => capitaliseFirstLetter(permission))}
        onChange={handleChange}
        inputProps={{ 'aria-label': 'Without label' }}
        renderValue={(selected) => selected.length === 0 ? "None" : selected.join(' & ')}
        MenuProps={MenuProps}
        sx={{ borderRadius: '30px'}}

      >
        
        {options.map((name) => (
          <MenuItem key={name} value={name}>
            <Checkbox color="success" checked={givenPermission.indexOf(name) > -1} />
            <ListItemText primary={capitaliseFirstLetter(name)} />
          </MenuItem>
        ))}
        <MenuItem
          value="all"
          sx = {{ borderColor: "#CAEEDC"}}
         
        >
          <ListItemIcon>
            <Checkbox
              color="success"
              checked={isAllSelected}
              indeterminate={
                givenPermission.length > 0 && givenPermission.length < options.length
              }
            />
          </ListItemIcon>
          <ListItemText
            primary="all"
          />
        </MenuItem>
          <Button onClick={() => handleSavdPermission()} fullWidth variant="outlined" sx={{ color: '#FFFFFF', backgroundColor: '#F0F8F1', border : "1px solid #2D6A4F", borderRadius : "20.5px", background: "#2D6A4F", width:"100%"}} style={{ backgroundColor: '#2D6A4F' }}>Save</Button>
         
      </Select>   
    </FormControl>
  );
}
