import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import { useDispatch, useSelector } from "react-redux";
import {
    deletePartnerPesticideMapping,
    resetDeletePartnerPesticide
} from "../../features/advisoryManagement/advisoryManagementSlice";
import { styled } from "@mui/material/styles";
import SnackBars from "../reusableComponents/SnackBar";
const Centerstyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const closeIconStyle = {
  position: "absolute",
  right: 1,
  cursor: "pointer",
  color: (theme) => theme.palette.grey[600],
};
const DeleteButtonStyle = styled(Button)({
  backgroundColor: "#2D6A4F",
  color: "white",
  textTransform: "none",
  borderRadius: "8px",
  fontSize: "18px",
  fontWeight: "600",
  minWidth: "120px",
  maxWidth: "150px",
  marginLeft: 20,
});

const CancelButtonStyle = styled(Button)({
  backgroundColor: "white",
  color: "#2D6A4F",
  textTransform: "none",
  borderRadius: "8px",
  fontSize: "18px",
  fontWeight: "600",
  minWidth: "120px",
  maxWidth: "150px",
  marginRight: 20,
});

export default function DeletePartnerPesticideMapping({
  handleClose = () => {},
  open,
  deleteData = {},
  setSnackBarMessage = () => {},
  setReload = () => {},
  setOpenAlertModal = () => {},
  setAlertMessage = () => {},
}) {
  const dispatch = useDispatch();

  const deletePartnerPesticideMappingApiResponse = useSelector(
    (state) => state.pesticidesSliceReducer.deletePartnerPesticideMappingApiResponse.message
  );

  const deletePartnerPesticideMappingErrorMessage = useSelector(
    (state) => state.pesticidesSliceReducer.deletePartnerPesticideMappingErrorMessage.message
  );

  const deletePartnerPesticideMappingIsSuccess = useSelector(
    (state) => state.pesticidesSliceReducer.deletePartnerPesticideMappingIsSuccess
  );

  useEffect(() => {
    setSnackBarMessage(deletePartnerPesticideMappingApiResponse);
  }, [deletePartnerPesticideMappingApiResponse]);

  useEffect(() => {
    dispatch(resetDeletePartnerPesticide());
  }, []);

  useEffect(() => {
    if (deletePartnerPesticideMappingIsSuccess && deletePartnerPesticideMappingApiResponse !== "") {
      setSnackBarMessage(deletePartnerPesticideMappingApiResponse);
      setReload(true);
      handleClose();
      dispatch(resetDeletePartnerPesticide());
    } else if (!deletePartnerPesticideMappingIsSuccess && deletePartnerPesticideMappingErrorMessage !== "") {
      setOpenAlertModal(true);
      setAlertMessage(deletePartnerPesticideMappingErrorMessage);
      handleClose();
      dispatch(resetDeletePartnerPesticide());
    }
  }, [
    deletePartnerPesticideMappingIsSuccess,
    deletePartnerPesticideMappingApiResponse,
    deletePartnerPesticideMappingErrorMessage,
  ]);

  const handleDelete = () => {
    dispatch(
        deletePartnerPesticideMapping({
        id: deleteData.id,
      })
    );
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <HighlightOffRoundedIcon sx={closeIconStyle} onClick={handleClose} />
      <DialogTitle sx={{ marginTop: 2 }}>
        Are you sure you want to delete this Partner-Pesticide Mapping?
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" sx={Centerstyle}>
          Partner - {deleteData.partner.name} <br />
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={Centerstyle}>
        <CancelButtonStyle
          variant="contained"
          onClick={handleClose}
          sx={{ marginRight: 5 }}
        >
          No
        </CancelButtonStyle>
        <DeleteButtonStyle
          variant="contained"
          sx={{ background: "#EB5451", marginLeft: 5 }}
          onClick={handleDelete}
          autoFocus
          disabled={deletePartnerPesticideMappingErrorMessage  !== ""}
        >
          YES, Delete
        </DeleteButtonStyle>
      </DialogActions>
    </Dialog>
  );
}
