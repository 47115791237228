import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Select,
  MenuItem,
  Grid,
  TextField,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

import { makeStyles } from "@mui/styles";
import { basicTheme } from "../../themes/customTheme";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import Divider from "@mui/material/Divider";
import { ReactComponent as DownGraph } from "../../styles/down.svg";
import { ReactComponent as UpGraph } from "../../styles/up.svg";
import Icon from "@mui/material/Icon";

const MainGrid = styled(Grid)({
  flex: "1",
});

const ListItemEnd = styled(Grid)({
  flex: "1",
  justifyContent: "flex-end",
  justify: "flex-end",
  alignItems: "center",
  display: "flex",
});

const ListItemStart = styled(Grid)({
  flex: "1",
  justifyContent: "flex-start",
  justify: "flex-start",
  alignItems: "left",
  display: "flex",
});

const useStyles = makeStyles({
  card: {
    backgroundColor: "#FFFFFF",
    borderRadius: "24px",
  },
});

export default function LongCard({
  title = "",
  menuList = [],
  dataList = [],
  value = "",
  setValue = () => {},
  disable = false,
}) {
  const classes = useStyles();

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const drawerWidth = useSelector(
    (state) => state.appBarSliceReducer.drawerWidth
  );

  return (
    <MainGrid container>
      <Grid item xs={12}>
        <Card
          className={classes.card}
          style={{ borderRadius: "24px" }}
          sx={{
            width: {
              xl: drawerWidth === 0 ? "440px" : "400px",
              lg: drawerWidth === 0 ? "380px" : "300px",
              md: drawerWidth === 0 ? "320px" : "260px",
              sm: drawerWidth === 0 ? "260px" : "200px",
            },
            opacity: disable && 0.5, // Add opacity to make it look disabled
            pointerEvents: disable && "none", // Disable pointer events to prevent interaction
            height: { xl: "400px", lg: "370px", md: "330px", sm: "300px" },
          }}
          disabled={disable}
        >
          {disable && (
            <Box
              sx={{
                // position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                // justifyContent: "center",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                borderRadius: "24px",
                zIndex: 1,
              }}
            >
              <Box
                style={{
                  color: "#2D6A4F",
                  background: "#F0F8F1",
                  borderRadius: "24px",
                  fontSize: "16px",
                  fontFamily: "Inter Medium",
                  border: "1px solid #74C69D",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "10px",
                }}
                sx={{
                  width: {
                    xl: drawerWidth === 0 ? "400px" : "360px",
                    lg: drawerWidth === 0 ? "340px" : "260px",
                    md: drawerWidth === 0 ? "280px" : "220px",
                    sm: drawerWidth === 0 ? "220px" : "180px",
                  },
                  height: { xl: "46px", lg: "40px", md: "34px", sm: "30px" },
                }}
              >
                <Typography
                  sx={{
                    fontSize: {
                      xl: "24px",
                      lg: "20px",
                      md: "18px",
                      sm: "16px",
                    },
                  }}
                >
                  {title}
                </Typography>
              </Box>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "140px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: {
                      xl: "24px",
                      lg: "20px",
                      md: "18px",
                      sm: "16px",
                    },
                    fontWeight: 700,
                    color: "#FFFFFF",
                  }}
                >
                  Coming Soon
                </Typography>
              </Box>
            </Box>
          )}
          {!disable && (
            <CardContent>
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
                sx={{
                  margin: { xl: "15px", lg: "12px", md: "10px", sm: "8px" },
                  opacity: disable && 0.5,
                  pointerEvents: disable && "none",
                }}
              >
                <Typography
                  sx={{
                    fontSize: {
                      xl: drawerWidth === 0 ? "24px" : "20px",
                      lg: drawerWidth === 0 ? "22px" : "18px",
                      md: drawerWidth === 0 ? "20px" : "16px",
                      sm: drawerWidth === 0 ? "18px" : "14px",
                    },
                    fontWeight: 400,
                    color: "#444444",
                  }}
                >
                  {title}
                </Typography>
                <Select
                  value={value}
                  onChange={handleChange}
                  style={{
                    border: "1px solid #74C69D",
                    borderRadius: "18px",
                    color: "#2D6A4F",
                    height: "34px",
                  }}
                  sx={{
                    width: {
                      xl: drawerWidth === 0 ? "170px" : "150px",
                      lg: drawerWidth === 0 ? "150px" : "130px",
                      md: drawerWidth === 0 ? "130px" : "110px",
                      sm: drawerWidth === 0 ? "110px" : "90px",
                    },
                    pointerEvents: disable && "none",
                    "& .MuiSelect-select": {
                      fontSize: {
                        xl: "16px",
                        lg: "14px",
                        md: "12px",
                        sm: "10px",
                      },
                    },
                  }}
                >
                  {menuList.map((menu) => {
                    return (
                      <MenuItem key={menu} value={menu}>
                        {menu}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Box>
              <Divider sx={{ marginTop: "20px" }} />
              <Box
                style={{ overflow: "auto" }}
                sx={{
                  height: {
                    xl: "300px",
                    lg: "260px",
                    md: "220px",
                    sm: "200px",
                  },
                  pointerEvents: disable && "none",
                }}
              >
                <List>
                  {dataList.length!==0 && dataList.map((item) => (
                    <div key={item.name}>
                      <ListItem
                        key={item.name}
                        sx={{
                          width: {
                            xl: drawerWidth === 0 ? "400px" : "360px",
                            lg: drawerWidth === 0 ? "360px" : "320px",
                            md: drawerWidth === 0 ? "300px" : "260px",
                            sm: drawerWidth === 0 ? "260px" : "200px",
                          },
                        }}
                      >
                        <ListItemStart>
                          <ListItemText
                            primary={item.name.charAt(0).toUpperCase() + item.name.slice(1)}
                            sx={{
                              fontSize: {
                                xl: "16px",
                                lg: "14px",
                                md: "12px",
                                sm: "10px",
                              },
                              color: "#444444",
                              fontWeight: 400,
                            }}
                          />
                        </ListItemStart>
                        <ListItemEnd sx={{ color: "green" }}>
                          <ListItemIcon>
                            <Typography>
                              {item.count}
                              {/* {item.graph === 0 ? (
                              <Icon sx={{ color: "red" }}>
                                <DownGraph />
                              </Icon>
                            ) : (
                              <Icon sx={{ color: "green" }}>
                                <UpGraph />
                              </Icon>
                            )} */}
                            </Typography>
                          </ListItemIcon>
                        </ListItemEnd>
                      </ListItem>
                      <Divider />
                    </div>
                  ))}
                </List>
              </Box>
            </CardContent>
          )}
        </Card>
      </Grid>
    </MainGrid>
  );
}
