
import React from "react";

export default function ExpendTranslations({
    data,
    initiallyExpandedCount
}){
    const [expanded, setExpanded] = React.useState(false);
    const handleExpand = () => {
      setExpanded(!expanded);
    };
  
    return (
      <>
        {expanded ? data.map((item, index) => (
          <p key={index}>
             {item.language} : {item.translation}
          </p>
        )) : data.slice(0, initiallyExpandedCount).map((item, index) => (
          <p key={index}>
              {item.language} : {item.translation}
          </p>
        ))}
        {data.length > initiallyExpandedCount && (
          <p size="small" 
          onClick={handleExpand}
          style={{
            color: "#52B788",
            cursor: "pointer",
          }}
          >
            {expanded ? 'See Less' : 'See More'}
          </p>
        )}
      </>
    );
}