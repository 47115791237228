import { BrowserRouter as Router, Route, Link, Routes,} from "react-router-dom";
import React from "react";
// import Login from "../features/Authentication/Login";
import Login from "../features/Authentication/NewLogin";
import PrivateRoute from "./PrivateRoute";
import Location from "../features/location/locationPage";
import SuperVisorAccess from "../features/supervisorAccess/supervisorAccessPage";
import Dashboard from "../features/dashboard/DashboardPage";
import Partner from "../features/partner/partnerPage";
import AppUsers from "../features/appUsers/AppUsersPage";
import AdvisoryManagement from "../features/advisoryManagement/advisoryManagementPage";
import RolesAndPermissions from "../features/rolesAndPermissions/rolesAndPermissionsPage";
import ForgotPassword from "../features/Authentication/ForgotPassword";
import PageNotFound from "../components/reusableComponents/PageNotFound";
import ReviewImages from "../features/reviewImages/reviewImagesPage";
import Sessions from "../features/dashboard/Sessions";
import Alerts from "../features/dashboard/Alerts";
import AppUsage from "../features/dashboard/AppUsage";
const App = () => {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<PrivateRoute path = "/"><Login /></PrivateRoute>} />
          <Route path="/*" element={<PageNotFound />} />
          <Route path="/forgot_password" element={<ForgotPassword />} />
          <Route path="/page_not_found" element={<PageNotFound />} />
          <Route
            path="/location"
            element={
              <PrivateRoute>
                <Location />
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
           <Route
            path="/sessions"
            element={
              <PrivateRoute>
                <Sessions />
              </PrivateRoute>
            }
          />
          <Route
            path="/alerts"
            element={
              <PrivateRoute>
                <Alerts />
              </PrivateRoute>
            }
          />
          <Route
            path="/app_usage"
            element={
              <PrivateRoute>
                <AppUsage />
              </PrivateRoute>
            }
          />
          <Route
            path="/supervisor_access/:type"
            element={
              <PrivateRoute>
                <SuperVisorAccess />
              </PrivateRoute>
            }
          />
          <Route
            path="/review_images"
            element={
              <PrivateRoute>
                <ReviewImages />
              </PrivateRoute>
            }
          />
          <Route
            path="/partner"
            element={
              <PrivateRoute>
                <Partner />
              </PrivateRoute>
            }
          />
          <Route
            path="/app_users/:type"
            element={
              <PrivateRoute>
                <AppUsers />
              </PrivateRoute>
            }
          />
          <Route
            path="/advisory_management/:type"
            element={
              <PrivateRoute>
                <AdvisoryManagement />
              </PrivateRoute>
            }
          />
          <Route
            path="/roles_permission"
            element={
              <PrivateRoute>
                <RolesAndPermissions />
              </PrivateRoute>
            }
          />
        </Routes>
      </Router>
    </div>
  );
};
export default App;
