/* eslint-disable no-unused-vars */
import React, { useEffect, useRef } from "react";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import {
  Grid,
  Button,
  TextField,
  Typography,
  List,
  MenuItem,
  FormControl,
  Select,
  ThemeProvider,
} from "@mui/material";
import GetAppRoundedIcon from "@mui/icons-material/GetAppRounded";
import PublishIcon from "@mui/icons-material/Publish";
import AddIcon from "@mui/icons-material/Add";
import { basicTheme } from "../../themes/customTheme";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import InputAdornment from "@mui/material/InputAdornment";
import { ReactComponent as FilterIcon } from "../../styles/FilterIcon.svg";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useSelector } from "react-redux";
import Badge from "@mui/material/Badge";

const useStyles = makeStyles(() => ({
  search: {
    [`& fieldset`]: {
      borderRadius: 12,
    },
  },
  iconStyle: {
    cursor: "pointer",
  },
  startPos: {
    //   justifyContent: "flex-start",
    // display: "flex",
    // alignItems: "left",
    marginLeft: "230px",
    // marginRight: "1000px",
  },
  endPos: {
    justifyContent: "flex-end",
    display: "flex",
    alignItems: "right",
  },
  title: {
    [basicTheme.breakpoints.down("sm")]: {
      fontSize: 20,
    },
    fontSize: 32,
    fontWeight: 400,
    fontStretch: "normal",
    fontStyle: "normal",
    color: "#202124",
    lineHeight: 1.25,
    letterSpacing: "normal",
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
  },
}));
const ButtonGrid = styled(Grid)({
  // marginTop: "70px",
  direction: "row",
  alignItems: "center",
  justifyContent: "flex-end",
  display: "flex",
  // alignItems: "right",
  marginRight: "100px",
});
const StartGrid = styled(Grid)({
  direction: "row",
  justifyContent: "flex-start",
  display: "flex",
  alignItems: "center",
  marginRight: "10px",
  marginLeft: 80,
});

const SearchGrid = styled(Grid)({
  direction: "row",
  justifyContent: "flex-end",
  display: "flex",
  alignItems: "center",
  marginRight: "10px",
});
const MainGrid = styled(Grid)({
  direction: "row",
  justifyContent: "center",
  alignItems: "center",
});

const ButtonStyle = styled(Button)({
  backgroundColor: "#2D6A4F",
  color: "white",
  textTransform: "none",
  borderRadius: "12px",
  fontSize: "16px",
  fontWeight: "600",
  minWidth: "100px",
  maxWidth: "200px",
  height: "48px",
});

const ExportButtonStyle = styled(Button)({
  backgroundColor: "white",
  color: "#2D6A4F",
  textTransform: "none",
  borderRadius: "12px",
  fontSize: "16px",
  fontWeight: "600",
  minWidth: "100px",
  maxWidth: "150px",
  height: "48px",
});

const StyledFormControl = styled(FormControl)({
  backgroundColor: "#F0F8F1",
  color: "white",
  borderRadius: "10px",
});

const TextFieldStyled = styled(TextField)({
  color: "white",
  textTransform: "none",
  borderRadius: 100,
  fontSize: "14px",
  fontWeight: "600",
  // width:"600px",
  // minWidth: "600px",
  // maxWidth: "600px",
  // marginTop: "-1px",
});

export default function TopFilterBar({
  addtext = "",
  setAddOpen = () => {},
  showListButton = false,
  itemButtonMenu,
  addButton = true,
  addButtonMenu = [],
  setSearchBarData = () => {},
  searchBarText,
  setSeachBarText = () => {},
  setExportData = () => {},
  handleShowFilterPopOver = () => {},
  setMenuId = () => {},
  searchBy = "Search",
  isImport = false,
  setIsUpload = () => {},
  menuId = 1,
  loading = false,
  showFilter = true,
  filterSelected = false,
  exportButton = true,
  showSearchBar = true,
}) {
  const classes = useStyles();

  const [selectedTextHeading, setSelectedTextHeading] = React.useState(1);
  const [addHeadingText, setAddHeadingText] = React.useState("");
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const [searchError, setSearchError] = React.useState("");
  const drawerWidth = useSelector(
    (state) => state.appBarSliceReducer.drawerWidth
  );

  const handleChangeSelectedHeading = (event) => {
    setSelectedTextHeading(event.target.value);
    setMenuId(event.target.value);
  };

  useEffect(() => {
    addButtonMenu.length !== 0 &&
      setAddHeadingText(
        addButtonMenu.filter((obj) => obj.id === menuId)[0].name
      );
  }, [menuId]);

  // useEffect to change add heading based on option selected from menu
  useEffect(() => {
    addButtonMenu.length !== 0 &&
      setAddHeadingText(
        addButtonMenu.filter((obj) => obj.id === selectedTextHeading)[0].name
      );
  }, [selectedTextHeading]);

  const stringValPatternValidation = (stringVal) => {
    return /^\s+$/.test(stringVal);
  };

  const displayDropdown = (selectedTextHeading, handleChange, menuList) => {
    return (
      <StyledFormControl fullWidth variant="outlined">
        <Select
          value={selectedTextHeading}
          defaultValue={selectedTextHeading}
          // label={text}
          onChange={handleChange}
          sx={{
            borderRadius: 3,
            color: "#2D6A4F",
            border: 1,
            borderColor: "#2D6A4F",
          }}
        >
          {menuList.map((menu) => {
            return (
              <MenuItem key={menu.id} value={menu.id}>
                {menu.name}
              </MenuItem>
            );
          })}
        </Select>
      </StyledFormControl>
    );
  };

  return (
    <MainGrid container style={{ paddingLeft: "80px" }}>
      <ThemeProvider theme={basicTheme}>
        <Grid item xs={2} lg={2} xl={2}>
          <StartGrid
            container
            style={{ justifyContent: "flex-start", marginLeft: "0" }}
          >
            {showListButton && (
              <Grid item style={{ maxWidth: "200px" }}>
                {displayDropdown(
                  selectedTextHeading,
                  handleChangeSelectedHeading,
                  itemButtonMenu
                )}
              </Grid>
            )}
            {showFilter && (
              <Grid
                item
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Badge
                  variant="dot"
                  invisible={filterSelected === false}
                  sx={{
                    "& .MuiBadge-badge": {
                      color: "red",
                      backgroundColor: "red",
                    },
                  }}
                >
                  <Icon
                    sx={{
                      height: "50px",
                      width: "50px",
                      color: "#ACADAD",
                      stroke: "#F8F8F8",
                      marginLeft: 1,
                    }}
                    onClick={handleShowFilterPopOver}
                  >
                    <FilterIcon />
                  </Icon>
                </Badge>
                <Typography
                  variant="h5"
                  noWrap
                  component="div"
                  sx={{
                    color: "#78828C",
                    fontFamily: "Inter",
                    fontSize: "14px",
                    marginLeft: "10px",
                  }}
                >
                  Filter
                </Typography>
              </Grid>
            )}
          </StartGrid>
        </Grid>
        <Grid item xs={8} lg={4} xl={5}>
          {showSearchBar && (
            <SearchGrid
              container
              spacing={2}
              style={{ justifyContent: "center" }}
            >
              <Grid item xs={12}>
                <TextFieldStyled
                  fullWidth
                  disabled={loading}
                  label={searchBy}
                  className={classes.search}
                  margin="normal"
                  variant="outlined"
                  value={searchBarText}
                  error={searchError !== ""}
                  helperText={searchError}
                  onInput={(e) => {
                    var emptyString = stringValPatternValidation(
                      e.target.value
                    );
                    if (emptyString) {
                      setSearchError("White Spaces are not allowed");
                    } else {
                      setSearchError("");
                    }
                    setSeachBarText(e.target.value);
                  }}
                  onKeyPress={(e) => {
                    if (e.key === "Enter" && searchBarText.trim() !== "") {
                      var tempSearchBarText = searchBarText;
                      tempSearchBarText = tempSearchBarText.replace(
                        /^\s+/g,
                        ""
                      );
                      setSearchBarData(tempSearchBarText);
                    }
                  }}
                  onChange={(e) => {
                    if (e.target.value.trim() === "")
                      setSearchBarData(e.target.value.trim());
                  }}
                  inputProps={{ style: { borderColor: "green" } }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => {
                            if (searchBarText !== "") {
                              var text = searchBarText.replace(
                                /^\s+|\s+$/gm,
                                ""
                              );
                              setSearchBarData(text);
                            }
                          }}
                        >
                          <SearchRoundedIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </SearchGrid>
          )}
        </Grid>
        <Grid item xs={12} lg={6} xl={5}>
          <ButtonGrid container spacing={1}>
            {isImport && (
              <Grid item style={{ marginRight: !addButton ? "40px" : "0px" }}>
                <ExportButtonStyle
                  variant="contained"
                  name="Download"
                  startIcon={<PublishIcon className={classes.iconStyle} />}
                  onClick={(event) => setIsUpload(event.currentTarget)}
                  // sx={{width:{xl:"200px",lg:"130px",md:"100px",sm:"80px"},}}
                >
                  Import
                </ExportButtonStyle>
              </Grid>
            )}
            {exportButton && (
              <Grid item style={{ marginRight: !addButton ? "40px" : "0px" }}>
                <ExportButtonStyle
                  variant="contained"
                  name="Download"
                  startIcon={
                    <GetAppRoundedIcon className={classes.iconStyle} />
                  }
                  onClick={() => setExportData(true)}
                  // sx={{width:{xl:"200px",lg:"130px",md:"100px",sm:"80px"},}}
                >
                  Export
                </ExportButtonStyle>
              </Grid>
            )}
            {addButton && (
              <Grid item style={{ marginRight: "40px" }}>
                <ButtonStyle
                  variant="contained"
                  name="add"
                  onClick={() => setAddOpen(true)}
                  // sx={{width:{xl:"200px",lg:"130px",md:"100px",sm:"80px"},}}
                  startIcon={
                    <AddIcon size="large" className={classes.iconStyle} />
                  }
                >
                  {addButtonMenu.length === 0 ? addtext : addHeadingText}
                </ButtonStyle>
              </Grid>
            )}
          </ButtonGrid>
        </Grid>
      </ThemeProvider>
    </MainGrid>
  );
}
