import React, { useState, useEffect, useRef, useContext } from "react";
import { styled } from "@mui/material/styles";
import { Grid, Box, Typography, ThemeProvider } from "@mui/material";
import { basicTheme } from "../../themes/customTheme";
import TopFilterBar from "../../components/reusableComponents/TopFilterBar";
import DesktopTable from "../../components/reusableComponents/DesktopTable";
import { useDispatch, useSelector } from "react-redux";
import { getAlertsData } from "./DashboardSlice";
import LinearProgress from "@mui/material/LinearProgress";
import { getAppUageCount} from "./DashboardSlice";
import useMediaQuery from "@mui/material/useMediaQuery";
import StackTable from "../../components/reusableComponents/StackTable";
import PartnerFilterPopOver from "../../components/partner/partnerFilterPopOver";
import SnackBars from "../../components/reusableComponents/SnackBar";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useLocation } from "react-router-dom";
import CommonContext from "../appbar/ContextProvider";
import dayjs from "dayjs";
import DashboardFilterPopOver from "../../components/dashboard/DashboardFilterPopOver";

const MainGrid = styled(Grid)({
    flex: "1",
    backgroundColor: "white",
    // [theme.breakpoints.down("sm")]: {
    //   marginTop: "60px",
    // },
    marginTop: "80px",
  });

  
  


// "village_name": "rithad",
// "state_name": "maharashtra",
// "partner_name": "wiai public",
// "created_at": "2023-04-24",
// "session_status": "Complete",
// "pest_count": "PBW : 0,     ABW : 0",
// "alert_type": "green",
// "name": "qa test2"

const initialHeadCells = [
    {
      key: "name",
      label: "User Name",
      type: "text",
      sort: false,
      capital: true,
    },
    {
        key: "partner",
        label: "Partner",
        type: "text",
        sort: false,
        capital: true,
    },
    {
        key: "location",
        label: "Location",
        type: "text",
        sort: false,
        capital:true
    },
    {
        key: "phone_number",
        label:"Phone Number",
        type: "text",
        sort: false,
        capital: true, 
     },
    {
      key: "user_type",
      label: "User Type",
      type: "text",
      sort: false,
      capital: true,
    }
  ];
  

  export default function AppUsage({}) {
    const dispatch = useDispatch();
    const [applyFilter, setApplyFilter] = useState(false);
    const [clearFilter, setClearFilter] = useState(false);
    const [selectedStatesOptions, setSelectedStatesOptions] = useState([]);
    const [selectedPartnerOptions, setSelectedPartnerOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const rowsPerPage = 10;
    const [locationIds, setLocationIds] = useState([]);
    const [partnerIds, setPartnerIds] = useState([]);
    const [reload, setReload] = useState(false);
    const [apiCall, setApiCall] = useState(false);
    const [sessionSnackBarType, setSessionSnackBarType] = useState("success");
    const [sessionSnackBarMessage, setSessionSnackBarMessage] = useState("");
    const [showSessionSnackBar, setSessionShowSnackBar] = useState(false);
    const [offset, setOffset] = useState(0);
    const [page, setPage] = React.useState(0);
    const [sortingType, setSortingType] = useState("desc");
    const [sortBy, setSortBy] = useState("created_at");

    const drawerWidth = useSelector(
        (state) => state.appBarSliceReducer.drawerWidth
      );

      const appUsageData = useSelector(
        (state) => state.dashboardSliceReducer.appUsageCount.data
      )

      const appUsageCount = useSelector(
        (state) => state.dashboardSliceReducer.appUsageCount.count
      )

      const appUsageDataIsSuccess = useSelector(
        (state) => state.dashboardSliceReducer.appUsageDataCountSuccess
      );
    
      const appUsageDataLoading = useSelector(
        (state) => state.dashboardSliceReducer.appUsageDataCountLoading
      );
    

    const { dashboardPopoverOpen, setDashboardPopoverOpen } = useContext(
        CommonContext
    );

    const handleCloseFilterPopOver = () => {
        setDashboardPopoverOpen(null);
        setApplyFilter(false);
        setClearFilter(false);
      };



      useEffect(() => {
        if (applyFilter) {
          handleCloseFilterPopOver();
          setPage(0);
          setOffset(0);
          var tempLocationIds = []
          var tempPartnerIds = []
          console.log("selectedStatesOptions",selectedStatesOptions)
          if(selectedStatesOptions.length > 0) {
            selectedStatesOptions.forEach((obj)=>{
              tempLocationIds.push(obj.id)
            })
          }
          console.log("tempLocationIds",tempLocationIds)
          if(selectedPartnerOptions.length > 0) {
            selectedPartnerOptions.forEach((obj)=>{
              tempPartnerIds.push(obj.id)
            })
          }
          setLocationIds(tempLocationIds)
          setPartnerIds(tempPartnerIds)
          setApiCall(true);
        }
      }, [applyFilter]);
    
      useEffect(() => {
        if (clearFilter) {
          setPage(0);
          setOffset(0);
          setSelectedStatesOptions([]);
          setSelectedPartnerOptions([]);
        //   setSessionGeneratedBy("Total")
          setApiCall(false);
          console.log(selectedStatesOptions,selectedPartnerOptions)
        }
      }, [clearFilter]);
    
    
      useEffect(() => {
        setApiCall(true);
      }, [offset, sortingType, sortBy]);
    
      useEffect(() => {
        if (apiCall || reload) {
          setLoading(true);
        //   var modifiedSessionGeneratedBy = sessionGeneratedByAbbreviations[sessionGeneratedBy];
        //   var stateName = locationIds;
        console.log("locationIds",locationIds)
          dispatch(
            getAppUageCount({
                filters: (locationIds.length === 0 && partnerIds.length === 0) ?JSON.stringify([]) : (locationIds.length === 0 && partnerIds.length !== 0)? JSON.stringify([{type:"program_id","value":partnerIds}]) : (locationIds.length !== 0 && partnerIds.length === 0)? JSON.stringify([{type:"state","value":locationIds}]):JSON.stringify([{type:"state","value":locationIds}, {type:"program_id","value":partnerIds}]),
                limit: rowsPerPage,
                offset: offset,
              })
          ).then(() => {
            setLoading(false);
            setApiCall(false);
            setReload(false);
          });
        }
      }, [apiCall, reload]);
    
      useEffect(() => {
        if (sessionSnackBarMessage !== "" && sessionSnackBarMessage !== undefined)
          setSessionShowSnackBar(true);
      }, [sessionSnackBarMessage]);

      console.log('mai hu don', appUsageData)
  return(
    <MainGrid
        container
        id="main"
        sx={{ marginLeft: drawerWidth + "px", width: "auto" }}
        spacing={3}>
        <ThemeProvider theme={basicTheme}>
        <Grid item xs={12}>
          {dashboardPopoverOpen && (
          <DashboardFilterPopOver
            handleClose={handleCloseFilterPopOver}
            openFilterPopOver={dashboardPopoverOpen}
            setApplyFilter={setApplyFilter}
            clearFilter={clearFilter}
            setClearFilter={setClearFilter}
            appUsageBy={true}
            selectedStatesOptions={selectedStatesOptions}
            setSelectedStatesOptions={setSelectedStatesOptions}
            selectedPartnersOptions = {selectedPartnerOptions}
            setSelectedPartners = {setSelectedPartnerOptions}
          />
        )}
        </Grid>
        <Grid item xs={12}>
          {appUsageDataLoading && !appUsageDataIsSuccess && loading && (
            <Box>
              <LinearProgress color="success" />
            </Box>
          )}

           {( !appUsageDataLoading &&
            appUsageDataIsSuccess &&
            !loading && (
              <DesktopTable
                key={appUsageData}
                headCells={initialHeadCells}
                tableResponse={appUsageData}
                totalCount={appUsageCount}
                setOffset={setOffset}
                rowsPerPage={rowsPerPage}
                setSortingType={setSortingType}
                sortingType={sortingType}
                setSortBy={setSortBy}
                sortBy={sortBy}
                setPage={setPage}
                page={page}
              />
            )
          )}
          </Grid>
          {showSessionSnackBar && (
          <SnackBars
            open={showSessionSnackBar}
            handleClose={() => setSessionShowSnackBar(false)}
            severity={sessionSnackBarType}
            message={sessionSnackBarMessage}
            autoHideDuration={6000}
            vertical="bottom"
            horizontal="center"
          />
        )}
        {loading && (
          <Backdrop
            sx={{
              color: "#fff",
              position: "absolute",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        </ThemeProvider>
      </MainGrid>

    );
  }