import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import * as Structures from "../../apiResonseStructres/partnerModule";
import {getHeaders} from "../../utils/index"
import { partnerDetailsAPI, exportPartnerDataAPI } from "../../api's/index";
const initialState = {
  partnerData: Structures.GET_PARTNER_DETAIL_API_RESPONSE,
  partnerDataLoading: false,
  partnerDataIsSuccess: false,
  partnerDataErrorMessage: Structures.ERROR_MESSAGE_API_RESPONSE,
  partnerNames: Structures.GET_PARTNER_NAMES_API_RESPONSE,
  partnerNamesLoading: false,
  partnerNamesIsSuccess: false,
  partnerNamesErrorMessage: Structures.ERROR_MESSAGE_API_RESPONSE,
  exportedData: [],
  addPartnerApiResponse: Structures.ADD_PARTNER_API_RESPONSE,
  addPartnerErrorMessage: Structures.ERROR_MESSAGE_API_RESPONSE,
  addPartnerIsSuccess: false,
  addPartnerIsLoading: false,
  editPartnerApiResponse: Structures.ADD_PARTNER_API_RESPONSE,
  editPartnerErrorMessage: Structures.ERROR_MESSAGE_API_RESPONSE,
  editPartnerIsSuccess: false,
  editPartnerIsLoading: false,
  deletePartnerApiResponse: Structures.ADD_PARTNER_API_RESPONSE,
  deletePartnerErrorMessage: Structures.ERROR_MESSAGE_API_RESPONSE,
  deletePartnerIsSuccess: false,
};
var qs = require("qs");
const headers = getHeaders();

export const getPartnerData = createAsyncThunk(
  "partner/getPartnerData",
  async (data, { rejectWithValue }) => {
    try {
      const headers = getHeaders();
      const response = await axios.get(partnerDetailsAPI, {
        params: data,
        headers: headers
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getPartnerNames = createAsyncThunk(
  "partner/getPartnerNames",
  async (data, { rejectWithValue }) => {
    try {
      var headers = getHeaders();
      headers["verified"]="true"
      const response = await axios.get(partnerDetailsAPI, {
        params: data,
        headers: headers
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getExportedData = createAsyncThunk(
  "partner/getExportedData",
  async (data, { rejectWithValue }) => {
    try {
      const headers = getHeaders();
      const response = await axios.get(exportPartnerDataAPI, {
        params: data,
        headers: headers,
        paramsSerializer: {
          serialize: (params) =>
            qs.stringify(params, { arrayFormat: "repeat" }),
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addPartner = createAsyncThunk(
  "partner/addPartner",
  async (data, { rejectWithValue }) => {
    try {
      const headers = getHeaders();
      const response = await axios({
        method: "post",
        url: partnerDetailsAPI,
        data: data,
        headers: headers
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const editPartner = createAsyncThunk(
  "partner/editpartner",
  async (data, { rejectWithValue }) => {
    try {
      const headers = getHeaders();
      const response = await axios({
        method: "put",
        url: partnerDetailsAPI,
        data: data,
        headers: headers
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deletePartner = createAsyncThunk(
  "partner/deletePartner",
  async (data, { rejectWithValue }) => {
    try {
      const headers = getHeaders();
      const response = await axios({
        method: "put",
        url: partnerDetailsAPI,
        data: data,
        headers: headers
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const partnerSlice = createSlice({
  name: "partner",
  initialState,
  reducers: {
    resetAddPartner(state) {
      Object.assign(
        state.addPartnerApiResponse,
        Structures.ADD_PARTNER_API_RESPONSE
      );
      Object.assign(
        state.addPartnerErrorMessage,
        Structures.ERROR_MESSAGE_API_RESPONSE
      );
      Object.assign(state.addPartnerIsSuccess, false);
      Object.assign(state.addPartnerIsLoading, false);
    },
    resetEditPartner(state) {
      Object.assign(
        state.editPartnerApiResponse,
        Structures.ADD_PARTNER_API_RESPONSE
      );
      Object.assign(
        state.editPartnerErrorMessage,
        Structures.ERROR_MESSAGE_API_RESPONSE
      );
      Object.assign(state.editPartnerIsSuccess, false);
      Object.assign(state.editPartnerIsLoading, false);
    },
    resetDeletePartner(state) {
      Object.assign(
        state.deletePartnerApiResponse,
        Structures.ADD_PARTNER_API_RESPONSE
      );
      Object.assign(
        state.deletePartnerErrorMessage,
        Structures.ERROR_MESSAGE_API_RESPONSE
      );
      Object.assign(state.deletePartnerIsSuccess, false);
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getPartnerData.fulfilled, (state, { payload }) => {
        state.partnerData = payload;
        state.partnerDataLoading = false;
        state.partnerDataIsSuccess = true;
      })
      .addCase(getPartnerData.pending, (state, { payload }) => {
        state.partnerDataLoading = true;
        state.partnerDataIsSuccess = false;
      })
      .addCase(getPartnerData.rejected, (state, { payload }) => {
        state.partnerDataErrorMessage = payload;
        state.partnerDataLoading = false;
        state.partnerDataIsSuccess = false;
      })
      .addCase(getPartnerNames.fulfilled, (state, { payload }) => {
        state.partnerNames = payload;
        state.partnerNamesLoading = false;
        state.partnerNamesIsSuccess = true;
      })
      .addCase(getPartnerNames.pending, (state, { payload }) => {
        state.partnerNamesLoading = true;
        state.partnerNamesIsSuccess = false;
      })
      .addCase(getPartnerNames.rejected, (state, { payload }) => {
        state.partnerNamesErrorMessage = payload;
        state.partnerNamesLoading = false;
        state.partnerNamesIsSuccess = false;
      })
      .addCase(getExportedData.fulfilled, (state, { payload }) => {
        state.exportedData = payload;
      })
      .addCase(addPartner.fulfilled, (state, { payload }) => {
        state.addPartnerApiResponse = payload;
        state.addPartnerIsSuccess = true;
        state.addPartnerIsLoading = false;
      })
      .addCase(addPartner.rejected, (state, { payload }) => {
        state.addPartnerErrorMessage = payload;
        state.addPartnerIsSuccess = false;
        state.addPartnerIsLoading = false;
      })
      .addCase(addPartner.pending, (state) => {
        state.addPartnerIsLoading = true;
        state.addPartnerIsSuccess = false;
      })
      .addCase(editPartner.fulfilled, (state, { payload }) => {
        state.editPartnerApiResponse = payload;
        state.editPartnerIsSuccess = true;
      })
      .addCase(editPartner.rejected, (state, { payload }) => {
        state.editPartnerErrorMessage = payload;
        state.editPartnerIsSuccess = false;
        state.editPartnerIsLoading = false;
      })
      .addCase(editPartner.pending, (state) => {
        state.editPartnerIsLoading = true;
        state.editPartnerIsSuccess = false;
      })
      .addCase(deletePartner.fulfilled, (state, { payload }) => {
        state.deletePartnerApiResponse = payload;
        state.deletePartnerIsSuccess = true;
      })
      .addCase(deletePartner.rejected, (state, { payload }) => {
        state.deletePartnerErrorMessage = payload;
        state.deletePartnerIsSuccess = false;
      });
  },
});
export const {
  resetAddPartner,
  resetEditPartner,
  resetDeletePartner,
} = partnerSlice.actions;
export default partnerSlice.reducer;
