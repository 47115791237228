import React, { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import AppMenuBar from "../features/appbar/AppMenuBar";
import { ContextProvider } from "../features/appbar/ContextProvider";

function PrivateRoute({ children, path }) {
  const loggedInUser = JSON.parse(window.localStorage.getItem("loggedInUser"));
  const userPermission = JSON.parse(
    window.localStorage.getItem("userPermission")
  );
  const { pathname } = useLocation();
  const pageUrl = {
    "/app_users/paf": "CottonAce App Users",
    "/app_users/puf": "CottonAce App Users",
    "/app_users/ew": "CottonAce App Users",
    "/roles_permission": "Roles and Permissions",
    "/location": "Location",
    "/partner": "Partners",
    "/supervisor_access/state_head": "Supervisors",
    "/supervisor_access/district_head": "Supervisors",
    "/supervisor_access/taluka_head": "Supervisors",
    "/supervisor_access/government": "Supervisors",
    "/advisory_management/pesticide": "Advisory Management",
    "/advisory_management/partner_pesticide": "Advisory Management",
    "/review_images": "Review Images",
    "/dashboard" : "Dashboard",
    "/sessions": "Dashboard",
    "/alerts": "Dashboard",
    "/app_usage": "Dashboard"
  };

  const checkLoggedIn = () => {
    if (
      loggedInUser !== null &&
      loggedInUser !== undefined &&
      loggedInUser.token !== undefined &&
      loggedInUser.token !== null &&
      loggedInUser.token !== ""
    )
      return true;
    return false;
  };

  if (pathname === "/" && checkLoggedIn() === false) {
    return <React.Fragment>{children}</React.Fragment>;
  } else if (pathname === "/" && checkLoggedIn() === true && loggedInUser.details.role!==undefined && loggedInUser.details.role === "Government") {
    return <Navigate to="/dashboard" replace />;
  }else if (pathname === "/" && checkLoggedIn() === true) {
    return <Navigate to="/app_users/paf" replace />;
  } 
  else if (
    userPermission !== null &&
    userPermission !== undefined &&
    userPermission.includes(pageUrl[pathname]) == false
  ) {
    return <Navigate to="/page_not_found" replace />;
  } 
  else if (checkLoggedIn())
    return (
      <ContextProvider>
        <AppMenuBar />
        {children}
      </ContextProvider>
    );
  else return <Navigate to="/" replace />;
}
export default PrivateRoute;
