import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import * as Structures from "../../apiResonseStructres/dashboardModule";
import { getHeaders } from "../../utils/index";
import { partnerCountAPI, sessionCountAPI, partnerBasedUsersCountAPI, alertCountAPI,imageCountAPI, activeUserCountAPI, monitoringBasedCountAPI, infestationBasedCountAPI, sessionDataAPI, alertsDataAPI ,installCountAPI,appUsageCountListAPI} from "../../api's/index";

const initialState = {
  partnerCount: Structures.GET_PARTNER_COUNT_API_RESPONSE,
  partnerCountLoading: false,
  partnerCountIsSuccess: false,
  partnerCountErrorMessage: Structures.ERROR_MESSAGE_API_RESPONSE,
  alertCount: Structures.GET_COUNT_API_RESPONSE,
  alertCountLoading: false,
  alertCountIsSuccess: false,
  alertCountErrorMessage: Structures.ERROR_MESSAGE_API_RESPONSE,
  activeUserCount: Structures.GET_COUNT_API_RESPONSE,
  appUsageCount:Structures.GET_APPUSAGE_COUNT_DATA,
  activeUserLeadCount:Structures.GET_COUNT_API_RESPONSE,
  activeUserCascadeCount :Structures.GET_COUNT_API_RESPONSE,
  activeUserLeadCountLoading: false,
  activeUserLeadCountIsSuccess: false,
  activeUserCascadeCountLoading: false,
  activeUserCascadeCountIsSuccess: false,
  activeUserCountLoading: false,
  activeUserCountIsSuccess: false,
  
  sessionExportDataLoading : false,
  sessionExportDataSuccess : false,

  installCountDataLoading : false,
  installCountDataSuccess : false,

  appUsageDataCountLoading : false,
  appUsageDataCountSuccess : false,
  activeUserLeadCountErrorMessage: Structures.ERROR_MESSAGE_API_RESPONSE,
  appUsageCountErrorMessage : Structures.ERROR_MESSAGE_API_RESPONSE,
  installCountErrorMessage: Structures.ERROR_MESSAGE_API_RESPONSE,
  activeUserCascadeCountErrorMessage: Structures.ERROR_MESSAGE_API_RESPONSE,
  activeUserCountErrorMessage: Structures.ERROR_MESSAGE_API_RESPONSE,
  imageCount: Structures.GET_COUNT_API_RESPONSE,
  imageCountLoading: false,
  imageCountIsSuccess: false,
  imageCountErrorMessage: Structures.ERROR_MESSAGE_API_RESPONSE,
  sessionCount: Structures.GET_COUNT_API_RESPONSE,
  sessionCountLoading: false,
  sessionCountIsSuccess: false,
  sessionCountErrorMessage: Structures.ERROR_MESSAGE_API_RESPONSE,
  partnerBasedUserCount: Structures.GET_PARTNER_BASED_USER_COUNT,
  partnerBasedUserCountLoading: false,
  partnerBasedUserCountIsSuccess: false,
  partnerBasedUserCountErrorMessage: Structures.ERROR_MESSAGE_API_RESPONSE,
  monitoringBasedCount: Structures.GET_TOP_COUNTS_API_RESPONSE,
  monitoringBasedCountLoading: false,
  monitoringBasedCountErrorMessage: Structures.ERROR_MESSAGE_API_RESPONSE,
  monitoringBasedCountIsSuccess: false,
  infestationBasedCount: Structures.GET_TOP_COUNTS_API_RESPONSE,
  infestationBasedCountLoading: false,
  infestationBasedCountErrorMessage: Structures.ERROR_MESSAGE_API_RESPONSE,
  infestationBasedCountIsSuccess: false,
  sessionData: Structures.GET_SESSION_DATA_API_RESPONSE,
  sessionDataLoading: false,
  sessionExportData: Structures.GET_SESSION_DATA_API_RESPONSE,
  installCountData:Structures.GET_APPINSTALL_DATA_API_RESPONSE,
  sessionDataIsSuccess: false,
  sessionDataErrorMessage: Structures.ERROR_MESSAGE_API_RESPONSE,
  alertsData: Structures.GET_ALERTS_DATA_API_RESPONSE,
  alertsDataLoading: false,
  alertsDataIsSuccess: false,
  alertsDataErrorMessage: Structures.ERROR_MESSAGE_API_RESPONSE,
  sessionExportDataErrorMessage: Structures.ERROR_MESSAGE_API_RESPONSE
};

var qs = require("qs");

export const getPartnerCount = createAsyncThunk(
  "dashboard/getPartnerCount",
  async (data, { rejectWithValue }) => {
    try {
      const headers = getHeaders();
      const response = await axios.get(partnerCountAPI, {
        params: data,
        headers: headers,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getSessionData = createAsyncThunk(
  "dashboard/getSessionData",
  async (data, { rejectWithValue }) => {
    try {
      const headers = getHeaders();
      const response = await axios.get(sessionDataAPI, {
        params: data,
        headers: headers,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getSessionExportData = createAsyncThunk(
  "dashboard/getSessionExportData",
  async (data, { rejectWithValue }) => {
    try {
      const headers = getHeaders();
      const response = await axios.get(sessionDataAPI, {
        params: data,
        headers: headers,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAlertsData = createAsyncThunk(
  "dashboard/getAlertsData",
  async (data, { rejectWithValue }) => {
    try {
      const headers = getHeaders();
      const response = await axios.get(alertsDataAPI, {
        params: data,
        headers: headers,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAlertCount = createAsyncThunk(
  "dashboard/getAlertCount",
  async (data, { rejectWithValue }) => {
    try {
      const headers = getHeaders();
      const response = await axios.get(alertCountAPI, {
        params: data,
        headers: headers,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getInstallCount = createAsyncThunk(
  "dashboard/getInstallCount",
  async (data,{ rejectWithValue }) => {
    try {
      console.log("89898")
      const headers = getHeaders();
      const response = await axios.get(installCountAPI, {
        headers: headers,
      });
      console.log(response)
      return response.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const getActiveUserCount = createAsyncThunk(
  "dashboard/getActiveUserCount",
  async (data, { rejectWithValue }) => {
    try {
      const headers = getHeaders();
      const response = await axios.get(activeUserCountAPI, {
        params: data,
        headers: headers,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getActiveUserLeadCount = createAsyncThunk(
  "dashboard/getActiveUserLeadCount",
  async (data, { rejectWithValue }) => {
    try {
      const headers = getHeaders();
      const response = await axios.get(activeUserCountAPI, {
        params: data,
        headers: headers,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getActiveUserCascadeCount = createAsyncThunk(
  "dashboard/getActiveUserCascadeCount",
  async (data, { rejectWithValue }) => {
    try {
      const headers = getHeaders();
      const response = await axios.get(activeUserCountAPI, {
        params: data,
        headers: headers,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getImageCount = createAsyncThunk(
  "dashboard/getImageCount",
  async (data, { rejectWithValue }) => {
    try {
      const headers = getHeaders();
      const response = await axios.get(imageCountAPI, {
        params: data,
        headers: headers,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getSessionCount = createAsyncThunk(
  "dashboard/getSessionCount",
  async (data, { rejectWithValue }) => {
    try {
      const headers = getHeaders();
      const response = await axios.get(sessionCountAPI, {
        params: data,
        headers: headers,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getPartnerBasedUserCount = createAsyncThunk(
  "dashboard/getPartnerBasedUserCount",
  async (data, { rejectWithValue }) => {
    try {
      const headers = getHeaders();
      const response = await axios.get(partnerBasedUsersCountAPI, {
        params: data,
        headers: headers,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getMonitoringBasedCount = createAsyncThunk(
  "dashboard/getMonitoringBasedCount",
  async (data, { rejectWithValue }) => {
    try {
      const headers = getHeaders();
      const response = await axios.get(monitoringBasedCountAPI, {
        params: data,
        headers: headers,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getInfestationBasedCount = createAsyncThunk(
  "dashboard/getInfestationBasedCount",
  async (data, { rejectWithValue }) => {
    try {
      const headers = getHeaders();
      const response = await axios.get(infestationBasedCountAPI, {
        params: data,
        headers: headers,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAppUageCount = createAsyncThunk(
  "dashboard/getAppUsageCount",
  async (data, { rejectWithValue }) => {
    try {
      const headers = getHeaders();
      const response = await axios.get(appUsageCountListAPI, {
        params: data,
        headers: headers,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getPartnerCount.fulfilled, (state, { payload }) => {
        state.partnerCount = payload;
        state.partnerCountLoading = false;
        state.partnerCountIsSuccess = true;
      })
      .addCase(getPartnerCount.pending, (state, { payload }) => {
        state.partnerCountLoading = true;
        state.partnerCountIsSuccess = false;
      })
      .addCase(getPartnerCount.rejected, (state, { payload }) => {
        state.partnerCountErrorMessage = payload;
        state.partnerCountLoading = false;
        state.partnerCountIsSuccess = false;
      })
      .addCase(getAlertCount.fulfilled, (state, { payload }) => {
        state.alertCount = payload;
        state.alertCountLoading = false;
        state.alertCountIsSuccess = true;
      })
      .addCase(getAlertCount.pending, (state, { payload }) => {
        state.alertCountLoading = true;
        state.alertCountIsSuccess = false;
      })
      .addCase(getAlertCount.rejected, (state, { payload }) => {
        state.alertCountErrorMessage = payload;
        state.alertCountLoading = false;
        state.alertCountIsSuccess = false;
      })
      .addCase(getActiveUserCount.fulfilled, (state, { payload }) => {
        state.activeUserCount = payload;
        state.activeUserCountLoading = false;
        state.activeUserCountIsSuccess = true;
      })
      .addCase(getActiveUserCount.pending, (state, { payload }) => {
        state.activeUserCountLoading = true;
        state.activeUserCountIsSuccess = false;
      })
      .addCase(getActiveUserCount.rejected, (state, { payload }) => {
        state.activeUserCountErrorMessage = payload;
        state.activeUserCountLoading = false;
        state.activeUserCountIsSuccess = false;
      })
      .addCase(getActiveUserCascadeCount.fulfilled,(state,{payload}) =>{
        state.activeUserCascadeCount = payload
        state.activeUserCascadeCountLoading = true;
        state.activeUserCascadeCountIsSuccess = true;
      })
      .addCase(getActiveUserCascadeCount.pending, (state, { payload }) => {
        state.activeUserCascadeCountLoading = true;
        state.activeUserCascadeCountIsSuccess = false;
      })
      .addCase(getActiveUserCascadeCount.rejected, (state, { payload }) => {
        state.activeUserCascadeCountErrorMessage = payload;
        state.activeUserCascadeCountLoading = false;
        state.activeUserCascadeCountIsSuccess = false;
      })
      .addCase(getActiveUserLeadCount.fulfilled,(state,{payload}) =>{
        state.activeUserLeadCount = payload
        state.activeUserLeadCountLoading = true;
        state.activeUserLeadCountIsSuccess = true;
      })
      .addCase(getActiveUserLeadCount.pending, (state, { payload }) => {
        state.activeUserLeadCountLoading = true;
        state.activeUserLeadCountIsSuccess = false;
      })
      .addCase(getActiveUserLeadCount.rejected, (state, { payload }) => {
        state.activeUserLeadCountErrorMessage = payload;
        state.activeUserLeadCountLoading = false;
        state.activeUserLeadCountIsSuccess = false;
      })
      .addCase(getImageCount.fulfilled, (state, { payload }) => {
        state.imageCount = payload;
        state.imageCountLoading = false;
        state.imageCountIsSuccess = true;
      })
      .addCase(getImageCount.pending, (state, { payload }) => {
        state.imageCountLoading = true;
        state.imageCountIsSuccess = false;
      })
      .addCase(getImageCount.rejected, (state, { payload }) => {
        state.imageCountErrorMessage = payload;
        state.imageCountLoading = false;
        state.imageCountIsSuccess = false;
      })
      .addCase(getSessionCount.fulfilled, (state, { payload }) => {
        state.sessionCount = payload;
        state.sessionCountLoading = false;
        state.sessionCountIsSuccess = true;
      })
      .addCase(getSessionCount.pending, (state, { payload }) => {
        state.sessionCountLoading = true;
        state.sessionCountIsSuccess = false;
      })
      .addCase(getSessionCount.rejected, (state, { payload }) => {
        state.sessionCountErrorMessage = payload;
        state.sessionCountLoading = false;
        state.sessionCountIsSuccess = false;
      })
      .addCase(getPartnerBasedUserCount.fulfilled, (state, { payload }) => {
        state.partnerBasedUserCount = payload;
        state.partnerBasedUserCountLoading = false;
        state.partnerBasedUserCountIsSuccess = true;
      })
      .addCase(getPartnerBasedUserCount.pending, (state, { payload }) => {
        state.partnerBasedUserCountLoading = true;
        state.partnerBasedUserCountIsSuccess = false;
      })
      .addCase(getPartnerBasedUserCount.rejected, (state, { payload }) => {
        state.partnerBasedUserCountErrorMessage = payload;
        state.partnerBasedUserCountLoading = false;
        state.partnerBasedUserCountIsSuccess = false;
      })
      .addCase(getMonitoringBasedCount.fulfilled, (state, { payload }) => {
        state.monitoringBasedCount = payload;
        state.monitoringBasedCountLoading = false;
        state.monitoringBasedCountIsSuccess = true;
      })
      .addCase(getMonitoringBasedCount.pending, (state, { payload }) => {
        state.monitoringBasedCountLoading = true;
        state.monitoringBasedCountIsSuccess = false;
      })
      .addCase(getMonitoringBasedCount.rejected, (state, { payload }) => {
        state.monitoringBasedCountErrorMessage = payload;
        state.monitoringBasedCountLoading = false;
        state.monitoringBasedCountIsSuccess = false;
      })
      .addCase(getInfestationBasedCount.fulfilled, (state, { payload }) => {
        state.infestationBasedCount = payload;
        state.infestationBasedCountLoading = false;
        state.infestationBasedCountIsSuccess = true;
      })
      .addCase(getInfestationBasedCount.pending, (state, { payload }) => {
        state.infestationBasedCountLoading = true;
        state.infestationBasedCountIsSuccess = false;
      })
      .addCase(getInfestationBasedCount.rejected, (state, { payload }) => {
        state.infestationBasedCountErrorMessage = payload;
        state.infestationBasedCountLoading = false;
        state.infestationBasedCountIsSuccess = false;
      })
       .addCase(getAppUageCount.fulfilled, (state, { payload }) => {
        state.appUsageCount = payload;
        state.appUsageDataCountLoading = false;
        state.appUsageDataCountSuccess = true;
      })
      .addCase(getAppUageCount.pending, (state, { payload }) => {
        state.appUsageDataCountLoading = true;
        state.appUsageDataCountSuccess = false;
      })
      .addCase(getAppUageCount.rejected, (state, { payload }) => {
        state.appUsageCountErrorMessage = payload;
        state.appUsageDataCountLoading = false;
        state.appUsageDataCountSuccess = false;
      })
      .addCase(getSessionData.fulfilled, (state, { payload }) => {
        state.sessionData = payload;
        state.sessionDataLoading = false;
        state.sessionDataIsSuccess = true;
      })
      .addCase(getSessionData.pending, (state, { payload }) => {
        state.sessionDataLoading = true;
        state.sessionDataIsSuccess = false;
      })
      .addCase(getSessionData.rejected, (state, { payload }) => {
        state.sessionDataErrorMessage = payload;
        state.sessionDataLoading = false;
        state.sessionDataIsSuccess = false;
      })
      .addCase(getAlertsData.fulfilled, (state, { payload }) => {
        state.alertsData = payload;
        state.alertsDataLoading = false;
        state.alertsDataIsSuccess = true;
      })
      .addCase(getAlertsData.pending, (state, { payload }) => {
        state.alertsDataLoading = true;
        state.alertsDataIsSuccess = false;
      })
      .addCase(getAlertsData.rejected, (state, { payload }) => {
        state.alertsDataErrorMessage = payload;
        state.alertsDataLoading = false;
        state.alertsDataIsSuccess = false;
      })
      .addCase(getSessionExportData.fulfilled,(state,{payload}) => {
        state.sessionExportData = payload;
        state.sessionExportDataLoading = false;
        state.sessionExportDataSuccess = true;
      })
      .addCase(getSessionExportData.pending, (state, { payload }) => {
        state.sessionExportDataLoading = true;
        state.sessionExportDataSuccess = false;
      })
      .addCase(getSessionExportData.rejected, (state, { payload }) => {
        state.sessionExportDataErrorMessage = payload;
        state.sessionExportDataLoading = false;
        state.sessionExportDataSuccess = false;
      })
      .addCase(getInstallCount.fulfilled,(state,{payload}) => {
        state.installCountData = payload;
        state.installCountDataLoading = false;
        state.installCountDataSuccess = true;
      })
      .addCase(getInstallCount.pending, (state, { payload }) => {
        state.installCountDataLoading = true;
        state.installCountDataSuccess = false;
      })
      .addCase(getInstallCount.rejected, (state, { payload }) => {
        state.installCountErrorMessage = payload;
        state.installCountDataLoading = false;
        state.installCountDataSuccess = false;
      }) ;
},
});
export const {} = dashboardSlice.actions;
export default dashboardSlice.reducer;
