import { createSlice } from '@reduxjs/toolkit'
import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import * as Structures from "../../apiResonseStructres/appBarModule";
import { rolesAndPermissionsAPI } from "../../api's/index";
import {getHeaders} from "../../utils/index"
const initialState = {
   drawerWidth: 0,
   userPermissionData : Structures.USER_PERMISSION,
   userPermissionIsSuccess: false,
   userPermissionErrorMessage: Structures.ERROR_MESSAGE_API_RESPONSE,
  }
  const headers = getHeaders();

  export const getUsersPermission = createAsyncThunk(
    "app-bar/getUserPermisison",
    async (data, { rejectWithValue }) => {
      try {
        const response = await axios.get(rolesAndPermissionsAPI, {
          params: data,
          headers: headers
        });
        return response.data;
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  );

  export const appBarSlice = createSlice({
    name: 'AppBar',
    initialState,
    reducers: {
      increaseDrawerWidth(state) {
        state.drawerWidth = 240
        
      },
      decreaseDrawerWidth:(state)=>{
        state.drawerWidth = 0
      }
      
    },
    extraReducers: (builder) => {
      builder
        .addCase(getUsersPermission.fulfilled, (state, { payload }) => {
          state.userPermissionData = payload;
          state.usersDataLoading = false;
          state.userPermissionIsSuccess = true;
        })
        .addCase(getUsersPermission.rejected, (state, { payload }) => {
          state.userPermissionErrorMessage = payload;
          state.userPermissionIsSuccess = false;
        })
        
    },
  })

  export const { increaseDrawerWidth,decreaseDrawerWidth } = appBarSlice.actions
  export default appBarSlice.reducer