export const GET_PARTNER_DETAIL_API_RESPONSE = {
  data: [
    {
      id: "",
      name: "",
      state_wise_location: [],
      protocol: { id: "", name: "" },
      spoc_details: { name: "", email: "", phone_number: "" },
      created_at: "",
      is_active: false,
      formatted_location:[]
    },
  ],
  count: -1,
};

export const GET_PARTNER_NAMES_API_RESPONSE = {
  data: [
    {
      id: "",
      name: "",
    },
  ],
  count: -1,
};

export const ERROR_MESSAGE_API_RESPONSE = {
  message: "",
};

export const ADD_PARTNER_API_RESPONSE = {
  message: "",
};
