import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteUser,
  resetDeleteUser,
} from "../../features/appUsers/appUserSlice";
import { styled } from "@mui/material/styles";

const Centerstyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const closeIconStyle = {
  position: "absolute",
  right: 1,
  cursor: "pointer",
  color: (theme) => theme.palette.grey[600],
};
const DeleteButtonStyle = styled(Button)({
  backgroundColor: "#2D6A4F",
  color: "white",
  textTransform: "none",
  borderRadius: "8px",
  fontSize: "18px",
  fontWeight: "600",
  minWidth: "198px",
  maxWidth: "200px",
  marginLeft: 20,
});

const CancelButtonStyle = styled(Button)({
  backgroundColor: "white",
  color: "#2D6A4F",
  textTransform: "none",
  borderRadius: "8px",
  fontSize: "18px",
  fontWeight: "600",
  minWidth: "198px",
  maxWidth: "200px",
  marginRight: 20,
});

export default function DeleteAppUser({
  handleClose = () => {},
  open,
  deleteData = {},
  setUserSnackBarMessage = () => {},
  setReload = () => {},
  setSnackBarType = ()=>{},
}) {
  const dispatch = useDispatch();

  const deleteUserApiResponse = useSelector(
    (state) => state.usersSliceReducer.deleteUserApiResponse.message
  );

  const deleteUserErrorMessage = useSelector(
    (state) => state.usersSliceReducer.deleteUserErrorMessage.message
  );

  const deleteUserIsSuccess = useSelector(
    (state) => state.usersSliceReducer.deleteUserIsSuccess
  );

  // useEffect(() => {
  //   setUserSnackBarMessage(deleteUserApiResponse);
  // }, [deleteUserApiResponse]);

  useEffect(() => {
    dispatch(resetDeleteUser());
  }, []);

  useEffect(() => {
    if (deleteUserIsSuccess && deleteUserApiResponse !== "") {
      setUserSnackBarMessage(deleteUserApiResponse);
      setReload(true);
      setSnackBarType("success");
      handleClose();
      dispatch(resetDeleteUser());
    }
    else if (!deleteUserIsSuccess && deleteUserErrorMessage !== "") {
      setUserSnackBarMessage(deleteUserErrorMessage);
      setSnackBarType("error");
      dispatch(resetDeleteUser());
    }
  }, [
    deleteUserIsSuccess,
    deleteUserErrorMessage,
    deleteUserApiResponse,
  ]);

  const handleDelete = () => {
    var params = {
      farmer_id:deleteData.id,
      farmer_name: deleteData.name,
      farmer_phone_number: deleteData.phone_number,
      is_deleted:true,
      farmer_type:deleteData.farmer_type,
      
    }
    dispatch(
      deleteUser(params)
    );
  };

  return (
    <Dialog open={open}>
      <HighlightOffRoundedIcon sx={closeIconStyle} onClick={handleClose} />
      <DialogTitle sx={{ marginTop: 2 }}>
        Are you sure you want to delete this farmer?
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" sx={Centerstyle}>
          {deleteData.name}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={Centerstyle}>
        <CancelButtonStyle
          variant="contained"
          onClick={handleClose}
          sx={{ marginRight: 5 }}
        >
          No
        </CancelButtonStyle>
        <DeleteButtonStyle
          variant="contained"
          sx={{ background: "#EB5451", marginLeft: 5 }}
          onClick={handleDelete}
          autoFocus
          disabled={deleteUserErrorMessage !== ""}
        >
          YES, Delete
        </DeleteButtonStyle>
      </DialogActions>
    </Dialog>
  );
}
