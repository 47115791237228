import React, { useState, useEffect } from "react";
import { Grid, Typography, Checkbox } from "@mui/material";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import { displayLocationDropdown, getDistrictList, getTalukaList, getVillageList } from "../../utils/index"
import AutoCompleteMultiDropdown from "../reusableComponents/AutoCompleteMultiDropDown";
import AutoCompleteDropdown from "../reusableComponents/AutoCompleteDropDown";
import _without from "lodash/without";

export default function LocationDropDown({
    index = 0,
    location,
    handleChangeLocation,
    handleChangeDeleteLocation,
    getStatesResponse = [],
    isPartner = false,
    partnerLocations = {},
    disable = false
}) {
    if (!disable &&
        isPartner &&
        partnerLocations.state_ids !== undefined &&
        partnerLocations.state_ids.length > 0 &&
        getStatesResponse.length > 0) {
        let filtered = getStatesResponse.filter((item) => partnerLocations.state_ids.includes(item.id));
        getStatesResponse = filtered;
    }
    const [getDistrictResponse, setGetDistrictResponse] = useState([])
    const [getTalukaResponse, setGetTalukaResponse] = useState([])
    const [getVillageResponse, setGetVillageResponse] = useState([])

    useEffect(() => {
        setGetDistrictResponse([])
        setGetTalukaResponse([]);
        setGetVillageResponse([])
        if (location[index].state !== null) {
            getDistrictList(location[index].state.id).then((data) => {
                if (!disable && isPartner &&
                    partnerLocations.district_ids !== undefined &&
                    partnerLocations.district_ids.length > 0 &&
                    data.length > 0) {
                    let filtered = data.filter((item) => partnerLocations.district_ids.includes(item.id));
                    data = filtered;
                }
                setGetDistrictResponse(data)
            })
        }
    }, [location[index].state]);

    useEffect(() => {
        if (location[index].district !== null) {
            setGetTalukaResponse([]);
            setGetVillageResponse([])
            getTalukaList(location[index].district.id).then((data) => {
                if (!disable && isPartner &&
                    partnerLocations.taluka_ids &&
                    partnerLocations.taluka_ids.length > 0 &&
                    data.length > 0) {
                    let filtered = data.filter((item) => partnerLocations.taluka_ids.includes(item.id));
                    data = filtered;
                }
                setGetTalukaResponse(data)
            })
        }
    }, [location[index].district]);

    useEffect(() => {
        if (location[index].taluka !== null) {
            setGetVillageResponse([])
            getVillageList(location[index].taluka.id).then((data) => {
                if (!disable && isPartner &&
                    partnerLocations.village_ids &&
                    partnerLocations.village_ids.length > 0 &&
                    data.length > 0) {
                    let filtered = data.filter((item) => partnerLocations.village_ids.includes(item.id));
                    data = filtered;
                }
                setGetVillageResponse(data)
            })
        }
    }, [location[index].taluka]);


    return (
        <Grid
            item
            xs={11}
            sx={{
                border: 1,
                borderColor: "#D3D3D3",
                borderRadius: "10px",
                margin: 2,
            }}
        >
            <Grid container spacing={3}>
                <Grid item xs={12} sm={index !== 0 && !disable ? 2.5 : 3}>
                    <Typography sx={{ marginBottom: 1 }}>State</Typography>
                    <AutoCompleteDropdown
                        width="auto"
                        menuList={getStatesResponse}
                        selectedOptions={getStatesResponse[0].id === "" ? null : location[index].state}
                        disable={disable || getStatesResponse[0].id === ""}
                        label="Select State"
                        onHighlightChange={handleChangeLocation}
                        index={index}
                        type="state"
                        required={true}
                    />
                </Grid>
                <Grid item xs={12} sm={index !== 0 && !disable ? 2.5 : 3}>
                    <Typography sx={{ marginBottom: 1 }}>District</Typography>
                    <AutoCompleteDropdown
                        width="auto"
                        menuList={getDistrictResponse}
                        selectedOptions={getDistrictResponse.length === 0 ? null : location[index].district}
                        disable={disable || getDistrictResponse.length === 0}
                        label="Select District"
                        onHighlightChange={handleChangeLocation}
                        index={index}
                        type="district"
                        required={true}
                    />
                </Grid>
                <Grid item xs={12} sm={index !== 0 && !disable ? 2.5 : 3}>
                    <Typography sx={{ marginBottom: 1 }}>Taluka</Typography>
                    <AutoCompleteDropdown
                        width="auto"
                        menuList={getTalukaResponse}
                        selectedOptions={getTalukaResponse.length === 0 ? null : location[index].taluka}
                        disable={disable || getTalukaResponse.length === 0}
                        label="Select Taluka"
                        onHighlightChange={handleChangeLocation}
                        index={index}
                        type="taluka"
                        required={true}
                    />
                </Grid>

                <Grid item xs={12} sm={index !== 0 && !disable ? 2.5 : 3}>
                    <Typography sx={{ marginBottom: 1 }}>Village</Typography>
                    <AutoCompleteMultiDropdown
                        width={230}
                        menuList={getVillageResponse}
                        selectedOptions={getVillageResponse.length === 0 ? [] : location[index].villages}
                        onHighlightChange={handleChangeLocation}
                        index={index}
                        type="village"
                        label="Select Villages"
                        disable={disable || getVillageResponse.length === 0}
                        required={true}
                    />
                </Grid>
                <Grid item xs={12} sm={2}>
                    {index > 0 && !disable &&
                        <DeleteRoundedIcon
                            sx={{ cursor: "pointer", marginTop: 5 }}
                            onClick={() => handleChangeDeleteLocation(index)}
                        />}
                </Grid>
            </Grid>
        </Grid>

    );
}
