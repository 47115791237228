export const GET_PESTICIDES_API_RESPONSE = [
        {
        
          code: "",
          title: "",
          price: "",
          quantity: "",
          pest_code: "",
          crop: "",
          dosage: "",
          description: "",
          // farming_type: "organic",
          status: "",
          title_translations: [
            {
              "language": "",
              "translation": ""
            },
          ]
        }
  ]

export const GET_PESTICIDE_TITLE_LIST_API_RESPONSE = [
  {
    code: "",
  }
]

export const GET_PEST_API_RESPONSE = [
  {
    code: "",
    name: "",
  }   
]
export const GET_CROP_API_RESPONSE = [
  {
    name: "",
  }   
]
export const GET_FARMING_TYPE_API_RESPONSE = [
  {
    name: "",
  } 
]
export const GET_SEASON_API_RESPONSE = [
  {
    code: "",
    name: "",
    year: ""

  } 
]
export const GET_ALERT_API_RESPONSE = [
  {
    name: "",
  } 
]

export const ADD_PESTICIDE_API_RESPONSE = {
  message:""
}

export const ERROR_MESSAGE_API_RESPONSE = {
  message:""
}

export const ADD_PARTNER_PESTICIDE_MAPPING_API_RESPONSE = {
  message:""
}

export const GET_PARTNER_PESTICIDE_MAPPING_API_RESPONSE = [
  {
    partner:"",
    id:"",
    min_days_after_sowing:"",
    max_days_after_sowing:"",
    season:"",
    farming_type:"",
    alert:"",
    crop:"",
    pesticides:[],
    pest:"",
    location:[],
    created_at:"",
    updated_at:"",
    is_deleted:"",
    last_updated_by:"",
  }

]

export const BULK_ADD_PESTICIDE_API_RESPONSE = []
export const BULK_ADD_ERROR_MESSAGE_API_RESPONSE = []