import React, { useState, useEffect, useRef } from "react";
import { styled } from "@mui/material/styles";
import { Grid, Box, ThemeProvider } from "@mui/material";
import { useParams } from 'react-router-dom';
import TopFilterBar from "../../components/reusableComponents/TopFilterBar";
import DesktopTable from "../../components/reusableComponents/DesktopTable";
import { useDispatch, useSelector } from "react-redux";
import { getUsersData,getEwData, resetGetUsers, getExportedData } from "./appUserSlice";
import AppUserFilterPopOver from "../../components/appUsers/appUserFilterPopOver";
import SnackBars from "../../components/reusableComponents/SnackBar";
import LinearProgress from "@mui/material/LinearProgress";
import { CSVLink } from "react-csv";
import useMediaQuery from "@mui/material/useMediaQuery";
import { updateArray, searchType } from "../../utils";
import { basicTheme } from "../../themes/customTheme";
import AddUser from "../../components/appUsers/AddUserModal";
import EditFarmer from "../../components/appUsers/EditFarmerModal";
import EditEw from "../../components/appUsers/EditEwModal";
import StateWiseLocationModal from "../../components/location/statewiseLocationModal";
import DeleteAppUser from "../../components/appUsers/deleteAppUserModal";
import DeleteEw from "../../components/appUsers/deleteEwModal";
import AlertDialog from "../../components/reusableComponents/AlertDialog";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import BulkUploadUsers from "../../components/appUsers/bulkUploadUsers";

const MainGrid = styled(Grid)({
  flex: "1",
  backgroundColor: "white",
  // [theme.breakpoints.down("sm")]: {
  //   marginTop: "60px",
  // },
  marginTop: "80px",
});

const initialHeadCells = [
  {
    key: "action",
    label: "Actions",
    type: "editDelete",
    sticky: true,
  },
  {
    key: "name",
    label: "Farmer",
    type: "name_number_location",
    sort: true,
  },
  {
    key: "farmer_type",
    label: "Farmer Type",
    type: "text",
  },
  {
    key: "farming_type",
    label: "Farming Type",
    type: "text",
    capital: true
  },
  {
    key: "season",
    label: "Season",
    type: "season",
    capital: true
  },
  {
    key: "status",
    label: "Status",
    type: "boolean",
  },
  {
    key: "partner",
    label: "Partner",
    type: "text",
    capital:true
  },
  {
    key: "crop",
    label: "Crop Name",
    type: "season",
    capital: true
  },
  {
    key: "sowing_date",
    label: "Date of Sowing",
    type: "text",
  },
  {
    key: "extension_worker",
    label: "Extension Worker",
    type: "name_number",
  },
];

const initialEwHeadCells = [
  {
    key: "action",
    label: "Actions",
    type: "editDelete",
    sticky: true,
  },
  {
    key: "name",
    label: "Extension Worker",
    type: "name_number_location",
    sort: true,
  },
  {
    key: "state_wise_location",
    label: "Location",
    type: "location",
  },
  {
    key: "status",
    label: "Status",
    type: "boolean",
  },
  {
    key: "partner",
    label: "Partner",
    type: "text",
    capital:true
  },
];

const modifiedLocationHeadCell = [
  {
    key: "district",
    label: "District",
    type: "text",
  },
]

const listButtonMenu = [
  { name: "Partner Farmer", id: 1, user_type: "paf" },
  { name: "WIAI FARMER", id: 3, user_type: "puf" },
  { name: "Extension Worker", id: 2, user_type: "ew" },
];

export default function AppUsers() {
  const dispatch = useDispatch();
  const { type } = useParams();
  const csvLink = useRef();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const rowsPerPage = 10;
  const [openAddModal, setopenAddModal] = React.useState(false);
  const [offset, setOffset] = useState(0);
  const [page, setPage] = React.useState(0);
  const [sortingType, setSortingType] = useState("desc");
  const [sortBy, setSortBy] = useState("id");
  const [applyFilter, setApplyFilter] = useState(false);
  const [searchBarData, setSearchBarData] = useState("");
  const [searchBarText, setSeachBarText] = useState("");
  const [exportData, setExportData] = useState("");
  const [exportedFileName, setExportedFileName] = useState("");
  const [isUpload, setIsUpload] = useState(null);
  const [userSnackBarType, setUserSnackBarType] = useState("success");
  const [userSnackBarMessage, setUserSnackBarMessage] = useState("");
  const [showUserSnackBar, setUserShowSnackBar] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openEditModal, setopenEditModal] = useState(false);
  const [editData, setEditData] = useState({});
  const [deleteData, setDeleteData] = useState({});
  const [reload, setReload] = useState(false);
  const [filters, setFilters] = useState([
    { type: "user_type", value: ["paf"] },
    {type:"program_id",value:[]}
  ]);
  const [apiCall, setApiCall] = useState(false);
  const [openLocationSubModal, setOpenLocationSubModal] = React.useState(false);
  const [openFilterPopOver, setOpenFilterPopOver] = React.useState(null);
  const [openAlertModal, setOpenAlertModal] = useState(false);
  const [clearFilter, setClearFilter] = useState(false);
  const [userType, setUserType] = useState(type);
  const [selectedStatesOptions, setSelectedStatesOptions] = useState([]);
  const [selectedDistrictOptions, setSelectedDistrictOptions] = useState([]);
  const [selectedTalukaOptions, setSelectedTalukaOptions] = useState([]);
  const [selectedPartnersOptions, setSelectedPartnersOptions] = useState([]);
  const [selectedEwOptions, setSelectedEwOptions] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [loading, setLoading] = useState(true);
  const loggedInUser = JSON.parse(window.localStorage.getItem("loggedInUser"));
  const drawerWidth = useSelector(
    (state) => state.appBarSliceReducer.drawerWidth
  );

  const usersDataResponse = useSelector(
    (state) => state.usersSliceReducer.usersData.data
  );

  const usersDataErrorMessage = useSelector(
    (state) => state.usersSliceReducer.usersDataErrorMessage
  );

  const usersDataIsSuccess = useSelector(
    (state) => state.usersSliceReducer.usersDataIsSuccess
  );

  const usersDataLoading = useSelector(
    (state) => state.usersSliceReducer.usersDataLoading
  );

  const totalUsersData = useSelector(
    (state) => state.usersSliceReducer.usersData.count
  );

  const ewNamesDataCount = useSelector(
    (state) => state.usersSliceReducer.EwData.count
  );

  const exportedData = useSelector(
    (state) => state.usersSliceReducer.exportedData
  );

  useEffect(()=>{
   dispatch(resetGetUsers())
  },[])

  const handleShowFilterPopOver = (event) => {
    setOpenFilterPopOver(event.currentTarget);
  };

  const handleCloseFilterPopOver = () => {
    setOpenFilterPopOver(null);
    setApplyFilter(false);
    setClearFilter(false);
  };

  useEffect(() => {
    dispatch(resetGetUsers())
    var roleArray = [];
    roleArray.push(type);
    setUserType(type);
    var newRoleArray = updateArray(filters, "user_type", roleArray);
    if(loggedInUser.details.partner!==null)
    {
      newRoleArray = updateArray(newRoleArray, "program_id", [loggedInUser.details.partner.id]);
    }
    else{
      updateArray(filters, "program_id", [], true, setFilters);
    }
    setFilters(newRoleArray);
    updateArray(filters, "state", [], true, setFilters);
    updateArray(filters, "district", [], true, setFilters);
    updateArray(filters, "taluka", [], true, setFilters);
    setSelectedStatesOptions([]);
    setSelectedDistrictOptions([]);
    setSelectedTalukaOptions([]);
    setSelectedPartnersOptions([]);
    setPage(0);
    setOffset(0);
    setSearchBarData("");
    setSeachBarText("");
  }, [type]);

  useEffect(() => {
    if (clearFilter) {
      setPage(0);
      setOffset(0);
      setSelectedEwOptions([])
    }
  }, [clearFilter]);

  useEffect(()=>{
    setPage(0);
    setOffset(0);
  },[searchBarData])

  const handleChangeFilter = (selectedOptionsList) => {
    var tempFilter = [...filters]
    selectedOptionsList.forEach((options)=>{
      var type = options.type
      var selectedOptions = options.value
      if (selectedOptions.length > 0) {
        var selectedIds = [];
        selectedOptions.forEach((options) => selectedIds.push(options.id));
        if (!searchType(type, filters)) {
          tempFilter.push({ type: type, value: selectedIds })
        }
        else {
          const objWithIdIndex = tempFilter.findIndex((obj) => obj.type === type);
          tempFilter[objWithIdIndex].value = selectedIds;
        }
      }
      else if (selectedOptions.length === 0 && searchType(type, filters)) {
        const objWithIdIndex = tempFilter.findIndex((obj) => obj.type === type);
        tempFilter.splice(objWithIdIndex, 1);
      }
  })
  setFilters(tempFilter);
    
  };

  useEffect(() => {
    if (exportData){
      setLoading(true)
      dispatch(
        getExportedData({
          sort_by: sortingType,
          sort_type: sortBy,
          search: searchBarData,
          filters: JSON.stringify(filters),
          offset: 0,
          limit: totalUsersData,
        })
      ).then((res) => {
        setLoading(false)
        setExportData(false);
        if(res.error!==undefined){
          setUserSnackBarMessage("Something went wrong!")
          setUserSnackBarType("error")
          setUserShowSnackBar(true)
        }
        else{
          setExportedFileName("users_data" + searchBarData + ".csv");
          csvLink.current.link.click();
        }
      });
    }
  }, [exportData]);

  useEffect(() => {
    if (applyFilter) {
      handleCloseFilterPopOver();
      setPage(0);
      setOffset(0);
      setFilters((fil) =>
        fil.filter((value) => Object.keys(value).length !== 0)
      );
      if(userType === "paf"){
        var tempSelectedOptions = [{type:"program_id",value:selectedPartnersOptions},{type:"state",value:selectedStatesOptions},{type:"district",value:selectedDistrictOptions},{type:"taluka",value:selectedTalukaOptions},{type:"extension_worker_id",value:selectedEwOptions}]
      }else{
        var tempSelectedOptions = [{type:"program_id",value:selectedPartnersOptions},{type:"state",value:selectedStatesOptions},{type:"district",value:selectedDistrictOptions},{type:"taluka",value:selectedTalukaOptions}]
      }
      handleChangeFilter(tempSelectedOptions)
    }
  }, [applyFilter]);

  useEffect(() => {
    setApiCall(true);
  }, [
    searchBarData,
    offset,
    sortingType,
    sortBy,
    userType,
    filters
  ]);

  useEffect(() => {
    if (apiCall || reload) {
      setLoading(true)
      dispatch(
        getUsersData({
          filters: JSON.stringify(filters),
          search: searchBarData,
          offset: offset,
          limit: rowsPerPage,
          sort_by: sortingType,
          sort_type: sortBy,
        }),
      ).then(() => {
        setLoading(false)
        setReload(false);
        setApiCall(false);
      });
    }
  }, [reload,apiCall]);

  useEffect(() => {
    if (apiCall || reload) {
      setLoading(true)
      dispatch(
        getEwData({
          filters: JSON.stringify([{ type: "user_type", value: ["ew"] },
          {type:"program_id",value:[]}]),
          search: searchBarData,
          offset: 0,
          limit: 1000000,
          sort_by: sortingType,
          sort_type: sortBy,
          fields:'id,name'
        })
      ).then(() => {
        setLoading(false)
        setReload(false);
        setApiCall(false);
      });
    }
  }, [reload,apiCall]);

  useEffect(() => {
    if (userSnackBarMessage !== "" && userSnackBarMessage !== undefined)
      setUserShowSnackBar(true);
      
  }, [userSnackBarMessage]);

  useEffect(() => {
    if (
      !usersDataIsSuccess &&
      (usersDataErrorMessage === undefined ||
        (usersDataErrorMessage.message === undefined &&
          usersDataErrorMessage.message !== ""))
    ) {
      setUserShowSnackBar(true);
      setUserSnackBarMessage("Something went wrong on server side ");
      setUserSnackBarType("error");
    } else if (
      !usersDataLoading &&
      !usersDataIsSuccess &&
      usersDataErrorMessage !== undefined &&
      usersDataErrorMessage.message !== undefined &&
      usersDataErrorMessage.message !== ""
    ) {
      setUserShowSnackBar(true);
      setUserSnackBarMessage(usersDataErrorMessage.message);
      setUserSnackBarType("error");
    }
  }, [usersDataErrorMessage, usersDataLoading]);
  return (
    <MainGrid
      container
      id="main"
      sx={{ marginLeft: drawerWidth + "px", width: "auto"}}
      spacing={3}
    >
      <ThemeProvider theme={basicTheme}>
      <Grid item xs={12}>
        <TopFilterBar
          addtext="Add Farmer"
          setAddOpen={setopenAddModal}
          searchBarData={searchBarData}
          setSearchBarData={setSearchBarData}
          searchBarText={searchBarText}
          setSeachBarText={setSeachBarText}
          handleShowFilterPopOver={handleShowFilterPopOver}
          setExportData={setExportData}
          searchBy="Search By Name and Number"
          addButton={userType==="paf"?true:false}
          isImport={userType==="paf"?true:false}
          setIsUpload={setIsUpload}
          loading={loading}
          filterSelected = {selectedPartnersOptions.length!==0 || selectedStatesOptions.length!==0 || selectedStatus!==null}
        />
        {openFilterPopOver && (
          <AppUserFilterPopOver
            handleClose={handleCloseFilterPopOver}
            openFilterPopOver={openFilterPopOver}
            setApplyFilter={setApplyFilter}
            clearFilter={clearFilter}
            setClearFilter={setClearFilter}
            selectedPartnersOptions={selectedPartnersOptions}
            selectedEwOptions={selectedEwOptions}
            setSelectedPartnersOptions={setSelectedPartnersOptions}
            setSelectedEwsOptions={setSelectedEwOptions}
            selectedStatesOptions={selectedStatesOptions}
            setSelectedStatesOptions={setSelectedStatesOptions}
            selectedDistrictOptions={selectedDistrictOptions}
            setSelectedDistrictOptions={setSelectedDistrictOptions}
            selectedTalukaOptions={selectedTalukaOptions}
            setSelectedTalukaOptions={setSelectedTalukaOptions}
            selectedStatus={selectedStatus}
            setSelectedStatus={setSelectedStatus}
            filters = {filters}
            setFilters={setFilters}
            userType = {userType}
          />
        )}
      </Grid>
      <Grid item xs={12}>
        {usersDataLoading && !usersDataIsSuccess && loading &&(
          <Box>
            <LinearProgress color="success" />
          </Box>
        )}
        {usersDataIsSuccess && !usersDataLoading && !loading && (
          <DesktopTable
            headCells={
              userType === "ew" ? initialEwHeadCells : initialHeadCells
            }
            tableResponse={usersDataResponse}
            key={usersDataResponse}
            totalCount={totalUsersData}
            setOffset={setOffset}
            rowsPerPage={rowsPerPage}
            setSortingType={setSortingType}
            sortingType={sortingType}
            setSortBy={setSortBy}
            sortBy={sortBy}
            setopenEditModal={setopenEditModal}
            setEditData={setEditData}
            setOpenDeleteModal={setOpenDeleteModal}
            setDeleteData={setDeleteData}
            setPage={setPage}
            page={page}
            setOpenLocationSubModal={setOpenLocationSubModal}
            openEditModal={openEditModal}
            openDeleteModal={openDeleteModal}
          />
        )}
      </Grid>
      {showUserSnackBar && (
          <SnackBars
            open={showUserSnackBar}
            handleClose={() => setUserShowSnackBar(false)}
            severity={userSnackBarType}
            message={userSnackBarMessage}
            autoHideDuration={6000}
            vertical="bottom"
            horizontal="center"
          />
        )}
      {openAddModal && (
          <AddUser
            openAddModal={openAddModal}
            handleClose={() => setopenAddModal(false)}
            setUserSnackBarMessage={setUserSnackBarMessage}
            setReload={setReload}
          />
        )}
        {isUpload && <BulkUploadUsers
                isUpload={isUpload}
                handleClose={() => setIsUpload(null)}
                setSnackBarMessage={setUserSnackBarMessage}
                setSnackBarType={setUserSnackBarType}
              />}
        {openEditModal && userType!=="ew" && (
          <EditFarmer
            openModal={openEditModal}
            handleClose={() => setopenEditModal(false)}
            data={editData}
            setEditData={setEditData}
            setUserSnackBarMessage={setUserSnackBarMessage}
            setReload={setReload}
          />
        )}

      {openEditModal && userType==="ew" && (
          <EditEw
            openModal={openEditModal}
            handleClose={() => setopenEditModal(false)}
            data={editData}
            setEditData={setEditData}
            setUserSnackBarMessage={setUserSnackBarMessage}
            setReload={setReload}
          />
        )}
        {openLocationSubModal && (
          <StateWiseLocationModal
            openModal={openLocationSubModal}
            handleClose={() => setOpenLocationSubModal(false)}
            data={editData.state_wise_location}
            modifiedLocationHeadCell = {modifiedLocationHeadCell}
            modifiedHeadCell = {true}
          />
        )}
        {openDeleteModal && (
          <DeleteAppUser
            handleClose={() => setOpenDeleteModal(false)}
            open={openDeleteModal}
            deleteData={deleteData}
            setUserSnackBarMessage={setUserSnackBarMessage}
            setSnackBarType = {setUserSnackBarType}
            setReload={setReload}
          />
        )}
        {openDeleteModal && userType==="ew" && (
          <DeleteEw
            handleClose={() => setOpenDeleteModal(false)}
            open={openDeleteModal}
            deleteData={deleteData}
            setUserSnackBarMessage={setUserSnackBarMessage}
            setSnackBarType = {setUserSnackBarType}
            setReload={setReload}
            setOpenAlertModal={setOpenAlertModal}
          />
        )}
        {openAlertModal && (
          <AlertDialog
            handleClose={() => setOpenAlertModal(false)}
            open={openAlertModal}
            heading="Can't delete this Extension Worker"
            text="Farmers are mapped with this extension Worker.Please unmap them and then
            try deleting again!"
          />
        )}
        <CSVLink
          data={exportedData}
          filename={exportedFileName}
          className="hidden"
          ref={csvLink}
          target="_blank"
          separator={";"}
        />
        {loading && (
          <Backdrop
            sx={{
              color: "#fff",
              position:"absolute",
              zIndex: (theme) => theme.zIndex.drawer +1,
            }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
      </ThemeProvider>
    </MainGrid>
  );
}