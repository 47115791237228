import React, { useState, useEffect } from "react";
import { Button, Grid, Typography, Modal, ThemeProvider } from "@mui/material";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { basicTheme } from "../../themes/customTheme";
import Backdrop from "@mui/material/Backdrop";
import LocationDropDown from "../LocationDropDown";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import {
  removeDuplicates,
} from "../../utils/index";
import { getStatesData } from "../../features/location/locationSlice";
import {
  editPartner,
  resetEditPartner,
} from "../../features/partner/partnerSlice";
import SnackBars from "../reusableComponents/SnackBar";
import BasicTextfield from "../reusableComponents/BasicTextfield";
import BasicDropDown from "../reusableComponents/BasicDropDown";

const subGridStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
  width: "65%",
  maxHeight: "80%",
  overflow: "auto",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-around",
  paddingLeft: 5,
};

const useStyles = makeStyles(() => ({
  closeBtnStyle: {
    display: "flex",
    justifyContent: "flex-end",
    zoom: "2.2",
    alignItems: "right",
    "&:hover": {
      color: "#081C15",
    },
    cursor: "pointer",
  },
}));

const ButtonStyle = styled(Button)({
  backgroundColor: "#2D6A4F",
  color: "white",
  textTransform: "none",
  borderRadius: "8px",
  fontSize: "16px",
  fontWeight: "600",
  minWidth: "140px",
  maxWidth: "150px",
  height: "54px",
});

const CancelButtonStyle = styled(Button)({
  backgroundColor: "white",
  color: "#2D6A4F",
  textTransform: "none",
  borderRadius: "8px",
  fontSize: "16px",
  fontWeight: "600",
  minWidth: "140px",
  maxWidth: "150px",
  height: "54px",
});

export default function EditPartnerModal({
  openModal = false,
  handleClose = () => {},
  data = [],
  setEditData = () => {},
  setReload = () => {},
  setPartnerSnackBarMessage = () => {},
  setPartnerSnackBarType = () =>{}
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [partnerName, setPartnerName] = useState(data.name || "");
  const [spocName, setSpocName] = useState(data.spoc_details.name || "");
  const [spocNumber, setSpocNumber] = useState(
    data.spoc_details.phone_number || ""
  );
  const [spocEmail, setSpocEmail] = useState(data.spoc_details.email || "");
  const [partnerProtocol, setPartnerProtocol] = useState(data.protocol || "");
  const activeStatus = data.is_active ? "Active" : "Inactive";
  const [partnerStatus, setPartnerStatus] = useState(activeStatus || "");
  const [editPartnerSnackBar, setEditPartnerSnackBar] = useState(false);
  const [editPartnerSnackBarMessage, setEditPartnerSnackBarMessage] = useState(
    ""
  );
  const basicError = {
    partnerName: "",
    email: "",
    phoneNumber: "",
    spocName: "",
    protocol: "",
  };

  const [snackBarType, setSnackBarType] = useState("success");
  const [errors, setErrors] = useState(basicError);

  const partnerStatusList = ["Active", "Inactive"];
  const protocolList = ["Weekly Monitoring Protocol", "CICR"];
  const [loading, setLoading] = useState(false);

  const [addAnotherLocationDropDown, setAddAnotherLocationDropDown] = useState(
    data.formatted_location ? data.formatted_location.length : 1
  );
  const totalLocationEditableData = data.formatted_location
    ? data.formatted_location.length
    : 0;
  const [location, setLocation] = useState(
    data.formatted_location || [
      {
        state: null,
        district: null,
        taluka: null,
        villages: [],
      },
    ]
  );
  const getStatesResponse = useSelector(
    (state) => state.locationSliceReducer.statesData
  );

  const editPartnerApiResponse = useSelector(
    (state) => state.partnerSliceReducer.editPartnerApiResponse.message
  );
  const editPartnerErrorMessage = useSelector(
    (state) => state.partnerSliceReducer.editPartnerErrorMessage.message
  );

  const editPartnerIsSuccess = useSelector(
    (state) => state.partnerSliceReducer.editPartnerIsSuccess
  );

  useEffect(() => {
    if(getStatesResponse[0].id==="")
      dispatch(getStatesData());
    dispatch(resetEditPartner());
    setPartnerSnackBarMessage("");
  }, []);

  const handleChangePartnerName = (value) => {
    value = value.replace(/^\s+/g, "");
    const ALPHA_NUMERIC_DASH_REGEX = /^[a-zA-Z0-9\s]{1,}[\.]{0,1}[A-Za-z0-9\s]{0,}$/;
    if (value !== "" && !ALPHA_NUMERIC_DASH_REGEX.test(value)) value = "";
    if (value === "")
      setErrors({ ...errors, partnerName: "Partner Name can't be empty " });
    else {
      setErrors({ ...errors, partnerName: "" });
    }
    setPartnerName(value);
  };

  const handleChangeSpocName = (value) => {
    value = value.replace(/^\s+/g, "");
    const ALPHA_NUMERIC_DASH_REGEX = /^[a-zA-Z0-9\s]{1,}[\.]{0,1}[A-Za-z0-9\s]{0,}$/;
    if (value !== "" && !ALPHA_NUMERIC_DASH_REGEX.test(value)) value = "";
    if (value === "")
      setErrors({ ...errors, spocName: "SPOC Name can't be empty " });
    else {
      setErrors({ ...errors, spocName: "" });
    }
    setSpocName(value);
  };

  const handleChangeSpocEmail = (value) => {
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (value.match(mailformat)) {
      setErrors({ ...errors, email: "" });
    } else {
      setErrors({ ...errors, email: "Invalid Email " });
    }
    setSpocEmail(value);
  };

  const handleChangePartnerStatus = (event) => {
    setPartnerStatus(event.target.value);
  };
  const handleChangePartnerProtocol = (event) => {
    if (event.target.value !== (null || undefined)) {
      setPartnerProtocol(event.target.value);
      setErrors({ ...errors, protocol: "" });
    }
  };
  const handleChangeSpocNumber = (phoneNumber) => {
    const reg = /^[1-9][0-9]*$/;
    if (phoneNumber === "") {
      setErrors({ ...errors, phoneNumber: "Phone number can't be empty " });
    } else if (!reg.test(phoneNumber)) {
      setErrors({
        ...errors,
        phoneNumber: "Phone number should not start with 0 ",
      });
    } else if (phoneNumber.length !== 10) {
      setErrors({ ...errors, phoneNumber: "Phone number should be 10 digits" });
    } else {
      setErrors({ ...errors, phoneNumber: "" });
    }
    setSpocNumber(phoneNumber);
  };

  const handleCloseByClearIcon = () => {
    setErrors(basicError);
    setPartnerName("");
    setPartnerName("");
    setSpocName("");
    setSpocNumber("");
    setSpocEmail("");
    setPartnerProtocol("");
    setPartnerStatus("");
    setEditPartnerSnackBar(false);
    setSnackBarType("success");
    setEditPartnerSnackBarMessage("");
    setPartnerSnackBarMessage("");
    setLocation({
      state: null,
      district: null,
      taluka: null,
      villages: [],
    });
    dispatch(resetEditPartner());
    handleClose();
  };

  const setLocationForApi = () => {
    var locations = {
      state_ids: [],
      district_ids: [],
      taluka_ids: [],
      village_ids: [],
    };
    if(location[0].villages.length > 0){
      location.forEach((item) => {
        locations["state_ids"].push(item.state.id);
        locations["district_ids"].push(item.district.id);
        locations["taluka_ids"].push(item.taluka.id);
        item.villages.forEach((villageObj) => {
          if(villageObj.id!=="all")
            locations["village_ids"].push(villageObj.id);
        });
      });
      locations["state_ids"] = removeDuplicates(locations["state_ids"]);
      locations["district_ids"] = removeDuplicates(locations["district_ids"]);
      locations["taluka_ids"] = removeDuplicates(locations["taluka_ids"]);
      locations["village_ids"] = removeDuplicates(locations["village_ids"]);
    }
    return locations;
  };

  const handleSaveData = () => {
    dispatch(resetEditPartner());
    var locations = setLocationForApi();
    if (
      errors.partnerName === "" &&
      errors.email === "" &&
      errors.spocName === "" &&
      errors.phoneNumber === "" &&
      errors.protocol === "" &&
      partnerName !== "" &&
      spocName !== "" &&
      spocNumber !== "" &&
      spocEmail !== "" &&
      partnerProtocol !== ""
    ) {
      setEditPartnerSnackBar(false);
      setSnackBarType("success");
      setEditPartnerSnackBarMessage("");
      setLoading(true);
      var isActive = partnerStatus === "Active" ? true : false;
      dispatch(
        editPartner({
          id: data.id,
          name: partnerName,
          spoc_name: spocName,
          spoc_email: spocEmail,
          spoc_phone_number: spocNumber,
          is_active: isActive,
          status: partnerStatus,
          protocol: partnerProtocol,
          location: locations,
        })
      ).then(() => {
        setLoading(false);
      });
    } else {
      setEditPartnerSnackBar(true);
      setSnackBarType("error");
      setEditPartnerSnackBarMessage("Please Fill all required values");
    }
  };

  const handleChangeLocation = (newValue, index, type, menuList) => {
    let newLocation = [...location];
    if (type === "state") {
      newLocation[index].state = newValue;
      newLocation[index].district = null;
      newLocation[index].taluka = null;
      newLocation[index].villages = [];
      setLocation(newLocation);
    } else if (type === "district") {
      newLocation[index].district = newValue;
      setLocation(newLocation);
      newLocation[index].taluka = null;
      newLocation[index].villages = [];
    } else if (type === "taluka") {
      newLocation[index].taluka = newValue;
      setLocation(newLocation);
      newLocation[index].villages = [];
    } else if (type === "village") {
      var oldSelected = newLocation[index].villages
      var containsAllInOld = oldSelected.some(obj => obj.name === "All");
      var containsAll = newValue.some(obj => obj.name === "All");
      if(containsAll){
        newLocation[index].villages = menuList;
      }
      else if(!containsAll && containsAllInOld){
        newLocation[index].villages = []
      }
      else{
        newLocation[index].villages = newValue;
      }
      
      setLocation(newLocation);
    }
  };

  const handleChangeAddAnotherLocationBox = (event) => {
    if (location[addAnotherLocationDropDown - 1].villages.length !== 0) {
      const newLocation = [...location];
      newLocation.push({
        state: null,
        district: null,
        taluka: null,
        villages: [],
      });
      setLocation(newLocation);
      setAddAnotherLocationDropDown((prevIndex) => prevIndex + 1);
    }
  };

  useEffect(() => {
    if (editPartnerIsSuccess && editPartnerApiResponse !== "") {
      setPartnerSnackBarMessage(editPartnerApiResponse);
      setPartnerSnackBarType("success")
      setReload(true);
      setEditData({});
      handleClose();
      dispatch(resetEditPartner());
    } else if (!editPartnerIsSuccess && editPartnerErrorMessage !== "") {
      setEditPartnerSnackBar(true);
      setSnackBarType("error");
      setEditPartnerSnackBarMessage(editPartnerErrorMessage);
    }
  }, [editPartnerIsSuccess, editPartnerErrorMessage, editPartnerApiResponse]);

  const handleChangeDeleteLocation = (index) => {
    setAddAnotherLocationDropDown((prevIndex) => prevIndex - 1);
    setLocation((names) => names.filter((_, ind) => ind !== index));
  };

  const handleAddAnotherLocationBox = (index) => {
    return (
      <React.Fragment key={index}>
        <LocationDropDown
          location={location}
          handleChangeLocation={handleChangeLocation}
          handleChangeDeleteLocation={handleChangeDeleteLocation}
          index={index}
          getStatesResponse={getStatesResponse}
          disable={index < totalLocationEditableData}
          addAllVillage = {true}
        />
      </React.Fragment>
    );
  };

  return (
    <Modal
      open={openModal}
    >
      <Grid container sx={subGridStyle} spacing={1}>
        <ThemeProvider theme={basicTheme}>
            <Grid item xs={11}>
              <Typography variant="h4" style={{ marginBottom: 10 }}>
                Edit Partner
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <ClearIcon
                className={classes.closeBtnStyle}
                onClick={handleCloseByClearIcon}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ marginBottom: 1 }}>Partner Name</Typography>
                  <BasicTextfield
                    label="Enter Partner Name"
                    is_phone={false}
                    handleChange={handleChangePartnerName}
                    is_disabled={false}
                    index={1}
                    error={errors.partnerName !== ""}
                    helperText={errors.partnerName}
                    required={true}
                    value={partnerName}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ marginBottom: 1 }}>
                    Partner Status
                  </Typography>
                  <BasicDropDown
                    text="Select Partner Status"
                    value={partnerStatus}
                    handleChange={handleChangePartnerStatus}
                    menuList={partnerStatusList}
                    error={false}
                    required={true}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography sx={{ marginBottom: 1 }}>Spoc Name</Typography>
                  <BasicTextfield
                    label="Enter Spoc Name"
                    is_phone={false}
                    handleChange={handleChangeSpocName}
                    is_disabled={false}
                    index={3}
                    error={errors.spocName !== ""}
                    helperText={errors.spocName}
                    required={true}
                    value={spocName}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ marginBottom: 1 }}>
                    Spoc Mobile Number
                  </Typography>
                  <BasicTextfield
                    label="Enter Spoc Mobile Number"
                    is_phone={true}
                    handleChange={handleChangeSpocNumber}
                    is_disabled={false}
                    index={4}
                    error={errors.phoneNumber !== ""}
                    helperText={errors.phoneNumber}
                    required={true}
                    value={spocNumber}
                    number={true}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ marginBottom: 1 }}>
                    Spoc Email Id
                  </Typography>
                  <BasicTextfield
                    label="Enter Spoc Email Id"
                    is_phone={false}
                    handleChange={handleChangeSpocEmail}
                    is_disabled={false}
                    index={5}
                    error={errors.email !== ""}
                    helperText={errors.email}
                    required={true}
                    value={spocEmail}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ marginBottom: 1 }}>Protocol</Typography>
                  <BasicDropDown
                    text="Select Protocol"
                    value={partnerProtocol}
                    handleChange={handleChangePartnerProtocol}
                    menuList={protocolList}
                    error={false}
                    required={true}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography sx={{ marginBottom: 1 }}>Location </Typography>
                </Grid>
                {Array.from({
                  length: addAnotherLocationDropDown,
                }).map((_, index) => handleAddAnotherLocationBox(index))}
              </Grid>
              <Grid item xs={12}>
                <Typography
                  sx={{
                    marginBottom: 4,
                    color: "#2D6A4F",
                    marginTop: 2,
                    cursor: "pointer",
                  }}
                  onClick={(e) => handleChangeAddAnotherLocationBox(e)}
                >
                  + Add in another State/District/Taluka
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                item
                sm={12}
                spacing={0}
                sx={{
                  direction: "row",
                  justifyContent: "center",
                  marginTop: 5,
                  marginBottom: 5,
                }}
              >
                <Grid
                  item
                  xs={12}
                  sm={2.5}
                  style={{ backgroundColor: "transparent", boxShadow: "none" }}
                >
                  <CancelButtonStyle
                    variant="contained"
                    name="cancel"
                    onClick={handleClose}
                  >
                    Cancel
                  </CancelButtonStyle>
                </Grid>
                <Grid item xs={12} sm={2.5}>
                  <ButtonStyle
                    variant="contained"
                    name="add"
                    startIcon={<CheckIcon size="large" />}
                    onClick={handleSaveData}
                  >
                    Save
                  </ButtonStyle>
                </Grid>
              </Grid>
            </Grid>
        </ThemeProvider>
        {loading && (
          <Backdrop
            sx={{
              color: "#fff",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        {editPartnerSnackBar && (
          <SnackBars
            open={editPartnerSnackBar}
            handleClose={() => setEditPartnerSnackBar(false)}
            severity={snackBarType}
            message={editPartnerSnackBarMessage}
            autoHideDuration={6000}
            vertical="bottom"
            horizontal="center"
          />
        )}
      </Grid>
    </Modal>
  );
}
