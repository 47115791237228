export const GET_LOCATION_DETAIL_API_RESPONSE = {
  data: [
    {
      state: { id: "", name: "" },
      district: { id: "", name: "" },
      taluka: { id: "", name: "" },
      village: { id: "", name: "" },
      is_active: false,
    },
  ],
  count: -1,
};

export const GET_STATES_API_RESPONSE = [
  {
    id: "",
    name: "",
  },
];

export const GET_HIERARCHY_API_RESPONSE = [
  {
    id: "",
    name: "",
  },
];

export const ADD_LOCATION_API_RESPONSE = {
  message:""
}

export const ERROR_MESSAGE_API_RESPONSE = {
  message:""
}