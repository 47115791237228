import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import { Typography } from "@mui/material";

const Centerstyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "0.5rem",
};

const closeIconStyle = {
  position: "absolute",
  right: 3,
  cursor: "pointer",
  color: (theme) => theme.palette.grey[600],
};
export default function HelpDialog({
  handleClose = () => {},
  open = false,
  heading = "",
  text = "",
}) {
  return (
    <Dialog
      open={open}
      PaperProps={{
        style: { borderRadius: "20px" },
      }}
    >
      <HighlightOffRoundedIcon sx={closeIconStyle} onClick={handleClose} />
      <DialogTitle sx={{ marginTop: 2, color: "#5E6060" }} align="center">
        {heading}
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={Centerstyle}>
          <Typography sx={{ color: "#5E6060" }}>Write to us at </Typography>
          <Typography sx={{ color: "#2D6A4F",cursor: "pointer",}}>
            agri-support@wadhwaniai.org
          </Typography>
          <Typography sx={{ color: "#5E6060" }}>for queries.</Typography>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}
