import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import * as Structures from "../../apiResonseStructres/locationModule";

import {
  locationDetailsAPI,
  statesApi,
  hierarchyApi,
  filteredLocationDetailsAPI,
  exportLocationDataAPI,
  addLocationAPI,
} from "../../api's/index";
import {getHeaders} from "../../utils/index"

const initialState = {
  locationData: Structures.GET_LOCATION_DETAIL_API_RESPONSE,
  locationDataLoading: false,
  locationDataIsSuccess: false,
  locationDataErrorMessage: Structures.ERROR_MESSAGE_API_RESPONSE,
  statesData: Structures.GET_STATES_API_RESPONSE,
  statesDataLoading: false,
  statesDataIsSuccess: false,
  statesDataErrorMessage: Structures.ERROR_MESSAGE_API_RESPONSE,
  districtData: Structures.GET_HIERARCHY_API_RESPONSE,
  talukaData: Structures.GET_HIERARCHY_API_RESPONSE,

  editLocationApiResponse: Structures.ADD_LOCATION_API_RESPONSE,
  editLocationErrorMessage: Structures.ERROR_MESSAGE_API_RESPONSE,
  editLocationIsSuccess: false,
  statesDataErrorMessage: Structures.ERROR_MESSAGE_API_RESPONSE,
  deleteLocationApiResponse: Structures.ADD_LOCATION_API_RESPONSE,
  deleteLocationErrorMessage: Structures.ERROR_MESSAGE_API_RESPONSE,
  deleteLocationIsSuccess: false,
  exportedData: [],

  addLocationApiResponse: Structures.ADD_LOCATION_API_RESPONSE,
  addLocationErrorMessage: Structures.ERROR_MESSAGE_API_RESPONSE,
  addLocationIsSuccess: false,
};
var qs = require("qs");
const headers = getHeaders();


export const getlocationData = createAsyncThunk(
  "location/getlocationData",
  async (data, { rejectWithValue }) => {
    const headers = getHeaders();
    try {
      const response = await axios.get(locationDetailsAPI, {
        params: data,
        headers: headers
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getFilteredLocationData = createAsyncThunk(
  "location/getFilteredlocationData",
  async (data, { rejectWithValue }) => {
    const headers = getHeaders();
    try {
      const response = await axios.get(filteredLocationDetailsAPI, {
        params: data,
        headers: headers,
        paramsSerializer: {
          serialize: (params) =>
            qs.stringify(params, { arrayFormat: "repeat" }),
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getStatesData = createAsyncThunk(
  "location/getStatesData",
  
  async (data, { rejectWithValue }) => {
    const headers = getHeaders();
    try {
      const response = await axios.get(statesApi, {
        params: data,
        headers: headers,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getDistrictData = createAsyncThunk(
  "location/getDistrictData",
  async (data, { rejectWithValue }) => {
    const headers = getHeaders();
    try {
      const response = await axios.get(hierarchyApi, {
        params: data,
        headers: headers,
        paramsSerializer: {
          serialize: (params) =>
            qs.stringify(params, { arrayFormat: "repeat" }),
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getTalukaData = createAsyncThunk(
  "location/getTalukaData",
  async (data, { rejectWithValue }) => {
    const headers = getHeaders();
    // var qs = require('qs');
    try {
      const response = await axios.get(hierarchyApi, {
        params: data,
        headers: headers,
        paramsSerializer: {
          serialize: (params) =>
            qs.stringify(params, { arrayFormat: "repeat" }),
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getExportedData = createAsyncThunk(
  "location/getExportedData",
  async (data, { rejectWithValue }) => {
    const headers = getHeaders();
    // var qs = require('qs');
    try {
      const response = await axios.get(exportLocationDataAPI, {
        params: data,
        headers: headers,
        paramsSerializer: {
          serialize: (params) =>
            qs.stringify(params, { arrayFormat: "repeat" }),
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addLocation = createAsyncThunk(
  "location/addLocation",
  async (data, { rejectWithValue }) => {
    const headers = getHeaders();
    try {
      const response = await axios({
        method: "post",
        url: addLocationAPI,
        data: data,
        headers: headers
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const editLocation = createAsyncThunk(
  "location/editLocation",
  async (data, { rejectWithValue }) => {
    const headers = getHeaders();
    try {
      const response = await axios({
        method: "put",
        url: addLocationAPI,
        data: data,
        headers: headers
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteLocation = createAsyncThunk(
  "location/deleteLocation",
  async (data, { rejectWithValue }) => {
    const headers = getHeaders();
    try {
      const response = await axios({
        method: "put",
        url: addLocationAPI,
        data: data,
        headers: headers
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const locationSlice = createSlice({
  name: "getlocationData",
  initialState,
  reducers: {
    resetDistrict(state) {
      Object.assign(state.districtData, Structures.GET_HIERARCHY_API_RESPONSE);
    },
    resetTaluka(state) {
      Object.assign(state.talukaData, Structures.GET_HIERARCHY_API_RESPONSE);
    },
    resetAddlocation(state) {
      Object.assign(
        state.addLocationApiResponse,
        Structures.ADD_LOCATION_API_RESPONSE
      );
      Object.assign(
        state.addLocationErrorMessage,
        Structures.ERROR_MESSAGE_API_RESPONSE
      );
      Object.assign(state.addLocationIsSuccess, false);
    },
    resetEditlocation(state) {
      Object.assign(
        state.editLocationApiResponse,
        Structures.ADD_LOCATION_API_RESPONSE
      );
      Object.assign(
        state.editLocationErrorMessage,
        Structures.ERROR_MESSAGE_API_RESPONSE
      );
      Object.assign(state.editLocationIsSuccess, false);
    },
    resetDeletelocation(state) {
      Object.assign(
        state.deleteLocationApiResponse,
        Structures.ADD_LOCATION_API_RESPONSE
      );
      Object.assign(
        state.deleteLocationErrorMessage,
        Structures.ERROR_MESSAGE_API_RESPONSE
      );
      Object.assign(state.deleteLocationIsSuccess, false);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getlocationData.fulfilled, (state, { payload }) => {
        state.locationData = payload;
        state.locationDataLoading = false;
        state.locationDataIsSuccess = true;
      })
      .addCase(getlocationData.pending, (state, { payload }) => {
        state.locationDataLoading = true;
        state.locationDataIsSuccess = false;
      })
      .addCase(getlocationData.rejected, (state, { payload }) => {
        state.locationDataErrorMessage = payload;
        state.locationDataLoading = false;
        state.locationDataIsSuccess = false;
      })
      .addCase(getFilteredLocationData.fulfilled, (state, { payload }) => {
        state.locationData = payload;
        state.locationDataLoading = false;
        state.locationDataIsSuccess = true;
      })
      .addCase(getFilteredLocationData.pending, (state, { payload }) => {
        state.locationDataLoading = true;
      })
      .addCase(getFilteredLocationData.rejected, (state, { payload }) => {
        state.locationDataErrorMessage = payload;
        state.locationDataLoading = false;
        state.locationDataIsSuccess = false;
      })
      .addCase(getStatesData.fulfilled, (state, { payload }) => {
        state.statesData = payload;
        state.statesDataLoading = false;
        state.statesDataIsSuccess = true;
      })
      .addCase(getStatesData.pending, (state, { payload }) => {
        state.statesDataLoading = true;
      })
      .addCase(getStatesData.rejected, (state, { payload }) => {
        state.statesDataErrorMessage = payload;
        state.statesDataLoading = false;
        state.statesDataIsSuccess = false;
      })
      .addCase(getDistrictData.fulfilled, (state, { payload }) => {
        state.districtData = payload;
      })
      .addCase(getTalukaData.fulfilled, (state, { payload }) => {
        state.talukaData = payload;
      })

      .addCase(getExportedData.fulfilled, (state, { payload }) => {
        state.exportedData = payload;
      })
      .addCase(addLocation.fulfilled, (state, { payload }) => {
        state.addLocationApiResponse = payload;
        state.addLocationIsSuccess = true;
      })
      .addCase(addLocation.rejected, (state, { payload }) => {
        state.addLocationErrorMessage = payload;
        state.addLocationIsSuccess = false;
      })
      .addCase(editLocation.fulfilled, (state, { payload }) => {
        state.editLocationApiResponse = payload;
        state.editLocationIsSuccess = true;
      })
      .addCase(editLocation.rejected, (state, { payload }) => {
        state.editLocationErrorMessage = payload;
        state.editLocationIsSuccess = false;
      })
      .addCase(deleteLocation.fulfilled, (state, { payload }) => {
        state.deleteLocationApiResponse = payload;
        state.deleteLocationIsSuccess = true;
      })
      .addCase(deleteLocation.rejected, (state, { payload }) => {
        state.deleteLocationErrorMessage = payload;
        state.deleteLocationIsSuccess = false;
      });
  },
});
export const {
  resetDistrict,
  resetTaluka,
  resetAddlocation,
  resetEditlocation,
  resetDeletelocation,
} = locationSlice.actions;
export default locationSlice.reducer;
