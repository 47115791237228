import React, { useState, useEffect } from "react";
import Popover from "@mui/material/Popover";
import { Grid, Button, Typography, CircularProgress, Box, Divider } from "@mui/material";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles"
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import CloudUploadRoundedIcon from "@mui/icons-material/CloudUploadRounded";
import GetAppRoundedIcon from "@mui/icons-material/GetAppRounded";
import { getHeaders } from "../../utils/index";
import axios from "axios";
import LinearProgress from "@mui/material/LinearProgress";
import { bulkUploadAdvisoryAPI, bulkUploadAdvisoryReportAPI } from "../../api's";
import SamplePesticideFile from "../../files/pesticide.xlsx";
import { downloadFile } from "../../utils";

const StyledGrid = styled(Grid)({
    display: "flex",
    width: "300px",
});

const useStyles = makeStyles(() => ({
}));

const closeIconStyle = {
    position: "absolute",
    cursor: "pointer",
    color: (theme) => theme.palette.grey[600],
};

export default function BulkUploadPesticide({
    isUpload,
    handleClose = () => { },
    setSnackBarMessage = () => { },
    setSnackBarType = () => { },
}) {
    const classes = useStyles();
    const open = Boolean(isUpload);
    const id = open ? "simple-popover" : undefined;
    const [file, setFile] = useState(null);
    const [taskStatus, setTaskStatus] = useState(null);
    const [isFileUploaded, setIsFileUploaded] = useState(false);
    const [showDownloadButton, setshowDownloadButton] = useState(false);
    const [lastUpdateTime, setLastUpdateTime] = useState(null);
    const [showUploadButton, setshowUploadButton] = useState(true);
    const [loading, setLoading] = useState(false);

    // fetching celery task status
    useEffect(() => {
        getTaskStatus().then((response) => {
            setTaskStatus(response);
        });
    }, [isFileUploaded]);

    const getTaskStatus = async () => {
        let headers = getHeaders();
        try {
            const response = await axios.get(
                bulkUploadAdvisoryAPI + '?type=pesticide', { headers: headers }
            )
            return response.data;
        } catch (error) {
            console.log(error);
        }
    }

    const handleUpload = async () => {
        setLoading(true);
        setSnackBarType("success")
        let headers = getHeaders();
        headers['Content-Type'] = 'multipart/form-data';
        const formData = new FormData();
        formData.append("file", file);
        try {
            const response = await axios.post(
                bulkUploadAdvisoryAPI + '?type=pesticide', formData, { headers: headers }
            ).then((response) => {
                setFile(null);
                setIsFileUploaded(true);
                setSnackBarMessage("File uploaded successfully");
            })
        } catch (error) {
            setSnackBarMessage(error.response.data.message ? error.response.data.message : "File upload failed");
            setSnackBarType("error");
        }
        finally {
            setLoading(false);
        }
    }

    const handleDownload = async () => {
        let headers = getHeaders();
        try {
            const response = await axios.get(bulkUploadAdvisoryReportAPI + '?type=pesticide', {
            headers: headers,
            });
            const presignedUrl = response.data['data']
            downloadFile('report.xlsx', presignedUrl)
        } catch (error) {
            console.error(error);
        }
    }
    useEffect(() => {
        if (taskStatus !== null &&
            taskStatus.message === 'Completed') {
            setshowDownloadButton(true);
            setshowUploadButton(true);

            // fomate date and time
            const date = new Date(taskStatus.updated_at);
            const options = {
                day: 'numeric', month: 'short', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true
            }
            const formattedDate = date.toLocaleDateString('en-US', options).replaceAll(',', '');

            setLastUpdateTime(formattedDate);
        }
        else if (taskStatus !== null &&
            taskStatus.message === 'No Record') {
            setshowDownloadButton(false);
            setshowUploadButton(true);
        }
        else if (taskStatus !== null &&
            taskStatus.message === 'Inprogress') {
            setshowDownloadButton(false);
            setshowUploadButton(false);
        }
    }, [taskStatus]);

    return (
        <Popover
            id={id}
            open={open}
            anchorEl={isUpload}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
            }}
            sx={{
                '& .MuiPopover-paper': {
                    marginTop: 1,
                    padding: 1.2,
                    borderRadius: '16px',
                }
            }}
        >  {taskStatus ? (
            <StyledGrid container spacing={1}>
                <Grid container item xs={12}>
                    <Grid item xs={11} sx={{ color: '#5E6060' }}>
                        <Typography variant="h7" fontFamily={'inter'} >Upload File</Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <HighlightOffRoundedIcon sx={closeIconStyle} onClick={handleClose} />
                    </Grid>
                </Grid>
                {showUploadButton ? (
                    <>
                        <Grid container item xs={12}
                            // design input field
                            sx={{
                                marginLeft: '0.5rem',
                                marginTop: '0.5rem',
                                backgroundColor: '#F5F5F5',
                                borderRadius: '8px',
                                padding: '0.5rem',
                                display: 'grid',
                                alignContent: 'space-between',
                                height: '150px',
                            }}
                        >
                            <Grid item xs={12} sx={{ display: 'grid', justifyContent: 'center', alignContent: 'center', marginTop: 3 }}>
                                <Grid item>
                                    <input
                                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                        style={{ display: 'none' }}
                                        id="raised-button-file"
                                        multiple
                                        type="file"
                                        onChange={(e) => setFile(e.target.files[0])}
                                    />
                                    <label htmlFor="raised-button-file">
                                        <Box
                                            sx={{
                                                width: '100%',
                                                height: '100%',
                                                borderRadius: '8px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                flexDirection: 'column',
                                                cursor: 'pointer',
                                                "&:hover": {
                                                    backgroundColor: '#F5F5F5',
                                                    opacity: [0.9, 0.8, 0.7],
                                                },
                                            }}
                                        >
                                            <CloudUploadRoundedIcon sx={{ fontSize: 40, color: '#78828C' }} />
                                        </Box>
                                    </label>
                                </Grid>
                                <Grid item>
                                    <Typography variant="h8"
                                        sx={{
                                            color: '#78828C',
                                            marginTop: '0.5rem',
                                        }}
                                    >
                                        {file ? file.name : "No file selected"}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid
                                item
                                onClick={() => downloadFile('Sample Pesticide File.xlsx', SamplePesticideFile)}
                                sx={{ color: '#2D6A4F', display: 'flex', cursor: 'pointer', '&:hover': { opacity: [0.9, 0.8, 0.7], }, justifyContent: 'end' }}
                            >
                                <GetAppRoundedIcon sx={{ fontSize: 16, marginTop: '0.3rem' }} />
                                <Typography variant="h8" sx={{ marginLeft: '0.2rem' }}>Download Sample</Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Button
                                variant="contained"
                                onClick={handleUpload}
                                sx={{
                                    width: '130px',
                                    height: '41px',
                                    background: '#2D6A4F',
                                    borderRadius: '10px',
                                    color: '#FFFFFF',
                                    textTransform: 'none',
                                    '&:hover': {
                                        backgroundColor: '#2D6A4F',
                                        opacity: [0.9, 0.8, 0.7],
                                    },
                                }}
                                disabled={file === null}
                            >
                                {loading && <CircularProgress size={20} color="inherit" />}
                                {!loading && "Upload"}
                            </Button>
                        </Grid>
                    </>
                ) : (
                    <Grid item xs={12}>
                        <Typography variant="h8" >File is already in progress</Typography>
                        <Box>
                            <LinearProgress color="success" />
                        </Box>
                    </Grid>
                )
                }
                {/* create a heading named 'report' and download button */}
                {showDownloadButton && (
                    <Grid container item xs={12} spacing={1} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h7" fontFamily={'inter'} color='#5E6060'>Updated at {lastUpdateTime}</Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="h8" fontFamily={'inter'} color='#5E6060'>Report</Typography>
                        </Grid>
                        {/* download link text with download icon */}
                        <Grid
                            item
                            onClick={handleDownload}
                            sx={{ color: '#2D6A4F', display: 'flex', cursor: 'pointer', '&:hover': { opacity: [0.9, 0.8, 0.7], } }}
                        >
                            <GetAppRoundedIcon sx={{ fontSize: 16, marginTop: '0.3rem' }} />
                            <Typography variant="h8" sx={{ marginLeft: '0.2rem' }}>Download Report</Typography>
                        </Grid>
                    </Grid>)}
            </StyledGrid>
        ) : (
            <Box sx={{ display: 'flex' }}>
                <CircularProgress />
            </Box>
        )}
        </Popover>
    );
}
