import React, { useState, useEffect, useRef } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { Grid, Box, Typography, ThemeProvider, Button } from "@mui/material";
import { basicTheme } from "../../themes/customTheme";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CollectionsIcon from "@mui/icons-material/Collections";
import ReviewImageDialog from "./ReviewImageDialog";
import {
  getallPendingImagesData,
  assignJob,
} from "../../features/reviewImages/reviewImagesSlice";
import SnackBars from "../reusableComponents/SnackBar";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import TablePagination from "@mui/material/TablePagination";

const MainGrid = styled(Grid)({
  direction: "row",
  justifyContent: "flex-start",
  alignItems: "center",
});

const StyledTitled = styled(Typography)({
  fontWeight: 500,
  fontStretch: "normal",
  fontStyle: "Inter",
  color: "#202124",
  lineHeight: "40px",
  letterSpacing: "normal",
  color: "#5E6060",
});

const StyledSubTitled = styled(Grid)({
  fontWeight: 500,
  fontStretch: "normal",
  fontStyle: "Inter",
  lineHeight: "60px",
  letterSpacing: "normal",
  color: "#1C1C28",
  minWidth: "250px",
  color: "#78828C",
});

const useStyles = makeStyles(() => ({
  imgNumber: {
    display: "flex",
    justifyContent: "flex-end",
    backgroundColor: "grey",
    borderRadius: "16px",
    // width:"65px",
    // height:"32px"
  },
}));

const ButtonStyle = styled(Button)({
  backgroundColor: "#2D6A4F",
  color: "white",
  textTransform: "none",
  borderRadius: "12px",
  fontWeight: "600",
  minWidth: "100px",
  maxWidth: "200px",
  height: "48px",
});
export default function PendingImages({
  setTotalPending,
  setTotalCompleted,
  totalCount = 0,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [reload, setReload] = useState(true);
  const [reviewImageData, setReviewImageData] = useState({});
  const [pendingSnackBarType, setPendingSnackBarType] = useState("success");
  const [pendingSnackBarMessage, setPendingSnackBarMessage] = useState("");
  const [showPendingSnackBar, setPendingShowSnackBar] = useState(false);
  const [pendingData, setPendingData] = useState({});
  const rowsPerPage = 10;
  const [offset, setOffset] = useState(0);
  const [page, setPage] = React.useState(0);
  useEffect(() => {
    setOffset(page * rowsPerPage);
    setReload(true)
  }, [page]);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const allPendingImagesData = useSelector(
    (state) => state.reviewImagesSliceReducer.allPendingImagesData
  );

  const allPendingImagesDataErrorMessage = useSelector(
    (state) => state.reviewImagesSliceReducer.allPendingImagesDataErrorMessage
  );

  const allPendingImagesDataIsSuccess = useSelector(
    (state) => state.reviewImagesSliceReducer.allPendingImagesDataIsSuccess
  );

  const allPendingImagesDataLoading = useSelector(
    (state) => state.reviewImagesSliceReducer.allPendingImagesDataLoading
  );

  const [openReviewImagesModal, setOpenReviewImagesModal] = useState(false);

  useEffect(() => {
    if (reload)
      dispatch(
        getallPendingImagesData({ offset: offset, limit: rowsPerPage })
      ).then(() => {
        setReload(false);
      });
  }, [reload, offset]);

  useEffect(() => {
    setTotalPending(allPendingImagesData.pending_jobs_count);
    setTotalCompleted(allPendingImagesData.completed_jobs_count);
    if (allPendingImagesData.pending_jobs_count !== -1) {
      var tempPendingSession = { ...allPendingImagesData };
      var tempPendingSessionSorted = sortedSessions(tempPendingSession);
      setPendingData(tempPendingSessionSorted);
    }
  }, [allPendingImagesData]);

  useEffect(() => {
    if (
      !allPendingImagesDataIsSuccess &&
      (allPendingImagesDataErrorMessage === undefined ||
        (allPendingImagesDataErrorMessage.message === undefined &&
          allPendingImagesDataErrorMessage.message !== ""))
    ) {
      setPendingShowSnackBar(true);
      setPendingSnackBarMessage("Something went wrong on server side ");
      setPendingSnackBarType("error");
    } else if (
      !allPendingImagesDataLoading &&
      !allPendingImagesDataIsSuccess &&
      allPendingImagesDataErrorMessage !== undefined &&
      allPendingImagesDataErrorMessage.message !== undefined &&
      allPendingImagesDataErrorMessage.message !== ""
    ) {
      setPendingShowSnackBar(true);
      setPendingSnackBarMessage(allPendingImagesDataErrorMessage.message);
      setPendingSnackBarType("error");
    }
  }, [allPendingImagesDataErrorMessage, allPendingImagesDataLoading]);

  const sortedSessions = (tempPendingSession) => {
    const pendingSessions = JSON.parse(JSON.stringify(tempPendingSession));
    pendingSessions.pending_sessions.sort((a, b) => {
      if (a.status === "in_progress" && b.status !== "in_progress") {
        return -1;
      }
      if (b.status === "in_progress" && a.status !== "in_progress") {
        return 1;
      }
      return 0;
    });
    return pendingSessions;
  };

  const handleClickReview = (data) => {
    dispatch(assignJob({ session_id: data.session_id })).then(() => {
      setReviewImageData(data);
      setOpenReviewImagesModal(true);
    });
  };

  return (
    <MainGrid container style={{ marginLeft: "110px", marginTop: "40px" }}>
      {reload && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 1000,
          }}
        >
          <Backdrop open={reload}>
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
      {showPendingSnackBar && (
        <SnackBars
          open={showPendingSnackBar}
          handleClose={() => setPendingShowSnackBar(false)}
          severity={pendingSnackBarType}
          message={pendingSnackBarMessage}
          autoHideDuration={6000}
          vertical="bottom"
          horizontal="center"
        />
      )}
      {openReviewImagesModal === true &&
        Object.keys(reviewImageData).length !== 0 && (
          <ReviewImageDialog
            openModal={openReviewImagesModal}
            handleClose={setOpenReviewImagesModal}
            data={reviewImageData}
            setReload={setReload}
            setPendingShowSnackBar={setPendingShowSnackBar}
            setPendingSnackBarMessage={setPendingSnackBarMessage}
            setPendingSnackBarType={setPendingSnackBarType}
          />
        )}
      {Object.keys(pendingData).length !== 0 &&
        pendingData.pending_jobs_count !== -1 &&
        pendingData.pending_sessions.map((data, ind) => {
          return (
            <Grid
              key={ind}
              item
              xs={12}
              sx={{
                height: { xl: "300px", lg: "240px", md: "180px", sm: "140px" },
              }}
            >
              <Grid container>
                <Grid
                  item
                  xs={2.25}
                  sx={{
                    marginRight: {
                      xl: "0px",
                      lg: "20px",
                      md: "16px",
                      sm: "6px",
                    },
                  }}
                >
                  <Card
                    sx={{
                      height: {
                        xl: "250px",
                        lg: "200px",
                        md: "160px",
                        sm: "120px",
                      },
                      width: {
                        xl: "220px",
                        lg: "180px",
                        md: "140px",
                        sm: "100px",
                      },
                      borderRadius: "12px",
                    }}
                  >
                    <div style={{ position: "relative" }}>
                      <CardMedia
                        sx={{
                          height: {
                            xl: "250px",
                            lg: "200px",
                            md: "160px",
                            sm: "120px",
                          },
                          width: {
                            xl: "220px",
                            lg: "180px",
                            md: "140px",
                            sm: "100px",
                          },
                          objectFit: "cover",
                          borderRadius: "12px",
                        }}
                        image={data.images[0].pre_signed_url}
                        component="img"
                      />
                      <Box
                        className={classes.imgNumber}
                        style={{
                          position: "absolute",
                          color: "white",
                          top: 0,
                          right: 0,
                          left: "82%",
                          transform: "translateX(-50%)",
                        }}
                        sx={{
                          flex: "1",
                          justifyContent: "center",
                          justify: "center",
                          alignItems: "center",
                          display: "flex",
                          marginTop: "4px",
                          width: { xl: "65px", lg: "56px", md: "48px" },
                          height: { xl: "32px", lg: "27px", md: "22px" },
                        }}
                      >
                        <CollectionsIcon
                          sx={{
                            color: "white",
                            width: { xl: "24px", lg: "20px", md: "16px" },
                            height: { xl: "24px", lg: "20px", md: "16px" },
                          }}
                        />
                        <Typography
                          sx={{
                            marginLeft: { xl: "6px", lg: "4px", md: "2px" },
                          }}
                        >
                          {data.images.length}
                        </Typography>
                      </Box>
                    </div>
                  </Card>
                </Grid>
                <Grid item xs={6}>
                  {/* <StyledTitled
                  sx={{
                    fontSize: {
                      xl: "32px",
                      lg: "26px",
                      md: "20px",
                      sm: "16px",
                    },
                    marginTop: {
                      xl: "18px",
                      lg: "12px",
                      md: "8px",
                      sm: "4px",
                    },
                  }}
                >
                  PROGRAM: {data.partner.toUpperCase()}
                </StyledTitled> */}
                  <StyledSubTitled
                    sx={{
                      fontSize: {
                        xl: "24px",
                        lg: "18px",
                        md: "14px",
                        sm: "10px",
                      },
                      // marginTop: {
                      // xl: "18px",
                      // lg: "12px",
                      // md: "8px",
                      // sm: "4px",
                      // },
                      marginTop: {
                        xl: "60px",
                        lg: "50px",
                        md: "40px",
                        sm: "30px",
                      },
                    }}
                  >
                    {data.images.length} images captured | {data.time_stamp} | {data.farm_type}
                  </StyledSubTitled>
                  <ButtonStyle
                    sx={{
                      fontSize: {
                        xl: "16px",
                        lg: "12px",
                        md: "8px",
                        sm: "6px",
                      },
                      marginTop: {
                        xl: "30px",
                        lg: "20px",
                        md: "10px",
                        sm: "8px",
                      },
                      height: {
                        xl: "48px",
                        lg: "40px",
                        md: "30px",
                        sm: "32px",
                      },
                      width: {
                        xl: "156px",
                        lg: "130px",
                        md: "110px",
                        sm: "90px",
                      },
                    }}
                    variant="contained"
                    disabled={ind !== 0 || page!==0}
                    name="review"
                    onClick={() => handleClickReview(data)}
                    // sx={{width:{xl:"200px",lg:"130px",md:"100px",sm:"80px"},}}
                    endIcon={
                      <KeyboardArrowRightIcon
                        size="large"
                        className={classes.iconStyle}
                      />
                    }
                  >
                    Review Now
                  </ButtonStyle>
                </Grid>
              </Grid>
              <Divider sx={{ marginTop: "20px" }} />
            </Grid>
          );
        })}
      <Box style={{ display: "flex", justifyContent: "center", width: "100%" }}>
        <TablePagination
          rowsPerPageOptions={[10]}
          component="div"
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
        />
      </Box>
    </MainGrid>
  );
}
