const API_ENDPOINT_SERVER = process.env.REACT_APP_API_ENDPOINT_SERVER
export const defaultAdvisoryPartnerId = process.env.REACT_APP_ADMIN_PARTNER
export const locationDetailsAPI = API_ENDPOINT_SERVER + "location/all/"
export const statesApi = API_ENDPOINT_SERVER + "location/states/"
export const districtsApi = API_ENDPOINT_SERVER + "location/districts/"
export const talukasApi = API_ENDPOINT_SERVER + "location/talukas/"
export const hierarchyApi = API_ENDPOINT_SERVER + "location/hierarchy/"
export const filteredLocationDetailsAPI = API_ENDPOINT_SERVER + "location/filter/"
export const exportLocationDataAPI = API_ENDPOINT_SERVER + "location/export/"
export const addLocationAPI = API_ENDPOINT_SERVER + "location/"
export const partnerDetailsAPI = API_ENDPOINT_SERVER + "partners/"
export const exportPartnerDataAPI = API_ENDPOINT_SERVER + "partners/export/"
export const loginAPI = API_ENDPOINT_SERVER + "accounts/login/"
export const forgotPasswordAPI = API_ENDPOINT_SERVER + "accounts/forgot-password/"
export const rolesAndPermissionsAPI = API_ENDPOINT_SERVER + "supervisors/permission/"
export const exportRolesAndPermissionsDataAPI = API_ENDPOINT_SERVER + "supervisors/permission/export"
export const supervisorDetailsAPI = API_ENDPOINT_SERVER + "supervisors/"
export const exportSupervisorDataAPI = API_ENDPOINT_SERVER + "supervisors/export/"
export const PesticidesAPI = API_ENDPOINT_SERVER + "crops/pesticide"
export const exportPesticidesDataAPI = API_ENDPOINT_SERVER + "crops/pesticide-export"  
export const cropAPI = API_ENDPOINT_SERVER + "crops/crop"
export const pestAPI = API_ENDPOINT_SERVER + "crops/pest"
export const bulkUploadAdvisoryAPI = API_ENDPOINT_SERVER + 'crops/upload-file'
export const bulkUploadAdvisoryReportAPI = API_ENDPOINT_SERVER + 'crops/export-report'
export const farmingTypeAPI = API_ENDPOINT_SERVER + "crops/farming-type"
export const seasonAPI = API_ENDPOINT_SERVER + "crops/season"
export const alertAPI = API_ENDPOINT_SERVER + "crops/alert"
export const partnerPesticideMappingAPI = API_ENDPOINT_SERVER + "crops/partner-pesticide-mapping"
export const locationByIdAPI = API_ENDPOINT_SERVER + "location/info"
export const appUsersAPI = API_ENDPOINT_SERVER + "app-users/"
export const farmerAPI = API_ENDPOINT_SERVER + "app-users/farmer/"
export const extensionWorkerAPI = API_ENDPOINT_SERVER + "app-users/extension-worker/"
export const exportAppUsersAPI = API_ENDPOINT_SERVER + "app-users/export-app-users"
export const bulkUploadUsersAPI = API_ENDPOINT_SERVER + "app-users/bulk-upload/"
export const bulkUploadUsersReportAPI = API_ENDPOINT_SERVER + "app-users/export-report/"
export const allPendingImagesAPI = API_ENDPOINT_SERVER + "session/get-pending-jobs/"
export const getAndSendAdvisoryAPI = API_ENDPOINT_SERVER + "session/send-advisory/"
export const assignJobAPI = API_ENDPOINT_SERVER + "session/assign-job/"
export const allCompletedImagesAPI = API_ENDPOINT_SERVER + "session/get-completed-jobs/"
export const partnerCountAPI = API_ENDPOINT_SERVER + "partners/count/"
export const sessionCountAPI = API_ENDPOINT_SERVER + "session/session-count/"
export const partnerBasedUsersCountAPI =  API_ENDPOINT_SERVER + "app-users/analytics/user-partner-count"
export const alertCountAPI = API_ENDPOINT_SERVER + "session/alert-count/"
export const imageCountAPI = API_ENDPOINT_SERVER + "session/image-count/"
export const activeUserCountAPI = API_ENDPOINT_SERVER + "app-users/analytics/user-count"
export const monitoringBasedCountAPI = API_ENDPOINT_SERVER + "session/monitoring/"
export const infestationBasedCountAPI = API_ENDPOINT_SERVER + "session/infestation/"
export const appUsageCountListAPI = API_ENDPOINT_SERVER + "app-users/active"
export const sessionDataAPI = API_ENDPOINT_SERVER + "session/list/sessions"
export const alertsDataAPI = API_ENDPOINT_SERVER + "session/list/alerts"
export const installCountAPI = API_ENDPOINT_SERVER + "accounts/app-install/"
