import React, { useState, useEffect, useRef, useContext } from "react";
import { styled } from "@mui/material/styles";
import { Grid, Box, Typography, ThemeProvider } from "@mui/material";
import { basicTheme } from "../../themes/customTheme";
import TopFilterBar from "../../components/reusableComponents/TopFilterBar";
import DesktopTable from "../../components/reusableComponents/DesktopTable";
import { useDispatch, useSelector } from "react-redux";
import { getSessionData,getSessionExportData} from "./DashboardSlice";
import { CSVLink } from "react-csv";
import GetAppRoundedIcon from "@mui/icons-material/GetAppRounded";
import LinearProgress from "@mui/material/LinearProgress";
import useMediaQuery from "@mui/material/useMediaQuery";
import StackTable from "../../components/reusableComponents/StackTable";
import PartnerFilterPopOver from "../../components/partner/partnerFilterPopOver";
import SnackBars from "../../components/reusableComponents/SnackBar";
import Backdrop from "@mui/material/Backdrop";
import { makeStyles } from "@mui/styles";
import {
  Button,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useLocation } from "react-router-dom";
import CommonContext from "../appbar/ContextProvider";
import dayjs from "dayjs";
import DashboardFilterPopOver from "../../components/dashboard/DashboardFilterPopOver";

const MainGrid = styled(Grid)({
  flex: "1",
  backgroundColor: "white",
  // [theme.breakpoints.down("sm")]: {
  //   marginTop: "60px",
  // },
  marginTop: "80px",
});


const initialHeadCells = [
  {
    key: "name",
    label: "User Name",
    type: "text",
    sort: false,
    capital: true,
  },
  {
    key: "partner_name",
    label: "Partner",
    type: "text",
    sort: true,
    capital:true
  },
  {
    key: "state_name",
    label: "State Name",
    type: "text",
    sort: false,
    capital: true,
  },
  {
    key: "village_name",
    label: "Village Name",
    type: "text",
    sort: false,
    capital: true,
  },
  {
    key: "session_status",
    label: "Session Status",
    type: "text",
    sort: false,
    capital: true,
  },
  {
    key: "pest_count",
    label: "Pest Count",
    type: "text",
    sort: false,
    capital: true,
  },
  {
    key: "alert_type",
    label: "Alert Type",
    type: "text",
    sort: false,
    capital: true,
  },
  {
    key: "created_at",
    label: "Session done on ",
    type: "text",
    sort: false,
  },
];


export default function Sessions({}) {
  const location = useLocation();
  const dispatch = useDispatch();
  const csvLink = useRef();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const rowsPerPage = 10;
  const [offset, setOffset] = useState(0);
  const [page, setPage] = React.useState(0);
  const [sortingType, setSortingType] = useState("desc");
  const [sortBy, setSortBy] = useState("created_at");
  const [applyFilter, setApplyFilter] = useState(false);
  const [sessionSnackBarType, setSessionSnackBarType] = useState("success");
  const [sessionSnackBarMessage, setSessionSnackBarMessage] = useState("");
  const [showSessionSnackBar, setSessionShowSnackBar] = useState(false);
  const [reload, setReload] = useState(false);
  const [apiCall, setApiCall] = useState(false);
  const [exportData, setExportData] = useState("");
  const [exportedFileName, setExportedFileName] = useState("");
  const [clearFilter, setClearFilter] = useState(false);
  const [loading, setLoading] = useState(false);
  const [locationIds, setLocationIds] = useState([]);
  const [partnerIds, setPartnerIds] = useState([]);
  const [sessionGeneratedBy, setSessionGeneratedBy] = useState("Total");
  const [sessionGeneratedInState , setSessionGeneratedInState] = useState(["All"])
  const [selectedStatesOptions, setSelectedStatesOptions] = useState([]);
  const [selectedPartnerOptions, setSelectedPartnerOptions] = useState([]);
  const sessionGeneratedByMenu = ["Total", "Lead Farmer", "Extension Worker"];
  const sessionGeneratedInStateMenu = ["All", "UttarPradesh" , "Delhi","Haryana","MadhyaPradesh"]
  const sessionGeneratedByAbbreviations = {
    "Total": "None",
    "Lead Farmer": "lf",
    "Extension Worker": "ew",
  };
  const { dashboardPopoverOpen, setDashboardPopoverOpen } = useContext(
    CommonContext
  );
  const [startDate, setStartDate] = useState(dayjs().subtract(1, "year"));
  const [endDate, setEndDate] = useState(dayjs(new Date()));

  const handleCloseFilterPopOver = () => {
    setDashboardPopoverOpen(null);
    setApplyFilter(false);
    setClearFilter(false);
  };

  const drawerWidth = useSelector(
    (state) => state.appBarSliceReducer.drawerWidth
  );


const useStyles = makeStyles(() => ({
  // Your styles here
  iconStyle: {
    cursor: "pointer",
  },
}));

const ExportButtonStyle = styled(Button)({
  backgroundColor: "white",
  color: "#2D6A4F",
  float:"right",
  marginRight: "40px",
  textTransform: "none",
  borderRadius: "12px",
  fontSize: "16px",
  fontWeight: "600",
  minWidth: "100px",
  maxWidth: "150px",
  height: "48px",
});

const classes = useStyles();

  const sessionData = useSelector(
    (state) => state.dashboardSliceReducer.sessionData.data
  );
  const sessionDataCount = useSelector(
    (state) => state.dashboardSliceReducer.sessionData.count
  );

  const sessionDataErrorMessage = useSelector(
    (state) => state.dashboardSliceReducer.sessionDataErrorMessage
  );

  const sessionDataIsSuccess = useSelector(
    (state) => state.dashboardSliceReducer.sessionDataIsSuccess
  );

  const sessionDataLoading = useSelector(
    (state) => state.dashboardSliceReducer.sessionDataLoading
  );

  const sessionExportData = useSelector(
    (state) => state.dashboardSliceReducer.sessionExportData.data
  );


  useEffect(() => {
    if (exportData){
      setLoading(true)
      var modifiedSessionGeneratedBy = sessionGeneratedByAbbreviations[sessionGeneratedBy];
      dispatch(
        getSessionExportData({
          filters:(locationIds.length === 0 && partnerIds.length === 0) ?JSON.stringify([]) :JSON.stringify([{type:"state","value":locationIds}, {type:"program_id","value":partnerIds}]),//add partner filter
          session_generated_by: modifiedSessionGeneratedBy,
          offset: 0,
          exported: exportData,
          limit: sessionDataCount,
          from_date: dayjs(startDate).format("DD/MM/YYYY"),
          to_date: dayjs(endDate).format("DD/MM/YYYY"),
        })
      ).then((res) => {
        setLoading(false)
        setExportData(false);
        if(res.error!==undefined){
          setSessionSnackBarMessage("Something went wrong!")
          setSessionShowSnackBar(true)
        }
        else{
            setExportedFileName(
              "sessions_data" + ".csv"
            );
            if(sessionDataCount !== 0){
              csvLink.current.link.click();
            }
          }
      });
    }
  }, [exportData]);

  useEffect(() => {
    if (applyFilter) {
      handleCloseFilterPopOver();
      
      setPage(0);
      setOffset(0);
      var tempLocationIds = []
      var tempPartnerIds = []
      if(selectedStatesOptions.length > 0) {
        selectedStatesOptions.forEach((obj)=>{
          tempLocationIds.push(obj.id)
        })
      }
      if(selectedPartnerOptions.length > 0) {
        selectedPartnerOptions.forEach((obj)=>{
          tempPartnerIds.push(obj.id)
        })
      }
      setLocationIds(tempLocationIds)
      setPartnerIds(tempPartnerIds)
      setApiCall(true);
    }
  }, [applyFilter]);

  useEffect(() => {
    if (clearFilter) {
      setPage(0);
      setOffset(0);
      setSelectedStatesOptions([]);
      setSelectedPartnerOptions([]);
      setSessionGeneratedBy("Total")
      setApiCall(false);
      console.log(selectedStatesOptions,selectedPartnerOptions)
    }
  }, [clearFilter]);


  useEffect(() => {
    setApiCall(true);
  }, [offset, sortingType, sortBy]);

  useEffect(() => {
    if (apiCall || reload) {
      setLoading(true);
      var modifiedSessionGeneratedBy = sessionGeneratedByAbbreviations[sessionGeneratedBy];
      var stateName = locationIds;
      dispatch(
        getSessionData({
          filters:(locationIds.length === 0 && partnerIds.length === 0) ?JSON.stringify([]) : (locationIds.length === 0 && partnerIds.length !== 0)? JSON.stringify([{type:"program_id","value":partnerIds}]) : (locationIds.length !== 0 && partnerIds.length === 0)? JSON.stringify([{type:"state","value":locationIds}]):JSON.stringify([{type:"state","value":locationIds}, {type:"program_id","value":partnerIds}]),//add partner filter
          session_generated_by: modifiedSessionGeneratedBy,
          offset: offset,
          exported:false,
          limit: rowsPerPage,
          from_date: dayjs(startDate).format("DD/MM/YYYY"),
          to_date: dayjs(endDate).format("DD/MM/YYYY"),
        })
      ).then(() => {
        setLoading(false);
        setApiCall(false);
        setReload(false);
      });
    }
  }, [dispatch, apiCall, reload]);

  useEffect(() => {
    if (sessionSnackBarMessage !== "" && sessionSnackBarMessage !== undefined)
      setSessionShowSnackBar(true);
  }, [sessionSnackBarMessage]);

  useEffect(() => {
    if (
      !sessionDataIsSuccess &&
      (sessionDataErrorMessage === undefined ||
        (sessionDataErrorMessage.message === undefined &&
          sessionDataErrorMessage.message !== ""))
    ) {
      setSessionShowSnackBar(true);
      setSessionSnackBarMessage(
        "Something went wrong on Session Dashboard server side "
      );
      setSessionSnackBarType("error");
    } else if (
      !sessionDataLoading &&
      !sessionDataIsSuccess &&
      sessionDataErrorMessage !== undefined &&
      sessionDataErrorMessage.message !== undefined &&
      sessionDataErrorMessage.message !== ""
    ) {
      setSessionShowSnackBar(true);
      setSessionSnackBarMessage(sessionDataErrorMessage.message);
      setSessionSnackBarType("error");
    }
  }, [sessionDataErrorMessage, sessionDataLoading]);

  return (
    <MainGrid
      container
      id="main"
      sx={{ marginLeft: drawerWidth + "px", width: "auto" }}
      spacing={3}
    >
      <ThemeProvider theme={basicTheme}>
        <Grid item xs={12}>
          {dashboardPopoverOpen && (
          <DashboardFilterPopOver
            handleClose={handleCloseFilterPopOver}
            openFilterPopOver={dashboardPopoverOpen}
            setApplyFilter={setApplyFilter}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            clearFilter={clearFilter}
            setClearFilter={setClearFilter}
            selectedStatesOptions={selectedStatesOptions}
            setSelectedStatesOptions={setSelectedStatesOptions}
            selectedPartnersOptions = {selectedPartnerOptions}
            setSelectedPartners = {setSelectedPartnerOptions}
            sessionGeneratedBy={sessionGeneratedBy}
            sessionGeneratedInState={sessionGeneratedInState}
            setSessionGeneratedBy={setSessionGeneratedBy}
            setSessionGeneratedInState={setSessionGeneratedInState}
            sessionGeneratedByMenu={sessionGeneratedByMenu}
            sessionGeneratedInStateMenu = {sessionGeneratedInStateMenu}
            sessionFilterLabel="Session Done By"
          />
        )}
        </Grid>
        <Grid item xs={12}>
                <ExportButtonStyle
                  variant="contained"
                  name="Download"
                  
                  startIcon={
                    <GetAppRoundedIcon className={classes.iconStyle} />
                  }
                  onClick={() =>  setExportData(true)}
                  // sx={{width:{xl:"200px",lg:"130px",md:"100px",sm:"80px"},}}
                >
                  Export
                </ExportButtonStyle>
              </Grid>
        <Grid item xs={12}>
          {sessionDataLoading && !sessionDataIsSuccess && loading && (
            <Box>
              <LinearProgress color="success" />
            </Box>
          )}
          {isSmallScreen && sessionDataIsSuccess ? (
            <StackTable
            key={sessionData}
            headCells={initialHeadCells}
            tableResponse={sessionData}
            totalCount={sessionDataCount}
            setOffset={setOffset}
            rowsPerPage={rowsPerPage}
            setSortingType={setSortingType}
            sortingType={sortingType}
            setSortBy={setSortBy}
            sortBy={sortBy}
            setPage={setPage}
            page={page}
          />
          ) : (
            !sessionDataLoading &&
            sessionDataIsSuccess &&
            !loading && (
              <DesktopTable
                key={sessionData}
                headCells={initialHeadCells}
                tableResponse={sessionData}
                totalCount={sessionDataCount}
                setOffset={setOffset}
                rowsPerPage={rowsPerPage}
                setSortingType={setSortingType}
                sortingType={sortingType}
                setSortBy={setSortBy}
                sortBy={sortBy}
                setPage={setPage}
                page={page}
              />
            )
          )}
        </Grid>
        <CSVLink
          data={sessionExportData}
          filename={exportedFileName}
          className="hidden"
          target="_blank"
          ref={csvLink}
        />
        {showSessionSnackBar && (
          <SnackBars
            open={showSessionSnackBar}
            handleClose={() => setSessionShowSnackBar(false)}
            severity={sessionSnackBarType}
            message={sessionSnackBarMessage}
            autoHideDuration={6000}
            vertical="bottom"
            horizontal="center"
          />
        )}
        {loading && (
          <Backdrop
            sx={{
              color: "#fff",
              position: "absolute",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
      </ThemeProvider>
    </MainGrid>
  );
}
