import { createSlice } from "@reduxjs/toolkit";
import React, {useEffect, useState } from "react";
import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import * as Structures from "../../apiResonseStructres/appUsersModule";
import {getHeaders} from "../../utils/index"



import { appUsersAPI, farmerAPI, extensionWorkerAPI,exportAppUsersAPI } from "../../api's/index";
const initialState = {
  usersData: Structures.GET_APP_USERS_DETAIL_API_RESPONSE,
  usersDataLoading: false,
  usersDataIsSuccess: false,
  EwData: Structures.GET_APP_USERS_DETAIL_API_RESPONSE,
  EwDataLoading: false,
  EwDataIsSuccess: false,
  usersDataErrorMessage: Structures.ERROR_MESSAGE_API_RESPONSE,
  EwDataErrorMessage: Structures.ERROR_MESSAGE_API_RESPONSE,
  exportedData: [],
  addUserApiResponse: Structures.ADD_USER_API_RESPONSE,
  addUserErrorMessage: Structures.ERROR_MESSAGE_API_RESPONSE,
  addUserIsSuccess: false,
  addUserIsLoading: false,
  editUserApiResponse: Structures.ADD_USER_API_RESPONSE,
  editUserErrorMessage: Structures.ERROR_MESSAGE_API_RESPONSE,
  editUserIsSuccess: false,
  editUserIsLoading: false,
  deleteUserApiResponse: Structures.ADD_USER_API_RESPONSE,
  deleteUserErrorMessage: Structures.ERROR_MESSAGE_API_RESPONSE,
  deleteUserIsSuccess: false,
  editEwApiResponse: Structures.ADD_USER_API_RESPONSE,
  editEwErrorMessage: Structures.ERROR_MESSAGE_API_RESPONSE,
  editEwIsSuccess: false,
  editEwIsLoading: false,
  deleteEwApiResponse: Structures.ADD_USER_API_RESPONSE,
  deleteEwErrorMessage: Structures.ERROR_MESSAGE_API_RESPONSE,
  deleteEwIsSuccess: false,
};
var qs = require("qs");
const headers = getHeaders();


export const getUsersData = createAsyncThunk(
  "users/getUsersData",
  async (data, { rejectWithValue }) => {
    try {
      const headers = getHeaders();
      const response = await axios.get(appUsersAPI, {
        params: data,
        headers: headers
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


export const getEwData = createAsyncThunk(
  "users/getEwData",
  async (data, { rejectWithValue }) => {
    try {
      const headers = getHeaders();
      const response = await axios.get(appUsersAPI, {
        params: data,
        headers: headers
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addUser = createAsyncThunk(
  "users/addUser",
  async (data, { rejectWithValue }) => {
    try {
      const headers = getHeaders();
      const response = await axios({
        method: "post",
        url: farmerAPI,
        data: data,
        headers: headers
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const editUser = createAsyncThunk(
  "users/editUser",
  async (data, { rejectWithValue }) => {
    try {
      const headers = getHeaders();
      const response = await axios({
        method: "put",
        url: farmerAPI,
        data: data,
        headers: headers
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const editEw = createAsyncThunk(
  "users/editEw",
  async (data, { rejectWithValue }) => {
    try {
      const headers = getHeaders();
      const response = await axios({
        method: "patch",
        url: extensionWorkerAPI+data.id,
        data: data,
        headers: headers
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const deleteUser = createAsyncThunk(
  "users/deleteUser",
  async (data, { rejectWithValue }) => {
    try {
      const headers = getHeaders();
      const response = await axios({
        method: "delete",
        url: farmerAPI,
        data: data,
        headers: headers
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteEw = createAsyncThunk(
  "users/deleteEw",
  async (data, { rejectWithValue }) => {
    try {
      const headers = getHeaders();
      const response = await axios({
        method: "patch",
        url: extensionWorkerAPI+data.id,
        data: data,
        headers: headers
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getExportedData = createAsyncThunk(
  "app-users/getExportedData",
  async (data, { rejectWithValue }) => {
    try {
      const headers = getHeaders();
      const response = await axios.get(exportAppUsersAPI, {
        params: data,
        headers: headers,
        paramsSerializer: {
          serialize: (params) =>
            qs.stringify(params, { arrayFormat: "repeat" }),
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
)

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {

    resetGetUsers(state){
      Object.assign(
        state.usersData,
        Structures.GET_APP_USERS_DETAIL_API_RESPONSE
      );
      Object.assign(
        state.usersDataErrorMessage,
        Structures.ERROR_MESSAGE_API_RESPONSE
      );
      Object.assign(state.usersDataLoading, false);
      Object.assign(state.usersDataLoading, false);
    },

    resetAddUser(state) {
      Object.assign(
        state.addUserApiResponse,
        Structures.ADD_USER_API_RESPONSE
      );
      Object.assign(
        state.addUserErrorMessage,
        Structures.ERROR_MESSAGE_API_RESPONSE
      );
      Object.assign(state.addUserIsSuccess, false);
      Object.assign(state.addUserIsLoading, false);
    },
    resetEditUser(state) {
      Object.assign(
        state.editUserApiResponse,
        Structures.ADD_USER_API_RESPONSE
      );
      Object.assign(
        state.editUserErrorMessage,
        Structures.ERROR_MESSAGE_API_RESPONSE
      );
      Object.assign(state.editUserIsSuccess, false);
      Object.assign(state.editUserIsLoading, false);
    },
    resetEditEw(state) {
      Object.assign(
        state.editEwApiResponse,
        Structures.ADD_USER_API_RESPONSE
      );
      Object.assign(
        state.editEwErrorMessage,
        Structures.ERROR_MESSAGE_API_RESPONSE
      );
      Object.assign(state.editEwIsSuccess, false);
      Object.assign(state.editEwIsLoading, false);
    },
    resetDeleteUser(state) {
      Object.assign(
        state.deleteUserApiResponse,
        Structures.ADD_USER_API_RESPONSE
      );
      Object.assign(
        state.deleteUserErrorMessage,
        Structures.ERROR_MESSAGE_API_RESPONSE
      );
      Object.assign(state.deleteUserIsSuccess, false);
    },
    resetDeleteEw(state) {
      Object.assign(
        state.deleteEwApiResponse,
        Structures.ADD_USER_API_RESPONSE
      );
      Object.assign(
        state.deleteEwErrorMessage,
        Structures.ERROR_MESSAGE_API_RESPONSE
      );
      Object.assign(state.deleteEwIsSuccess, false);
    },
    
  },

  extraReducers: (builder) => {
    builder
      .addCase(getUsersData.fulfilled, (state, { payload }) => {
        state.usersData = payload;
        state.usersDataLoading = false;
        state.usersDataIsSuccess = true;
      })
      .addCase(getUsersData.pending, (state, { payload }) => {
        state.usersDataLoading = true;
        state.usersDataIsSuccess = false;
      })
      .addCase(getUsersData.rejected, (state, { payload }) => {
        state.usersDataErrorMessage = payload;
        state.usersDataLoading = false;
        state.usersDataIsSuccess = false;
      })
      .addCase(getEwData.fulfilled, (state, { payload }) => {
        state.EwData = payload;
        state.EwDataLoading = false;
        state.EwDataIsSuccess = true;
      })
      .addCase(getEwData.pending, (state, { payload }) => {
        state.EwDataLoading = true;
        state.EwDataIsSuccess = false;
      })
      .addCase(getEwData.rejected, (state, { payload }) => {
        state.EwDataErrorMessage = payload;
        state.EwDataLoading = false;
        state.EwDataIsSuccess = false;
      })
      .addCase(addUser.fulfilled, (state, { payload }) => {
        state.addUserApiResponse = payload;
        state.addUserIsSuccess = true;
        state.addUserIsLoading = false;
      })
      .addCase(addUser.rejected, (state, { payload }) => {
        state.addUserErrorMessage = payload;
        state.addUserIsSuccess = false;
        state.addUserIsLoading = false;
      })
      .addCase(addUser.pending, (state) => {
        state.addUserIsLoading = true;
        state.addUserIsSuccess = false;
      })
      .addCase(editUser.fulfilled, (state, { payload }) => {
        state.editUserApiResponse = payload;
        state.editUserIsSuccess = true;
      })
      .addCase(editUser.rejected, (state, { payload }) => {
        state.editUserErrorMessage = payload;
        state.editUserIsSuccess = false;
        state.editUserIsLoading = false;
      })
      .addCase(editUser.pending, (state) => {
        state.editUserIsLoading = true;
        state.editUserIsSuccess = false;
      })
      .addCase(editEw.fulfilled, (state, { payload }) => {
        state.editEwApiResponse = payload;
        state.editEwIsSuccess = true;
      })
      .addCase(editEw.rejected, (state, { payload }) => {
        state.editEwErrorMessage = payload;
        state.editEwIsSuccess = false;
        state.editEwIsLoading = false;
      })
      .addCase(editEw.pending, (state) => {
        state.editEwIsLoading = true;
        state.editEwIsSuccess = false;
      })
      .addCase(deleteUser.fulfilled, (state, { payload }) => {
        state.deleteUserApiResponse = payload;
        state.deleteUserIsSuccess = true;
      })
      .addCase(deleteUser.rejected, (state, { payload }) => {
        state.deleteUserErrorMessage = payload;
        state.deleteUserIsSuccess = false;
      })
      .addCase(deleteEw.fulfilled, (state, { payload }) => {
        state.deleteEwApiResponse = payload;
        state.deleteEwIsSuccess = true;
      })
      .addCase(deleteEw.rejected, (state, { payload }) => {
        state.deleteEwErrorMessage = payload;
        state.deleteEwIsSuccess = false;
      })
      .addCase(getExportedData.fulfilled, (state, { payload }) => {
        state.exportedData = payload;
      })
  },
});
export const {
  resetAddUser,
  resetEditUser,
  resetDeleteUser,
  resetEditEw,
  resetDeleteEw,
  resetGetUsers
} = usersSlice.actions;
export default usersSlice.reducer;
