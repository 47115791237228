import React, { useState, useEffect, useRef } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { Grid, Box, Typography, ThemeProvider, Button } from "@mui/material";
import { basicTheme } from "../../themes/customTheme";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CollectionsIcon from "@mui/icons-material/Collections";
import ReviewCompleteImageDialog from "./ReviewCompleteImageDialog";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { getallCompletedImagesData } from "../../features/reviewImages/reviewImagesSlice";
import SnackBars from "../reusableComponents/SnackBar";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import TablePagination from "@mui/material/TablePagination";

const MainGrid = styled(Grid)({
  direction: "row",
  justifyContent: "flex-start",
  alignItems: "center",
});

const StyledTitled = styled(Typography)({
  fontWeight: 500,
  fontStretch: "normal",
  fontStyle: "Inter",
  color: "#202124",
  lineHeight: "40px",
  letterSpacing: "normal",
  color: "#5E6060",
});

const StyledSubTitled = styled(Grid)({
  fontWeight: 500,
  fontStretch: "normal",
  fontStyle: "Inter",
  lineHeight: "60px",
  letterSpacing: "normal",
  color: "#1C1C28",
  minWidth: "250px",
  color: "#78828C",
});

const useStyles = makeStyles(() => ({
  imgNumber: {
    display: "flex",
    justifyContent: "flex-end",
    backgroundColor: "grey",
    borderRadius: "16px",
    // width:"65px",
    // height:"32px"
  },
}));

const ButtonStyle = styled(Button)({
  backgroundColor: "white",
  color: "#2D6A4F",
  textColor: "#2D6A4F",
  textTransform: "none",
  borderRadius: "12px",
  fontWeight: "500",
});
export default function CompletedImages({
  setTotalCompleted,
  setTotalPending,
  totalCount = 0,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [reviewImageData, setReviewImageData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openReviewImagesModal, setOpenReviewImagesModal] = useState(false);
  const [completedSnackBarType, setCompletedSnackBarType] = useState("success");
  const [completedSnackBarMessage, setCompletedSnackBarMessage] = useState("");
  const [showCompletedSnackBar, setCompletedShowSnackBar] = useState(false);
  const rowsPerPage = 10;
  const [offset, setOffset] = useState(0);
  const [page, setPage] = React.useState(0);
  useEffect(() => {
    setOffset(page * rowsPerPage);
  }, [page]);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const allCompletedImagesData = useSelector(
    (state) => state.reviewImagesSliceReducer.allCompletedImagesData
  );
  const allCompletedImagesDataErrorMessage = useSelector(
    (state) => state.reviewImagesSliceReducer.allCompletedImagesDataErrorMessage
  );

  const allCompletedImagesDataIsSuccess = useSelector(
    (state) => state.reviewImagesSliceReducer.allCompletedImagesDataIsSuccess
  );

  const allCompletedImagesDataLoading = useSelector(
    (state) => state.reviewImagesSliceReducer.allCompletedImagesDataLoading
  );

  useEffect(() => {
    if (
      !allCompletedImagesDataIsSuccess &&
      (allCompletedImagesDataErrorMessage === undefined ||
        (allCompletedImagesDataErrorMessage.message === undefined &&
          allCompletedImagesDataErrorMessage.message !== ""))
    ) {
      setCompletedShowSnackBar(true);
      setCompletedSnackBarMessage("Something went wrong on server side ");
      setCompletedSnackBarType("error");
    } else if (
      !allCompletedImagesDataLoading &&
      !allCompletedImagesDataIsSuccess &&
      allCompletedImagesDataErrorMessage !== undefined &&
      allCompletedImagesDataErrorMessage.message !== undefined &&
      allCompletedImagesDataErrorMessage.message !== ""
    ) {
      setCompletedShowSnackBar(true);
      setCompletedSnackBarMessage(allCompletedImagesDataErrorMessage.message);
      setCompletedSnackBarType("error");
    }
  }, [allCompletedImagesDataErrorMessage, allCompletedImagesDataLoading]);

  useEffect(() => {
    setLoading(true);
    dispatch(
      getallCompletedImagesData({ offset: offset, limit: rowsPerPage })
    ).then(() => setLoading(false));
  }, [offset]);

  useEffect(() => {
    setTotalPending(allCompletedImagesData.pending_jobs_count);
    setTotalCompleted(allCompletedImagesData.completed_jobs_count);
  }, [allCompletedImagesData]);

  return (
    <MainGrid container style={{ marginLeft: "110px", marginTop: "40px" }}>
      {loading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 1000,
          }}
        >
          <Backdrop open={loading}>
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
      {showCompletedSnackBar && (
        <SnackBars
          open={showCompletedSnackBar}
          handleClose={() => setCompletedShowSnackBar(false)}
          severity={completedSnackBarType}
          message={completedSnackBarMessage}
          autoHideDuration={6000}
          vertical="bottom"
          horizontal="center"
        />
      )}
      {openReviewImagesModal && (
        <ReviewCompleteImageDialog
          openModal={openReviewImagesModal}
          handleClose={setOpenReviewImagesModal}
          data={reviewImageData}
        />
      )}
      {allCompletedImagesData.completed_jobs_count !== -1 &&
        allCompletedImagesData.completed_sessions.map((data, ind) => {
          return (
            <Grid
              key={ind}
              item
              xs={12}
              sx={{
                height: { xl: "300px", lg: "240px", md: "180px", sm: "140px" },
              }}
            >
              <Grid container>
                <Grid
                  item
                  xs={2.25}
                  sx={{
                    marginRight: {
                      xl: "0px",
                      lg: "20px",
                      md: "16px",
                      sm: "6px",
                    },
                  }}
                >
                  <Card
                    sx={{
                      height: {
                        xl: "250px",
                        lg: "200px",
                        md: "160px",
                        sm: "120px",
                      },
                      width: {
                        xl: "220px",
                        lg: "180px",
                        md: "140px",
                        sm: "100px",
                      },
                      borderRadius: "12px",
                    }}
                  >
                    <div style={{ position: "relative" }}>
                      <CardMedia
                        sx={{
                          height: {
                            xl: "250px",
                            lg: "200px",
                            md: "160px",
                            sm: "120px",
                          },
                          width: {
                            xl: "220px",
                            lg: "180px",
                            md: "140px",
                            sm: "100px",
                          },
                          objectFit: "cover",
                          borderRadius: "12px",
                        }}
                        image={data.images[0].pre_signed_url}
                        component="img"
                      />
                      <Box
                        className={classes.imgNumber}
                        style={{
                          position: "absolute",
                          color: "white",
                          top: 0,
                          right: 0,
                          left: "82%",
                          transform: "translateX(-50%)",
                        }}
                        sx={{
                          flex: "1",
                          justifyContent: "center",
                          justify: "center",
                          alignItems: "center",
                          display: "flex",
                          marginTop: "4px",
                          width: { xl: "65px", lg: "56px", md: "48px" },
                          height: { xl: "32px", lg: "27px", md: "22px" },
                        }}
                      >
                        <CollectionsIcon
                          sx={{
                            color: "white",
                            width: { xl: "24px", lg: "20px", md: "16px" },
                            height: { xl: "24px", lg: "20px", md: "16px" },
                          }}
                        />
                        <Typography
                          sx={{
                            marginLeft: { xl: "6px", lg: "4px", md: "2px" },
                          }}
                        >
                          {data.images.length}
                        </Typography>
                      </Box>
                    </div>
                  </Card>
                </Grid>
                <Grid item xs={6}>
                  {/* <StyledTitled
                  sx={{
                    fontSize: {
                      xl: "32px",
                      lg: "26px",
                      md: "20px",
                      sm: "16px",
                    },
                    marginTop: {
                      xl: "18px",
                      lg: "12px",
                      md: "8px",
                      sm: "4px",
                    },
                  }}
                >
                  PROGRAM: {data.partner.toUpperCase()}
                </StyledTitled> */}
                  <StyledSubTitled
                    sx={{
                      fontSize: {
                        xl: "24px",
                        lg: "18px",
                        md: "14px",
                        sm: "10px",
                      },
                      // marginTop: {
                      //   xl: "18px",
                      //   lg: "12px",
                      //   md: "8px",
                      //   sm: "4px",
                      // },
                      marginTop: {
                        xl: "60px",
                        lg: "50px",
                        md: "40px",
                        sm: "30px",
                      },
                    }}
                  >
                    {data.images.length} images captured | {data.time_stamp}
                  </StyledSubTitled>
                  <Box
                    sx={{
                      flex: "1",
                      justifyContent: "flex-start",
                      justify: "flex-start",
                      alignItems: "center",
                      display: "flex",
                      marginTop: {
                        xl: "30px",
                        lg: "20px",
                        md: "10px",
                        sm: "8px",
                      },
                    }}
                  >
                    <TaskAltIcon
                      sx={{ color: "#2D6A4F", marginRight: "10px" }}
                    />
                    <Typography
                      sx={{
                        color: "#2D6A4F",
                        fontSize: {
                          xl: "16px",
                          lg: "14px",
                          md: "10px",
                          sm: "8px",
                        },
                        marginRight: "16px",
                      }}
                    >
                      Reviewed on {data.review_time_stamp}
                    </Typography>
                    <ButtonStyle
                      sx={{
                        fontSize: {
                          xl: "16px",
                          lg: "12px",
                          md: "8px",
                          sm: "6px",
                        },
                        height: {
                          xl: "48px",
                          lg: "40px",
                          md: "30px",
                          sm: "32px",
                        },
                        width: {
                          xl: "156px",
                          lg: "130px",
                          md: "110px",
                          sm: "90px",
                        },
                      }}
                      variant="outlined"
                      name="review"
                      onClick={() => {
                        setOpenReviewImagesModal(true);
                        setReviewImageData(data);
                      }}
                      // onClick={() => setOpenReviewImagesModal(true)}
                      // sx={{width:{xl:"200px",lg:"130px",md:"100px",sm:"80px"},}}
                      endIcon={
                        <KeyboardArrowRightIcon
                          size="large"
                          className={classes.iconStyle}
                        />
                      }
                    >
                      View Review
                    </ButtonStyle>
                  </Box>
                </Grid>
              </Grid>
              <Divider sx={{ marginTop: "20px" }} />
            </Grid>
          );
        })}
      <Box style={{ display: "flex", justifyContent: "center", width: "100%" }}>
        <TablePagination
          rowsPerPageOptions={[10]}
          component="div"
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
        />
      </Box>
    </MainGrid>
  );
}
