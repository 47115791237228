import React, { createContext, useState } from "react";

const CommonContext = createContext();

export const ContextProvider = ({ children }) => {
  const [dashboardPopoverOpen, setDashboardPopoverOpen] = useState(null);
  const [dashboardFilterSelected, setDashboardFilterSelected] = useState(false);

  return (
    <CommonContext.Provider
      value={{
        dashboardPopoverOpen,
        setDashboardPopoverOpen,
        dashboardFilterSelected,
        setDashboardFilterSelected,
      }}
    >
      {children}
    </CommonContext.Provider>
  );
};

export default CommonContext;
