import React, { useState, useEffect } from "react";
import Popover from "@mui/material/Popover";
import { Grid, Button, Typography, ThemeProvider } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import {
  getStatesData,
  getDistrictData,
  getTalukaData,
} from "../../features/location/locationSlice";
import {
  resetDistrict,
  resetTaluka,
} from "../../features/location/locationSlice";
import ClearIcon from "@mui/icons-material/Clear";
import { basicTheme } from "../../themes/customTheme";
import AutoCompleteMultiDropdown from "../reusableComponents/AutoCompleteMultiDropDown";

const StyledGrid = styled(Grid)({
  display: "flex",
  width: 800,
  borderRadius: 100,
});

const ClearBtn = styled(Button)({
  backgroundColor: "#FFFFFF",
  color: "#2D6A4F",
  textTransform: "none",
  borderRadius: 10,
  fontSize: "18px",
  fontWeight: "600",
  marginLeft: 20,
  marginBottom: 20,
  marginTop: 30,
  width: "80%",
  height: "60%",
  borderRadius: 10,
  borderColor: "1px solid #2D6A4F",
});
const ApplyBtn = styled(Button)({
  backgroundColor: "#2D6A4F",
  color: "#FFFFFF",
  textTransform: "none",
  borderRadius: 10,
  fontSize: "18px",
  fontWeight: "600",
  marginLeft: 20,
  marginBottom: 20,
  marginTop: 30,
  width: "80%",
  height: "60%",
  borderRadius: 10,
  borderColor: "1px solid #2D6A4F",
});


const useStyles = makeStyles(() => ({
  closeBtnStyle: {
    display: "flex",
    justifyContent: "flex-end",
    zoom: "1.5",
    alignItems: "right",
    "&:hover": {
      color: "#081C15",
    },
    cursor: "pointer",
  },
}));

export default function FilterPopOver({
  handleClose = () => {},
  openFilterPopOver = false,
  setApplyFilter = () => {},
  setClearFilter = () => {},
  selectedStatesOptions = [],
  setSelectedStatesOptions = () => {},
  selectedDistrictOptions = [],
  setSelectedDistrictOptions = () => {},
  selectedTalukaOptions = [],
  setSelectedTalukaOptions = () => {},
}) {
  const dispatch = useDispatch();
  const classes = useStyles();

  const open = Boolean(openFilterPopOver);
  const id = open ? "filter-popover" : undefined;
  const [districtList, setDistrictList] = useState([]);
  const [talukaList, setTalukaList] = useState([]);

  const getStatesResponse = useSelector(
    (state) => state.locationSliceReducer.statesData
  );
  const getDistrictResponse = useSelector(
    (state) => state.locationSliceReducer.districtData
  );

  const getTalukaResponse = useSelector(
    (state) => state.locationSliceReducer.talukaData
  );

  useEffect(() => {
    if(getStatesResponse[0].id==="")
      dispatch(getStatesData());
  }, []);

  useEffect(() => {
    if (selectedStatesOptions.length > 0) {
      var selectedStateIds = [];
      selectedStatesOptions.forEach((stateObj) => {
        selectedStateIds.push(stateObj.id);
      });
      dispatch(
        getDistrictData({
          type: "state",
          location_ids: selectedStateIds,
        })
      );
    } else {
      setSelectedDistrictOptions([]);
      setSelectedTalukaOptions([]);
      dispatch(resetDistrict());
      dispatch(resetTaluka());
    }
  }, [selectedStatesOptions]);

  useEffect(() => {
    if (selectedDistrictOptions.length > 0) {
      var tempDistrictsObj = [...selectedDistrictOptions];
      tempDistrictsObj.forEach((tempDistrictObj) => {
        var districtPresent = getDistrictResponse.find(
          (obj) => obj.id === tempDistrictObj.id
        );
        if (!districtPresent) {
          setSelectedDistrictOptions((districtsObj) =>
            districtsObj.filter(
              (district) => district.id !== tempDistrictObj.id
            )
          );
        }
      });
    }
    setDistrictList(getDistrictResponse);
  }, [getDistrictResponse]);

  useEffect(() => {
    if (selectedDistrictOptions.length > 0) {
      var selectedDistrictIds = [];
      selectedDistrictOptions.forEach((stateObj) => {
        selectedDistrictIds.push(stateObj.id);
      });
      dispatch(
        getTalukaData({
          type: "district",
          location_ids: selectedDistrictIds,
        })
      );
    } else {
      setSelectedTalukaOptions([]);
      dispatch(resetTaluka());
    }
  }, [selectedDistrictOptions]);

  useEffect(() => {
    if (selectedTalukaOptions.length > 0) {
      var tempTalukasObj = [...selectedTalukaOptions];
      tempTalukasObj.forEach((tempTalukaObj) => {
        var talukaPresent = getTalukaResponse.find(
          (obj) => obj.id === tempTalukaObj.id
        );
        if (!talukaPresent) {
          setSelectedTalukaOptions((talukaObj) =>
            talukaObj.filter((taluka) => taluka.id !== tempTalukaObj.id)
          );
        }
      });
    }
    setTalukaList(getTalukaResponse);
  }, [getTalukaResponse]);

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={openFilterPopOver}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      sx={{
        "& .MuiPopover-paper": {
          padding: 2.5,
          borderRadius: "20px",
        },
      }}
      onClose={handleClose}
    >
      <ThemeProvider theme={basicTheme}>
        <StyledGrid container>
          <Grid item xs={11}>
          <Typography sx={{fontSixe : "20px",color:"#999DA4"}}>
              Filter By
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <ClearIcon
              className={classes.closeBtnStyle}
              onClick={handleClose}
            />
          </Grid>
          <Grid item xs={12} md={4}>
          <Typography sx={{fontSixe : "18px",color:"#444444",margin: 1}}>
              State
            </Typography>
            <AutoCompleteMultiDropdown
              menuList={getStatesResponse}
              selectedOptions={selectedStatesOptions}
              setSelectedOptions={setSelectedStatesOptions}
            />
          </Grid>
          <Grid item xs={12} md={4}>
          <Typography sx={{fontSixe : "18px",color:"#444444",margin: 1}}>
              District
            </Typography>
            <AutoCompleteMultiDropdown
              menuList={districtList}
              selectedOptions={selectedDistrictOptions}
              setSelectedOptions={setSelectedDistrictOptions}
            />
          </Grid>
          <Grid item xs={12} md={4}>
          <Typography sx={{fontSixe : "18px",color:"#444444",margin: 1}}>
              Taluka
            </Typography>
            <AutoCompleteMultiDropdown
              menuList={talukaList}
              selectedOptions={selectedTalukaOptions}
              setSelectedOptions={setSelectedTalukaOptions}
            />
          </Grid>
          <Grid item xs={3}>
            <ClearBtn
              variant="contained"
              name="Clear Filter"
              onClick={() => setClearFilter(true)}
            >
              Clear All
            </ClearBtn>
          </Grid>

          <Grid item xs={3}>
            <ApplyBtn
              variant="contained"
              name="Apply Filter"
              onClick={() => setApplyFilter(true)}
            >
              Apply
            </ApplyBtn>
          </Grid>
        </StyledGrid>
      </ThemeProvider>
    </Popover>
  );
}
