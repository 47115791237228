import { createSlice } from '@reduxjs/toolkit'
import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import * as Structures from "../../apiResonseStructres/rolesAndPermissionModule";

import { rolesAndPermissionsAPI, exportRolesAndPermissionsDataAPI } from "../../api's/index";
const initialState = {
    rolesAndPermissionsData:Structures.GET_ROLES_AND_PERMISSIONS_API_RESPONSE,
    rolesAndPermissionsDataLoading:false,
    rolesAndPermissionsDataIsSuccess:false,
    editRolesAndPermissionsData:Structures.GET_EDIT_ROLES_AND_PERMISSIONS_API_RESPONSE,
    editRolesAndPermissionsDataLoading:false,
    editRolesAndPermissionsDataIsSuccess:false,
    exportedData:[]
    // rolesAndPermissionsDataErrorMessage:Structures.ERROR_MESSAGE_API_RESPONSE,

    // editLocationApiResponse:Structures.ADD_LOCATION_API_RESPONSE,
    // deleteLocationApiResponse:Structures.ADD_LOCATION_API_RESPONSE,
    // exportedData:[],
    
    
  }
  var qs = require('qs');

  export const getRolesAndPermissionsData = createAsyncThunk(
    "permissions/getRolesAndPermissionsData",
    async (data, { rejectWithValue }) => {

      try {
        const response = await axios.get(rolesAndPermissionsAPI,{
          params: data})
        return response.data;
      }
      catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  );

  export const editRolesAndPermissionsData = createAsyncThunk(
    "permissions/editRolesAndPermissionsData",
    async (data, { rejectWithValue }) => {
      try {
        const response = await axios.put(rolesAndPermissionsAPI, data)
        return response.data;
      }
      catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  );

  export const exportRolesAndPermissionsData = createAsyncThunk(
    "permissions/exportRolesAndPermissionsData",
    async (data, { rejectWithValue }) => {
      try {
        const response = await axios.get(exportRolesAndPermissionsDataAPI,{
          params: data,
        })
        return response.data;
      }
      catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  );




  export const rolesAndPermissionSlice = createSlice({
    name: 'getRolesAndPermissionsData',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
      builder
      .addCase(getRolesAndPermissionsData.fulfilled, (state, {payload}) => {
        state.rolesAndPermissionsData = payload;
        state.rolesAndPermissionsDataLoading = false;
        state.rolesAndPermissionsDataIsSuccess = true;
      })
      .addCase(getRolesAndPermissionsData.pending, (state, {payload}) => { 
        state.rolesAndPermissionsDataLoading = true;
        state.rolesAndPermissionsDataIsSuccess = false;
      })
      .addCase(getRolesAndPermissionsData.rejected, (state, {payload}) => {
        // state.locationDataErrorMessage = payload;
        state.rolesAndPermissionsDataLoading = false;
        state.rolesAndPermissionsDataIsSuccess = false;
      })
      .addCase(editRolesAndPermissionsData.fulfilled, (state, {payload}) => {
        state.editRolesAndPermissionsData = payload;
        state.editRolesAndPermissionsDataLoading = false;
        state.editRolesAndPermissionsDataIsSuccess = true;
      })
      .addCase(editRolesAndPermissionsData.pending, (state, {payload}) => {
        state.editRolesAndPermissionsDataLoading = true;
      })
      .addCase(editRolesAndPermissionsData.rejected, (state, {payload}) => {
        // state.locationDataErrorMessage = payload;
        state.editRolesAndPermissionsDataLoading = false;
        state.editRolesAndPermissionsDataIsSuccess = false;
      })
      .addCase(exportRolesAndPermissionsData.fulfilled, (state, {payload}) => {
        state.exportedData = payload;
      })
    },
  })
//   export const { resetDistrict,resetTaluka, resetAddlocation  } = locationSlice.actions
  export default rolesAndPermissionSlice.reducer
  