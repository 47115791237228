import { createSlice } from '@reduxjs/toolkit'
import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import * as Structures from "../../apiResonseStructres/advisoryManagementModule";
import {getHeaders} from "../../utils/index"

import { PesticidesAPI, exportPesticidesDataAPI, cropAPI, pestAPI, farmingTypeAPI, seasonAPI, alertAPI, partnerPesticideMappingAPI} from "../../api's/index";
const initialState = {
    pesticidesData: Structures.GET_PESTICIDES_API_RESPONSE,
    pesticidesDataLoading:false,
    pesticidesDataIsSuccess:false,
    pesticidesDataErrorMessage:Structures.ERROR_MESSAGE_API_RESPONSE,

    editPesticideData:Structures.ADD_PESTICIDE_API_RESPONSE,
    editPesticideDataErrorMessage:Structures.ERROR_MESSAGE_API_RESPONSE,
    editPesticideDataIsSuccess:false,

    deletePesticideApiResponse:Structures.ADD_PESTICIDE_API_RESPONSE,
    deletePesticideErrorMessage:Structures.ERROR_MESSAGE_API_RESPONSE,
    deletePesticideIsSuccess:false,

    pesticideTitleList: Structures.GET_PESTICIDE_TITLE_LIST_API_RESPONSE,
    pesticideTitleListLoading:false,
    pesticideTitleListIsSuccess:false,
    pesticideTitleListErrorMessage:Structures.ERROR_MESSAGE_API_RESPONSE,

    cropData: Structures.GET_CROP_API_RESPONSE,
    pestData: Structures.GET_PEST_API_RESPONSE,
    farmingTypeData: Structures.GET_FARMING_TYPE_API_RESPONSE,
    seasonData: Structures.GET_SEASON_API_RESPONSE,
    alertData: Structures.GET_ALERT_API_RESPONSE,

    addPesticideApiResponse:Structures.ADD_PESTICIDE_API_RESPONSE,
    addPesticideErrorMessage:Structures.ERROR_MESSAGE_API_RESPONSE,
    addPesticideIsSuccess:false,

    exportedPesticidesData:[],

    partnerPesticideMappingData:Structures.GET_PARTNER_PESTICIDE_MAPPING_API_RESPONSE,
    partnerPesticideMappingDataLoading:false,
    partnerPesticideMappingDataIsSuccess:false,
    partnerPesticideMappingDataErrorMessage:Structures.ERROR_MESSAGE_API_RESPONSE,

    addPartnerPesticideMappingApiResponse:Structures.ADD_PARTNER_PESTICIDE_MAPPING_API_RESPONSE,
    addPartnerPesticideMappingErrorMessage:Structures.ERROR_MESSAGE_API_RESPONSE,
    addPartnerPesticideMappingIsSuccess:false,

    editPartnerPesticideMappingApiResponse:Structures.ADD_PARTNER_PESTICIDE_MAPPING_API_RESPONSE,
    editPartnerPesticideMappingErrorMessage:Structures.ERROR_MESSAGE_API_RESPONSE,
    editPartnerPesticideMappingIsSuccess:false,

    deletePartnerPesticideMappingApiResponse:Structures.ADD_PARTNER_PESTICIDE_MAPPING_API_RESPONSE,
    deletePartnerPesticideMappingErrorMessage:Structures.ERROR_MESSAGE_API_RESPONSE,
    deletePartnerPesticideMappingIsSuccess:false,

    exportedPartnerPesticidesMappingData:[],
}
var qs = require('qs');
const headers = getHeaders();

// const headers = {
//   "Content-Type": "application/json",
// };

export const getPesticidesData = createAsyncThunk(
    "crops/getPesticidesData",
    async (data, { rejectWithValue }) => {
        const headers = getHeaders();
        try {
            const response = await axios.get(PesticidesAPI, {
                params: data,
                headers: headers
            })
            return response.data;
        }
        catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const getPesticideTitleList = createAsyncThunk(
    "crops/getPesticideTitleList",
    async (data, { rejectWithValue }) => {
        const headers = getHeaders();
        try {
            const response = await axios.get(PesticidesAPI, {
                params: data,
                headers: headers
            })
            return response.data;
        }
        catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const editPesticide = createAsyncThunk(
    "crops/editPesticide",
    async (data, { rejectWithValue }) => {
        const headers = getHeaders();
        try {
            const response = await axios.patch(
                PesticidesAPI + "/" + data.code , data,
                    {headers : headers})
            return response.data;
        }
        catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);


export const deletePesticide = createAsyncThunk(
    "crops/deletePesticide",
    async (data, { rejectWithValue }) => {
        const headers = getHeaders();
        try {
            const response = await axios.delete(
                PesticidesAPI + "/" + data.code ,
                {headers : headers})
            return response.data;
        }
        catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const getPestData = createAsyncThunk(
    "crops/getPestData",
    async (data, { rejectWithValue }) => {
        const headers = getHeaders();
        try {
            const response = await axios.get(pestAPI, {
                params: data,
                headers: headers
            })
            return response.data;
        }
        catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);
export const getCropData = createAsyncThunk(
    "crops/getCropData",
    async (data, { rejectWithValue }) => {
        const headers = getHeaders();
        try {
            const response = await axios.get(cropAPI, {
                params: data,
                headers: headers
            })
            return response.data;
        }
        catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);
export const getSeasonData = createAsyncThunk(
    "crops/getSeasonData",
    async (data, { rejectWithValue }) => {
        const headers = getHeaders();
        try {
            const response = await axios.get(seasonAPI, {
                params: data,
                headers: headers
            })
            return response.data;
        }
        catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const getAlertData = createAsyncThunk(
    "crops/getAlertData",
    async (data, { rejectWithValue }) => {
        const headers = getHeaders();
        try {
            const response = await axios.get(alertAPI, {
                params: data,
                headers: headers
            })
            return response.data;
        }
        catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const getFarmingTypeData = createAsyncThunk(
    "crops/getFarmingTypeData",
    async (data, { rejectWithValue }) => {
        const headers = getHeaders();
        try {
            const response = await axios.get(farmingTypeAPI, {
                params: data,
                headers: headers
            })
            return response.data;
        }
        catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const addPesticide = createAsyncThunk(
    "crops/addPesticide",
    async (data, { rejectWithValue }) => {
        const headers = getHeaders();
        try {
            const response = await axios.post(PesticidesAPI, data, {
                headers: headers})
            return response.data;
        }
        catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const getPartnerPesticideMappingData = createAsyncThunk(
    "crops/getPartnerPesticideMappingData",
    async (data, { rejectWithValue }) => {
        const headers = getHeaders();
        try {
            const response = await axios.get(partnerPesticideMappingAPI, {
                params: data,
                headers: headers
            })
            
            return response.data;
        }
        catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const addPartnerPesticideMapping = createAsyncThunk(
    "crops/addPartnerPesticideMapping",
    async (data, { rejectWithValue }) => {
       const headers = getHeaders();
        try {
            const response = await axios.post(partnerPesticideMappingAPI, data,
                {headers : headers})
            return response.data;
        }
        catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const bulkUploadPartnerPesticideMapping = createAsyncThunk(
    "crops/bulkUploadPartnerPesticideMapping",
    async (data, { rejectWithValue }) => {
        const headers = getHeaders();
        headers['Content-Type'] = 'multipart/form-data';
        try {
            const response = await axios.post(partnerPesticideMappingAPI, data,
                {headers : headers})
            return response.data;
        }
        catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const editPartnerPesticideMapping = createAsyncThunk(
    "crops/editPartnerPesticideMapping",
    async (data, { rejectWithValue }) => {
        const headers = getHeaders();
        try {
            const response = await axios.patch(partnerPesticideMappingAPI + "/" + data.id, data,
                {headers : headers})
            return response.data;
        }
        catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const deletePartnerPesticideMapping = createAsyncThunk(
    "crops/deletePartnerPesticideMapping",
    async (data, { rejectWithValue }) => {
        const headers = getHeaders();
        try {
            const response = await axios.delete(partnerPesticideMappingAPI + "/" + data.id, 
                {headers : headers})
            return response.data;
        }
        catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const exportPesticidesData = createAsyncThunk(
    "crops/exportPesticidesData",
    async (data, { rejectWithValue }) => {
        const headers = getHeaders();
        try {
            const response = await axios.get(exportPesticidesDataAPI, {
                params: data,
                headers: headers
            })
            return response.data;
        }
        catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const exportPartnerPesticideMappingData = createAsyncThunk(
    "crops/exportPartnerPesticideMappingData",
    async (data, { rejectWithValue }) => {
        const headers = getHeaders();
        try {
            const response = await axios.get(partnerPesticideMappingAPI + "/export", {
                params: data,
                headers: headers
            })
            return response.data;
        }
        catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);




export const pesticidesSlice = createSlice({
    name: 'getPesticidesData',
    initialState,
    reducers: {
        resetPesticidesData(state) {
            Object.assign(state.pesticidesData, Structures.GET_PESTICIDES_API_RESPONSE)
            Object.assign(state.pesticidesDataErrorMessage, Structures.ERROR_MESSAGE_API_RESPONSE)
            Object.assign(state.pesticidesDataIsSuccess, false)
        },
        resetAddPesticide(state) {
            Object.assign(state.addPesticideApiResponse, Structures.ADD_PESTICIDE_API_RESPONSE)
            Object.assign(state.addPesticideErrorMessage, Structures.ERROR_MESSAGE_API_RESPONSE)
            Object.assign(state.addPesticideIsSuccess, false)
        },
        resetEditPesticide(state) {
            Object.assign(state.editPesticideData, Structures.ADD_PESTICIDE_API_RESPONSE)
            Object.assign(state.editPesticideDataErrorMessage, Structures.ERROR_MESSAGE_API_RESPONSE)
            Object.assign(state.editPesticideDataIsSuccess, false)
        },
        resetDeletePesticide(state) {
            Object.assign(state.deletePesticideApiResponse, Structures.ADD_PESTICIDE_API_RESPONSE)
            Object.assign(state.deletePesticideErrorMessage, Structures.ERROR_MESSAGE_API_RESPONSE)
            Object.assign(state.deletePesticideIsSuccess, false)
        },
        resetAddPartnerPesticide(state){
            Object.assign(
                state.addPartnerPesticideMappingApiResponse,
                Structures.ADD_PARTNER_PESTICIDE_MAPPING_API_RESPONSE
            )
            Object.assign(
                state.addPartnerPesticideMappingErrorMessage,
                Structures.ERROR_MESSAGE_API_RESPONSE
            )
            Object.assign(state.addPartnerPesticideMappingIsSuccess, false)
        },
        resetEditPartnerPesticide(state){
            Object.assign(
                state.editPartnerPesticideMappingApiResponse,
                Structures.ADD_PARTNER_PESTICIDE_MAPPING_API_RESPONSE
            )
            Object.assign(
                state.editPartnerPesticideMappingErrorMessage,
                Structures.ERROR_MESSAGE_API_RESPONSE
            )
            Object.assign(state.editPartnerPesticideMappingIsSuccess, false)
        },
        resetDeletePartnerPesticide(state){
            Object.assign(
                state.deletePartnerPesticideMappingApiResponse,
                Structures.ADD_PARTNER_PESTICIDE_MAPPING_API_RESPONSE
            )
            Object.assign(
                state.deletePartnerPesticideMappingErrorMessage,
                Structures.ERROR_MESSAGE_API_RESPONSE
            )
            Object.assign(state.deletePartnerPesticideMappingIsSuccess, false)
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getPesticidesData.fulfilled, (pesticide, { payload }) => {
                pesticide.pesticidesData = payload;
                pesticide.pesticidesDataLoading = false;
                pesticide.pesticidesDataIsSuccess = true;
            })
            .addCase(getPesticidesData.rejected, (pesticide, { payload }) => {
                pesticide.pesticidesDataErrorMessage = payload;
                pesticide.pesticidesDataLoading = false;
                pesticide.pesticidesDataIsSuccess = false;
            })
            .addCase(getPesticidesData.pending, (pesticide, {payload}) => {
                pesticide.pesticidesDataLoading = true;
                pesticide.pesticidesDataIsSuccess = false;
            })
            .addCase(getPesticideTitleList.fulfilled, (pesticide, { payload }) => {
                pesticide.pesticideTitleList = payload.data;
                pesticide.pesticideTitleListLoading = false;
                pesticide.pesticideTitleListIsSuccess = true;
            })
            .addCase(getPesticideTitleList.rejected, (pesticide, { payload }) => {
                pesticide.pesticideTitleListErrorMessage = payload;
                pesticide.pesticideTitleListLoading = false;
                pesticide.pesticideTitleListIsSuccess = false;
            })
            .addCase(getPestData.fulfilled, (pest, { payload }) => {
                pest.pestData = payload;
            })
            .addCase(getCropData.fulfilled, (crop, { payload }) => {
                crop.cropData = payload;
            })
            .addCase(getFarmingTypeData.fulfilled, (farmingType, { payload }) => {
                farmingType.farmingTypeData = payload;
            })
            .addCase(getSeasonData.fulfilled, (season, { payload }) => {
                season.seasonData = payload;
            })
            .addCase(getAlertData.fulfilled, (alert, { payload }) => {
                alert.alertData = payload;
            })
            .addCase(addPesticide.fulfilled, (addPesticide, { payload }) => {
                addPesticide.addPesticideApiResponse = payload;
                addPesticide.addPesticideIsSuccess = true;
            })
            .addCase(addPesticide.rejected, (addPesticide, { payload }) => {
                addPesticide.addPesticideErrorMessage = payload;
                addPesticide.addPesticideIsSuccess = false;
            })
            .addCase(editPesticide.fulfilled, (state, { payload }) => {
                state.editPesticideData = payload;
                state.editPesticideDataIsSuccess = true;
            })
            .addCase(editPesticide.rejected, (state, { payload }) => {
                state.editPesticideDataErrorMessage = payload;
                state.editPesticideDataIsSuccess = false;
            })
            .addCase(deletePesticide.fulfilled, (state, { payload }) => {
                state.deletePesticideApiResponse = payload;
                state.deletePesticideIsSuccess = true;
            })
            .addCase(deletePesticide.rejected, (state, { payload }) => {
                state.deletePesticideErrorMessage = payload;
                state.deletePesticideIsSuccess = false;
            })
            .addCase(getPartnerPesticideMappingData.fulfilled, (partnerPesticideMapping, { payload }) => {
                partnerPesticideMapping.partnerPesticideMappingData = payload;
                partnerPesticideMapping.partnerPesticideMappingDataLoading = false;
                partnerPesticideMapping.partnerPesticideMappingDataIsSuccess = true;
            })
            .addCase(getPartnerPesticideMappingData.rejected, (partnerPesticideMapping, { payload }) => {
                partnerPesticideMapping.partnerPesticideMappingDataErrorMessage = payload;
                partnerPesticideMapping.partnerPesticideMappingDataLoading = false;
                partnerPesticideMapping.partnerPesticideMappingDataIsSuccess = false;
            })
            .addCase(getPartnerPesticideMappingData.pending, (partnerPesticideMapping, {payload}) => {
                partnerPesticideMapping.partnerPesticideMappingDataLoading = true;
                partnerPesticideMapping.partnerPesticideMappingDataIsSuccess = false;
            })
            .addCase(addPartnerPesticideMapping.fulfilled, (addPartnerPesticideMapping, { payload }) => {
                addPartnerPesticideMapping.addPartnerPesticideMappingApiResponse = payload;
                addPartnerPesticideMapping.addPartnerPesticideMappingIsSuccess = true;
            })
            .addCase(addPartnerPesticideMapping.rejected, (addPartnerPesticideMapping, { payload }) => {
                addPartnerPesticideMapping.addPartnerPesticideMappingErrorMessage = payload;
                addPartnerPesticideMapping.addPartnerPesticideMappingIsSuccess = false;
            })
            .addCase(editPartnerPesticideMapping.fulfilled, (editPartnerPesticideMapping, { payload }) => {
                editPartnerPesticideMapping.editPartnerPesticideMappingApiResponse = payload;
                editPartnerPesticideMapping.editPartnerPesticideMappingIsSuccess = true;
            })
            .addCase(editPartnerPesticideMapping.rejected, (editPartnerPesticideMapping, { payload }) => {
                editPartnerPesticideMapping.editPartnerPesticideMappingErrorMessage = payload;
                editPartnerPesticideMapping.editPartnerPesticideMappingIsSuccess = false;
            })
            .addCase(deletePartnerPesticideMapping.fulfilled, (deletePartnerPesticideMapping, { payload }) => {
                deletePartnerPesticideMapping.deletePartnerPesticideMappingApiResponse = payload;
                deletePartnerPesticideMapping.deletePartnerPesticideMappingIsSuccess = true;
            })
            .addCase(deletePartnerPesticideMapping.rejected, (deletePartnerPesticideMapping, { payload }) => {
                deletePartnerPesticideMapping.deletePartnerPesticideMappingErrorMessage = payload;
                deletePartnerPesticideMapping.deletePartnerPesticideMappingIsSuccess = false;
            })
            .addCase(exportPesticidesData.fulfilled, (state, { payload }) => {
                state.exportedPesticidesData = payload;
            })
            .addCase(exportPartnerPesticideMappingData.fulfilled, (state, { payload }) => {
                state.exportedPartnerPesticidesMappingData = payload;
            })
    },
})
export const { 
    resetPesticidesData,
    resetAddPesticide,
    resetEditPesticide,
    resetDeletePesticide,
    resetAddPartnerPesticide,
    resetEditPartnerPesticide, 
    resetDeletePartnerPesticide } = pesticidesSlice.actions
export default pesticidesSlice.reducer
