import React from "react";
import { TextField, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import Autocomplete from "@mui/material/Autocomplete";
import { createFilterOptions } from '@mui/material/Autocomplete';

const CustomAutocomplete = styled(Autocomplete)({
  "& .MuiInputBase-root.Mui-disabled": {
    backgroundColor: "#f0f0f0",
  },
});

export default function AutoCompleteDropdown({
  menuList = [],
  selectedOptions = null,
  setSelectedOptions = () => {},
  disable = false,
  nameNumber = false,
  label = "",
  onHighlightChange	= ()=>{},
  index = 0,
  type = "",
  required = false,
  onOpen = ()=>{}
}) {

  const MenuProps = {
      style: {
        display:(menuList.length>0 && menuList!==undefined && menuList!==null) && (( menuList[0].name!==undefined && menuList[0].name==="") ||( menuList[0].id!==undefined && menuList[0].id==="")) && "none"
      },
  };

  const handleChange = (event, value) => {
    setSelectedOptions(value);
    onHighlightChange(value,index,type);
  };

  return (
    <CustomAutocomplete
      onChange={(e, value) => {
        handleChange(e, value);
      }}
      onOpen = {onOpen}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      filterOptions={createFilterOptions({ trim: true })}
      value={selectedOptions}
      options={menuList}
      disabled={disable }
      ListboxProps = {MenuProps}
      // disabled={disable || (menuList.length > 0 && menuList[0].id === "")}
      getOptionLabel={(option) => (option.name ? option.name : "")}
      renderInput={(params) => <TextField {...params} label={label} required = {required}/>}
      renderOption={(props, option) =>
        nameNumber === true ? (
          <Box component="li" {...props} key={option.phone_number}>
            {option.name}-{option.phone_number}
          </Box>
        ) : (
          <li {...props} key={option.id}>{option.name}</li>
        )
      }
      sx={{
        ".MuiOutlinedInput-root": {
          marginBottom: 1,
          borderRadius: 2,
          backgroundColor: "#F5F5F5",
        },
        width: "90%",
      }}
    ></CustomAutocomplete>
  );
}
