import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import { useDispatch, useSelector } from "react-redux";
const Centerstyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}

const closeIconStyle = {
    position: 'absolute',
    right: 1,
    cursor: 'pointer',
    color: (theme) => theme.palette.grey[600],
}
export default function RolesConfirmationBox({
    handleClose = () => {},
    open,
    setSaveAlert,
    setDiscardAlert,
    setSnackBarMessage = () => {}
}) {
    const dispatch = useDispatch();
    const handleClick = () => {
        setSaveAlert(true);
        handleClose();
        // setSnackBarMessage("Changes saved successfully");  
    }
    const handleDiscard = () => {
        setDiscardAlert(true);
        handleClose();
        // setSnackBarMessage("Changes discarded successfully");
    }

    return (
        <Dialog
            open={open}                               
            disableEscapeKeyDown={false}
        >
            <HighlightOffRoundedIcon sx={closeIconStyle} onClick={handleDiscard} />
            <DialogTitle sx={{ marginTop: 2 }}>There are unsaved change.
                If you leave this. you’ll lose your changes.</DialogTitle>
            <DialogActions sx={Centerstyle}>
                <Button color="primary" variant="contained" onClick={handleDiscard} sx={{ marginRight: 5 }}>Discard Changes</Button>
                <Button color="secondary" variant="contained" sx={{ background: "#EB5451", marginLeft: 5 }} onClick={handleClick} >
                    Save Changes
                </Button>
            </DialogActions>
        </Dialog>
    );
}