import React, { useState, useEffect, useRef } from "react";
import { styled } from "@mui/material/styles";
import { Grid, Box, Button, ThemeProvider } from "@mui/material";
import { basicTheme } from "../../themes/customTheme";
import GetAppRoundedIcon from "@mui/icons-material/GetAppRounded";
import DesktopTable from "../../components/reusableComponents/DesktopTable";
import { useDispatch, useSelector } from "react-redux";
import { CSVLink } from "react-csv";
import { getRolesAndPermissionsData, editRolesAndPermissionsData, exportRolesAndPermissionsData } from "./rolesAndPermissionsSlice";
import RolesConfirmationBox from '../../components/rolesAndPermissions/rolesConfirmationBox';
import LinearProgress from "@mui/material/LinearProgress";


const MainGrid = styled(Grid)({
  flex: "1",
  backgroundColor: "white",
  position: 'relative',
  minWidth: '200px',
  marginTop: "80px",
});

const ExportButtonStyle = styled(Button)({
  backgroundColor: "white",
  color: "#2D6A4F",
  textTransform: "none",
  borderRadius: "12px",
  fontSize: "16px",
  fontWeight: "600",
  minWidth: "120px",
  maxWidth: "150px",
});

const initialHeadCells = [
  {
    key: "modules",
    label: "",
    type: "text",
    sort:false,
    sticky:true
  },
  {
    key: "partner_head",
    label: "Partner Head",
    type: "multiSelect",
    sort:false
  },
  {
    key: "state_head",
    label: "State Head",
    type: "multiSelect",
    sort:false
  },
  {
    key: "district_head",
    label: "District Head",
    type: "multiSelect",
    sort:false
  },
  {
    key: "taluka_head",
    label: "Taluka Head",
    type: "multiSelect",
    sort:false
  },
  {
    key: "government",
    label: "Government",
    type: "multiSelect",
    sort:false
  }
];

const getModifyData = (data) => {
  let modifyData = [];
  for (const [key, value] of Object.entries(data)) {
    let obj = {
      modules: key,
      partner_head: value["Partner Head"] || [],
      state_head: value["State Head"] || [],
      district_head: value["District Head"] || [],
      taluka_head: value["Taluka Head"] || [],
      government: value["Government"] || [],
    };
    modifyData.push(obj);
  }
  return modifyData;
};

export default function RolesAndPermissions({ }) {
  const dispatch = useDispatch();
  const csvLink = useRef();
  const [openAddModal, setopenAddModal] = React.useState(false);
  const [openRolesConfirmationBox, setOpenRolesConfirmationBox] = useState(false);
  const [permissionViaAlert, setPermissionViaAlert] = useState({});
  const [saveAlert, setSaveAlert] = useState(false);
  const [discardAlert, setDiscardAlert] = useState(false);
  const [exportData, setExportData] = useState("");
  const [exportedFileName, setExportedFileName] = useState("");
  const [reload, setReload] = useState(false);

  const handleEditPermission = (data) => {
    dispatch(
      editRolesAndPermissionsData(data)
    ).then(() => {
      setReload(!reload);
    }
    );
  };

  const drawerWidth = useSelector(
    (state) => state.appBarSliceReducer.drawerWidth
  )
  const getRolesAndPermissionsDataResponse = useSelector(
    (state) => state.rolesAndPermissionSliceReducer.rolesAndPermissionsData
  );
  const getRolesAndPermissionsDataLoading = useSelector(
    (state) => state.rolesAndPermissionSliceReducer.rolesAndPermissionsDataLoading
  );
  const getRolesAndPermissionsDataIsSuccess = useSelector(
    (state) => state.rolesAndPermissionSliceReducer.rolesAndPermissionsDataIsSuccess
  );
  const exportRolesAndPermissionsDataResponse = useSelector(
    (state) => state.rolesAndPermissionSliceReducer.exportedData
  );

  useEffect(() => {
    if (exportData) {
      dispatch(
        exportRolesAndPermissionsData()
      ).then(() => {
        setExportData(false);
        setExportedFileName(
          "permission_data" + ".csv"
        );
        csvLink.current.link.click();
      })
    }
  }, [exportData]);

  useEffect(() => {
    if (saveAlert) {
      dispatch(
        editRolesAndPermissionsData(permissionViaAlert)
      );
      setSaveAlert(false);
    }
  }, [saveAlert]);

  useEffect(() => {
    dispatch(
      getRolesAndPermissionsData()
    );
  }, [dispatch, reload, saveAlert]);
  return (
    <MainGrid container id="main" sx={{ marginLeft: drawerWidth + "px", width: "auto" }} spacing={3}>
      <ThemeProvider theme={basicTheme}>
      <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'end', marginLeft: 10, marginRight: 5, marginBottom: 2 }} >
        {/* <ExportButtonStyle
          variant="contained"
          name="Download"
          startIcon={
            <GetAppRoundedIcon sx={{ cursor: 'pointer' }} />
          }
          onClick={() => setExportData(true)}
        >
          Export
        </ExportButtonStyle> */}
      </Grid>
      <Grid item xs={12}>
        {getRolesAndPermissionsDataLoading && !getRolesAndPermissionsDataIsSuccess ? (
          <Box>
            <LinearProgress color="success" />
          </Box>
        ) : (
          !getRolesAndPermissionsDataLoading && getRolesAndPermissionsDataIsSuccess && (
            <DesktopTable
              headCells={initialHeadCells}
              tableResponse={getModifyData(getRolesAndPermissionsDataResponse)}
              showPagination={false}
              totalCount={100}
              handleEditPermission={handleEditPermission}
              setOpenRolesConfirmationBox={setOpenRolesConfirmationBox}
              setPermissionViaAlert={setPermissionViaAlert}
              discardAlert={discardAlert}
              setDiscardAlert={setDiscardAlert}
            />
          )
        )}
        <CSVLink
          data={exportRolesAndPermissionsDataResponse}
          filename={exportedFileName}
          className="hidden"
          ref={csvLink}
          target="_blank"
        />
      </Grid>
      </ThemeProvider>
      {
        openRolesConfirmationBox && <RolesConfirmationBox
          handleClose={() => setOpenRolesConfirmationBox(false)}
          open={openRolesConfirmationBox}
          setSaveAlert={setSaveAlert}
          setDiscardAlert={setDiscardAlert}
        />
      }
      
    </MainGrid>
  );
}
