import React, { useState, useEffect, useRef } from "react";
import { styled } from "@mui/material/styles";
import { useParams } from 'react-router-dom';
import { Grid, Box, ThemeProvider } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import StackTable from "../../components/reusableComponents/StackTable";
import { basicTheme } from "../../themes/customTheme";
import TopFilterBar from "../../components/reusableComponents/TopFilterBar";
import DesktopTable from "../../components/reusableComponents/DesktopTable";
import { useDispatch, useSelector } from "react-redux";
import AddSupervisor from "../../components/supervisorAccess/addSupervisorAccessModal";
import StateWiseLocationModal from "../../components/location/statewiseLocationModal";
import useMediaQuery from "@mui/material/useMediaQuery";
import SupervisorFilterPopOver from "../../components/supervisorAccess/supervisorFilterPopOver";
import { CSVLink } from "react-csv";
import SnackBars from "../../components/reusableComponents/SnackBar";
import DeleteSupervisor from "../../components/supervisorAccess/deleteSupervisorModal";
import EditSupervisor from "../../components/supervisorAccess/editSupervisorModal";
import {
  getSupervisorData,
  getExportedData,
} from "../../features/supervisorAccess/supervisorAccessSlice";
import { updateArray, searchType } from "../../utils";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const MainGrid = styled(Grid)({
  flex: "1",
  backgroundColor: "white",
  // [theme.breakpoints.down("sm")]: {
  //   marginTop: "60px",
  // },
  marginTop: "80px",
});

const initialHeadCells = [
  {
    key: "action",
    label: "Actions",
    type: "editDelete",
    sort: false,
    sticky: true,
  },
  {
    key: "spoc_details",
    label: "Supervisor",
    type: "user",
    sort: false,
  },
  {
    key: "partner",
    label: "Partner",
    capital:true,
    type: "text",
    sort: true,
  },
  {
    key: "role",
    label: "Role",
    type: "text",
    sort: false,
  },
  {
    key: "partner_spoc",
    label: "Partner SPOC",
    type: "text",
    sort: true,
  },
  {
    key: "location_data",
    label: "Location",
    type: "array_objects",
    sort: false,
  },
  {
    key: "is_active",
    label: "Status",
    type: "boolean",
    sort: true,
  },
  {
    key: "created_at",
    label: "Date Added",
    type: "text",
    sort: true,
  },
];

const initialGovtHeadCells = [
  {
    key: "action",
    label: "Actions",
    type: "editDelete",
    sort: false,
    sticky: true,
  },
  {
    key: "spoc_details",
    label: "Supervisor",
    type: "user",
    sort: false,
  },
  {
    key: "role",
    label: "Role",
    type: "text",
    sort: false,
  },
  {
    key: "is_active",
    label: "Status",
    type: "boolean",
    sort: true,
  },
  {
    key: "created_at",
    label: "Date Added",
    type: "text",
    sort: true,
  },
];

const listButtonMenu = [
  { name: "State Head", id: 1 },
  { name: "District Head", id: 2 },
  { name: "Taluka Head", id: 3 },
  { name: "Government", id: 4 },
];

const typeToRole = {
  "State Head":"state_head",
  "District Head":"district_head",
  "Taluka Head":"taluka_head",
  "Government":"government",
};

export default function SuperVisorAccess() {
  const { type } = useParams();
  const dispatch = useDispatch();
  const csvLink = useRef();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const rowsPerPage = 10;
  const [openAddModal, setopenAddModal] = React.useState(false);
  const [offset, setOffset] = useState(0);
  const [page, setPage] = React.useState(0);
  const [sortingType, setSortingType] = useState("desc");
  const [sortBy, setSortBy] = useState("created_at");
  const [applyFilter, setApplyFilter] = useState(false);
  const [searchBarData, setSearchBarData] = useState("");
  const [searchBarText, setSeachBarText] = useState("");
  const [exportData, setExportData] = useState("");
  const [exportedFileName, setExportedFileName] = useState("");
  const [supervisorSnackBarType, setSupervisorSnackBarType] = useState(
    "success"
  );
  const [supervisorSnackBarMessage, setSupervisorSnackBarMessage] = useState(
    ""
  );
  const [showSupervisorSnackBar, setSupervisorShowSnackBar] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openEditModal, setopenEditModal] = useState(false);
  const [editData, setEditData] = useState({});
  const [deleteData, setDeleteData] = useState({});
  const [reload, setReload] = useState(false);
  const [filters, setFilters] = useState([
    { type: "role", value: ["State Head"] },
    {type:"partner",value:[]}
  ]);
  const [apiCall, setApiCall] = useState(false);
  const [openLocationSubModal, setOpenLocationSubModal] = React.useState(false);
  const [openFilterPopOver, setOpenFilterPopOver] = React.useState(null);
  const [selectedPartners, setSelectedPartners] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [clearFilter, setClearFilter] = useState(false);
  const [menuId, setMenuId] = useState(1);
  const [role, setRole] = useState("");
  const [selectedLocationOptions, setSelectedLocationOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const loggedInUser = JSON.parse(window.localStorage.getItem("loggedInUser"));

  const drawerWidth = useSelector(
    (state) => state.appBarSliceReducer.drawerWidth
  );

  const supervisorDataResponse = useSelector(
    (state) => state.supervisorSliceReducer.supervisorData.data
  );

  const supervisorDataErrorMessage = useSelector(
    (state) => state.supervisorSliceReducer.supervisorDataErrorMessage
  );

  const supervisorDataIsSuccess = useSelector(
    (state) => state.supervisorSliceReducer.supervisorDataIsSuccess
  );

  const supervisorDataLoading = useSelector(
    (state) => state.supervisorSliceReducer.supervisorDataLoading
  );

  const totalSupervisorData = useSelector(
    (state) => state.supervisorSliceReducer.supervisorData.count
  );

  const exportedData = useSelector(
    (state) => state.supervisorSliceReducer.exportedData
  );

  useEffect(()=>{
    var currentRole = Object.keys(typeToRole).find(
      (key) => typeToRole[key] === type
    );
    setRole(currentRole);
  },[type])

  useEffect(() => {
    setPage(0);
    setOffset(0);
  }, [searchBarData]);

  const handleChangeFilters = (type = "") => {
    if (selectedLocationOptions.length > 0) {
      var locationIds = [];
      selectedLocationOptions.forEach((options) =>
        locationIds.push(options.id)
      );
      if (!searchType(type, filters)) {
        setFilters((oldArray) => [
          ...oldArray,
          { type: type, value: locationIds },
        ]);
      } else {
        var newArray = updateArray(filters, type, locationIds);
        setFilters(newArray);
      }
    } else if (
      selectedLocationOptions.length === 0 &&
      searchType(type, filters)
    ) {
      updateArray(filters, type, locationIds, true, setFilters);
    }
  };

  useEffect(() => {
    var roleArray = [];
    roleArray.push(role);
    var newRoleArray = updateArray(filters, "role", roleArray);
    if(loggedInUser.details.partner!==null)
    {
      newRoleArray = updateArray(newRoleArray, "partner", [loggedInUser.details.partner.id]);
    }
    else{
      updateArray(filters, "partner", [], true, setFilters);
    }
    setFilters(newRoleArray);
    // clearing selected options and filter
    setSelectedLocationOptions([]);
    updateArray(filters, "state", [], true, setFilters);
    updateArray(filters, "district", [], true, setFilters);
    updateArray(filters, "taluka", [], true, setFilters);
    // updateArray(filters, "partner", [], true, setFilters);
    setSelectedPartners([]);
    setPage(0);
    setOffset(0);
    setSearchBarData("");
    setSeachBarText("");
  }, [role]);

  useEffect(() => {
    if (clearFilter) {
      setPage(0);
      setOffset(0);
    }
  }, [clearFilter]);

  useEffect(() => {
    if (applyFilter) {
      if (role === "State Head") handleChangeFilters("state");
      else if (role === "District Head") handleChangeFilters("district");
      else if (role === "Taluka Head") handleChangeFilters("taluka");
      handleCloseFilterPopOver();
      setPage(0);
      setOffset(0);
    }
  }, [applyFilter]);

  useEffect(() => {
    setApiCall(true);
  }, [
    searchBarData,
    offset,
    sortingType,
    sortBy,
    role,
    filters
  ]);

  useEffect(() => {
    if (apiCall || reload) {
      setLoading(true);
      dispatch(
        getSupervisorData({
          filters: JSON.stringify(filters),
          search: searchBarData,
          offset: offset,
          limit: rowsPerPage,
          sort_by: sortingType,
          sort_type: sortBy,
        })
      ).then(() => {
        setApiCall(false);
        setReload(false);
        setLoading(false);
      });
    }
  }, [dispatch, apiCall,reload]);

  useEffect(() => {
    if (
      supervisorSnackBarMessage !== "" &&
      supervisorSnackBarMessage !== undefined
    )
      setSupervisorShowSnackBar(true);
  }, [supervisorSnackBarMessage]);

  useEffect(() => {
    if (
      !supervisorDataIsSuccess &&
      (supervisorDataErrorMessage === undefined ||
        supervisorDataErrorMessage.message === undefined)
    ) {
      setSupervisorShowSnackBar(true);
      setSupervisorSnackBarMessage(
        "Something went wrong on Supervisor server side "
      );
      setSupervisorSnackBarType("error");
    } else if (
      !supervisorDataLoading &&
      !supervisorDataIsSuccess &&
      supervisorDataErrorMessage !== undefined &&
      supervisorDataErrorMessage.message !== undefined &&
      supervisorDataErrorMessage.message !== ""
    ) {
      setSupervisorShowSnackBar(true);
      setSupervisorSnackBarMessage(supervisorDataErrorMessage.message);
      setSupervisorSnackBarType("error");
    }
  }, [supervisorDataErrorMessage, supervisorDataLoading]);

  useEffect(() => {
    if (exportData){
      setLoading(true)
      dispatch(
        getExportedData({
          sort_by: sortingType,
          sort_type: sortBy,
          search: searchBarData,
          filters: JSON.stringify(filters),
          offset: offset,
          limit: rowsPerPage,
        })
      ).then((res) => {
        setLoading(false)
        setExportData(false);
        if(res.error!==undefined){
          setSupervisorSnackBarMessage("Something went wrong!")
          setSupervisorSnackBarType("error")
          setSupervisorShowSnackBar(true)
        }
        else{
          setExportedFileName("supervisor_data" + ".csv");
          csvLink.current.link.click();
        }
      });
    }
  }, [exportData]);

  const handleShowFilterPopOver = (event) => {
    setOpenFilterPopOver(event.currentTarget);
  };

  const handleCloseFilterPopOver = () => {
    setOpenFilterPopOver(null);
    setApplyFilter(false);
    setClearFilter(false);
  };

  return (
    <MainGrid
      container
      id="main"
      sx={{ marginLeft: drawerWidth + "px", width: "auto" }}
      spacing={3}
    >
      <ThemeProvider theme={basicTheme}>
        <Grid item xs={12}>
          <TopFilterBar
            addtext="Add Supervisor"
            setAddOpen={setopenAddModal}
            searchBarData={searchBarData}
            setSearchBarData={setSearchBarData}
            searchBarText={searchBarText}
            setSeachBarText={setSeachBarText}
            handleShowFilterPopOver={handleShowFilterPopOver}
            setExportData={setExportData}
            searchBy="Search By Supervisor Name"
            loading={loading}
            showFilter={role==="Government" ? false : true}
            filterSelected = {selectedLocationOptions.length!==0 || selectedPartners.length!==0 || selectedStatus!==null}
          />
          {openFilterPopOver && role !== "Government" && (
            <SupervisorFilterPopOver
              handleClose={handleCloseFilterPopOver}
              openFilterPopOver={openFilterPopOver}
              setApplyFilter={setApplyFilter}
              filters={filters}
              setFilters={setFilters}
              selectedPartners={selectedPartners}
              setSelectedPartners={setSelectedPartners}
              selectedStatus={selectedStatus}
              setSelectedStatus={setSelectedStatus}
              clearFilter={clearFilter}
              setClearFilter={setClearFilter}
              role={role}
              selectedLocationOptions={selectedLocationOptions}
              setSelectedLocationOptions={setSelectedLocationOptions}
            />
          )}
        </Grid>
        {openDeleteModal && (
          <DeleteSupervisor
            handleClose={() => setOpenDeleteModal(false)}
            open={openDeleteModal}
            deleteData={deleteData}
            setSnackBarMessage={setSupervisorSnackBarMessage}
            setReload={setReload}
            setSnackBarType={setSupervisorSnackBarType}
          />
        )}
        {openEditModal && (
          <EditSupervisor
            openModal={openEditModal}
            handleClose={() => setopenEditModal(false)}
            data={editData}
            setEditData={setEditData}
            setSupervisorSnackBarMessage={setSupervisorSnackBarMessage}
            setReload={setReload}
          />
        )}
        <CSVLink
          data={exportedData}
          filename={exportedFileName}
          className="hidden"
          ref={csvLink}
          target="_blank"
          separator={";"}
        />
        {openLocationSubModal && (
          <StateWiseLocationModal
            openModal={openLocationSubModal}
            handleClose={() => setOpenLocationSubModal(false)}
            data={editData.location}
          />
        )}
        <Grid item xs={12}>
          {supervisorDataLoading && !supervisorDataIsSuccess && loading && (
            <Box>
              <LinearProgress color="success" />
            </Box>
          )}
          {isSmallScreen && supervisorDataIsSuccess ? (
            <StackTable
              key={supervisorDataResponse}
              headCells={
                role === "Government" ? initialGovtHeadCells : initialHeadCells
              }
              tableResponse={supervisorDataResponse}
              totalCount={totalSupervisorData}
              setOffset={setOffset}
              rowsPerPage={rowsPerPage}
              setSortingType={setSortingType}
              sortingType={sortingType}
              setSortBy={setSortBy}
              sortBy={sortBy}
              setopenEditModal={setopenEditModal}
              setEditData={setEditData}
              setOpenDeleteModal={setOpenDeleteModal}
              setDeleteData={setDeleteData}
              setPage={setPage}
              page={page}
              setOpenLocationSubModal={setOpenLocationSubModal}
              openEditModal={openEditModal}
              openDeleteModal={openDeleteModal}
            />
          ) : (
            !supervisorDataLoading &&
            supervisorDataIsSuccess &&
            !loading && (
              <DesktopTable
                key={supervisorDataResponse}
                headCells={
                  role === "Government"
                    ? initialGovtHeadCells
                    : initialHeadCells
                }
                tableResponse={supervisorDataResponse}
                totalCount={totalSupervisorData}
                setOffset={setOffset}
                rowsPerPage={rowsPerPage}
                setSortingType={setSortingType}
                sortingType={sortingType}
                setSortBy={setSortBy}
                sortBy={sortBy}
                setopenEditModal={setopenEditModal}
                setEditData={setEditData}
                setOpenDeleteModal={setOpenDeleteModal}
                setDeleteData={setDeleteData}
                setPage={setPage}
                page={page}
                setOpenLocationSubModal={setOpenLocationSubModal}
                openEditModal={openEditModal}
                openDeleteModal={openDeleteModal}
              />
            )
          )}
        </Grid>
        {showSupervisorSnackBar && (
          <SnackBars
            open={showSupervisorSnackBar}
            handleClose={() => setSupervisorShowSnackBar(false)}
            severity={supervisorSnackBarType}
            message={supervisorSnackBarMessage}
            autoHideDuration={6000}
            vertical="bottom"
            horizontal="center"
          />
        )}
        {openAddModal && (
          <AddSupervisor
            openAddModal={openAddModal}
            handleClose={() => setopenAddModal(false)}
            setSupervisorSnackBarMessage={setSupervisorSnackBarMessage}
            setReload={setReload}
          />
        )}
        {loading && (
          <Backdrop
            sx={{
              color: "#fff",
              position:"absolute",
              zIndex: (theme) => theme.zIndex.drawer +1,
            }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
      </ThemeProvider>
    </MainGrid>
  );
}
