export const GET_LOGIN_DETAIL_API_RESPONSE = {
  token: "",
  username: "",
  name: "",
  email: "",
  last_login: "",
  is_superuser: false,
  details: {
    message: "",
  },
};

export const FORGOT_PASSWORD_API_RESPONSE = {
  message: "",
};

export const ERROR_MESSAGE_API_RESPONSE = {
  message: "",
};

export const LOGIN_ERROR_RESPONSE = {
  non_field_errors: [""],
};
