import React, { useState, useEffect, useRef, useContext } from "react";
import { styled } from "@mui/material/styles";
import { Grid, Box, Typography, ThemeProvider } from "@mui/material";
import { basicTheme } from "../../themes/customTheme";
import TopFilterBar from "../../components/reusableComponents/TopFilterBar";
import DesktopTable from "../../components/reusableComponents/DesktopTable";
import { useDispatch, useSelector } from "react-redux";
import { getAlertsData } from "./DashboardSlice";
import LinearProgress from "@mui/material/LinearProgress";
import useMediaQuery from "@mui/material/useMediaQuery";
import StackTable from "../../components/reusableComponents/StackTable";
import PartnerFilterPopOver from "../../components/partner/partnerFilterPopOver";
import SnackBars from "../../components/reusableComponents/SnackBar";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useLocation } from "react-router-dom";
import CommonContext from "../appbar/ContextProvider";
import dayjs from "dayjs";
import DashboardFilterPopOver from "../../components/dashboard/DashboardFilterPopOver";

const MainGrid = styled(Grid)({
  flex: "1",
  backgroundColor: "white",
  // [theme.breakpoints.down("sm")]: {
  //   marginTop: "60px",
  // },
  marginTop: "80px",
});

const initialHeadCells = [
  {
    key: "name",
    label: "User Name",
    type: "text",
    sort: false,
    capital: true,
  },
  {
    key: "partner_name",
    label: "Partner",
    type: "text",
    sort: true,
    capital:true
  },
  {
    key: "state_name",
    label: "State Name",
    type: "text",
    sort: false,
    capital: true,
  },
  {
    key: "village_name",
    label: "Village Name",
    type: "text",
    sort: false,
    capital: true,
  },
  {
    key: "result_generated_by",
    label: "Result Generated By",
    type: "text",
    sort: false,
    capital: true,
  },
  {
    key: "alert_type",
    label: "Alert Type",
    type: "text",
    sort: false,
    capital: true,
  },
  {
    key: "created_at",
    label: "Alert generated on",
    type: "text",
    sort: false,
  },
];

export default function Alerts({}) {
  const location = useLocation();
  const dispatch = useDispatch();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const rowsPerPage = 10;
  const [offset, setOffset] = useState(0);
  const [page, setPage] = React.useState(0);
  const [sortingType, setSortingType] = useState("desc");
  const [sortBy, setSortBy] = useState("created_at");
  const [applyFilter, setApplyFilter] = useState(false);
  const [alertsSnackBarType, setAlertsSnackBarType] = useState("success");
  const [alertsSnackBarMessage, setAlertsSnackBarMessage] = useState("");
  const [showAlertsSnackBar, setAlertsShowSnackBar] = useState(false);
  const [reload, setReload] = useState(false);
  const [partnerIds, setPartnerIds] = useState([]);
  const [locationIds, setLocationIds] = useState([]);
  const [apiCall, setApiCall] = useState(false);
  const [clearFilter, setClearFilter] = useState(false);
  const [selectedStatesOptions, setSelectedStatesOptions] = useState([]);
  const [selectedPartnerOptions, setSelectedPartnerOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [alertsGeneratedBy, setAlertsGeneratedBy] = useState("Total");
  const alertsGeneratedByMenu = ["Total","Phone","Cloud","Expert"];
  const alertsGeneratedByAbbreviations = {
    "Total": "None",
    "Phone": "phone",
    "Cloud": "cloud",
    "Expert": "expert",
  };
  const { dashboardPopoverOpen, setDashboardPopoverOpen } = useContext(
    CommonContext
  );
  const [startDate, setStartDate] = useState(dayjs().subtract(1, "year"));
  const [endDate, setEndDate] = useState(dayjs(new Date()));

  const handleCloseFilterPopOver = () => {
    setDashboardPopoverOpen(null);
    setApplyFilter(false);
    setClearFilter(false);
  };

  const drawerWidth = useSelector(
    (state) => state.appBarSliceReducer.drawerWidth
  );

  const alertsData = useSelector(
    (state) => state.dashboardSliceReducer.alertsData.data
  );
  const alertsDataCount = useSelector(
    (state) => state.dashboardSliceReducer.alertsData.count
  );
  const alertsDataErrorMessage = useSelector(
    (state) => state.dashboardSliceReducer.alertsDataErrorMessage
  );

  const alertsDataIsSuccess = useSelector(
    (state) => state.dashboardSliceReducer.alertsDataIsSuccess
  );

  const alertsDataLoading = useSelector(
    (state) => state.dashboardSliceReducer.alertsDataLoading
  );

  useEffect(() => {
    if (clearFilter) {
      setPage(0);
      setOffset(0);
      setSelectedStatesOptions([]);
      setSelectedPartnerOptions([]);
      setApiCall(true);
    }
  }, [clearFilter]);

  useEffect(() => {
    if (applyFilter) {
      var tempLocationIds = []
      if(selectedStatesOptions.length > 0) {
        selectedStatesOptions.forEach((obj)=>{
          tempLocationIds.push(obj.id)
        })
      }
      setLocationIds(tempLocationIds)
    }
  }, [applyFilter]);


  useEffect(() => {
    if (applyFilter) {
      var tempPartnerIds = []
      if(selectedPartnerOptions.length > 0) {
        selectedPartnerOptions.forEach((obj)=>{
          tempPartnerIds.push(obj.id)
        })
      }
      setPartnerIds(tempPartnerIds)
    }
  }, [applyFilter]);

  useEffect(() => {
    if (applyFilter) {
      handleCloseFilterPopOver();
      setApiCall(true);
      setPage(0);
      setOffset(0);
    }
  }, [applyFilter]);

  useEffect(() => {
    setApiCall(true);
  }, [offset, sortingType, sortBy]);

  useEffect(() => {
    if (apiCall || reload) {
      setLoading(true);
      var modifiedAlertsGeneratedBy = alertsGeneratedByAbbreviations[alertsGeneratedBy];
      dispatch(
        getAlertsData({
          filters: JSON.stringify([{type:"state","value":locationIds},{type:"program_id","value":partnerIds}]),
          result_generated_by: modifiedAlertsGeneratedBy,
          offset: offset,
          limit: rowsPerPage,
          from_date: dayjs(startDate).format("DD/MM/YYYY"),
          to_date: dayjs(endDate).format("DD/MM/YYYY"),
        })
      ).then(() => {
        setLoading(false);
        setApiCall(false);
        setReload(false);
      });
    }
  }, [dispatch, apiCall, reload]);

  useEffect(() => {
    if (alertsSnackBarMessage !== "" && alertsSnackBarMessage !== undefined)
      setAlertsShowSnackBar(true);
  }, [alertsSnackBarMessage]);

  useEffect(() => {
    if (
      !alertsDataIsSuccess &&
      (alertsDataErrorMessage === undefined ||
        (alertsDataErrorMessage.message === undefined &&
          alertsDataErrorMessage.message !== ""))
    ) {
      setAlertsShowSnackBar(true);
      setAlertsSnackBarMessage(
        "Something went wrong on Alerts Dashboard server side "
      );
      setAlertsSnackBarType("error");
    } else if (
      !alertsDataLoading &&
      !alertsDataIsSuccess &&
      alertsDataErrorMessage !== undefined &&
      alertsDataErrorMessage.message !== undefined &&
      alertsDataErrorMessage.message !== ""
    ) {
      setAlertsShowSnackBar(true);
      setAlertsSnackBarMessage(alertsDataErrorMessage.message);
      setAlertsSnackBarType("error");
    }
  }, [alertsDataErrorMessage, alertsDataLoading]);

  return (
    <MainGrid
      container
      id="main"
      sx={{ marginLeft: drawerWidth + "px", width: "auto" }}
      spacing={3}
    >
      <ThemeProvider theme={basicTheme}>
        <Grid item xs={12}>
          {dashboardPopoverOpen && (
          <DashboardFilterPopOver
            handleClose={handleCloseFilterPopOver}
            openFilterPopOver={dashboardPopoverOpen}
            setApplyFilter={setApplyFilter}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            clearFilter={clearFilter}
            setClearFilter={setClearFilter}
            sessionGeneratedBy={alertsGeneratedBy}
            setSessionGeneratedBy={setAlertsGeneratedBy}
            sessionGeneratedByMenu={alertsGeneratedByMenu}
            selectedStatesOptions={selectedStatesOptions}
            setSelectedStatesOptions={setSelectedStatesOptions}
            selectedPartnersOptions = {selectedPartnerOptions}
            setSelectedPartners = {setSelectedPartnerOptions}
            sessionFilterLabel="Alerts Generated By"
          />
        )}
        </Grid>
        <Grid item xs={12}>
          {alertsDataLoading && !alertsDataIsSuccess && loading && (
            <Box>
              <LinearProgress color="success" />
            </Box>
          )}
          {isSmallScreen && alertsDataIsSuccess ? (
            <StackTable
              key={alertsData}
              headCells={initialHeadCells}
              tableResponse={alertsData}
              totalCount={alertsDataCount}
              setOffset={setOffset}
              rowsPerPage={rowsPerPage}
              setSortingType={setSortingType}
              sortingType={sortingType}
              setSortBy={setSortBy}
              sortBy={sortBy}
              setPage={setPage}
              page={page}
            />
          ) : (
            !alertsDataLoading &&
            alertsDataIsSuccess &&
            !loading && (
              <DesktopTable
                key={alertsData}
                headCells={initialHeadCells}
                tableResponse={alertsData}
                totalCount={alertsDataCount}
                setOffset={setOffset}
                rowsPerPage={rowsPerPage}
                setSortingType={setSortingType}
                sortingType={sortingType}
                setSortBy={setSortBy}
                sortBy={sortBy}
                setPage={setPage}
                page={page}
              />
            )
          )}
        </Grid>
        {showAlertsSnackBar && (
          <SnackBars
            open={showAlertsSnackBar}
            handleClose={() => setAlertsShowSnackBar(false)}
            severity={alertsSnackBarType}
            message={alertsSnackBarMessage}
            autoHideDuration={6000}
            vertical="bottom"
            horizontal="center"
          />
        )}
        {loading && (
          <Backdrop
            sx={{
              color: "#fff",
              position: "absolute",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
      </ThemeProvider>
    </MainGrid>
  );
}
