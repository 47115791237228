import React, { useState, useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import AutoCompleteDropdown from "../reusableComponents/AutoCompleteDropDown";
import { useDispatch, useSelector } from "react-redux";
import { getStatesData } from "../../features/location/locationSlice";
import {
  getDistrictList,
  getTalukaList,
  getVillageList,
} from "../../utils/index";
export default function LocationDropDown({
  partnerLocations = [],
  selectedStateOptions = null,
  setSelectedStateOptions = () => {},
  selectedDistrictOptions = null,
  setSelectedDistrictOptions = () => {},
  selectedTalukaOptions = null,
  setSelectedTalukaOptions = () => {},
  selectedVillageOptions = null,
  setSelectedVillageOptions = () => {},
  isEw = false,
  handleChangeLocation = () =>{},
}) {
  const dispatch = useDispatch();
  const [stateList, setStateList] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [talukaList, setTalukaList] = useState([]);
  const [villageList, setVillageList] = useState([]);
  const getStatesResponse = useSelector(
    (state) => state.locationSliceReducer.statesData
  );
  useEffect(() => {
    dispatch(getStatesData());
    if (
      partnerLocations.state_ids !== undefined &&
      partnerLocations.state_ids.length > 0 &&
      getStatesResponse[0].id !== ""
    ) {
      let filtered = getStatesResponse.filter((item) =>
        partnerLocations.state_ids.includes(item.id)
      );
      setStateList(filtered);
      setDistrictList([])
      setTalukaList([]);
      setVillageList([]);
    }
    if (
      partnerLocations.district_ids !== undefined &&
      partnerLocations.district_ids.length > 0 &&
      selectedStateOptions !== null
    ) {
      getFilteredDistricts();
    }
    if (
      partnerLocations.taluka_ids !== undefined &&
      partnerLocations.taluka_ids.length > 0 &&
      selectedDistrictOptions !== null
    ) {
      getFilteredTalukas();
    }
    if (
      partnerLocations.village_ids !== undefined &&
      partnerLocations.village_ids.length > 0 &&
      selectedTalukaOptions !== null
    ) {
      getFilteredVillages();
    }
  }, [partnerLocations]);
  const getFilteredDistricts = () => {
    getDistrictList(selectedStateOptions.id).then((data) => {
      let filtered = [];
      if (
        partnerLocations.district_ids !== undefined &&
        partnerLocations.district_ids.length > 0 &&
        data.length > 0
      ) {
        filtered = data.filter((item) =>
          partnerLocations.district_ids.includes(item.id)
        );
      }
      setDistrictList(filtered);
    });
  };
  const getFilteredTalukas = () => {
    getTalukaList(selectedDistrictOptions.id).then((data) => {
      let filtered = [];
      if (
        partnerLocations.taluka_ids !== undefined &&
        partnerLocations.taluka_ids.length > 0 &&
        data.length > 0
      ) {
        filtered = data.filter((item) =>
          partnerLocations.taluka_ids.includes(item.id)
        );
      }
      setTalukaList(filtered);
    });
  };
  const getFilteredVillages = () => {
    getVillageList(selectedTalukaOptions.id).then((data) => {
      let filtered = [];
      if (
        partnerLocations.village_ids !== undefined &&
        partnerLocations.village_ids.length > 0 &&
        data.length > 0
      ) {
        filtered = data.filter((item) =>
          partnerLocations.village_ids.includes(item.id)
        );
      }
      setVillageList(filtered);
    });
  };
  const onHighlightStateChange = () => {
    setSelectedDistrictOptions(null);
    setSelectedTalukaOptions(null);
    setSelectedVillageOptions(null);
    setDistrictList([]);
    setTalukaList([]);
    setVillageList([]);
    handleChangeLocation()
  };
  const onHighlightDistrictChange = () => {
    setSelectedTalukaOptions(null);
    setSelectedVillageOptions(null);
    setTalukaList([]);
    setVillageList([]);
  };
  const onHighlightTalukaChange = () => {
    setSelectedVillageOptions(null);
    setVillageList([]);
  };
  useEffect(() => {
    if (selectedStateOptions !== null) {
      getFilteredDistricts();
    }
  }, [selectedStateOptions]);
  useEffect(() => {
    if (selectedDistrictOptions !== null) {
      getFilteredTalukas();
    }
  }, [selectedDistrictOptions]);
  useEffect(() => {
    if (selectedTalukaOptions !== null) {
      getFilteredVillages();
    }
  }, [selectedTalukaOptions]);
  return (
    <Grid
      item
      xs={12}
      sx={{
        border: 1,
        borderColor: "#D3D3D3",
        borderRadius: "10px",
        margin: 2,
      }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} sm={3}>
          <Typography
            sx={{
              marginBottom: 1,
              marginLeft: 2,
              color: stateList.length === 0 ? "#ACADAD" : "#444444",
            }}
          >
            State
          </Typography>
          <AutoCompleteDropdown
            width="auto"
            menuList={stateList}
            selectedOptions={selectedStateOptions}
            setSelectedOptions={setSelectedStateOptions}
            // disable={stateList.length === 0}
            label="Select State"
            onHighlightChange={onHighlightStateChange}
            required = {true}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography
            sx={{
              marginBottom: 1,
              marginLeft: 2,
              color: districtList.length === 0 ? "#ACADAD" : "#444444",
            }}
          >
            District
          </Typography>
          <AutoCompleteDropdown
            width="auto"
            menuList={districtList}
            selectedOptions={selectedDistrictOptions}
            setSelectedOptions={setSelectedDistrictOptions}
            disable={districtList.length === 0}
            label="Select District"
            onHighlightChange={onHighlightDistrictChange}
            required = {true}
          />
        </Grid>
        {!isEw && (
          <React.Fragment>
            <Grid item xs={12} sm={3}>
              <Typography
                sx={{
                  marginBottom: 1,
                  marginLeft: 2,
                  color: talukaList.length === 0 ? "#ACADAD" : "#444444",
                }}
              >
                Taluka
              </Typography>
              <AutoCompleteDropdown
                width="auto"
                menuList={talukaList}
                selectedOptions={selectedTalukaOptions}
                setSelectedOptions={setSelectedTalukaOptions}
                disable={talukaList.length === 0}
                label="Select Taluka"
                onHighlightChange={onHighlightTalukaChange}
                required = {true}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography
                sx={{
                  marginBottom: 1,
                  marginLeft: 2,
                  color: villageList.length === 0 ? "#ACADAD" : "#444444",
                }}
              >
                Village
              </Typography>
              <AutoCompleteDropdown
                width="auto"
                menuList={villageList}
                selectedOptions={selectedVillageOptions}
                setSelectedOptions={setSelectedVillageOptions}
                disable={villageList.length === 0}
                label="Select Village"
                required = {true}
              />
            </Grid>
          </React.Fragment>
        )}
      </Grid>
    </Grid>
  );
}