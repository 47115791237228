import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import * as Structures from "../../apiResonseStructres/reviewImagesModule";
import { getHeaders } from "../../utils/index";
import {
  allPendingImagesAPI,
  getAndSendAdvisoryAPI,
  assignJobAPI,
  allCompletedImagesAPI,
} from "../../api's/index";

const initialState = {
  allPendingImagesData: Structures.GET_ALL_PENDING_IMAGES_API_RESPONSE,
  allPendingImagesDataLoading: false,
  allPendingImagesDataIsSuccess: false,
  allPendingImagesDataErrorMessage: Structures.ERROR_MESSAGE_API_RESPONSE,
  allCompletedImagesData: Structures.GET_ALL_COMPLETED_IMAGES_API_RESPONSE,
  allCompletedImagesDataLoading: false,
  allCompletedImagesDataIsSuccess: false,
  allCompletedImagesDataErrorMessage: Structures.ERROR_MESSAGE_API_RESPONSE,
  getAndSendAdvisoryResponse: Structures.SEND_ORGANIC_ADVISORY_RESPONSE,
  getAndSendAdvisoryLoading: false,
  getAndSendAdvisoryIsSuccess: false,
  getAndSendAdvisoryErrorMessage: Structures.ERROR_MESSAGE_API_RESPONSE,
  assignJobResponse: Structures.ASSIGN_JOB_RESPONSE,
  assignJobLoading: false,
  assignJobIsSuccess: false,
  assignJobErrorMessage: Structures.ERROR_MESSAGE_API_RESPONSE,
};

var qs = require("qs");
const headers = getHeaders();

export const getallPendingImagesData = createAsyncThunk(
  "review/getallPendingImagesData",
  async (data, { rejectWithValue }) => {
    try {
      const headers = getHeaders();
      const response = await axios.get(allPendingImagesAPI, {
        params: data,
        headers: headers,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getallCompletedImagesData = createAsyncThunk(
  "review/getallCompletedImagesData",
  async (data, { rejectWithValue }) => {
    try {
      const headers = getHeaders();
      const response = await axios.get(allCompletedImagesAPI, {
        params: data,
        headers: headers,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAndSendAdvisory = createAsyncThunk(
  "review/getAndSendAdvisory",
  async (data, { rejectWithValue }) => {
    try {
      const headers = getHeaders();
      const response = await axios({
        method: "post",
        url: getAndSendAdvisoryAPI,
        data: data,
        headers: headers,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const assignJob = createAsyncThunk(
  "session/assignJob",
  async (data, { rejectWithValue }) => {
    try {
      const headers = getHeaders();
      const response = await axios({
        method: "post",
        url: assignJobAPI,
        data: data,
        headers: headers,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const reviewImagesSlice = createSlice({
  name: "reviewImages",
  initialState,
  reducers: {
    resetgetAndSendAdvisory(state) {
      Object.assign(
        state.getAndSendAdvisoryResponse,
        Structures.SEND_ORGANIC_ADVISORY_RESPONSE
      );
      Object.assign(
        state.getAndSendAdvisoryErrorMessage,
        Structures.ERROR_MESSAGE_API_RESPONSE
      );
      Object.assign(state.getAndSendAdvisoryLoading, false);
      Object.assign(state.getAndSendAdvisoryIsSuccess, false);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getallPendingImagesData.fulfilled, (state, { payload }) => {
        state.allPendingImagesData = payload;
        state.allPendingImagesDataLoading = false;
        state.allPendingImagesDataIsSuccess = true;
      })
      .addCase(getallPendingImagesData.pending, (state, { payload }) => {
        state.allPendingImagesDataLoading = true;
        state.allPendingImagesDataIsSuccess = false;
      })
      .addCase(getallPendingImagesData.rejected, (state, { payload }) => {
        state.allPendingImagesDataErrorMessage = payload;
        state.allPendingImagesDataLoading = false;
        state.allPendingImagesDataIsSuccess = false;
      })
      .addCase(getallCompletedImagesData.fulfilled, (state, { payload }) => {
        state.allCompletedImagesData = payload;
        state.allCompletedImagesDataLoading = false;
        state.allCompletedImagesDataIsSuccess = true;
      })
      .addCase(getallCompletedImagesData.pending, (state, { payload }) => {
        state.allCompletedImagesDataLoading = true;
        state.allCompletedImagesDataIsSuccess = false;
      })
      .addCase(getallCompletedImagesData.rejected, (state, { payload }) => {
        state.allCompletedImagesDataErrorMessage = payload;
        state.allCompletedImagesDataLoading = false;
        state.allCompletedImagesDataIsSuccess = false;
      })
      .addCase(getAndSendAdvisory.fulfilled, (state, { payload }) => {
        state.getAndSendAdvisoryResponse = payload;
        state.getAndSendAdvisoryLoading = false;
        state.getAndSendAdvisoryIsSuccess = true;
      })
      .addCase(getAndSendAdvisory.pending, (state, { payload }) => {
        state.getAndSendAdvisoryLoading = true;
        state.getAndSendAdvisoryIsSuccess = false;
      })
      .addCase(getAndSendAdvisory.rejected, (state, { payload }) => {
        state.getAndSendAdvisoryErrorMessage = payload;
        state.getAndSendAdvisoryLoading = false;
        state.getAndSendAdvisoryIsSuccess = false;
      })
      .addCase(assignJob.fulfilled, (state, { payload }) => {
        state.assignJobResponse = payload;
        state.assignJobLoading = false;
        state.assignJobIsSuccess = true;
      })
      .addCase(assignJob.pending, (state, { payload }) => {
        state.assignJobLoading = true;
        state.assignJobIsSuccess = false;
      })
      .addCase(assignJob.rejected, (state, { payload }) => {
        state.assignJobErrorMessage = payload;
        state.assignJobLoading = false;
        state.assignJobIsSuccess = false;
      });
  },
});
export const { resetgetAndSendAdvisory } = reviewImagesSlice.actions;
export default reviewImagesSlice.reducer;
