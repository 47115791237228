import React from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { styled } from "@mui/material/styles";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const CustomSelect = styled(Select)({
  "& .MuiInputBase-root.Mui-disabled": {
    backgroundColor: "#f0f0f0",
  },
});

export default function BasicDropDown({
  text = "",
  value = "",
  handleChange = () => {},
  menuList = [],
  error = false,
  required = false,
  disable = false,
}) {
  const hasSelectedOption = value !== "";
  return (
    <FormControl
      fullWidth
      variant="outlined"
      required={required}
      sx={{
        marginBottom: 1,
        borderRadius: 2,
        width: "90%",
        backgroundColor: "#F5F5F5",
      }}
      error={error}
    >
      <InputLabel
        htmlFor="outlined-select"
        sx={{
          color: hasSelectedOption ? "#5E6060" : undefined,
        }}
      >
        {hasSelectedOption ? "" : text}
      </InputLabel>
      <CustomSelect
        value={value}
        label={hasSelectedOption ? undefined : text}
        onChange={handleChange}
        sx={{ borderRadius: 2 }}
        MenuProps={MenuProps}
        inputProps={{ "aria-label": "Without label" }}
        placeholder={text}
        disabled={
          (menuList[0] !== undefined &&
            menuList[0].id !== undefined &&
            menuList[0].id === "") ||
          menuList.length === 0 ||
          disable
        }
      >
        {menuList.map((menu) => {
          return menu.id ? (
            <MenuItem key={menu.id} value={menu.id}>
              {menu.name}
            </MenuItem>
          ) : (
            <MenuItem key={menu} value={menu}>
              {menu}
            </MenuItem>
          );
        })}
      </CustomSelect>
    </FormControl>
  );
}
