import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

export default function SnackBars ({ open, autoHideDuration = 4000, handleClose, severity, message,vertical,horizontal ,style}) {

    return (
        <div style={style}>
        <Snackbar open={open} autoHideDuration={autoHideDuration} onClose={handleClose} anchorOrigin={{vertical, horizontal} }>
            <MuiAlert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
              {message}
            </MuiAlert>
        </Snackbar>
        </div>
    )
}
