export const GET_ALL_PENDING_IMAGES_API_RESPONSE = {
  pending_jobs_count:-1,
  completed_jobs_count:-1,
  pending_sessions: [
    {
      session_id: "",
      time_stamp: "",
      partner: "",
      images: [
        {
          pre_signed_url: "",
          abw_count: 0,
          pbw_count: 0,
        },
      ],
    },
  ],
};

export const GET_ALL_COMPLETED_IMAGES_API_RESPONSE = {
  pending_jobs_count:-1,
  completed_jobs_count:-1,
  completed_sessions: [
    {
      session_id: "",
      time_stamp: "",
      partner: "",
      images: [
        {
          pre_signed_url: "",
          abw_count: 0,
          pbw_count: 0,
        },
      ],
    },
  ],
};

export const SEND_ORGANIC_ADVISORY_RESPONSE = {
  message: "",
};

export const ASSIGN_JOB_RESPONSE = {
  message: "",
};

export const ERROR_MESSAGE_API_RESPONSE = {
  message: "",
};
