import React, { useEffect, useState, useContext } from "react";
import { Grid, Box, Typography, ThemeProvider } from "@mui/material";
import { basicTheme } from "../../themes/customTheme";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import DashboardCard from "../../components/dashboard/Card";
import LongCard from "../../components/dashboard/LongCard";
import { useNavigate } from "react-router-dom";
// import BarGraph from "../../components/dashboard/BarGraph";
import {
  getPartnerCount,
  getPartnerBasedUserCount,
  getAlertCount,
  getInstallCount,
  getSessionCount,
  getImageCount,
  getActiveUserCount,
  getActiveUserCascadeCount,
  getActiveUserLeadCount,
  getMonitoringBasedCount,
  getInfestationBasedCount,
  getAppUageCount,
} from "./DashboardSlice";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import SnackBars from "../../components/reusableComponents/SnackBar";
import DetailModal from "../../components/dashboard/DetailModal";
import CommonContext from "../appbar/ContextProvider";
import DashboardFilterPopOver from "../../components/dashboard/DashboardFilterPopOver";
import dayjs from "dayjs";

const MainGrid = styled(Grid)({
  flex: "1",
  backgroundColor: "white",
  marginTop: "80px",
  overflow: "auto",
});
export default function Dashboard({}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const drawerWidth = useSelector(
    (state) => state.appBarSliceReducer.drawerWidth
  );

  const [loading, setLoading] = useState(false);
  const { dashboardPopoverOpen, setDashboardPopoverOpen } = useContext(
    CommonContext
  );
  
  const useStyles = makeStyles(() => ({
      // Your styles here
      iconStyle: {
        cursor: "pointer",
      },
  }));

  const classes = useStyles();
  const [exportData, setExportData] = useState("");
  const [onboardingFilter, setOnboardingFilter] = useState([]);
  const [dashboardSnackBarType, setDashboardSnackBarType] = useState("success");
  const [dashboardSnackBarMessage, setDashboardSnackBarMessage] = useState("");
  const [showDashboardSnackBar, setDashboardShowSnackBar] = useState(false);

  const partnerCountResponse = useSelector(
    (state) => state.dashboardSliceReducer.partnerCount.count
  );

  const appInstallCountResponse = useSelector(
    (state) => state.dashboardSliceReducer.installCountData.count
  );

  const partnerCountErrorMessage = useSelector(
    (state) => state.dashboardSliceReducer.partnerCountErrorMessage
  );

  const partnerCountIsSuccess = useSelector(
    (state) => state.dashboardSliceReducer.partnerCountIsSuccess
  );

  const partnerCountLoading = useSelector(
    (state) => state.dashboardSliceReducer.partnerCountLoading
  );

  const alertCountResponse = useSelector(
    (state) => state.dashboardSliceReducer.alertCount.count
  );
  const alertCountErrorMessage = useSelector(
    (state) => state.dashboardSliceReducer.alertCountErrorMessage
  );

  const alertCountIsSuccess = useSelector(
    (state) => state.dashboardSliceReducer.alertCountIsSuccess
  );

  const alertCountLoading = useSelector(
    (state) => state.dashboardSliceReducer.alertCountLoading
  );

  const activeUserCountResponse = useSelector(
    (state) => state.dashboardSliceReducer.activeUserCount.count
  );

  const activeUserLeadCountResponse = useSelector(
    (state) => state.dashboardSliceReducer.activeUserLeadCount.count
  );
  const activeUserCascadeCountResponse = useSelector(
    (state) => state.dashboardSliceReducer.activeUserCascadeCount.count
  );

  const sessionCountResponse = useSelector(
    (state) => state.dashboardSliceReducer.sessionCount.count
  );
  const activeUserCountErrorMessage = useSelector(
    (state) => state.dashboardSliceReducer.activeUserCountErrorMessage
  );

  const activeUserCountIsSuccess = useSelector(
    (state) => state.dashboardSliceReducer.activeUserCountIsSuccess
  );

  const activeUserCountLoading = useSelector(
    (state) => state.dashboardSliceReducer.activeUserCountLoading
  );


  const activeUserLeadCountErrorMessage = useSelector(
    (state) => state.dashboardSliceReducer.activeUserLeadCountErrorMessage
  );

  const activeUserLeadCountIsSuccess = useSelector(
    (state) => state.dashboardSliceReducer.activeUserLeadCountIsSuccess
  );

  const activeUserLeadCountLoading = useSelector(
    (state) => state.dashboardSliceReducer.activeUserLeadCountLoading
  );

  const activeUserCascadeCountErrorMessage = useSelector(
    (state) => state.dashboardSliceReducer.activeUserCascadeCountErrorMessage
  );

  const activeCascadeLeadCountIsSuccess = useSelector(
    (state) => state.dashboardSliceReducer.activeUserCascadeCountIsSuccess
  );

  const activeCascadeLeadCountLoading = useSelector(
    (state) => state.dashboardSliceReducer.activeUserCascadeCountLoading
  );

  const imageCountResponse = useSelector(
    (state) => state.dashboardSliceReducer.imageCount.count
  );
  const imageCountErrorMessage = useSelector(
    (state) => state.dashboardSliceReducer.imageCountErrorMessage
  );

  const imageCountIsSuccess = useSelector(
    (state) => state.dashboardSliceReducer.imageCountIsSuccess
  );

  const imageCountLoading = useSelector(
    (state) => state.dashboardSliceReducer.imageCountLoading
  );

  const partnerBasedUserCount = useSelector(
    (state) => state.dashboardSliceReducer.partnerBasedUserCount
  );

  const partnerBasedUserCountErrorMessage = useSelector(
    (state) => state.dashboardSliceReducer.partnerBasedUserCountErrorMessage
  );

  const partnerBasedUserCountIsSuccess = useSelector(
    (state) => state.dashboardSliceReducer.partnerBasedUserCountIsSuccess
  );

  const partnerBasedUserCountLoading = useSelector(
    (state) => state.dashboardSliceReducer.partnerBasedUserCountLoading
  );

  const monitoringBasedCount = useSelector(
    (state) => state.dashboardSliceReducer.monitoringBasedCount
  );
  const monitoringBasedCountErrorMessage = useSelector(
    (state) => state.dashboardSliceReducer.monitoringBasedCountErrorMessage
  );
  const monitoringBasedCountIsSuccess = useSelector(
    (state) => state.dashboardSliceReducer.monitoringBasedCountIsSuccess
  );
  const monitoringBasedCountLoading = useSelector(
    (state) => state.dashboardSliceReducer.monitoringBasedCountLoading
  );

  const infestationBasedCount = useSelector(
    (state) => state.dashboardSliceReducer.infestationBasedCount
  );

  const appUsageBasedCount = useSelector(
    (state) => state.dashboardSliceReducer.appUsageCount
  )

  const infestationBasedCountLoading = useSelector(
    (state) => state.dashboardSliceReducer.infestationBasedCountLoading
  );
  const infestationBasedCountErrorMessage = useSelector(
    (state) => state.dashboardSliceReducer.infestationBasedCountErrorMessage
  );
  const infestationBasedCountIsSuccess = useSelector(
    (state) => state.dashboardSliceReducer.infestationBasedCountIsSuccess
  );

  const onboardingMenuList = ["Active Partner", "InActive Partner"];
  const enrollmentMenuList = [
    "Active LF's",
    "Active EW's",
    "Active CF's",
    "Active Public Farmers",
  ];

  const alertsMenuList = ["Total Alerts", "Red", "Yellow", "Green"];
  const sessionsMenuList = ["Total Sessions"];
  const imagesMenuList = ["Total Images"];
  const [activeUsersList, setActiveUsersList] = useState([
    // "Lead Farmers",
    // "Cascade Farmers",
    "Total Users",
    "Partner Farmers",
    "Extension Workers",
  ]);

  const graphList = ["Partners"];

  const [monitoringList, setMonitoringList] = useState([]);
  const [infestationList, setInfestationList] = useState([]);
  const [appUsageList,setAppUsageList] = useState([]);
  const appInstallationList = ["Top 10 Partners"];
  const legendList = ["Lead Farmer", "Extension Worker", "Cascade Farmer"];

  const [selectedOnboarding, setSelectedOnboarding] = useState(
    "Active Partner"
  );
  const [selectedEnrollmentType, setSelectedEnrollmentType] = useState(
    "Active LF's"
  );
  const [selectedUserType, setSelectedUserType] = useState("Total Users");
  const [selectedLeadType, setSelectedLeadType] = useState(-1);
  const [selectedCascadeType, setSelectedCascadeType] = useState(-1);
  const [selectedMonitoringMenu, setSelectedMonitoringMenu] = useState("");
  const [selectedInfestationMenu, setSelectedInfestationMenu] = useState("");
  const [selectedAppUsageMenu,setSelectedAppUsageMenu] = useState("");
  const [selectedAlert, setSelectedAlert] = useState("Total Alerts");
  const [selectedImage, setSelectedImage] = useState("Total Images");
  const [selectedSession, setSelectedSession] = useState("Total Sessions");
  const [
    selectedAppInstallationMenu,
    setSelectedAppInstallationMenu,
  ] = useState("Top 10 Partners");
  // const [selectedGraph, setSelectedGraph] = useState("Partners");
  const [detailModal, setDetailModal] = useState("");
  // const [detailModalSelect, setDetailModalSelect] = useState("");
  const [applyFilter, setApplyFilter] = useState(false);
  const [clearFilter, setClearFilter] = useState(false);
  const [startDate, setStartDate] = useState(dayjs().subtract(1, "year"));
  const [endDate, setEndDate] = useState(dayjs(new Date()));
  const [apiCall, setApiCall] = useState(false);
  const [apiCounter, setApiCounter] = useState(0);
  const loggedInUser = JSON.parse(window.localStorage.getItem("loggedInUser"));

  const handleCloseFilterPopOver = () => {
    setDashboardPopoverOpen(null);
    setApplyFilter(false);
    setClearFilter(false);
  };
  useEffect(() => {
    if (applyFilter) {
      handleCloseFilterPopOver();
      setApiCall(true);
    }
  }, [applyFilter]);

  useEffect(() => {
    if(clearFilter) {
      setApiCall(true);
    }
  }, [
    clearFilter,
  ]);

  useEffect(() => {
    if (detailModal === "Onboarding") {
      var statusValue = true;
      if (selectedOnboarding === "InActive Partner") statusValue = false;
      const queryString = `?status=${statusValue}`;
      navigate(`/partner${queryString}`, { replace: true });
    } else if (detailModal === "Users") {
      if (selectedUserType === "WIAI Farmers")
        navigate(`/app_users/puf`, { replace: true });
      else if (selectedUserType === "Partner Farmers")
        navigate(`/app_users/paf`, { replace: true });
      else if (selectedUserType === "Extension Workers")
        navigate(`/app_users/ew`, { replace: true });
    } else if (detailModal === "Sessions") {
      navigate(`/sessions`, { replace: true });
    } else if (detailModal === "Alert Generated") {
      navigate(`/alerts`, { replace: true });
    }else if(detailModal === "App Usage"){
      navigate(`/app_usage`,{ replace: true});
    }
  }, [detailModal]);

  const appInstallationData = [
    { name: "Partner A", count: 7, graph: 0 },
    { name: "Partner B", count: 2, graph: 1 },
    { name: "Partner C", count: 4, graph: 0 },
    { name: "Partner D", count: 8, graph: 1 },
    { name: "Partner E", count: 3, graph: 0 },
    { name: "Partner F", count: 1, graph: 0 },
    { name: "Partner G", count: 6, graph: 1 },
    { name: "Partner H", count: 9, graph: 0 },
    { name: "Partner I", count: 5, graph: 1 },
    { name: "Partner J", count: 0, graph: 1 },
  ];

  useEffect(() => {
    setLoading(apiCounter > 0);
  }, [apiCounter]);

  useEffect(() => {
    setApiCounter((prevCounter) => prevCounter + 1);
    installCountApiCall()
    dispatch(getPartnerBasedUserCount({ filters: JSON.stringify([]) })).then(
      () => {
        setApiCounter((prevCounter) => prevCounter - 1);
      }
    );
  }, []);

  useEffect(() => {
    if (apiCall) {
      sessionCountApiCall();
      activeUserCountApiCall();
      activeCascadeFarmerCountApiCall();
      activeLeadFarmerCountApiCall();
      imageCountApiCall();
      installCountApiCall();
      alertCountApiCall();
      partnerCountApiCall();
      monitoringBasedCountApiCall();
      getInfestationBasedCountApiCall();
      appUsageApiCall();
      setApiCall(false);
    }
  }, [apiCall]);


  const sessionCountApiCall = () => {
    setApiCounter((prevCounter) => prevCounter + 1);
    dispatch(
      getSessionCount({
        filters: JSON.stringify([]),
        from_date: dayjs(startDate).format("DD/MM/YYYY"),
        to_date: dayjs(endDate).format("DD/MM/YYYY"),
      })
    ).then(() => {
      setApiCounter((prevCounter) => prevCounter - 1);
    });
  };

  const appUsageApiCall = () => {
    setApiCounter((prevCounter) => prevCounter + 1);
    dispatch(
      getAppUageCount({
        filters: JSON.stringify([]),
        limit: 100000,
        offset: 0,
      })
    )
    setApiCounter((prevCounter) => prevCounter - 1);
  } 

  useEffect(() => {
    sessionCountApiCall();
  }, [selectedSession]);


  useEffect(() => {
    if (selectedMonitoringMenu !== "") monitoringBasedCountApiCall();
  }, [selectedMonitoringMenu]);

  const getMonitoringListByUserRole = (userRole) => {
    switch (userRole) {
      case "Wadhwani Admin":
        setSelectedMonitoringMenu("Top 10 Partners");
        return [
          "Top 10 States",
          "Top 10 Districts",
          "Top 10 Talukas",
          "Top 10 Partners",
        ];
      case "Partner Head":
        setSelectedMonitoringMenu("Top 10 States");
        return ["Top 10 States", "Top 10 Districts", "Top 10 Talukas"];
      case "State Head":
        setSelectedMonitoringMenu("Top 10 Districts");
        return ["Top 10 Districts", "Top 10 Talukas"];
      case "District Head":
        setSelectedMonitoringMenu("Top 10 Talukas");
        return ["Top 10 Talukas"];
      case "Government":
        setSelectedMonitoringMenu("Top 10 Partners");
        return [
          "Top 10 States",
          "Top 10 Districts",
          "Top 10 Talukas",
          "Top 10 Partners",
        ];
      default:
        return [];
    }
  };

  const getInfestationListByUserRole = (userRole) => {
    switch (userRole) {
      case "Wadhwani Admin":
        setSelectedInfestationMenu("Top 10 States");
        return ["Top 10 States", "Top 10 Districts", "Top 10 Talukas"];
      case "Partner Head":
        setSelectedInfestationMenu("Top 10 States");
        return ["Top 10 States", "Top 10 Districts", "Top 10 Talukas"];
      case "State Head":
        setSelectedInfestationMenu("Top 10 Districts");
        return ["Top 10 Districts", "Top 10 Talukas"];
      case "District Head":
        setSelectedInfestationMenu("Top 10 Talukas");
        return ["Top 10 Talukas"];
      case "Government":
        setSelectedInfestationMenu("Top 10 States");
        return ["Top 10 States", "Top 10 Districts", "Top 10 Talukas"];
      default:
        return [];
    }
  };

  const getAppUsage = () => {
        setSelectedAppUsageMenu("Partner");
        return ["Partner", "Location"];
  };

  useEffect(() => {
    if (loggedInUser.details.role === "Wadhwani Admin") {
      if (!activeUsersList.includes("WIAI Farmers")) {
        setActiveUsersList((prevList) => [...prevList, "WIAI Farmers"]);
      }
    }
    setAppUsageList(getAppUsage())

    const updatedMonitoringList = getMonitoringListByUserRole(
      loggedInUser.details.role
    );
    setMonitoringList(updatedMonitoringList);

    const updatedInfestationList = getInfestationListByUserRole(
      loggedInUser.details.role
    );
    setInfestationList(updatedInfestationList);
  }, [loggedInUser.details.role]);

  const monitoringBasedCountApiCall = () => {
    var filter_type = "state";
    if (selectedMonitoringMenu == "Top 10 States") filter_type = "state";
    else if (selectedMonitoringMenu == "Top 10 Districts")
      filter_type = "district";
    else if (selectedMonitoringMenu == "Top 10 Talukas") filter_type = "taluka";
    else if (selectedMonitoringMenu == "Top 10 Partners")
      filter_type = "partner";
    setApiCounter((prevCounter) => prevCounter + 1);
    dispatch(
      getMonitoringBasedCount({
        filters: JSON.stringify([]),
        type: filter_type,
        from_date: dayjs(startDate).format("DD/MM/YYYY"),
        to_date: dayjs(endDate).format("DD/MM/YYYY"),
      })
    ).then(() => {
      setApiCounter((prevCounter) => prevCounter - 1);
    });
  };

  const getInfestationBasedCountApiCall = () => {
    var filter_type = "state";
    if (selectedInfestationMenu == "Top 10 States") filter_type = "state";
    else if (selectedInfestationMenu == "Top 10 Districts")
      filter_type = "district";
    else if (selectedInfestationMenu == "Top 10 Talukas")
      filter_type = "taluka";
    setApiCounter((prevCounter) => prevCounter + 1);
    dispatch(
      getInfestationBasedCount({
        type: filter_type,
        filters: JSON.stringify([]),
      })
    ).then(() => {
      setApiCounter((prevCounter) => prevCounter - 1);
    });
  };

  useEffect(() => {
    if (selectedInfestationMenu !== "") getInfestationBasedCountApiCall();
  }, [selectedInfestationMenu]);

  const activeUserCountApiCall = () => {
    setApiCounter((prevCounter) => prevCounter + 1);
    var userType = "pf";
    if (selectedUserType === "WIAI Farmers") userType = "pf";
    else if (selectedUserType === "Partner Farmers") userType = "paf";
    else if (selectedUserType === "Total Users") userType = "total";
    else if (selectedUserType === "Lead Farmers") userType = "lf";
    else if (selectedUserType === "Extension Workers") userType = "ew";
    else if (selectedUserType === "Cascade Farmers") userType = "cf";

    const requestData = {
      filters: JSON.stringify([]),
      user_type: userType,
      from_date: dayjs(startDate).format("DD/MM/YYYY"),
      to_date: dayjs(endDate).format("DD/MM/YYYY"),
    };
    dispatch(getActiveUserCount(requestData)).then(() => {
      setApiCounter((prevCounter) => prevCounter - 1);
    });
  };

  const installCountApiCall = () =>{
    setApiCounter((prevCounter) => prevCounter + 1)
    dispatch(getInstallCount()).then(() =>{
      setApiCounter((prevCounter) => prevCounter - 1)
    })
  };
  
  const activeLeadFarmerCountApiCall = () => {
    setApiCounter((prevCounter) => prevCounter + 1);
    var userType = "lf";
    const requestDataForLead = {
      filters: JSON.stringify([]),
      user_type: userType,
      from_date: dayjs(startDate).format("DD/MM/YYYY"),
      to_date: dayjs(endDate).format("DD/MM/YYYY"),
    };
    dispatch(getActiveUserLeadCount(requestDataForLead)).then(() => {
      setApiCounter((prevCounter) => prevCounter - 1);
    });

  };
  const activeCascadeFarmerCountApiCall = () => {
    setApiCounter((prevCounter) => prevCounter + 1);
    var userType = "cf";
    const requestDataForCascade = {
      filters: JSON.stringify([]),
      user_type: userType,
      from_date: dayjs(startDate).format("DD/MM/YYYY"),
      to_date: dayjs(endDate).format("DD/MM/YYYY"),
    };

    dispatch(getActiveUserCascadeCount(requestDataForCascade)).then(() => {
      setApiCounter((prevCounter) => prevCounter - 1);
    });
  };


  useEffect(() => {
    activeUserCountApiCall();
    appUsageApiCall();
  }, [selectedUserType]);


  useEffect(() => {
    activeLeadFarmerCountApiCall(); //same
  }, [selectedUserType]);


  useEffect(() => {
    activeCascadeFarmerCountApiCall(); // check
  }, [selectedUserType]);

  const imageCountApiCall = () => {
    setApiCounter((prevCounter) => prevCounter + 1);
    dispatch(
      getImageCount({
        filters: JSON.stringify([]),
        from_date: dayjs(startDate).format("DD/MM/YYYY"),
        to_date: dayjs(endDate).format("DD/MM/YYYY"),
      })
    ).then(() => {
      setApiCounter((prevCounter) => prevCounter - 1);
    });
  };

  useEffect(() => {
    imageCountApiCall();
  }, [selectedImage]);

  const alertCountApiCall = () => {
    setApiCounter((prevCounter) => prevCounter + 1);
    dispatch(
      getAlertCount({
        filters: JSON.stringify([]),
        alert: selectedAlert.toLowerCase(),
        from_date: dayjs(startDate).format("DD/MM/YYYY"),
        to_date: dayjs(endDate).format("DD/MM/YYYY"),
      })
    ).then(() => {
      setApiCounter((prevCounter) => prevCounter - 1);
    });
  };

  useEffect(() => {
    alertCountApiCall();
  }, [selectedAlert]);

  useEffect(() => {
    if (selectedOnboarding === "InActive Partner")
      setOnboardingFilter([{ type: "is_active", value: [false] }]);
    else setOnboardingFilter([{ type: "is_active", value: [true] }]);
  }, [selectedOnboarding]);

  const partnerCountApiCall = () => {
    setApiCounter((prevCounter) => prevCounter + 1);
    dispatch(
      getPartnerCount({
        filters: JSON.stringify(onboardingFilter),
        from_date: dayjs(startDate).format("DD/MM/YYYY"),
        to_date: dayjs(endDate).format("DD/MM/YYYY"),
      })
    ).then(() => {
      setApiCounter((prevCounter) => prevCounter - 1);
    });
  };

  useEffect(() => {
    if (onboardingFilter.length !== 0) {
      partnerCountApiCall();
    }
  }, [onboardingFilter]);

  useEffect(() => {
    if (
      !partnerCountIsSuccess &&
      (partnerCountErrorMessage === undefined ||
        (partnerCountErrorMessage.message === undefined &&
          partnerCountErrorMessage.message !== ""))
    ) {
      setDashboardShowSnackBar(true);
      setDashboardSnackBarMessage("Something went wrong on server side ");
      setDashboardSnackBarType("error");
    } else if (
      !partnerCountLoading &&
      !partnerCountIsSuccess &&
      partnerCountErrorMessage !== undefined &&
      partnerCountErrorMessage.message !== undefined &&
      partnerCountErrorMessage.message !== ""
    ) {
      setDashboardShowSnackBar(true);
      setDashboardSnackBarMessage(partnerCountErrorMessage.message);
      setDashboardSnackBarType("error");
    }
  }, [partnerCountErrorMessage, partnerCountLoading]);

  useEffect(() => {
    if (
      !alertCountIsSuccess &&
      (alertCountErrorMessage === undefined ||
        (alertCountErrorMessage.message === undefined &&
          alertCountErrorMessage.message !== ""))
    ) {
      setDashboardShowSnackBar(true);
      setDashboardSnackBarMessage("Something went wrong on Alert server side ");
      setDashboardSnackBarType("error");
    } else if (
      !alertCountLoading &&
      !alertCountIsSuccess &&
      alertCountErrorMessage !== undefined &&
      alertCountErrorMessage.message !== undefined &&
      alertCountErrorMessage.message !== ""
    ) {
      setDashboardShowSnackBar(true);
      setDashboardSnackBarMessage(alertCountErrorMessage.message);
      setDashboardSnackBarType("error");
    }
  }, [alertCountErrorMessage, alertCountLoading]);

  useEffect(() => {
    if (
      !imageCountIsSuccess &&
      (imageCountErrorMessage === undefined ||
        (imageCountErrorMessage.message === undefined &&
          imageCountErrorMessage.message !== ""))
    ) {
      setDashboardShowSnackBar(true);
      setDashboardSnackBarMessage("Something went wrong on Image server side ");
      setDashboardSnackBarType("error");
    } else if (
      !imageCountLoading &&
      !imageCountIsSuccess &&
      imageCountErrorMessage !== undefined &&
      imageCountErrorMessage.message !== undefined &&
      imageCountErrorMessage.message !== ""
    ) {
      setDashboardShowSnackBar(true);
      setDashboardSnackBarMessage(imageCountErrorMessage.message);
      setDashboardSnackBarType("error");
    }
  }, [imageCountErrorMessage, imageCountLoading]);

  useEffect(() => {
    if (
      !partnerBasedUserCountIsSuccess &&
      (partnerBasedUserCountErrorMessage === undefined ||
        (partnerBasedUserCountErrorMessage.message === undefined &&
          partnerBasedUserCountErrorMessage.message !== ""))
    ) {
      setDashboardShowSnackBar(true);
      setDashboardSnackBarMessage(
        "Something went wrong on Partner Based User Count Graph Server side "
      );
      setDashboardSnackBarType("error");
    } else if (
      !partnerBasedUserCountLoading &&
      !partnerBasedUserCountIsSuccess &&
      partnerBasedUserCountErrorMessage !== undefined &&
      partnerBasedUserCountErrorMessage.message !== undefined &&
      partnerBasedUserCountErrorMessage.message !== ""
    ) {
      setDashboardShowSnackBar(true);
      setDashboardSnackBarMessage(partnerBasedUserCountErrorMessage.message);
      setDashboardSnackBarType("error");
    }
  }, [partnerBasedUserCountErrorMessage, partnerBasedUserCountLoading]);
  useEffect(() => {
    if (
      !monitoringBasedCountIsSuccess &&
      (monitoringBasedCountErrorMessage === undefined ||
        (monitoringBasedCountErrorMessage.message === undefined &&
          monitoringBasedCountErrorMessage.message !== ""))
    ) {
      setDashboardShowSnackBar(true);
      setDashboardSnackBarMessage(
        "Something went wrong on Monitoring Based Count Server side "
      );
      setDashboardSnackBarType("error");
    } else if (
      !monitoringBasedCountLoading &&
      !monitoringBasedCountIsSuccess &&
      monitoringBasedCountErrorMessage !== undefined &&
      monitoringBasedCountErrorMessage.message !== undefined &&
      monitoringBasedCountErrorMessage.message !== ""
    ) {
      setDashboardShowSnackBar(true);
      setDashboardSnackBarMessage(monitoringBasedCountErrorMessage.message);
      setDashboardSnackBarType("error");
    }
  }, [monitoringBasedCountErrorMessage, monitoringBasedCountLoading]);

  useEffect(() => {
    if (
      !infestationBasedCountIsSuccess &&
      (infestationBasedCountErrorMessage === undefined ||
        (infestationBasedCountErrorMessage.message === undefined &&
          infestationBasedCountErrorMessage.message !== ""))
    ) {
      setDashboardShowSnackBar(true);
      setDashboardSnackBarMessage(
        "Something went wrong on Infestation Based Count Server side "
      );
      setDashboardSnackBarType("error");
    } else if (
      !infestationBasedCountLoading &&
      !infestationBasedCountIsSuccess &&
      infestationBasedCountErrorMessage !== undefined &&
      infestationBasedCountErrorMessage.message !== undefined &&
      infestationBasedCountErrorMessage.message !== ""
    ) {
      setDashboardShowSnackBar(true);
      setDashboardSnackBarMessage(infestationBasedCountErrorMessage.message);
      setDashboardSnackBarType("error");
    }
  }, [infestationBasedCountErrorMessage, infestationBasedCountLoading]);

  return (
    <MainGrid
      container
      id="main"
      sx={{ marginLeft: drawerWidth + "px", width: "auto" }}
      spacing={3}
      direction="row"
      justifyContent="center"
      alignItems="center"
    >
      <ThemeProvider theme={basicTheme}>
        {dashboardPopoverOpen && (
          <DashboardFilterPopOver
            handleClose={handleCloseFilterPopOver}
            openFilterPopOver={dashboardPopoverOpen}
            setApplyFilter={setApplyFilter}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            clearFilter={clearFilter}
            setClearFilter={setClearFilter}
          />
        )}
        <Grid item xs={12}>
          <Grid
            container
            spacing={3}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={5} lg={3.5}>
              <DashboardCard
                title="Users"
                count={activeUserCountResponse}
                lCount ={activeUserLeadCountResponse}
                cCount = {activeUserCascadeCountResponse}
                menuList={activeUsersList}
                value={selectedUserType}
                lValue = {selectedLeadType}
                cValue = {selectedCascadeType}
                setValue={setSelectedUserType}
                setDetailModal={setDetailModal}
                forSize = {selectedUserType === "Partner Farmers"}
                forVisibility = {selectedUserType === "Partner Farmers"}
                clickable={(selectedUserType==="Total Users" && loggedInUser.details.role==="Government") || selectedUserType==="Total Users" ?false : true}
              />
            </Grid>
            <Grid item xs={5} lg={3.5}>
              <DashboardCard
                title="Sessions"
                count={sessionCountResponse}
                menuList={sessionsMenuList}
                value={selectedSession}
                forVisibility = {false}
                setValue={setSelectedSession}
                setDetailModal={setDetailModal}
                forSize = {false}
                clickable={loggedInUser.details.role==="Government" ? false : true}
              />
            </Grid>
            <Grid item xs={5} lg={3.5}>
              <DashboardCard
                title="Alert Generated"
                count={alertCountResponse}
                menuList={alertsMenuList}
                value={selectedAlert}
                setValue={setSelectedAlert}
                setDetailModal={setDetailModal}
                forSize = {false}
                forVisibility = {false}
                clickable={loggedInUser.details.role==="Government" ? false: true}
              />
            </Grid>
            <Grid item xs={5} lg={3.5}>
              <DashboardCard
                title="Image Uploads"
                count={imageCountResponse}
                menuList={imagesMenuList}
                value={selectedImage}
                forSize = {false}
                forHover = {false}
                forVisibility = {false}
                setValue={setSelectedImage}
                setDetailModal={setDetailModal}
                clickable={loggedInUser.details.role==="Government" ? true: false}
              />
            </Grid>
            <Grid item xs={5} lg={3.5}>
              {loggedInUser.details.role === "Wadhwani Admin" && (
                <DashboardCard
                  title="Onboarding"
                  count={partnerCountResponse}
                  menuList={onboardingMenuList}
                  value={selectedOnboarding}
                  forSize = {false}
                  forHover = {true}
                  forVisibility = {false}
                  setValue={setSelectedOnboarding}
                  setDetailModal={setDetailModal}
                  clickable={loggedInUser.details.role==="Government" ? false: true}
                />
              )}
            </Grid>
            <Grid item xs={5} lg={3.5}>
              {loggedInUser.details.role === "Wadhwani Admin" && (
                <DashboardCard
                  title="App Usage"
                  count={appInstallCountResponse}
                  isAppInstallation ={true}
                  forSize = {false}
                  forHover = {true}
                  forVisibility = {false}
                  setDetailModal={setDetailModal}
                  clickable={loggedInUser.details.role==="Government" ? false: true}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
        {loggedInUser.details.role !== "Taluka Head" && (
          <Grid item xs={12} sx={{ marginTop: "30px", marginBottom: "30px" }}>
            <Grid
              container
              spacing={3}
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={5} lg={3.5}>
                <LongCard
                  title="Monitoring"
                  menuList={monitoringList}
                  dataList={monitoringBasedCount["data"]}
                  value={selectedMonitoringMenu}
                  setValue={setSelectedMonitoringMenu}
                />
              </Grid>
              <Grid item xs={5} lg={3.5}>
                <LongCard
                  title="Infestation"
                  menuList={infestationList}
                  dataList={infestationBasedCount["data"]}
                  value={selectedInfestationMenu}
                  setValue={setSelectedInfestationMenu}
                />
              </Grid>
              {/* <Grid item xs={5} lg={3.5}>
                <LongCard
                  title="App usage"
                  menuList={appUsageList}
                  dataList={appUsageBasedCount["data"]}
                  value={selectedAppUsageMenu}
                  setValue={setSelectedAppUsageMenu}
                />
              </Grid> */}
              {/* <Grid item xs={5} lg={3.5}> */}
                {/* <LongCard
                  title="App Installation"
                  menuList={appInstallationList}
                  dataList={appInstallationData}
                  value={selectedAppInstallationMenu}
                  setValue={setSelectedAppInstallationMenu}
                  disable={true}
                /> */}
              {/* </Grid> */}
            </Grid>
          </Grid>
        )}
        {/* <Grid item xs={12}>
          <BarGraph
        
            menuList={graphList}
            value={selectedGraph}
            setValue={setSelectedGraph}
            legendList={legendList}
            data={partnerBasedUserCount}
          />
        </Grid> */}
        {loading && (
          <Backdrop
            sx={{
              color: "#fff",
              width: "100%",
              height: "100%",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        {showDashboardSnackBar && (
          <SnackBars
            open={showDashboardSnackBar}
            handleClose={() => setDashboardShowSnackBar(false)}
            severity={dashboardSnackBarType}
            message={dashboardSnackBarMessage}
            autoHideDuration={6000}
            vertical="bottom"
            horizontal="center"
          />
        )}
      </ThemeProvider>
    </MainGrid>
  );
}
