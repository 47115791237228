export const GET_APP_USERS_DETAIL_API_RESPONSE = {
    data: [
      {
        id:"",
        name: "",
        phone_number: "",
        location: "",
        formatted_location:{state_ids:[],district_ids:[],taluks_ids:[],village_ids:[]},
        farmer_type: "",
        farming_type: "",
        season: {code:"",name:""},
        status: "",
        extension_worker: {name:"",phone_number:""},
        partner: {id:"",name:""},
        crop_name: "",
        sowing_date:"",
      },
    ],
    count: -1,
};

export const ERROR_MESSAGE_API_RESPONSE = {
  message:""
}

export const ADD_USER_API_RESPONSE = {
  message:""
}