export const GET_SUPERVISOR_DETAIL_API_RESPONSE = {
    data: [
      {
        id: "",
        name: "",
        location: [], 
        spoc_details: { name: "", email: "", phone_number: "" },
        created_at: "",
        is_active: false,
        location_data:[],
        role:"",
        partner:{id:"",name:""},
        partner_spoc:"",
      },
    ],
    count: -1,
  };
  
  export const GET_SUPERVISOR_NAMES_API_RESPONSE = {
    data: [
      {
        id: "",
        name: "",
      },
    ],
    count: -1,
  };
  
  export const ERROR_MESSAGE_API_RESPONSE = {
    message: "",
  };
  
  export const ADD_SUPERVISOR_API_RESPONSE = {
    message: "",
  };
  