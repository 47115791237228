import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import {
  Button,
  Box,
  useMediaQuery,
  Grid,
  MenuItem,
  TextField,
} from "@mui/material";
import { makeStyles, useTheme, ThemeProvider } from "@mui/styles";
import { basicTheme } from "../../themes/customTheme";
import { styled } from "@mui/material/styles";
import ClearIcon from "@mui/icons-material/Clear";
import Divider from "@mui/material/Divider";
import Select from "@mui/material/Select";
import FilterListIcon from "@mui/icons-material/FilterList";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import InputAdornment from "@mui/material/InputAdornment";
import { ReactComponent as FilterIcon } from "../../styles/FilterIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import Badge from "@mui/material/Badge";
import DesktopTable from "../reusableComponents/DesktopTable";

const subGridStyle = {
  position: "relative",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
  width: { xl: "900px", lg: "800px", md: "600px" },
  maxHeight: "80%",
  // overflow: "auto",
  overflowY: "auto",
  display: "flex",
  // alignItems: "center",
  justifyContent: "space-around",
  paddingLeft: 5,
  scrollbarWidth: "thin",
  scrollbarColor: "transparent transparent",
  "&::-webkit-scrollbar": {
    width: "0.5em",
  },
  "&::-webkit-scrollbar-track": {
    background: "transparent",
  },
  "&::-webkit-scrollbar-thumb": {
    background: "transparent",
  },
};

const useStyles = makeStyles({
  closeBtnStyle: {
    position: "absolute",
    top: "8px",
    right: "8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "32px",
    height: "32px",
    borderRadius: "50%",
    backgroundColor: "#E0E0E0",
    color: "#FFFFFF", // Set the icon color to white
    "&:hover": {
      backgroundColor: "#BDBDBD",
    },
    cursor: "pointer",
  },
});

const TextFieldStyled = styled(TextField)({
  color: "white",
  textTransform: "none",
  fontSize: "14px",
  fontWeight: "600",
  "& .MuiOutlinedInput-root": {
    borderRadius: "12px",
  },
});

const ButtonStyle = styled(Button)(({ theme, selected }) => ({
  backgroundColor: selected ? "#F0F8F1" : "#F8FAF8",
  color: selected ? "#2D6A4F" : "#5E6060",
  textTransform: "none",
  borderRadius: "12px",
  fontSize: "16px",
  fontWeight: "600",
  height: "54px",
  minWidth: "140px",
  border: selected ? "1px solid #2D6A4F" : 0,
}));

const DetailModal = ({
  open,
  handleClose,
  selectedCard = "",
  setSelectedCard = () => {},
  menuList = [],
  detailModalSelect = "",
  setDetailModalSelect = () => {},
  filterSelected = false,
  handleShowFilterPopOver = () => {},
  setSearchBarData = () => {},
  searchBarText,
  setSeachBarText = () => {},
  loading = false,
  searchBy = "Search",
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [searchError, setSearchError] = React.useState("");
  const [initialHeadCells, setInitialHeadCells] = useState([]);
  const isXl = useMediaQuery(theme.breakpoints.up("xl"));
  const isLg = useMediaQuery(theme.breakpoints.up("lg"));

  const stringValPatternValidation = (stringVal) => {
    return /^\s+$/.test(stringVal);
  };

  const handleButtonClick = (buttonName) => {
    setSelectedCard(buttonName);
  };
  const handleChange = (event) => {
    setDetailModalSelect(event.target.value);
  };
  const drawerWidth = useSelector(
    (state) => state.appBarSliceReducer.drawerWidth
  );

  // const initialHeadCells = [
  //   {
  //     key: "name",
  //     label: "Name",
  //     type: "text",
  //   },
  //   {
  //     key: "mobile",
  //     label: "Mobile",
  //     type: "text",
  //   },
  //   {
  //     key: "village",
  //     label: "Village",
  //     type: "text",
  //   },
  // ];

  const data = [
    {
      name: "Farmer 1",
      mobile: 9887766123,
      village: "Jalandhar, punjab",
    },
    {
      name: "Farmer 2",
      mobile: 9887766122,
      village: "Jalandhar, punjab, P1",
    },
    {
      name: "Farmer 1",
      mobile: 9887766123,
      village: "Jalandhar, punjab",
    },
    {
      name: "Farmer 2",
      mobile: 9887766122,
      village: "Jalandhar, punjab, P1",
    },
    {
      name: "Farmer 1",
      mobile: 9887766123,
      village: "Jalandhar, punjab",
    },
    {
      name: "Farmer 2",
      mobile: 9887766122,
      village: "Jalandhar, punjab, P1",
    },
  ];

  useEffect(() => {
    setInitialHeadCells(
      Object.keys(data[0]).map((key) => ({
        key,
        label: key.charAt(0).toUpperCase() + key.slice(1),
        type: "text",
      }))
    );
  }, []);

  return (
    <Modal open={open} onClose={handleClose}>
      <Grid container sx={subGridStyle} spacing={1}>
        <ThemeProvider theme={basicTheme}>
          <Box>
            <ClearIcon
              className={classes.closeBtnStyle}
              onClick={handleClose}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              marginTop: 2,
              //   justifyContent: "center",
              overflowX: "auto",
              whiteSpace: "nowrap",
              gap: "10px",
              scrollbarWidth: "thin",
              scrollbarColor: "transparent transparent",
              "&::-webkit-scrollbar": {
                width: "0.5em",
              },
              "&::-webkit-scrollbar-track": {
                background: "transparent",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "transparent",
              },
            }}
          >
            <ButtonStyle
              variant="outlined"
              selected={selectedCard === "Onboarding"}
              onClick={() => handleButtonClick("Onboarding")}
            >
              Onboarding
            </ButtonStyle>
            <ButtonStyle
              variant="outlined"
              selected={selectedCard === "Enrollment"}
              onClick={() => handleButtonClick("Enrollment")}
            >
              Enrollment
            </ButtonStyle>
            <ButtonStyle
              variant="outlined"
              selected={selectedCard === "Active Users"}
              onClick={() => handleButtonClick("Active Users")}
            >
               Users
            </ButtonStyle>
            <ButtonStyle
              variant="outlined"
              selected={selectedCard === "Sessions"}
              onClick={() => handleButtonClick("Sessions")}
            >
              Sessions
            </ButtonStyle>
            <ButtonStyle
              variant="outlined"
              selected={selectedCard === "Alert Generated"}
              onClick={() => handleButtonClick("Alert Generated")}
            >
              Alert Generated
            </ButtonStyle>
            <ButtonStyle
              variant="outlined"
              selected={selectedCard === "Image Uploads"}
              onClick={() => handleButtonClick("Image Uploads")}
            >
              Image Uploads
            </ButtonStyle>
          </Box>
          <Divider
            sx={{
              marginTop: { xl: "20px", lg: "12px", md: "7px" },
              color: "black",
              width: "900px",
            }}
          />
          <Box
            sx={{
              marginTop: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              borderRadius: "4px",
              width: { xl: "800px", lg: "700px", md: "500px" },
              // "@media (max-width: 600px)": {
              //   flexDirection: "column",
              // },
            }}
          >
            <Select
              value={detailModalSelect}
              onChange={handleChange}
              style={{
                border: "1px solid #74C69D",
                borderRadius: "18px",
                color: "#2D6A4F",
                height: "34px",
              }}
              sx={{
                // margin: {
                //   xl: drawerWidth === 0 ? "30px" : "28px",
                //   lg: drawerWidth === 0 ? "28px" : "16px",
                //   md: drawerWidth === 0 ? "26px" : "14px",
                //   sm: drawerWidth === 0 ? "24px" : "10px",
                // },
                width: {
                  xl: drawerWidth === 0 ? "160px" : "140px",
                  lg: drawerWidth === 0 ? "140px" : "120px",
                  md: drawerWidth === 0 ? "120px" : "110px",
                  sm: drawerWidth === 0 ? "110px" : "100px",
                },
              }}
            >
              {menuList.map((menu) => {
                return (
                  <MenuItem key={menu} value={menu}>
                    {menu}
                  </MenuItem>
                );
              })}
            </Select>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: { xl: "250px", lg: "220px", md: "180px" },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: 1.5,
                  borderRadius: "5px",
                }}
              >
                <Badge
                  variant="dot"
                  invisible={filterSelected === false}
                  sx={{
                    "& .MuiBadge-badge": {
                      color: "red",
                      backgroundColor: "red",
                    },
                  }}
                >
                  <Icon
                    sx={{
                      height: "50px",
                      width: "50px",
                      color: "#ACADAD",
                      stroke: "#F8F8F8",
                      // marginLeft: 1,
                    }}
                    onClick={handleShowFilterPopOver}
                  >
                    <FilterIcon />
                  </Icon>
                </Badge>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <TextFieldStyled
                  sx={{
                    borderRadius: "5px",
                    width: { xl: "160px", lg: "150px", md: "120px" },
                  }}
                  fullWidth
                  disabled={loading}
                  label={searchBy}
                  className={classes.search}
                  margin="normal"
                  variant="outlined"
                  value={searchBarText}
                  error={searchError !== ""}
                  helperText={searchError}
                  onInput={(e) => {
                    var emptyString = stringValPatternValidation(
                      e.target.value
                    );
                    if (emptyString) {
                      setSearchError("White Spaces are not allowed");
                    } else {
                      setSearchError("");
                    }
                    setSeachBarText(e.target.value);
                  }}
                  onKeyPress={(e) => {
                    if (e.key === "Enter" && searchBarText.trim() !== "") {
                      var tempSearchBarText = searchBarText;
                      tempSearchBarText = tempSearchBarText.replace(
                        /^\s+/g,
                        ""
                      );
                      setSearchBarData(tempSearchBarText);
                    }
                  }}
                  onChange={(e) => {
                    if (e.target.value.trim() === "")
                      setSearchBarData(e.target.value.trim());
                  }}
                  inputProps={{ style: { borderColor: "green" } }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => {
                            if (searchBarText !== "") {
                              var text = searchBarText.replace(
                                /^\s+|\s+$/gm,
                                ""
                              );
                              setSearchBarData(text);
                            }
                          }}
                        >
                          <SearchRoundedIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              marginTop: 3,
            }}
          >
            <DesktopTable
              tableResponse={data}
              headCells={initialHeadCells}
              totalCount={20}
              showPagination={true}
              tableWidth={isXl ? "800px" : isLg ? "700px" : "500px"}
            />
          </Box>
        </ThemeProvider>
      </Grid>
    </Modal>
  );
};

export default DetailModal;
