import React, { useState, useEffect } from "react";
import {
  Button,
  Grid,
  Typography,
  Modal,
  ThemeProvider,
  TextField,
  Checkbox,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { basicTheme } from "../../themes/customTheme";
import {
  editLocation,
  resetEditlocation,
} from "../../features/location/locationSlice";
import { useDispatch, useSelector } from "react-redux";
import SnackBars from "../reusableComponents/SnackBar";
import BasicTextfield from "../reusableComponents/BasicTextfield";

const mainGridStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "65%",
  // height: "80%",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 4,
  p: 4,
};

const useStyles = makeStyles(() => ({
  closeBtnStyle: {
    display: "flex",
    justifyContent: "flex-end",
    zoom: "2.2",
    alignItems: "right",
    "&:hover": {
      color: "#081C15",
    },
    cursor: "pointer",
  },
}));

const ButtonStyle = styled(Button)({
  backgroundColor: "#2D6A4F",
  color: "white",
  textTransform: "none",
  borderRadius: "8px",
  fontSize: "18px",
  fontWeight: "600",
  minWidth: "120px",
  maxWidth: "150px",
  marginLeft: 20,
});

const CancelButtonStyle = styled(Button)({
  backgroundColor: "white",
  color: "#2D6A4F",
  textTransform: "none",
  borderRadius: "8px",
  fontSize: "18px",
  fontWeight: "600",
  minWidth: "120px",
  maxWidth: "150px",
  marginRight: 20,
});

export default function EditLocationModal({
  openModal,
  handleClose,
  data = {},
  setEditData = () => {},
  setSnackBarMessage = () => {},
  setReload = () => {},
}) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [newVillageName, setNewVillageName] = useState(data.village.name);
  const [villageId, setVillageId] = useState(data.village.id);
  const [activeStatus, setActiveStatus] = useState(data.is_active);
  const [editVillageSnackBar, setEditVillageSnackBar] = useState(false);
  const [editVillageSnackBarMessage, setEditVillageSnackBarMessage] = useState(
    ""
  );
  const [editVillageSnackBarType, setEditVillageSnackBarType] = useState(
    "success"
  );
  // const [snackBarType, setSnackBarType] = useState("success");

  const editLocationApiResponse = useSelector(
    (state) => state.locationSliceReducer.editLocationApiResponse.message
  );

  const editLocationErrorMessage = useSelector(
    (state) => state.locationSliceReducer.editLocationErrorMessage.message
  );

  const editLocationIsSuccess = useSelector(
    (state) => state.locationSliceReducer.editLocationIsSuccess
  );

  useEffect(() => {
    dispatch(resetEditlocation());
  }, []);

  const handleChangeVillage = (value) => {
    value=value.replace(/^\s+/g, '');
    setNewVillageName(value);
  };

  const handleActiveStatusChange = (e) => {
    setActiveStatus(e.target.checked);
  };

  const saveData = () => {
    dispatch(
      editLocation({
        id: villageId,
        name: newVillageName,
        is_active: activeStatus,
      })
    );
  };

  const handleCloseByClearIcon = () => {
    handleClose();
    setActiveStatus("");
    setSnackBarMessage("");
    setReload(false);
    setEditVillageSnackBar(false);
    setEditVillageSnackBarMessage("");
    setEditData({});
    dispatch(resetEditlocation());
  };

  useEffect(() => {
    if (editLocationIsSuccess && editLocationApiResponse !== "") {
      setSnackBarMessage(editLocationApiResponse);
      setReload(true);
      setActiveStatus("");
      setEditData({});
      handleClose();
    } else if (
      !editLocationIsSuccess &&
      editLocationErrorMessage !== "" &&
      editLocationErrorMessage !== undefined
    ) {
      setEditVillageSnackBar(true);
      setEditVillageSnackBarMessage(editLocationErrorMessage);
      setEditVillageSnackBarType("error");
    }
    dispatch(resetEditlocation());
  }, [
    editLocationIsSuccess,
    editLocationErrorMessage,
    editLocationApiResponse,
  ]);

  const displayTextField = (
    handleChange,
    is_disabled,
    index,
    value,
    allowOnChange
  ) => {
    return (
      <TextField
        key={index}
        defaultValue={value}
        disabled={is_disabled}
        variant="outlined"
        sx={{ width: "100%", backgroundColor: "#F5F5F5", borderRadius: 2 }}
        inputProps={{
          style: {
            height: "40%",
            borderRadius: 2,
          },
        }}
        onChange={(e) => allowOnChange && handleChange(e.target.value, index)}
      />
    );
  };

  return (
    <Modal
      open={openModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{ backgroundColor: "transparent", boxShadow: "none" }}
      overlaystyle={{ backgroundColor: "transparent" }}
    >
      <Grid container sx={mainGridStyle} spacing={2}>
        <ThemeProvider theme={basicTheme}>
          <Grid item xs={11}>
            <Typography variant="h4" style={{ marginBottom: 20 }}>
              Edit Location
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <ClearIcon
              className={classes.closeBtnStyle}
              onClick={handleCloseByClearIcon}
            />
          </Grid>
          <Grid
            item
            xs={12}
            style={{ backgroundColor: "#F5F5F5", borderRadius: 10 }}
          >
            <Grid container spacing={3}>
              <Grid item xs={12} sm={2.5}>
                <Typography sx={{ marginBottom: 1 }}>State</Typography>
                {displayTextField(() => {}, true, 0, data.state.name, false)}
              </Grid>
              <Grid item xs={12} sm={2.5}>
                <Typography sx={{ marginBottom: 1 }}>District</Typography>
                {displayTextField(() => {}, true, 1, data.district.name, false)}
              </Grid>
              <Grid item xs={12} sm={2.5}>
                <Typography sx={{ marginBottom: 1 }}>Taluka</Typography>
                {displayTextField(() => {}, true, 2, data.taluka.name, false)}
              </Grid>
              <Grid item xs={12} sm={2.5}>
                <Typography sx={{ marginBottom: 1 }}>Village</Typography>
                {displayTextField(
                  handleChangeVillage,
                  false,
                  3,
                  data.village.name,
                  true
                )}
              </Grid>
              <Grid
                item
                xs={12}
                sm={1}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: 2,
                }}
              >
                <Checkbox
                  defaultChecked={data.is_active}
                  onChange={(e) => handleActiveStatusChange(e)}
                  sx={{
                    color: "#AAC1B0",
                    "&.Mui-checked": { color: "#2D6A4F" },
                  }}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "30px",
            }}
          >
            <CancelButtonStyle
              variant="contained"
              name="cancel"
              onClick={handleClose}
            >
              Cancel
            </CancelButtonStyle>
            <ButtonStyle
              variant="contained"
              name="add"
              startIcon={<CheckIcon size="large" />}
              onClick={saveData}
              disabled={newVillageName===""}
            >
              Save
            </ButtonStyle>
          </Grid>
        </ThemeProvider>
        {editVillageSnackBar && (
          <SnackBars
            open={editVillageSnackBar}
            handleClose={() => setEditVillageSnackBar(false)}
            severity={editVillageSnackBarType}
            message={editVillageSnackBarMessage}
            autoHideDuration={6000}
            vertical="bottom"
            horizontal="center"
          />
        )}
      </Grid>
    </Modal>
  );
}
