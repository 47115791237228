import React, { useState, useEffect } from "react";
import Popover from "@mui/material/Popover";
import { Grid, Button, Typography, ThemeProvider } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import ClearIcon from "@mui/icons-material/Clear";
import { getPartnerNames } from "../../features/partner/partnerSlice";
import AutoCompleteMultiDropdown from "../reusableComponents/AutoCompleteMultiDropDown";
import {
  getStatesData,
  getDistrictData,
  getTalukaData,
} from "../../features/location/locationSlice";
import { basicTheme } from "../../themes/customTheme";
import {
  resetDistrict,
  resetTaluka,
} from "../../features/location/locationSlice";
import { updateArray } from "../../utils";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import OptionalDropDown from "../reusableComponents/OptionalDropDown";

const StyledGrid = styled(Grid)({
  display: "flex",
  width: 800,
  borderRadius: 100,
});

const ClearBtn = styled(Button)({
  backgroundColor: "#FFFFFF",
  color: "#2D6A4F",
  textTransform: "none",
  borderRadius: 10,
  fontSize: "18px",
  fontWeight: "600",
  marginLeft: 20,
  marginBottom: 20,
  marginTop: 30,
  width: "80%",
  height: "60%",
  borderRadius: 10,
  borderColor: "1px solid #2D6A4F",
});
const ApplyBtn = styled(Button)({
  backgroundColor: "#2D6A4F",
  color: "#FFFFFF",
  textTransform: "none",
  borderRadius: 10,
  fontSize: "18px",
  fontWeight: "600",
  marginLeft: 20,
  marginBottom: 20,
  marginTop: 30,
  width: "80%",
  height: "60%",
  borderRadius: 10,
  borderColor: "1px solid #2D6A4F",
});

const useStyles = makeStyles(() => ({
  closeBtnStyle: {
    display: "flex",
    justifyContent: "flex-end",
    zoom: "1.5",
    alignItems: "right",
    "&:hover": {
      color: "#081C15",
    },
    cursor: "pointer",
  },
}));

export default function AppUserFilterPopOver({
  handleClose = () => {},
  setApplyFilter = () => {},
  openFilterPopOver,
  clearFilter = false,
  setClearFilter = () => {},
  selectedPartnersOptions = [],
  selectedEwOptions = [],
  setSelectedPartnersOptions = () => {},
  setSelectedEwsOptions = () =>{},
  selectedStatesOptions = [],
  setSelectedStatesOptions = () => {},
  selectedDistrictOptions = [],
  setSelectedDistrictOptions = () => {},
  selectedTalukaOptions = [],
  setSelectedTalukaOptions = () => {},
  selectedStatus,
  setSelectedStatus = () => {},
  filters = [],
  setFilters = () => {},
  userType = "",
}) {
  const dispatch = useDispatch();
  const classes = useStyles();

  const open = Boolean(openFilterPopOver);
  const id = open ? "filter-popover" : undefined;
  const [districtList, setDistrictList] = useState([]);
  const [talukaList, setTalukaList] = useState([]);
  const [statusId, setStatusId] = useState(selectedStatus);
  const [loading, setLoading] = useState(false);

  const getStatesResponse = useSelector(
    (state) => state.locationSliceReducer.statesData
  );
  const getDistrictResponse = useSelector(
    (state) => state.locationSliceReducer.districtData
  );

  const getTalukaResponse = useSelector(
    (state) => state.locationSliceReducer.talukaData
  );

  const partnerNamesData = useSelector(
    (state) => state.partnerSliceReducer.partnerNames.data
  );

  const ewNamesData = useSelector(
    (state) => state.usersSliceReducer.EwData.data
  );


  const loggedInUser = JSON.parse(window.localStorage.getItem("loggedInUser"));

  const statusData = [
    { id: 1, name: "Active" },
    { id: 2, name: "Inactive" },
  ];

  const handleChangeStatus = (event) => {
    setStatusId(event.target.value);
    setSelectedStatus(event.target.value);
  };

  const handleChangeApplyFilter = () => {
    const isActiveFilterIndex = filters.findIndex(filter => filter.type === "is_active");
  
  if (selectedStatus !== null) {
      const filterIsActive = statusId === 1 ? [true] : [false];
      
      if (isActiveFilterIndex !== -1) {
        setFilters(oldArray => {
          const updatedFilters = [...oldArray];
          updatedFilters[isActiveFilterIndex].value = filterIsActive;
          return updatedFilters;
        });
      } else {
        setFilters(oldArray => [
          ...oldArray,
          { type: "is_active", value: filterIsActive },
        ]);
      }
    }
    setApplyFilter(true);
  };

  useEffect(() => {
    if (loggedInUser.details.partner !== null)
      setSelectedPartnersOptions([loggedInUser.details.partner]);
    if (getStatesResponse[0].id === "") dispatch(getStatesData());
    if (partnerNamesData[0].id === "") {
      setLoading(true);
      dispatch(
        getPartnerNames({
          fields: ["name", "id"].toString(),
          filters: JSON.stringify([{ type: "is_active", value: [true] }]),
          internal: true,
        })
      ).then(() => setLoading(false));
    }
  }, []);

  useEffect(() => {
    if (clearFilter) {
      setSelectedStatesOptions([]);
      setSelectedDistrictOptions([]);
      setSelectedTalukaOptions([]);
      setDistrictList([]);
      setTalukaList([]);
      setStatusId(null);
      setSelectedStatus(null);
      var tempFilter = [
        { type: "state", value: [] },
        { type: "district", value: [] },
        { type: "taluka", value: [] },
        { type: "user_type", value: [userType] },
      ];
      if (loggedInUser.details.partner === null) {
        tempFilter.push({ type: "program_id", value: [] });
        setSelectedPartnersOptions([]);
      } else {
        tempFilter.push({
          type: "program_id",
          value: [loggedInUser.details.partner.id],
        });
      }
      setFilters(tempFilter);
    }
  }, [clearFilter]);

  useEffect(() => {
    if (selectedStatesOptions.length !== 0) {
      var location_ids = [];
      selectedStatesOptions.forEach((stateObj) => {
        location_ids.push(stateObj.id);
      });
      dispatch(
        getDistrictData({
          type: "state",
          location_ids: location_ids,
        })
      );
    } else {
      setSelectedDistrictOptions([]);
      setSelectedTalukaOptions([]);
      dispatch(resetDistrict());
      dispatch(resetTaluka());
    }
  }, [selectedStatesOptions]);

  useEffect(() => {
    if (selectedDistrictOptions.length !== 0) {
      var location_ids = [];
      selectedDistrictOptions.forEach((districtObj) => {
        location_ids.push(districtObj.id);
      });
      dispatch(
        getTalukaData({
          type: "district",
          location_ids: location_ids,
        })
      );
    } else {
      setSelectedTalukaOptions([]);
      dispatch(resetTaluka());
    }
  }, [selectedDistrictOptions]);

  useEffect(() => {
    if (selectedDistrictOptions.length > 0) {
      var newSelectedDistrict = [...selectedDistrictOptions];
      newSelectedDistrict.forEach((newDistrictObj) => {
        var districtPresent = getDistrictResponse.find(
          (obj) => obj.id === newDistrictObj.id
        );
        if (!districtPresent) {
          setSelectedDistrictOptions((districtsObj) =>
            districtsObj.filter((district) => district.id !== newDistrictObj.id)
          );
        }
      });
    }
    setDistrictList(getDistrictResponse);
  }, [getDistrictResponse]);

  useEffect(() => {
    if (selectedTalukaOptions.length > 0) {
      var newSelectedTaluka = [...selectedTalukaOptions];
      newSelectedTaluka.forEach((newTalukaObj) => {
        var talukaPresent = getTalukaResponse.find(
          (obj) => obj.id === newTalukaObj.id
        );
        if (!talukaPresent) {
          setSelectedTalukaOptions((talukaObj) =>
            talukaObj.filter((taluka) => taluka.id !== newTalukaObj.id)
          );
        }
      });
    }
    setTalukaList(getTalukaResponse);
  }, [getTalukaResponse]);

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={openFilterPopOver}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      onClose={handleClose}
      sx={{
        "& .MuiPopover-paper": {
          padding: 2.5,
          borderRadius: "20px",
        },
      }}
    >
      <ThemeProvider theme={basicTheme}>
        <StyledGrid container>
          <Grid item xs={11}>
            <Typography sx={{ fontSixe: "20px", color: "#999DA4" }}>
              Filter By
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <ClearIcon
              className={classes.closeBtnStyle}
              onClick={handleClose}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography sx={{ fontSixe: "18px", color: "#444444", margin: 1 }}>
              State
            </Typography>
            <AutoCompleteMultiDropdown
              width={230}
              menuList={getStatesResponse}
              selectedOptions={selectedStatesOptions}
              setSelectedOptions={setSelectedStatesOptions}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography
              sx={{
                fontSixe: "18px",
                color:
                  selectedStatesOptions.length === 0 ? "#ACADAD" : "#444444",
                margin: 1,
              }}
            >
              District
            </Typography>
            <AutoCompleteMultiDropdown
              width={230}
              menuList={districtList}
              selectedOptions={selectedDistrictOptions}
              setSelectedOptions={setSelectedDistrictOptions}
            />
          </Grid>
          {userType !== "ew" && (
            <React.Fragment>
              <Grid item xs={12} md={4}>
                <Typography
                  sx={{
                    fontSixe: "18px",
                    color:
                      selectedDistrictOptions.length === 0
                        ? "#ACADAD"
                        : "#444444",
                    margin: 1,
                  }}
                >
                  Taluka
                </Typography>
                <AutoCompleteMultiDropdown
                  width={230}
                  menuList={talukaList}
                  selectedOptions={selectedTalukaOptions}
                  setSelectedOptions={setSelectedTalukaOptions}
                />
              </Grid>
            </React.Fragment>
          )}
          <Grid item xs={12} md={4}>
            <Typography sx={{ fontSixe: "18px", color: "#444444", margin: 1 }}>
              Partner
            </Typography>
            <AutoCompleteMultiDropdown
              width={230}
              menuList={partnerNamesData}
              selectedOptions={
                partnerNamesData[0].id !== "" ? selectedPartnersOptions : []
              }
              setSelectedOptions={setSelectedPartnersOptions}
              disable={loggedInUser.details.partner !== null}
            />
          </Grid>
          {userType === "paf" && (
          <Grid item xs={12} md={4}>
            <Typography sx={{ fontSixe: "18px", color: "#444444", margin: 1 }}>
              Extension Workers
            </Typography>
            <AutoCompleteMultiDropdown
              width={230}
              menuList={ewNamesData}
              selectedOptions={
                ewNamesData[0].id !== "" ? selectedEwOptions : []
              }
              setSelectedOptions={setSelectedEwsOptions}
            />
          </Grid>
          )}
          <Grid item xs={12} md={4}>
            <Typography sx={{ fontSixe: "18px", color: "#444444", margin: 1 }}>
              Status
            </Typography>
            <OptionalDropDown
              id={statusId}
              handleChange={handleChangeStatus}
              menuList={statusData}
            />
          </Grid>
          {userType !== "ew" && <Grid item md={4}></Grid>}
          {userType === "ew" && <Grid item md={8}></Grid>}
          <Grid item xs={3}>
            <ClearBtn
              variant="contained"
              name="Clear Filter"
              onClick={() => setClearFilter(true)}
            >
              Clear All
            </ClearBtn>
          </Grid>

          <Grid item xs={3}>
            <ApplyBtn
              variant="contained"
              name="Apply Filter"
              onClick={handleChangeApplyFilter}
            >
              Apply
            </ApplyBtn>
          </Grid>
          {loading && (
            <Backdrop
              sx={{
                color: "#fff",
                position: "absolute",
                zIndex: (theme) => theme.zIndex.drawer + 1,
              }}
              open={loading}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          )}
        </StyledGrid>
      </ThemeProvider>
    </Popover>
  );
}
