import React, { useState, useEffect } from "react";
import { styled, createTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import TablePagination from "@mui/material/TablePagination";
import SnackBars from "./SnackBar";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import MultipleSelectCheckmarks from "../rolesAndPermissions/MultipleSelectCheckmarks";
import EditDelete from "./EditDelete";
import ExpendTranslations from "../advisoryManagement/expendTranslations";
const theme = createTheme({});

const PaperStyled = styled(Paper)(() => ({
  width: "100%",
  textAlign: "center",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  overflowX: "auto",
  alignItems: "center",
  "& > *:not(:last-child)": {
    marginRight: theme.spacing(2),
  },
}));

const useStyles = makeStyles((theme) => ({
  editIcon: {
    color: "#DADADA",
    cursor: "pointer",
    stroke: "#AAC1B0",
    strokeWidth: 2,
    backgroundColor: "#AAC1B0",
    borderRadius: "50%",
  },
  tableRightBorder: {
    borderWidth: 0,
    borderWidth: "0.5px",
    borderColor: "#acadad40",
    borderStyle: "solid"
  },
  sticky: { position: "sticky", left: 0, background: "white", zIndex: 2000, },
}));

export default function DesktopTable({
  headCells,
  tableResponse = [],
  totalCount,
  setOpenLocationSubModal = () => {},
  showPagination = true,
  rowsPerPage = 10,
  setOffset = () => {},
  setSortingType = () => {},
  sortingType,
  setSortBy = () => {},
  sortBy,
  setopenEditModal = () => {},
  setEditData = () => {},
  setOpenDeleteModal = () => {},
  setDeleteData = () => {},
  setPage = () => {},
  page = 0,
  handleEditPermission = () => {},
  setOpenRolesConfirmationBox = {},
  setPermissionViaAlert,
  discardAlert,
  setDiscardAlert,
  openEditModal,
  openDeleteModal,
  tableWidth=""
}) {

  const classes = useStyles();
  const [showAllTranslations, setShowAllTranslations] = React.useState(
    "see more"
  );
  const [translationsToShow, setTranslationsToShow] = React.useState(2);
  const [permission, setPermission] = React.useState(tableResponse);
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [showSnackBarMessage, setShowSnackBarMessage] = useState("");

  const handleShowAllTranslation = (lengthOfArray) => {
    if (translationsToShow === 2) {
      setTranslationsToShow(lengthOfArray);
      setShowAllTranslations("see less");
    } else {
      setTranslationsToShow(2);
      setShowAllTranslations("see more");
    }
  };

  function capitaliseFirstLetter(str) {
    if  (!str || str==="" || str == undefined){
      return str
    }
      
    let words = str.split(" ");
    for (let i = 0; i < words.length; i++) {
      if (words[i] && words[i].length>0)
        words[i] = words[i][0].toUpperCase() + words[i].substring(1);
    }
    let newStr = words.join(" ");
    return newStr
}

  useEffect(() => {
    if (totalCount === -1 || totalCount === 0) {
      setShowSnackBar(true);
      setShowSnackBarMessage("No Data Found! Try changing filters");
    } else {
      setShowSnackBar(false);
      setShowSnackBarMessage("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalCount]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleSortRequest = (cellId) => {
    const shouldSortDesc = sortBy === cellId && sortingType === "asc";
    setSortingType(shouldSortDesc ? "desc" : "asc");
    // setPage(0);
    setSortBy(cellId);
  };

  useEffect(() => {
    setOffset(page * rowsPerPage);
  }, [page]);

  return (
    <PaperStyled sx={{ marginLeft: tableWidth==="" && 10, marginRight: tableWidth==="" && 5, width: tableWidth!==""?tableWidth: "auto" , borderRadius: "12px"}}>
      {totalCount !== -1 && (
        <Grid item xs={12}>
          <TableContainer
            sx={{ maxHeight: "750px", minWidth: 100, borderRadius: "12px" }}
            id="infestation-table"
          >
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow
                  sx={{
                    "& th": { backgroundColor: "#F0F8F1" },
                    textAlign: "center",
                    width:"2000px"
                  }}
                >
                  {headCells.map((headCell) => (
                    <TableCell
                      key={headCell.key}
                      style = {headCell.sticky === true  ? { position: "sticky", left: 0, zIndex: 1000 ,borderRight: "1px solid #E6E6E6",top: 0}:{width:"1000px"}}
                      sx={{
                        fontFamily: 'Inter',
                        fontStyle: "normal",
                        color:"#5E6060",
                        fontWeight: 500,
                        fontSize: "18px",
                        lineHeight: "22px"
                        
                      }}
                      className={classes.tableRightBorder}
                    >
                      {headCell.sort ? (
                        <TableSortLabel
                          onClick={() => {
                            handleSortRequest(headCell.key);
                          }}
                          direction={
                            sortBy === headCell.key ? sortingType : "asc"
                          }
                          active={sortBy === headCell.key}
                        >
                          {headCell.label}
                        </TableSortLabel>
                      ) : (
                        headCell.label
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {tableResponse.map((row, index) => {
                  return (
                    <TableRow hover role="checkbox" key={index}>
                      {headCells.map((headCell) => {
                        return (
                          <TableCell
                            style = {headCell.sticky === true  ? { position: "sticky", left: 0, background: "white", zIndex: 800, top: 0
                          }:{}}
                            key={headCell.key}
                            sx={{
                              fontSize: "18px",
                              fontFamily: "Inter",
                              color: "#5E6060",
                              fontWeight: 400,
                              padding:"10px"
                            }}
                            className={classes.tableRightBorder}
                          >
                            {headCell.type === "checkbox" ? (
                              <Checkbox
                                disabled
                                checked={row[headCell.key] ? true : false}
                                sx={{
                                  color: "#2D6A4F",
                                  "&.Mui-checked": { color: "#2D6A4F" },
                                }}
                              />
                            ) : headCell.type === "editDelete" ? (
                              <EditDelete
                                setopenEditModal={setopenEditModal}
                                setOpenDeleteModal={setOpenDeleteModal}
                                data={row}
                                setEditData={setEditData}
                                setDeleteData={setDeleteData}
                              />
                            ) : headCell.type === "multiSelect" ? (
                              <>
                                {MultipleSelectCheckmarks(
                                  permission,
                                  handleEditPermission,
                                  index,
                                  headCell.key,
                                  tableResponse[index].modules,
                                  setOpenRolesConfirmationBox,
                                  setPermissionViaAlert,
                                  discardAlert,
                                  setDiscardAlert
                                )}
                              </>
                            ) : headCell.type === "title_translations" ? (
                              <ExpendTranslations
                                data={row.title_translations}
                                initiallyExpandedCount={2}
                              />
                            ) : headCell.type === "dosage_translations" ? (
                              <ExpendTranslations
                                data={row.dosage_translations}
                                initiallyExpandedCount={2}
                              />
                            ) : headCell.type === "price_translations" ? (
                              <ExpendTranslations
                                data={row.price_translations}
                                initiallyExpandedCount={2}
                              />
                            ) : headCell.type === "description_translations" ? (
                              <ExpendTranslations
                                data={row.description_translations}
                                initiallyExpandedCount={2}
                              />
                            ) : headCell.type === "location" ? (
                              row[headCell.key]!==null && row[headCell.key]!==undefined &&
                              row[headCell.key].length > 0 &&
                              row[headCell.key].map((state_wise, index) => {
                                return (
                                  <p key={index}>
                                    {capitaliseFirstLetter(state_wise.state.name)}{" "}
                                    <span
                                      onClick={() => {
                                        setOpenLocationSubModal(true);
                                        setEditData(row);
                                      }}
                                      style={{
                                        color: "#52B788",
                                        cursor: "pointer",
                                      }}
                                    >
                                      ( {state_wise.locations.length} )
                                    </span>
                                  </p>
                                );
                              })
                            ) : headCell.type === "array_objects" ? (
                              row["role"] !== "Government" &&
                              row[headCell.key].data.length > 0 &&
                              row[headCell.key].data.map((arr, index) => {
                                return <p key={index}>{capitaliseFirstLetter(arr.name)}</p>;
                              })
                            ) : headCell.type === "pesticide" ? (
                              <p>
                                <span>{row.code}</span><br/><span>{row.title}</span>
                              </p>
                            ) : headCell.type === "partnerPesticide" ? (
                              row.pesticides.map((list_data, ind) => {
                                return <p key={ind}>{list_data} </p>;
                              })
                            ) : headCell.type === "location" ? (
                              row[headCell.key].length > 0 &&
                              row[headCell.key].map((state_wise, index) => {
                                return (
                                  <p key={index}>
                                    {state_wise.state.name}{" "}
                                    <span
                                      onClick={() => {
                                        setOpenLocationSubModal(true);
                                        setEditData(row);
                                      }}
                                      style={{
                                        color: "#52B788",
                                        cursor: "pointer",
                                      }}
                                    >
                                      ( {state_wise.locations.length} )
                                    </span>
                                  </p>
                                );
                              })
                            ) : headCell.type === "user" ? (
                              <p>
                                <span>{row[headCell.key].name}</span>
                                <br />
                                <span>{row[headCell.key].email}</span>
                                <br />
                                <span>{row[headCell.key].phone_number}</span>
                              </p>
                            ) : headCell.type === "name_number" ? (
                              <p>
                                <span>{capitaliseFirstLetter(row[headCell.key].name)}</span>
                                <br />
                                <span>{row[headCell.key].phone_number }</span>
                              </p>
                            ) : headCell.type === "season" ? (
                              <p>
                                {headCell.capital ? capitaliseFirstLetter(row[headCell.key].name) : row[headCell.key].name}
                              </p>
                            ): headCell.type === "name_number_location" ? (
                              <p>
                              <span>{capitaliseFirstLetter(row.name)}</span>&nbsp;
                              <br/>
                              <span>{row.phone_number}</span>&nbsp;
                              <br/>
                               <span>{row.location_name}</span>
                            </p>
                            ): headCell.type === "boolean" ? (
                              row[headCell.key] == true ? (
                                <Typography sx={{ color: "#2D6A4F" }}>
                                  Active
                                </Typography>
                              ) : row[headCell.key] == false ? (
                                <Typography sx={{ color: "#EB5451" }}>
                                  Inactive
                                </Typography>
                              ) : (
                                ""
                              )
                            ) : row[headCell.key] && row[headCell.key].id ? (
                              headCell.capital ? capitaliseFirstLetter(row[headCell.key].name) : row[headCell.key].name
                            ) : (
                              <p>{headCell.capital ? capitaliseFirstLetter(row[headCell.key]): row[headCell.key]}</p>
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          {showPagination && (
            <TablePagination
              rowsPerPageOptions={[10]}
              component="div"
              count={totalCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              sx={{ display: "flex", justifyContent: "center" }}
            />
          )}
        </Grid>
      )}
      {showSnackBar && (
        <SnackBars
          open={showSnackBar}
          handleClose={() => setShowSnackBar(false)}
          severity="error"
          message={showSnackBarMessage}
          autoHideDuration={6000}
          vertical="bottom"
          horizontal="center"
        />
      )}
    </PaperStyled>
  );
}