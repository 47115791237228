import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Select,
  MenuItem,
  Grid,
  TextField,
  Box,
} from "@mui/material";

import { makeStyles } from "@mui/styles";
import { basicTheme } from "../../themes/customTheme";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { click } from "@testing-library/user-event/dist/click";

const MainGrid = styled(Grid)({
  flex: "1",
});

const useStyles = makeStyles({
  card: {
    backgroundColor: "#FFFFFF",
    borderRadius: "24px",
  },
});

export default function DashboardCard({
  title = "",
  count = "",
  lCount = "",
  cCount = "",
  menuList = [],
  value = "",
  isAppInstallation = false,
  lValue = "",
  cValue = "",
  setValue = () => {},
  forVisibility = false,
  disable = false,
  forSize = false,
  setDetailModal = ()=>{},
  clickable=false
}) {
  const classes = useStyles();

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const drawerWidth = useSelector(
    (state) => state.appBarSliceReducer.drawerWidth
  );

  return (
    <MainGrid container>
      <Grid item xs={12}>
        <Card
          className={classes.card}
          style={{ borderRadius: "24px" }}
          sx={{
            width: {
              xl: drawerWidth === 0 ? "440px" : "400px",
              lg: drawerWidth === 0 ? "380px" : "300px",
              md: drawerWidth === 0 ? "320px" : "260px",
              sm: drawerWidth === 0 ? "260px" : "200px",
            },
            height: { xl: "220px", lg: "190px", md: "160px", sm: "140px" },
            // background: "#F0F8F1",
            opacity: disable && 0.5, // Add opacity to make it look disabled
            pointerEvents: disable && "none", // Disable pointer events to prevent interaction
            cursor: clickable ? "pointer" : "auto"
          }}
          disabled={disable} // Add the disabled prop
        >
          {disable && (
            <Box
              sx={{
                // position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                // justifyContent: "center",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                borderRadius: "24px",
                zIndex: 1,
              }}
            >
              <Box
                style={{
                  color: "#2D6A4F",
                  background: "#F0F8F1",
                  borderRadius: "24px",
                  fontSize: "16px",
                  fontFamily: "Inter Medium",
                  border: "1px solid #74C69D",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "10px",
                }}
                sx={{
                  width: {
                    xl: drawerWidth === 0 ? "400px" : "360px",
                    lg: drawerWidth === 0 ? "340px" : "260px",
                    md: drawerWidth === 0 ? "280px" : "220px",
                    sm: drawerWidth === 0 ? "220px" : "180px",
                  },
                  height: { xl: "46px", lg: "40px", md: "34px", sm: "30px" },
                }}
              >
                <Typography
                  sx={{
                    fontSize: {
                      xl: "24px",
                      lg: "20px",
                      md: "18px",
                      sm: "16px",
                    },
                  }}
                >
                  {title}
                </Typography>
              </Box>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "50px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: {
                      xl: "24px",
                      lg: "20px",
                      md: "18px",
                      sm: "16px",
                    },
                    fontWeight: 700,
                    color: "#FFFFFF",
                  }}
                >
                  Coming Soon
                </Typography>
              </Box>
            </Box>
          )}
          {!disable && (
            <CardContent>
              <Box
                style={{
                  color: "#2D6A4F",
                  background: "#F0F8F1",
                  borderRadius: "24px",
                  fontSize: "16px",
                  fontFamily: "Inter Medium",
                  border: "1px solid #74C69D",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  opacity: disable && 0.5,
                  cursor: clickable ? "pointer" : "auto"
                }}
                sx={{
                  width: {
                    xl: drawerWidth === 0 ? "400px" : "360px",
                    lg: drawerWidth === 0 ? "340px" : "260px",
                    md: drawerWidth === 0 ? "280px" : "220px",
                    sm: drawerWidth === 0 ? "220px" : "180px",
                  },
                  height: { xl: "46px", lg: "40px", md: "34px", sm: "30px" },
                  pointerEvents: disable && "none",
                }}
              >
                <Typography
                  sx={{
                    fontSize: {
                      xl: "24px",
                      lg: "20px",
                      md: "18px",
                      sm: "16px",
                    },
                  }}
                >
                  {title}
                </Typography>
              </Box>
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                sx={{
                  margin: forSize ? { xl: "5px", lg: "4px", md: "0px", sm: "0px" } : { xl: "30px", lg: "28px", md: "24px", sm: "20px" },
                  pointerEvents: disable && "none", // Disable pointer events to prevent interaction,
                  cursor: clickable ? "pointer" : "auto" //do it when we are going to add click event
                }}
              >
                <Typography
                  sx={{
                    fontSize: {
                      xl: drawerWidth === 0 ? "48px" : "46px",
                      lg: drawerWidth === 0 ? "44px" : "40px",
                      md: drawerWidth === 0 ? "40px" : "36px",
                      sm: drawerWidth === 0 ? "36px" : "32px",
                    },
                    '&:hover': {
                      color: clickable  ? "#1A936F" : "#5E6060"
                    },
                    fontWeight: 700,
                    color: "#5E6060"
                  }}
                  onClick={()=>clickable ? setDetailModal(title) : ()=>{}} //do it when we are going to add click event
                >
                  {count}
                </Typography>
               {!isAppInstallation && (<Select
                  value={value}
                  onChange={handleChange}
                  style={{
                    border: "1px solid #74C69D",
                    borderRadius: "18px",
                    color: "#2D6A4F",
                    height: "34px",
                  }}
                  sx={{
                    margin: {
                      xl: drawerWidth === 0 ? "30px" : "28px",
                      lg: drawerWidth === 0 ? "28px" : "16px",
                      md: drawerWidth === 0 ? "26px" : "14px",
                      sm: drawerWidth === 0 ? "24px" : "10px",
                    },
                    width: {
                      xl: drawerWidth === 0 ? "160px" : "140px",
                      lg: drawerWidth === 0 ? "140px" : "120px",
                      md: drawerWidth === 0 ? "120px" : "110px",
                      sm: drawerWidth === 0 ? "110px" : "100px",
                    },
                    pointerEvents: disable && "none", // Disable pointer events to prevent interaction
                  }}
                >
                  {menuList.map((menu) => {
                    return (
                      <MenuItem key={menu} value={menu}>
                        {menu}
                      </MenuItem>
                    );
                  })}
                </Select>
              )}
              </Box>
              <Box   
                style={{ top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  borderRadius: "24px",
                  visibility: forVisibility? 'visible' : 'hidden',
                  zIndex: 1,}}
          
                sx={{
                  pointerEvents: disable && "none", // Disable pointer events to prevent interaction,
                  cursor: clickable ? "pointer" : "auto" //do it when we are going to add click event
                }}>
              <Typography
                  sx={{
                    alignItems : "center",
                    fontWeight: 500,
                    color: "#5E6060",
                    fontSize: {
                      xl: "20px",
                      lg: "16px",
                      md: "14px",
                      sm: "12px",
                    },
                  }}
                >
                 Lead : {lCount} , Cascade :{cCount}
                </Typography>
              </Box>
            </CardContent>
          )}
        </Card>
      </Grid>
    </MainGrid>
  );
}
