import React from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from "@mui/material";
import { makeStyles } from "@mui/styles";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;


export default function OptionalDropDown({
  text = "",
  id = "",
  handleChange = () => {},
  menuList = [],
  disabled = false,
  required = false,
  onOpen = () =>{},
}) {

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
        display:(menuList.length>0 && menuList!==undefined && menuList!==null) && (( menuList[0].name!==undefined && menuList[0].name==="") ||( menuList[0].id!==undefined && menuList[0].id==="")) && "none"
      },
    },
  };
  return (
    <FormControl
      fullWidth
      variant="outlined"
      required={required}
      sx={{
        marginBottom: 1,
        borderRadius: 2,
        width: "90%",
        backgroundColor: "#F5F5F5",
      }}
    >
      <InputLabel>{text}</InputLabel>
      <Select
        value={id}
        label={text}
        onChange={handleChange}
        onOpen = {onOpen}
        displayEmpty
        sx={{ borderRadius: 2,}}
        MenuProps={MenuProps}
        inputProps={{ "aria-label": "Without label" }}
        disabled={menuList[0].id === "" || disabled}
      >
        {menuList!==null && menuList.map((menu) => {
          return menu.id ? (
            <MenuItem key={menu.id} value={menu.id}>
              {menu.name}
            </MenuItem>
          ) : menu.code ? (
            <MenuItem key={menu.code} value={menu.code}>
              {menu.name}
            </MenuItem>
          ) : (
            <MenuItem key={menu.name} value={menu.name}>
              {menu.name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
