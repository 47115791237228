import React, { useState, useEffect, useRef } from "react";
import { styled } from "@mui/material/styles";
import { Grid, Box, Typography, ThemeProvider, Button } from "@mui/material";
import { basicTheme } from "../../themes/customTheme";
import { useDispatch, useSelector } from "react-redux";
import TopFilterBar from "../../components/reviewImages/ReviewImagesTopBar";
import PendingImages from "../../components/reviewImages/PendingImages";
import CompletedImages from "../../components/reviewImages/CompletedImages";
const MainGrid = styled(Grid)({
  flex: "1",
  backgroundColor: "white",
  // [theme.breakpoints.down("sm")]: {
  //   marginTop: "60px",
  // },
  marginTop: "80px",
});

export default function ReviewImages() {
  const dispatch = useDispatch();
  const reviewImagesTab = JSON.parse(
    window.localStorage.getItem("reviewImagesTab")
  );
  const [selectedTabValue, setSelectedTabValue] = React.useState(
    reviewImagesTab === "pending" || reviewImagesTab === "completed"
      ? reviewImagesTab
      : "pending"
  );
  const [totalPending, setTotalPending] = React.useState(0);
  const [totalCompleted, setTotalCompleted] = React.useState(0);

  const drawerWidth = useSelector(
    (state) => state.appBarSliceReducer.drawerWidth
  );

  return (
    <MainGrid
      container
      id="main"
      sx={{ marginLeft: drawerWidth + "px", width: "auto" }}
      spacing={3}
    >
      <ThemeProvider theme={basicTheme}>
        <Grid item xs={12}>
          <TopFilterBar
            setSelectedTabValue={setSelectedTabValue}
            selectedTabValue={selectedTabValue}
            totalPending={totalPending}
            totalCompleted={totalCompleted}
          />
        </Grid>
        {selectedTabValue === "pending" && (
          <PendingImages
            setTotalPending={setTotalPending}
            setTotalCompleted={setTotalCompleted}
            totalCount={
              selectedTabValue === "pending" ? totalPending : totalCompleted
            }
          />
        )}
        {selectedTabValue === "completed" && (
          <CompletedImages
            setTotalCompleted={setTotalCompleted}
            setTotalPending={setTotalPending}
            totalCount={
              selectedTabValue === "pending" ? totalPending : totalCompleted
            }
          />
        )}
      </ThemeProvider>
    </MainGrid>
  );
}
