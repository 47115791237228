import * as React from "react";
import { Grid, Typography, Modal, ThemeProvider } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ClearIcon from "@mui/icons-material/Clear";
import { basicTheme } from "../../themes/customTheme";
import DesktopTable from "../reusableComponents/DesktopTable";
const mainGridStyle = {
  position: "relative",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  // height: "80%",
  maxHeight:"80%",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 4,
  p: 4,
  overflow: "scroll"
};

const useStyles = makeStyles(() => ({
  closeBtnStyle: {
    display: "flex",
    justifyContent: "flex-end",
    zoom: "2.2",
    alignItems: "right",
    "&:hover": {
      color: "#081C15",
    },
    cursor: "pointer",
  },
}));

const initialHeadCellsState = [
  {
    key: "district",
    label: "District",
    type: "text",
  },
  {
    key: "taluka",
    label: "Taluka",
    type: "text",
  },
  {
    key: "village",
    label: "Village",
    type: "text",
  },
];

const statewiseLocationData = [
  {
    state: {id:1,name:"Maharashtra"},
    locations: [
      {
        district: { id: 1, name: "d1" },
        taluka: { id: 1, name: "t1" },
        village: { id: 1, name: "v1" },
      },
      {
        district: { id: 1, name: "d2" },
        taluka: { id: 1, name: "t2" },
        village: { id: 1, name: "v2" },
      },
      {
        district: { id: 1, name: "d3" },
        taluka: { id: 1, name: "t3" },
        village: { id: 1, name: "v3" },
      },
    ],
  },
  {
    state: {id:2,name:"Punjab"},
    locations: [
      {
        district: { id: 1, name: "d1" },
        taluka: { id: 1, name: "t1" },
        village: { id: 1, name: "v1" },
      },
      {
        district: { id: 1, name: "d2" },
        taluka: { id: 1, name: "t2" },
        village: { id: 1, name: "v2" },
      },
      {
        district: { id: 1, name: "d3" },
        taluka: { id: 1, name: "t3" },
        village: { id: 1, name: "v3" },
      },
    ],
  },
  {
    state: {id:3,name:"guju"},
    locations: [
      {
        district: { id: 1, name: "d1" },
        taluka: { id: 1, name: "t1" },
        village: { id: 1, name: "v1" },
      },
      {
        district: { id: 1, name: "d2" },
        taluka: { id: 1, name: "t2" },
        village: { id: 1, name: "v2" },
      },
      {
        district: { id: 1, name: "d3" },
        taluka: { id: 1, name: "t3" },
        village: { id: 1, name: "v3" },
      },
    ],
  },
];


export default function StateWiseLocationModal({ openModal=false, handleClose=()=>{},data=[],modifiedLocationHeadCell = ()=>{},modifiedHeadCell = false }) {

  // data is a type of example= [{state:{id,name},locations:[{district:{id,name},taluka:{id,name},village:{id,name}}]}]
  const classes = useStyles();
  const [locationData,setLocationData] = React.useState(data || [])

  return (
    <div>
      <Modal
        open={openModal}
        style={{ backgroundColor: "transparent", boxShadow: "none",overflow :" scroll" }}
        overlaystyle={{ backgroundColor: "transparent" }}
      >
        <Grid container sx={mainGridStyle} >
          <ThemeProvider theme={basicTheme}>
            <Grid item xs={11} >
              <Typography variant="h4">Location</Typography>
              <Typography sx={{ color: "#78828C" }}>
                Total States: {locationData.length}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <ClearIcon
                className={classes.closeBtnStyle}
                onClick={handleClose}
              />
            </Grid>
            {locationData.map((stateData,index) => {
              return (
                <React.Fragment key ={index}>
                  <Grid item xs={12}>
                    <Typography variant="h4">{stateData.state.name}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <DesktopTable
                      tableResponse={stateData.locations}
                      headCells={modifiedHeadCell ? modifiedLocationHeadCell : initialHeadCellsState}
                      totalCount={20}
                      showPagination = {false}
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </ThemeProvider>
        </Grid>
      </Modal>
    </div>
  );
}
