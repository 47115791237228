import React, { useState, useEffect } from "react";
import {
  Button,
  Grid,
  Typography,
  Modal,
  ThemeProvider,
  Box,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { basicTheme } from "../../themes/customTheme";
import CollectionsIcon from "@mui/icons-material/Collections";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";

const subGridStyle = {
  position: "relative",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  //   p: 4,
  borderRadius: 2,
  width: "70%",
  maxHeight: "92%",
  overflow: "hidden",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-around",
  //   paddingLeft: 2,
};

const ButtonStyle = styled(Button)({
  backgroundColor: "#2D6A4F",
  color: "white",
  textTransform: "none",
  borderRadius: "12px",
  fontWeight: "600",
});

const PreviousButtonStyle = styled(Button)({
  backgroundColor: "white",
  color: "#2D6A4F",
  textTransform: "none",
  borderRadius: "12px",
  fontWeight: "600",
});

const StyledTitled = styled(Typography)({
  fontWeight: 500,
  fontStretch: "normal",
  fontStyle: "Inter",
  color: "#202124",
  lineHeight: "40px",
  letterSpacing: "normal",
  color: "#5E6060",
});

const StyledSubTitled = styled(Grid)({
  fontWeight: 500,
  fontStretch: "normal",
  fontStyle: "Inter",
  lineHeight: "60px",
  letterSpacing: "normal",
  color: "#78828C",
  minWidth: "250px",
});

const useStyles = makeStyles(() => ({
  close: {
    display: "flex",
    justifyContent: "flex-end",
    zoom: "2.2",
    alignItems: "right",
  },
  iconColor: {
    color: "grey",
    cursor: "pointer",
  },
  carausel: {
    padding: "0px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  subContCarausel: {
    backgroundColor: "white",
  },
  imgNumber: {
    backgroundColor: "grey",
    borderRadius: "16px",
    margin: "10px",
  },
}));

export default function ReviewCompleteImageDialog({
  openModal,
  handleClose = () => {},
  data = {},
}) {
  const classes = useStyles();
  const [index, setIndex] = useState(0);
  const totalNumberOfImages = data.images.length;
  const [imageLoader, setImageLoader] = useState(true);

  const imageLoaded = () => {
    setImageLoader(false);
  };

  const getNextImage = () => {
    setIndex((prevIndex) => prevIndex + 1);
    setImageLoader(true);
  };

  const getPreviousImage = () => {
    setIndex((prevIndex) => prevIndex - 1);
    setImageLoader(true);
  };

  const TypographyModified = (key, value) => {
    return (
      <Box
        sx={{
          flex: "1",
          justifyContent: "flex-start",
          justify: "flex-start",
          alignItems: "center",
          display: "flex",
          marginTop: {
            xl: "10px",
            lg: "7px",
            md: "4px",
            sm: "2px",
          },
        }}
      >
        <Typography
          sx={{
            fontSize: { xl: "20px", lg: "18px", md: "16px" },
            color: "#78828C",
            // marginTop: { xl: "20px", lg: "16px", md: "14px" },
            marginLeft: { xl: "12px", lg: "10px", md: "8px" },
            // marginBottom: { xl: "20px", lg: "16px", md: "12px" },
          }}
        >
          {key}:
        </Typography>
        <Typography
          sx={{
            fontSize: { xl: "20px", lg: "18px", md: "16px" },
            color: "#5E6060",
            // marginTop: { xl: "20px", lg: "16px", md: "14px" },
            marginLeft: { xl: "12px", lg: "10px", md: "8px" },
            // marginBottom: { xl: "20px", lg: "16px", md: "12px" },
          }}
        >
          {" "}
          {value}
        </Typography>
      </Box>
    );
  };

  return (
    <Modal open={openModal}>
      <Grid container sx={subGridStyle} spacing={1}>
        <ThemeProvider theme={basicTheme}>
          <Box>
            <IconButton
              style={{ position: "absolute", top: 0, right: "0" }}
              onClick={() => handleClose(false)}
            >
              <HighlightOffIcon />
            </IconButton>
          </Box>
          <Grid item xs={12} className={classes.carausel}>
            <Grid container className={classes.subContCarausel}>
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={6}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <ImageList
                  sx={{
                    transform: "translateZ(0)",
                    aspectRatio: 1,
                    height: {
                      xl: "730px",
                      lg: "600px",
                      md: "500px",
                      sm: "350px",
                    },
                    width: {
                      xl: "566px",
                      lg: "460px",
                      md: "320px",
                      sm: "200px",
                    },
                    objectFit: "contain",
                    borderRadius: "24px",
                  }}
                >
                  <ImageListItem
                    sx={{
                      aspectRatio: 1,
                      height: {
                        xl: "730px",
                        lg: "600px",
                        md: "500px",
                        sm: "350px",
                      },
                      width: {
                        xl: "566px",
                        lg: "460px",
                        md: "320px",
                        sm: "200px",
                      },
                      objectFit: "contain",
                      borderRadius: "24px",
                    }}
                  >
                    {imageLoader ? (
                      <Backdrop
                        sx={{
                          color: "#fff",
                          position: "absolute",
                          zIndex: (theme) => theme.zIndex.drawer + 1,
                        }}
                        open={imageLoader}
                      >
                        <CircularProgress color="inherit" />
                      </Backdrop>
                    ) : (
                      ""
                    )}
                    <img
                      loading="lazy"
                      onLoad={imageLoaded}
                      style={{ display: imageLoader ? "none" : "flex" }}
                      src={
                        data.images[index] && data.images[index].pre_signed_url
                      }
                    />
                    <ImageListItemBar
                      sx={{
                        background:
                          "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " +
                          "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
                      }}
                      position="top"
                      actionIcon={
                        <Box
                          className={classes.imgNumber}
                          sx={{
                            flex: "1",
                            justifyContent: "center",
                            justify: "center",
                            alignItems: "center",
                            display: "flex",
                            marginTop: "10px",
                            width: { xl: "65px", lg: "56px", md: "48px" },
                            height: { xl: "32px", lg: "27px", md: "22px" },
                          }}
                        >
                          <IconButton sx={{ color: "white" }}>
                            <CollectionsIcon
                              sx={{
                                width: { xl: "24px", lg: "20px", md: "16px" },
                                height: {
                                  xl: "24px",
                                  lg: "20px",
                                  md: "16px",
                                },
                              }}
                            />
                            <Typography
                              sx={{
                                marginLeft: {
                                  xl: "6px",
                                  lg: "4px",
                                  md: "2px",
                                },
                              }}
                            >
                              {data.images.length}
                            </Typography>
                          </IconButton>
                        </Box>
                      }
                      actionPosition="right"
                    />
                  </ImageListItem>
                </ImageList>
              </Grid>
              <Grid item xs={12} md={6} lg={6} sx={{ paddingLeft: "20px" }}>
                <Grid container>
                  <Grid item xs={12}>
                    {/* <StyledTitled
                      sx={{
                        fontSize: {
                          xl: "36px",
                          lg: "28px",
                          md: "18px",
                          sm: "14px",
                        },
                        marginTop: {
                          xl: "50px",
                          lg: "30px",
                          md: "15px",
                          sm: "10px",
                        },
                      }}
                    >
                      PROGRAM: {data.partner.toUpperCase()}
                    </StyledTitled> */}
                  </Grid>
                  <Grid item xs={12}>
                    <StyledSubTitled
                      sx={{
                        fontSize: {
                          xl: "24px",
                          lg: "16px",
                          md: "12px",
                          sm: "8px",
                        },
                        marginTop: {
                          xl: "14px",
                          lg: "8px",
                          md: "4px",
                          sm: "2px",
                        },
                      }}
                    >
                      {data.images.length} images captured | {data.time_stamp}
                    </StyledSubTitled>
                  </Grid>
                  {data.images.map((imageData, ind) => (
                    <Grid item xs={12} key={ind}>
                      <Divider
                        sx={{
                          marginTop: { xl: "20px", lg: "12px", md: "7px" },
                        }}
                      />
                      {TypographyModified(
                        "Image " + (ind + 1),
                        imageData.is_trap_image === true
                          ? "Trap Image"
                          : "Not a trap image"
                      )}
                      {TypographyModified(
                        "Pink Bollworm Count",
                        imageData.pbw_count
                      )}
                      {TypographyModified(
                        "American Bollworm Count",
                        imageData.abw_count
                      )}
                      {TypographyModified(
                        "Additional Remarks",
                        imageData.comment ? imageData.comment : "No Remarks"
                      )}
                    </Grid>
                  ))}
                  <Grid item xs={12}>
                    <Divider
                      sx={{
                        marginTop: { xl: "20px", lg: "12px", md: "7px" },
                      }}
                    />
                    <Box
                      display="flex"
                      // justifyContent="space-between"
                      sx={{
                        marginTop: {
                          xl: "36px",
                          lg: "26px",
                          md: "16px",
                          sm: "10px",
                        },
                      }}
                    >
                      <PreviousButtonStyle
                        variant="contained"
                        name="previous"
                        sx={{
                          fontSize: {
                            xl: "16px",
                            lg: "12px",
                            md: "8px",
                            sm: "6px",
                          },
                          height: {
                            xl: "48px",
                            lg: "40px",
                            md: "30px",
                            sm: "32px",
                          },
                          width: {
                            xl: "156px",
                            lg: "130px",
                            md: "110px",
                            sm: "90px",
                          },
                          marginRight: {
                            xl: "36px",
                            lg: "26px",
                            md: "16px",
                            sm: "10px",
                          },
                          // bottom: {
                          //   xl: "40px",
                          //   lg: "30px",
                          //   md: "20px",
                          //   sm: "10px",
                          // },
                          // position: "absolute",
                        }}
                        disabled={index === 0}
                        onClick={() => getPreviousImage()}
                        startIcon={
                          <ArrowBackIosIcon
                            size="large"
                            className={classes.iconStyle}
                          />
                        }
                      >
                        Previous
                      </PreviousButtonStyle>
                      <ButtonStyle
                        sx={{
                          fontSize: {
                            xl: "16px",
                            lg: "12px",
                            md: "8px",
                            sm: "6px",
                          },
                          height: {
                            xl: "48px",
                            lg: "40px",
                            md: "30px",
                            sm: "32px",
                          },
                          width: {
                            xl: "156px",
                            lg: "130px",
                            md: "110px",
                            sm: "90px",
                          },
                        }}
                        variant="contained"
                        name="next/submit"
                        onClick={() => getNextImage()}
                        disabled={index === totalNumberOfImages - 1}
                        endIcon={
                          <KeyboardArrowRightIcon
                            size="large"
                            className={classes.iconStyle}
                          />
                        }
                      >
                        Next Image
                      </ButtonStyle>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ThemeProvider>
      </Grid>
    </Modal>
  );
}
