import React, { useState, useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import _without from "lodash/without";
import AutoCompleteDropdown from "../reusableComponents/AutoCompleteDropDown";
import { useDispatch, useSelector } from "react-redux";
import { getStatesData } from "../../features/location/locationSlice";
import { getDistrictList } from "../../utils/index";
import AutoCompleteMultiDropdown from "../reusableComponents/AutoCompleteMultiDropDown";
export default function EWLocationDropDown({
  partnerLocations = [],
  disable = false,
  selectedStateOptions = null,
  setSelectedStateOptions = () => {},
  selectedDistrictOptions = [],
  setSelectedDistrictOptions = () => {},
}) {
  const dispatch = useDispatch();

  const [stateList, setStateList] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const getStatesResponse = useSelector(
    (state) => state.locationSliceReducer.statesData
  );

  useEffect(() => {
    dispatch(getStatesData());
    if (
      partnerLocations.state_ids !== undefined &&
      partnerLocations.state_ids.length > 0 &&
      getStatesResponse[0].id !== ""
    ) {
      let filtered = getStatesResponse.filter((item) =>
        partnerLocations.state_ids.includes(item.id)
      );
      setStateList(filtered);
      setDistrictList([]);
    }
    if (
      partnerLocations.district_ids !== undefined &&
      partnerLocations.district_ids.length > 0 &&
      selectedStateOptions !== null
    ) {
      getFilteredDistricts();
    }
  }, [partnerLocations]);

  const getFilteredDistricts = () => {
    getDistrictList(selectedStateOptions.id).then((data) => {
      let filtered = [];
      if (
        partnerLocations.district_ids !== undefined &&
        partnerLocations.district_ids.length > 0 &&
        data.length > 0
      ) {
        filtered = data.filter((item) =>
          partnerLocations.district_ids.includes(item.id)
        );
      }
      setDistrictList(filtered);
    });
  };

  const onHighlightStateChange = () => {
    setSelectedDistrictOptions([]);
    setDistrictList([]);
  };

  useEffect(() => {
    if (selectedStateOptions !== null) {
      getFilteredDistricts();
    }
  }, [selectedStateOptions]);

  return (
    <Grid
      item
      xs={12}
      sx={{
        border: 1,
        borderColor: "#D3D3D3",
        borderRadius: "10px",
        margin: 2,
      }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Typography
            sx={{
              marginBottom: 1,
              marginLeft: 2,
              color: stateList.length === 0 ? "#ACADAD" : "#444444",
            }}
          >
            State
          </Typography>
          <AutoCompleteDropdown
            width="auto"
            menuList={stateList}
            selectedOptions={selectedStateOptions}
            setSelectedOptions={setSelectedStateOptions}
            disable={stateList.length === 0 || true}
            label="Select State"
            onHighlightChange={onHighlightStateChange}
            required = {true}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography
            sx={{
              marginBottom: 1,
              marginLeft: 2,
              color: districtList.length === 0 ? "#ACADAD" : "#444444",
            }}
          >
            District
          </Typography>
          <AutoCompleteMultiDropdown
            menuList={districtList}
            selectedOptions={selectedDistrictOptions}
            setSelectedOptions={setSelectedDistrictOptions}
            disable={districtList.length === 0}
            label="Select District"
            required = {true}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
