import React, { useState, useEffect } from "react";
import Popover from "@mui/material/Popover";
import { Grid, Button, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import { getStatesData } from "../../features/location/locationSlice";
import MultiSelectDropDownComma from "../reusableComponents/MultiSelectDropDownComma";
import ClearIcon from "@mui/icons-material/Clear";
import { getPartnerNames } from "../../features/partner/partnerSlice";
import AutoCompleteMultiDropdown from "../reusableComponents/AutoCompleteMultiDropDown";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import OptionalDropDown from "../reusableComponents/OptionalDropDown";

const StyledGrid = styled(Grid)({
  display: "flex",
  width: "800px",
});

const ClearBtn = styled(Button)({
  backgroundColor: "#FFFFFF",
  color: "#2D6A4F",
  textTransform: "none",
  borderRadius: 10,
  fontSize: "18px",
  fontWeight: "600",
  marginLeft: 20,
  marginBottom: 20,
  marginTop: 30,
  width: "80%",
  height: "60%",
  borderRadius: 10,
  borderColor: "1px solid #2D6A4F",
});
const ApplyBtn = styled(Button)({
  backgroundColor: "#2D6A4F",
  color: "#FFFFFF",
  textTransform: "none",
  borderRadius: 10,
  fontSize: "18px",
  fontWeight: "600",
  marginLeft: 20,
  marginBottom: 20,
  marginTop: 30,
  width: "80%",
  height: "60%",
  borderRadius: 10,
  borderColor: "1px solid #2D6A4F",
});

const useStyles = makeStyles(() => ({
  closeBtnStyle: {
    display: "flex",
    justifyContent: "flex-end",
    zoom: "1.5",
    alignItems: "right",
    "&:hover": {
      color: "#081C15",
    },
    cursor: "pointer",
    marginLeft: 8,
  },
}));

export default function PartnerFilterPopOver({
  handleClose = () => {},
  setApplyFilter = () => {},
  openFilterPopOver,
  filters = [],
  setFilters = () => {},
  selectedStates = [],
  setSelectedStates = () => {},
  selectedPartners = [],
  setSelectedPartners = () => {},
  selectedProtocols = [],
  setSelectedProtocols = () => {},
  selectedStatus = null,
  setSelectedStatus = () => {},
  clearFilter = false,
  setClearFilter = () => {},
}) {
  const dispatch = useDispatch();
  const classes = useStyles();

  const open = Boolean(openFilterPopOver);
  const id = open ? "filter-popover" : undefined;
  const [protocolIds, setProtocolIds] = useState(selectedProtocols);
  const [protocolNames, setProtocolNames] = useState([]);
  const [statusId, setStatusId] = useState(selectedStatus);
  const [loading, setLoading] = useState(false);

  const getStatesResponse = useSelector(
    (state) => state.locationSliceReducer.statesData
  );

  const partnerNamesData = useSelector(
    (state) => state.partnerSliceReducer.partnerNames.data
  );

  const loggedInUser = JSON.parse(window.localStorage.getItem("loggedInUser"));

  const protocolData = [
    { id: 1, name: "Weekly Monitoring Protocol" },
    { id: 2, name: "CICR" },
  ];

  const statusData = [
    { id: 1, name: "Active" },
    { id: 2, name: "Inactive" },
  ];

  function searchType(nameKey, myArray) {
    for (let i = 0; i < myArray.length; i++) {
      if (myArray[i].type === nameKey) {
        return true;
      }
    }
  }

  const updateArray = (array, type, newValue, remove = false, setArray) => {
    const newArray = [...array];
    var objIndex = newArray.findIndex((obj) => obj.type == type);
    if (remove) {
      setArray((rem) => rem.filter((_, ind) => ind !== objIndex));
      return;
    }

    newArray[objIndex].value = newValue;
    return newArray;
  };

  useEffect(() => {
    if (loggedInUser.details.partner !== null)
      setSelectedPartners([loggedInUser.details.partner]);
    if (getStatesResponse[0].id === "") dispatch(getStatesData());
    if (partnerNamesData[0].id === "") {
      setLoading(true);
      dispatch(
        getPartnerNames({
          fields: ["name", "id"].toString(),
          filters: JSON.stringify([{ type: "is_active", value: [true] }]),
          internal:true
        })
      ).then(() => setLoading(false));
    }
  }, []);

  const handleChangeStatus = (event) => {
    setStatusId(event.target.value);
    setSelectedStatus(event.target.value);
  };

  const handleChangeApplyFilter = () => {
    var tempLocationIds = [];
    selectedStates.forEach((location) => {
      tempLocationIds.push(location.id);
    });
    var tempPartnerNames = [];
    selectedPartners.forEach((partner) => {
      tempPartnerNames.push(partner.name);
    });
    var newProtocol = [...protocolIds];
    setSelectedProtocols(newProtocol);
    setFilters([
      { type: "protocol", value: protocolNames },
      { type: "location", value: tempLocationIds },
      { type: "name", value: tempPartnerNames },
    ]);
    if(selectedStatus !== null) {
      var filterIsActive = statusId==1? [true] : [false] ;
      setFilters((oldArray) => [
        ...oldArray,
        { type: "is_active", value: filterIsActive },
      ]);
    }
    setApplyFilter(true);
  };

  useEffect(() => {
    if (clearFilter) {
      setProtocolIds([]);
      var tempFilter = [
        { type: "protocol", value: [] },
        { type: "location", value: [] },
      ];
      if (loggedInUser.details.partner === null) {
        tempFilter.push({ type: "name", value: [] });
        setSelectedPartners([]);
      } else {
        tempFilter.push({
          type: "name",
          value: [loggedInUser.details.partner.name],
        });
      }
      setFilters(tempFilter);
      setSelectedProtocols([]);
      setSelectedStates([]);
      setStatusId(null);
      setSelectedStatus(null);
    }
  }, [clearFilter]);

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={openFilterPopOver}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      sx={{
        "& .MuiPopover-paper": {
          padding: 2.5,
          borderRadius: "20px",
        },
      }}
      onClose={handleClose}
    >
      <StyledGrid container>
        <Grid item xs={11} sx={{ color: "#78828C" }}>
          <Typography sx={{ fontSixe: "20px", color: "#999DA4" }}>
            Filter By
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <ClearIcon className={classes.closeBtnStyle} onClick={handleClose} />
        </Grid>
        <Grid container item sm={12} sx={{ color: "#5E6060" }}></Grid>
        <Grid item xs={12} md={4}>
          <Typography sx={{ fontSixe: "18px", color: "#444444", margin: 1 }}>
            State
          </Typography>
          <AutoCompleteMultiDropdown
            width={230}
            menuList={getStatesResponse}
            selectedOptions={selectedStates}
            setSelectedOptions={setSelectedStates}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography sx={{ fontSixe: "18px", color: "#444444", margin: 1 }}>
            Partner
          </Typography>
          <AutoCompleteMultiDropdown
            width={230}
            menuList={partnerNamesData}
            selectedOptions={
              partnerNamesData[0].id !== "" ? selectedPartners : []
            }
            setSelectedOptions={setSelectedPartners}
            disable={loggedInUser.details.partner !== null}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography sx={{ fontSixe: "18px", color: "#444444", margin: 1 }}>
            Protocol
          </Typography>
          <MultiSelectDropDownComma
            selectedValues={protocolIds}
            setSelectedValues={setProtocolIds}
            menuList={protocolData}
            selectedValuesNames={protocolNames}
            setSelectedValueNames={setProtocolNames}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography sx={{ fontSixe: "18px", color: "#444444", margin: 1 }}>
            Status
          </Typography>
          <OptionalDropDown
            id={statusId}
            handleChange={handleChangeStatus}
            menuList={statusData}
          />
        </Grid>
        <Grid item md={8}></Grid>
        <Grid item xs={3}>
          <ClearBtn
            variant="contained"
            name="Clear Filter"
            onClick={() => setClearFilter(true)}
          >
            Clear All
          </ClearBtn>
        </Grid>

        <Grid item xs={3}>
          <ApplyBtn
            variant="contained"
            name="Apply Filter"
            onClick={handleChangeApplyFilter}
          >
            Apply
          </ApplyBtn>
        </Grid>
        {loading && (
          <Backdrop
            sx={{
              color: "#fff",
              position: "absolute",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
      </StyledGrid>
    </Popover>
  );
}
