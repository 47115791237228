import React, { useEffect, useState } from "react";
import {
  MenuItem,
  FormControl,
  Select,
  Box,
  Checkbox,
  Button,
  ListItemText,
} from "@mui/material";
import _without from "lodash/without";
import { styled } from "@mui/material/styles";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 300,
    },
  },
};

const StyledButton = styled(Button)({
  backgroundColor: "#F0F8F1",
  color: "#2D6A4F",
  "&:hover": {
    backgroundColor: "#F0F8F1",
  },
});

export default function MultiSelectDropDownComma({
  selectedValues=()=>{},
  setSelectedValues=()=>{},
  menuList=[],
  selectedValuesNames = [],
  setSelectedValueNames=()=>{},
}) {

  

  const [openMultiSelect, setOpenMultiSelect] = useState(false);

  const handleChange = (event) => {
    const value = event.target.value;
    if (!selectedValues.includes(value[value.length - 1])) {
      setSelectedValues((prev) => [...prev, value[value.length - 1]]);
    } else {
      setSelectedValues((prev) => _without(prev, value[value.length - 1]));
    }
  };

  useEffect(() => {
    var selectedNames = [];
    menuList.forEach((item) => {
      if (selectedValues.includes(item.id)) {
        selectedNames.push(item.name);
      }
    });
    setSelectedValueNames(selectedNames);
  }, [selectedValues]);

  return (
    <FormControl
      sx={{ marginBottom: 1, width: "90%", borderRadius: 2, borderColor: "black" }}
    >
      <Select
        multiple
        value={selectedValuesNames}
        disabled={menuList[0].id === ""}
        onChange={handleChange}
        open={openMultiSelect}
        onOpen={() => setOpenMultiSelect(true)}
        onClose={() => setOpenMultiSelect(false)}
        sx={{ borderRadius: 2, borderColor: "black",backgroundColor: "#F5F5F5" }}
        renderValue={(selected) => selected.join(", ")}
        MenuProps={MenuProps}
        inputProps={{ "aria-label": "Without label" }}
      >
        {menuList.map((menu) => {
          return (
            <MenuItem key={menu.id} value={menu.id}>
              <Checkbox
                checked={selectedValues.includes(menu.id)}
                color="success"
              />
              <ListItemText primary={menu.name} />
            </MenuItem>
          );
        })}
        <Box sx={{ textAlign: "center" }}>
          <StyledButton
            onClick={() => setOpenMultiSelect(false)}
            fullWidth
            variant="outlined"
          >
            Done
          </StyledButton>
        </Box>
      </Select>
    </FormControl>
  );
}
