import React, { useEffect, useState } from "react";
import {Checkbox, TextField, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import _without from "lodash/without";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";

const CustomAutocomplete = styled(Autocomplete)({
  '& .MuiInputBase-root.Mui-disabled': {
    backgroundColor: '#f0f0f0',
  },
});


export default function AutoCompleteMultiDropdown({
  width = 250,
  menuList = [],
  selectedOptions = [],
  setSelectedOptions = () => {},
  disable = false,
  label = "",
  onHighlightChange	= ()=>{},
  required = false,
  onOpen = ()=>{}
}) {

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" color="success" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const handleChange = (event, value) => {
    setSelectedOptions(value);
    onHighlightChange(value);
    var newSelected = [];
    value.forEach((option) => {
      newSelected.push(option.code);
    });
  };

  return (
      <CustomAutocomplete
        onChange={(e, value) => {
          handleChange(e, value);
        }}
        isOptionEqualToValue={(option, value) => option.code === value.code}
        value={selectedOptions}
        onOpen = {onOpen}
        multiple
        options={menuList}
        disabled={disable}
        disableCloseOnSelect
        getOptionLabel={(option) => option.code}
        renderOption={(props, option, { selected }) => (
          <React.Fragment key={option.code}>
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.code}
            </li>
            <Divider />
          </React.Fragment>
        )}
        renderInput={(params) => <TextField {...params} label={label} required = {required}/>}
        sx={{
          ".MuiOutlinedInput-root": {
            marginBottom: 1,
            borderRadius: 2,
            backgroundColor: "#F5F5F5",
          },
          width: "90%",
        }}
      ></CustomAutocomplete>
  );
}
