import React, { useState, useEffect } from "react";
import Popover from "@mui/material/Popover";
import { Grid, Button, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import ClearIcon from "@mui/icons-material/Clear";
import { getPartnerNames } from "../../features/partner/partnerSlice";
import {
  updateArray,
  getAllDistrictList,
  getAllTalukaList,
  getAllStateList,
} from "../../utils";
import AutoCompleteMultiDropdown from "../reusableComponents/AutoCompleteMultiDropDown";
import OptionalDropDown from "../reusableComponents/OptionalDropDown";

const StyledGrid = styled(Grid)({
  display: "flex",
  width: "600px",
});

const ClearBtn = styled(Button)({
  backgroundColor: "#FFFFFF",
  color: "#2D6A4F",
  textTransform: "none",
  borderRadius: 10,
  fontSize: "18px",
  fontWeight: "600",
  marginLeft: 20,
  marginBottom: 20,
  marginTop: 30,
  width: "80%",
  height: "60%",
  borderRadius: 10,
  borderColor: "1px solid #2D6A4F",
});
const ApplyBtn = styled(Button)({
  backgroundColor: "#2D6A4F",
  color: "#FFFFFF",
  textTransform: "none",
  borderRadius: 10,
  fontSize: "18px",
  fontWeight: "600",
  marginLeft: 20,
  marginBottom: 20,
  marginTop: 30,
  width: "80%",
  height: "60%",
  borderRadius: 10,
  borderColor: "1px solid #2D6A4F",
});
const useStyles = makeStyles(() => ({
  closeBtnStyle: {
    display: "flex",
    justifyContent: "flex-end",
    zoom: "1.5",
    alignItems: "right",
    "&:hover": {
      color: "#081C15",
    },
    cursor: "pointer",
    marginLeft: 8,
  },
}));

export default function SupervisorFilterPopOver({
  handleClose = () => {},
  setApplyFilter = () => {},
  openFilterPopOver,
  filters = [],
  setFilters = () => {},
  selectedPartners = [],
  setSelectedPartners = () => {},
  selectedStatus,
  setSelectedStatus = () => {},
  clearFilter = false,
  setClearFilter = () => {},
  role = "",
  selectedLocationOptions = [],
  setSelectedLocationOptions = () => {},
}) {
  const dispatch = useDispatch();
  const classes = useStyles();

  const open = Boolean(openFilterPopOver);
  const id = open ? "filter-popover" : undefined;
  const [menuList, setMenuList] = useState([]);
  const [statusId, setStatusId] = React.useState(selectedStatus);
  const [loading, setLoading] = useState(false);
  const loggedInUser = JSON.parse(window.localStorage.getItem("loggedInUser"));

  const partnerNamesData = useSelector(
    (state) => state.partnerSliceReducer.partnerNames.data
  );

  const statusData = [
    { id: 1, name: "Active" },
    { id: 2, name: "Inactive" },
  ];

  function searchType(nameKey, myArray) {
    for (let i = 0; i < myArray.length; i++) {
      if (myArray[i].type === nameKey) {
        return true;
      }
    }
  }

  useEffect(() => {
    if(loggedInUser.details.partner!==null)
      setSelectedPartners([loggedInUser.details.partner])
    if(partnerNamesData[0].id===""){
    setLoading(true);
    dispatch(
      getPartnerNames({
        fields: ["name", "id"].toString(),
        filters: JSON.stringify([{ type: "is_active", value: [true] }]),
        internal:true
      })
    ).then(() => setLoading(false));
    }
    if (role === "State Head") {
      getAllStateList().then((states) => setMenuList(states));
    } else if (role === "District Head") {
      getAllDistrictList().then((districts) => {
        setMenuList(districts);
      });
    } else if (role === "Taluka Head") {
      getAllTalukaList().then((taluka) => {
        setMenuList(taluka);
      });
    }
  }, []);

  const handleChangeStatus = (event) => {
    setStatusId(event.target.value);
    setSelectedStatus(event.target.value);
  };

  const handleChangePartner = () => {
    if (selectedPartners.length !== 0) {
      var tempPartnerNames = [];
      selectedPartners.forEach((partner) => {
        tempPartnerNames.push(partner.id);
      });
      if (!searchType("partner", filters)) {
        setFilters((oldArray) => [
          ...oldArray,
          { type: "partner", value: tempPartnerNames },
        ]);
      } else {
        var newArray = updateArray(filters, "partner", tempPartnerNames);
        setFilters(newArray);
      }
    } else if (selectedPartners.length === 0 && searchType("partner", filters)) {
      updateArray(filters, "partner", selectedPartners, true, setFilters);
    }
  };

  const handleChangeApplyFilter = () => {
    handleChangePartner();
    const isActiveFilterIndex = filters.findIndex(filter => filter.type === "is_active");
  
    if (selectedStatus !== null) {
      const filterIsActive = statusId === 1 ? [true] : [false];
      
      if (isActiveFilterIndex !== -1) {
        setFilters(oldArray => {
          const updatedFilters = [...oldArray];
          updatedFilters[isActiveFilterIndex].value = filterIsActive;
          return updatedFilters;
        });
      } else {
        setFilters(oldArray => [
          ...oldArray,
          { type: "is_active", value: filterIsActive },
        ]);
      }
    }
    setApplyFilter(true);
  };

  useEffect(() => {
    if (clearFilter) {
      setSelectedLocationOptions([]);
      // role ni hatana h filters s , only clear other filters
      var tempFilter = [{type:"state",value:[]},{type:"district",value:[]},{type:"taluka",value:[]},{type:"role",value:[role]}]
      if(loggedInUser.details.partner===null)
      {
        tempFilter.push({type:"partner",value:[]})
        setSelectedPartners([]);
      }
      else{
        tempFilter.push({type:"partner",value:[loggedInUser.details.partner.id]})
      }
      setFilters(tempFilter)
      setStatusId(null);
      setSelectedStatus(null);
    }
  }, [clearFilter]);

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={openFilterPopOver}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      sx={{
        "& .MuiPopover-paper": {
          padding: 2.5,
          borderRadius: "20px",
        },
      }}
      onClose={handleClose}
    >
      <StyledGrid container>
        <Grid item xs={11}>
          <Typography xs={11} sx={{ color: "#78828C" }}>
            Filter By
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <ClearIcon className={classes.closeBtnStyle} onClick={handleClose} />
        </Grid>
        <Grid item xs={12}></Grid>
        <Grid item xs={12} md={4}>
          <Typography sx={{ fontSixe: "18px", color: "#444444", margin: 1 }}>
            {role === "State Head"
              ? "State"
              : role === "District Head"
              ? "District"
              : role === "Taluka Head"
              ? "Taluka"
              : null}
          </Typography>
          <AutoCompleteMultiDropdown
            width={230}
            menuList={menuList}
            selectedOptions={selectedLocationOptions}
            setSelectedOptions={setSelectedLocationOptions}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography sx={{ fontSixe: "18px", color: "#444444", margin: 1 }}>
            Partner
          </Typography>
          <AutoCompleteMultiDropdown
            width={230}
            menuList={partnerNamesData}
            selectedOptions={partnerNamesData[0].id!==""?selectedPartners:[]}
            setSelectedOptions={setSelectedPartners}
            disable = {loggedInUser.details.partner!==null}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography sx={{ fontSixe: "18px", color: "#444444", margin: 1 }}>
            Status
          </Typography>
          <OptionalDropDown
            id={statusId}
            handleChange={handleChangeStatus}
            menuList={statusData}
          />
        </Grid>

        <Grid item xs={3}>
          <ClearBtn
            variant="contained"
            name="Clear Filter"
            onClick={() => setClearFilter(true)}
          >
            Clear All
          </ClearBtn>
        </Grid>

        <Grid item xs={3}>
          <ApplyBtn
            variant="contained"
            name="Apply Filter"
            onClick={handleChangeApplyFilter}
          >
            Apply
          </ApplyBtn>
        </Grid>
        {loading && (
          <Backdrop
            sx={{
              color: "#fff",
              position: 'absolute',
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
      </StyledGrid>
    </Popover>
  );
}
