// import * as React from "react";
import React, { useState, useEffect } from "react";
import {
    Button,
    Grid,
    Typography,
    Modal,
    ThemeProvider,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { basicTheme } from "../../themes/customTheme";
import LanguageAndTranslation from "./languageAndTranslation";
import { useDispatch, useSelector } from "react-redux";
import { getPestData, getCropData, getFarmingTypeData, addPesticide, resetAddPesticide } from "../../features/advisoryManagement/advisoryManagementSlice";
import SnackBars from "../reusableComponents/SnackBar";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import BasicTextfield from "../reusableComponents/BasicTextfield";
import OptionalDropDown from "../reusableComponents/OptionalDropDown";

const subGridStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 2,
    width: "65%",
    maxHeight: "80%",
    overflow: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    paddingLeft: 5,
  };

const useStyles = makeStyles(() => ({
    closeBtnStyle: {
        display: "flex",
        justifyContent: "flex-end",
        zoom: "2.2",
        alignItems: "right",
        "&:hover": {
            color: "#081C15",
        },
        cursor: "pointer",
    },
}));

const ButtonStyle = styled(Button)({
    backgroundColor: "#2D6A4F",
    color: "white",
    textTransform: "none",
    borderRadius: "8px",
    fontSize: "16px",
    fontWeight: "600",
    minWidth: "140px",
    maxWidth: "150px",
    height: "54px",
});

const CancelButtonStyle = styled(Button)({
    backgroundColor: "white",
    color: "#2D6A4F",
    textTransform: "none",
    borderRadius: "8px",
    fontSize: "16px",
    fontWeight: "600",
    minWidth: "140px",
    maxWidth: "150px",
    height: "54px",
});
export default function AddPesticide({
    openAddModal,
    handleClose,
    setSnackBarMessage = () => { },
    setReload = () => { }
}) {
    const initialTranslation = {
        Hindi: {
            "Description": "",
            "Dosage": "",
            "Pesticide Name": "",
            // "Price": ""
        },
        Gujarati: {
            "Description": "",
            "Dosage": "",
            "Pesticide Name": "",
            // "Price": ""
        },
        Marathi: {
            "Description": "",
            "Dosage": "",
            "Pesticide Name": "",
            // "Price": ""
        },
        Tamil: {
            "Description": "",
            "Dosage": "",
            "Pesticide Name": "",
            // "Price": ""
        },
        Telugu: {
            "Description": "",
            "Dosage": "",
            "Pesticide Name": "",
            // "Price": ""
        },
        Kannada: {
            "Description": "",
            "Dosage": "",
            "Pesticide Name": "",
            // "Price": ""
        },
        Punjabi: {
            "Description": "",
            "Dosage": "",
            "Pesticide Name": "",
            // "Price": ""
        },
        Odia: {
            "Description": "",
            "Dosage": "",
            "Pesticide Name": "",
            // "Price": ""
        }
    }
    const dispatch = useDispatch();
    const classes = useStyles();
    const [PesticideCode, setPesticideCode] = React.useState("");
    const [PesticideName, setPesticideName] = React.useState("");
    // const [Price, setPrice] = React.useState("");
    const [PestId, setPestId] = React.useState("");
    const [CropId, setCropId] = React.useState("");
    const [Dosage, setDosage] = React.useState("");
    const [isActive, setIsActive] = React.useState('Active');
    const [Description, setDescription] = React.useState("");
    const [FarmingTypeId, setFarmingTypeId] = React.useState("");
    const [Translations, setTranslations] = React.useState(initialTranslation);
    const [addPesticideSnackBar, setAddPesticideSnackBar] = useState(false);
    const [addPesticideSnackBarMessage, setAddPesticideSnackBarMessage] = useState("");

    const basicError = {
        PesticideCode: "",
        PesticideName: "",
        // Price: "",
        Quantity: "",
        PestId: "",
        CropId: "",
        Dosage: "",
        Description: "",
        FarmingTypeId: "",
        isActive: "",
    };

    const isActiveList = [
        {name: 'Active'},
        {name: 'Inactive'}
    ]

    const [snackBarType, setSnackBarType] = useState("success");
    const [errors, setErrors] = useState(basicError);
    const [loading, setLoading] = useState(false)

    const handleChangePesticideCode = (value) => {
        value = value.replace(/^\s+/g, "");
        if (value === "")
            setErrors({ ...errors, PesticideCode: "Pesticide Code is required" })
        else if (/^[0-9\s]*$/.test(value)) {
            setErrors({ ...errors, PesticideCode: "Pesticide Code should not contain only number" })
        }
        else if (value.length > 100)
            setErrors({ ...errors, PesticideCode: "Pesticide Code should not be more than 100 characters" })
        else {
            setErrors({ ...errors, PesticideCode: "" })
        }
        setPesticideCode(value);
    };
    const handleChangePesticideName = (value) => {
        value = value.replace(/^\s+/g, "");
        if (value === "")
            setErrors({ ...errors, PesticideName: "Pesticide Name is required" })
        else if (/^[0-9\s]*$/.test(value)) {
            setErrors({ ...errors, PesticideName: "Pesticide Name should not contain only number" })
        }
        else if (value.length > 200)
            setErrors({ ...errors, PesticideName: "Pesticide Name should not be more than 100 characters" })
        else {
            setErrors({ ...errors, PesticideName: "" })
            setPesticideName(value);
        }
        setPesticideName(value);
    };
    // const handleChangePrice = (value) => {
    //     if (value === "")
    //         setErrors({ ...errors, Price: "Price is required" })
    //     else if (value.length > 100)
    //         setErrors({ ...errors, Price: "Price should not be more than 100 characters" })
    //     else {
    //         setErrors({ ...errors, Price: "" })
    //     }
    //     setPrice(value);
    // };
    const handleChangeDosage = (value) => {
        value = value.replace(/^\s+/g, "");
        if (value === "")
            setErrors({ ...errors, Dosage: "Dosage is required" })
        else if (value.length > 200)
            setErrors({ ...errors, Dosage: "Dosage should not be more than 200 characters" })
        else {
            setErrors({ ...errors, Dosage: "" })
        }
        setDosage(value);
    };
    const handleChangeDescription = (value) => {
        value = value.replace(/^\s+/g, "");
        if (value === "")
            setErrors({ ...errors, Description: "Description is required" })
        else if (value.length > 500)
            setErrors({ ...errors, Description: "Description should not be more than 500 characters" })
        else {
            setErrors({ ...errors, Description: "" })
        }
        setDescription(value);
    };
    const handleChangeFarmingType = (event) => {
        setFarmingTypeId(event.target.value);
    };
    const handleChangePest = (event) => {
        setPestId(event.target.value);
    };
    const handleChangeCrop = (event) => {
        setCropId(event.target.value);
    };
    const handleChangeIsActive = (event) => {
        setIsActive(event.target.value);
    };

    const handleChangeTranslation = (event, key) => {
        setTranslations((prevState) => ({
            ...prevState,
            [key]: event
        }));
    }

    const addPesticideApiResponse = useSelector(
        (state) => state.pesticidesSliceReducer.addPesticideApiResponse.message
    );
    const addPesticideErrorMessage = useSelector(
        (state) => state.pesticidesSliceReducer.addPesticideErrorMessage.message
    );
    const addPesticideIsSuccess = useSelector(
        (state) => state.pesticidesSliceReducer.addPesticideIsSuccess
    );
    const getPestDataResponse = useSelector(
        (state) => state.pesticidesSliceReducer.pestData
    );
    const getCropDataResponse = useSelector(
        (state) => state.pesticidesSliceReducer.cropData
    );
    const getFarmingTypeDataResponse = useSelector(
        (state) => state.pesticidesSliceReducer.farmingTypeData
    );


    const handleSave = () => {
        if (
            PesticideCode !== "" &&
            PesticideName !== "" &&
            // Price !== "" &&
            PestId !== "" &&
            CropId !== "" &&
            Dosage !== "" &&
            Description !== "" &&
            FarmingTypeId !== "" &&
            isActive !== ""
        ) {
            setAddPesticideSnackBar(false);
            setSnackBarType("success");
            setAddPesticideSnackBarMessage("");
            var is_active = isActive === "Active" ? true : false;
            setLoading(true);
            const data = {
                code: PesticideCode,
                title: PesticideName,
                // price: Price,
                pest_code: PestId,
                crop: CropId,
                dosage: Dosage,
                description: Description,
                farming_type: FarmingTypeId,
                translations: Translations,
                is_active: is_active
            }
            dispatch(
                addPesticide(data)
            ).then(() => {
                setLoading(false);
            })
        }
        else {
            setAddPesticideSnackBar(true);
            setSnackBarType("error");
            setAddPesticideSnackBarMessage("Please fill all the fields");
        }
    };

    useEffect(() => {
        if (addPesticideIsSuccess && addPesticideApiResponse !== "") {
            setSnackBarMessage(addPesticideApiResponse);
            setSnackBarType("success");
            setReload(true);
            handleClose();
            dispatch(resetAddPesticide())
        }
        else if (!addPesticideIsSuccess && addPesticideErrorMessage !== "") {
            setAddPesticideSnackBar(true);
            setSnackBarType("error");
            setAddPesticideSnackBarMessage(addPesticideErrorMessage.code ? addPesticideErrorMessage.code : addPesticideErrorMessage);
        }
    }, [addPesticideIsSuccess, addPesticideErrorMessage, addPesticideApiResponse])

    const handleCloseByClearIcon = () => {
        setErrors(basicError);
        setPesticideCode("");
        setPesticideName("");
        // setPrice("");
        setDosage("");
        setDescription("");
        setFarmingTypeId("");
        setPestId("");
        setCropId("");
        setIsActive("");
        setTranslations(initialTranslation);
        setSnackBarMessage("");
        setAddPesticideSnackBar(false);
        setAddPesticideSnackBarMessage("");
        dispatch(resetAddPesticide());
        handleClose();
    }

    const getPestList = ()=>{
        if(getPestDataResponse[0].code===""){
            setLoading(true);
            dispatch(getPestData({ fields: "code,name" })).then(() => setLoading(false));
       }
    }

    const getCropList = ()=>{
        if(getCropDataResponse[0].name===""){
          setLoading(true);
          dispatch(getCropData({ fields: "name" })).then(() => setLoading(false));
        }
    }

    const getFarmingTypeList = () =>{
        if(getFarmingTypeDataResponse[0].name===""){
            setLoading(true);
            dispatch(getFarmingTypeData({ fields: "name" })).then(() => setLoading(false));
        }
    }

    return (
        <Modal
            open={openAddModal}
        >
            <Grid container sx={subGridStyle} spacing={1}>
                <ThemeProvider theme={basicTheme}>
                        <Grid item xs={11}>
                            <Typography variant="h4" style={{ marginBottom: 10 }}>
                                Add Pesticide
                            </Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <ClearIcon
                                className={classes.closeBtnStyle}
                                onClick={handleCloseByClearIcon}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <Typography sx={{ marginBottom: 1 }}>Pesticide Code</Typography>
                                    <BasicTextfield
                                        label="Enter Pesticide Code"
                                        handleChange={handleChangePesticideCode}
                                        index={null}
                                        error={errors.PesticideCode !== ""}
                                        helperText={errors.PesticideCode}
                                        required={true}
                                        value={PesticideCode}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography sx={{ marginBottom: 1 }}>
                                        Pesticide Name
                                    </Typography>
                                    <BasicTextfield
                                        label="Enter Pesticide Name"
                                        handleChange={handleChangePesticideName}
                                        index={null}
                                        error={errors.PesticideName !== ""}
                                        helperText={errors.PesticideName}
                                        required={true}
                                        value={PesticideName}
                                    />
                                </Grid>
                                {/* <Grid item xs={12} sm={6}>
                                    <Typography sx={{ marginBottom: 1 }}>
                                        Price
                                    </Typography>
                                    <BasicTextfield
                                        label="Enter Price"
                                        handleChange={handleChangePrice}
                                        index={null}
                                        error={errors.Price !== ""}
                                        helperText={errors.Price}
                                        required={true}
                                        value={Price}
                                    />
                                </Grid> */}
                                <Grid item xs={12} sm={6}>
                                    <Typography sx={{ marginBottom: 1 }}>Pest Name*</Typography>
                                    <OptionalDropDown
                                        text={"Select Pest Name"}
                                        id={PestId}
                                        handleChange={handleChangePest}
                                        menuList={getPestDataResponse}
                                        onOpen = {getPestList}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography sx={{ marginBottom: 1 }}>Crop Name*</Typography>
                                    <OptionalDropDown
                                        text={"Select Crop Name"}
                                        id={CropId}
                                        handleChange={handleChangeCrop}
                                        menuList={getCropDataResponse}
                                        onOpen = {getCropList}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography sx={{ marginBottom: 1 }}>Dosage</Typography>
                                    <BasicTextfield
                                        label="Enter Dosage"
                                        handleChange={handleChangeDosage}
                                        index={null}
                                        error={errors.Dosage !== ""}
                                        helperText={errors.Dosage}
                                        required={true}
                                        value={Dosage}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography sx={{ marginBottom: 1 }}>Description</Typography>
                                    <BasicTextfield
                                        label="Write Description here..."
                                        handleChange={handleChangeDescription}
                                        index={null}
                                        error={errors.Description !== ""}
                                        helperText={errors.Description}
                                        required={true}
                                        value={Description}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography sx={{ marginBottom: 1 }}>Farming Type*</Typography>
                                    <OptionalDropDown
                                        text={"Select Farming Type"}
                                        id={FarmingTypeId}
                                        handleChange={handleChangeFarmingType}
                                        menuList={getFarmingTypeDataResponse}
                                        onOpen = {getFarmingTypeList}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography sx={{ marginBottom: 1 }}>Pesticide Status*</Typography>
                                    <OptionalDropDown
                                        text={"Select Pesticide Status"}
                                        id={isActive}
                                        handleChange={handleChangeIsActive}
                                        menuList={isActiveList}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography sx={{ marginBottom: 1 }}>Language & Translations* </Typography>
                                </Grid>
                                <Grid item></Grid>
                                <Grid
                                    item
                                    xs={11}
                                    sx={{
                                        border: 1,
                                        borderColor: "#D3D3D3",
                                        borderRadius: "10px",
                                        marginBottom: 2,
                                    }}
                                >
                                    <LanguageAndTranslation label={'Hindi'} onChange={handleChangeTranslation} />
                                    <LanguageAndTranslation label={'Gujarati'} onChange={handleChangeTranslation} />
                                    <LanguageAndTranslation label={'Marathi'} onChange={handleChangeTranslation} />
                                    <LanguageAndTranslation label={'Tamil'} onChange={handleChangeTranslation} />
                                    <LanguageAndTranslation label={'Telugu'} onChange={handleChangeTranslation} />
                                    <LanguageAndTranslation label={'Kannada'} onChange={handleChangeTranslation} />
                                    <LanguageAndTranslation label={'Punjabi'} onChange={handleChangeTranslation} />
                                    <LanguageAndTranslation label={'Odia'} onChange={handleChangeTranslation} />

                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            item
                            sm={12}
                            spacing={0}
                            sx={{ display: "flex", justifyContent: "center", marginTop: 2, marginBottom: 2 }}
                        >
                            <Grid
                                item
                                xs={12}
                                sm={2.5}
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    // justifyContent: "space-around",
                                }}
                                style={{ backgroundColor: "transparent", boxShadow: "none" }}
                            >
                                <CancelButtonStyle variant="contained" name="cancel" onClick={handleCloseByClearIcon}>
                                    Cancel
                                </CancelButtonStyle>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={2.5}
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-around",
                                }}
                            >
                                <ButtonStyle
                                    variant="contained"
                                    name="add"
                                    startIcon={<CheckIcon size="large" />}
                                    onClick={handleSave}
                                >
                                    Save
                                </ButtonStyle>
                            </Grid>
                            <Grid item xs={12}>
                            {addPesticideSnackBar &&
                            <SnackBars
                                open={addPesticideSnackBar}
                                handleClose={() => setAddPesticideSnackBar(false)}
                                severity={snackBarType}
                                message={addPesticideSnackBarMessage}
                                autoHideDuration={6000}
                                vertical="bottom"
                                horizontal="center"
                                style = {{ willChange: "transform" }}
                            />}
                            </Grid>
                        </Grid>
                </ThemeProvider>
                {loading && (
                    <Backdrop
                        sx={{
                            color: "#fff",
                            zIndex: (theme) => theme.zIndex.drawer + 1,
                        }}
                        open={loading}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                )}
                
            </Grid>
        </Modal>
    );
}
