import React, { useState, useEffect } from "react";
import { Grid, Typography, Checkbox } from "@mui/material";
import _without from "lodash/without";
import AutoCompleteMultiDropdown from "../reusableComponents/AutoCompleteMultiDropDown";
export default function LocationDropDown({
  role = "",
  menuList = [],
  selectedStateOptions = [],
  setSelectedStateOptions = () => {},
  selectedDistrictOptions = [],
  setSelectedDistrictOptions = () => {},
  selectedTalukaOptions = [],
  setSelectedTalukaOptions = () => {},
}) {
  return (
    <Grid
      item
      xs={12}
      sx={{
        border: 1,
        borderColor: "#D3D3D3",
        borderRadius: "10px",
        margin: 2,
      }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
          <Typography sx={{ marginBottom: 1, marginLeft: 2,color: role !== "State Head" && "#ACADAD"}}>State</Typography>
          <AutoCompleteMultiDropdown
            width="auto"
            menuList={menuList}
            selectedOptions={selectedStateOptions}
            setSelectedOptions={setSelectedStateOptions}
            disable={role !== "State Head"}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography sx={{ marginBottom: 1, marginLeft: 2, color: role !== "District Head" && "#ACADAD" }}>
            District
          </Typography>
          <AutoCompleteMultiDropdown
            width="auto"
            menuList={menuList}
            selectedOptions={selectedDistrictOptions}
            setSelectedOptions={setSelectedDistrictOptions}
            disable={role !== "District Head"}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography sx={{ marginBottom: 1, marginLeft: 2,color: role !== "Taluka Head" && "#ACADAD"  }}>
            Taluka
          </Typography>
          <AutoCompleteMultiDropdown
            width="auto"
            menuList={menuList}
            selectedOptions={selectedTalukaOptions}
            setSelectedOptions={setSelectedTalukaOptions}
            disable={role !== "Taluka Head"}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
