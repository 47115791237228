import React, { useState, useEffect, useRef } from "react";
import { styled } from "@mui/material/styles";
import { Grid, Box, Typography, ThemeProvider } from "@mui/material";
import { basicTheme } from "../../themes/customTheme";
import TopFilterBar from "../../components/reusableComponents/TopFilterBar";
import DesktopTable from "../../components/reusableComponents/DesktopTable";
import { useDispatch, useSelector } from "react-redux";
import {
  getPartnerData,
  getExportedData,
} from "../../features/partner/partnerSlice";
import { getEachPartnerData } from "../../utils";
import AddPartnerModal from "../../components/partner/addPartnerModal";
import { CSVLink } from "react-csv";
import LinearProgress from "@mui/material/LinearProgress";
import useMediaQuery from "@mui/material/useMediaQuery";
import StackTable from "../../components/reusableComponents/StackTable";
import PartnerFilterPopOver from "../../components/partner/partnerFilterPopOver";
import SnackBars from "../../components/reusableComponents/SnackBar";
import EditPartnerModal from "../../components/partner/editPartnerModal";
import StateWiseLocationModal from "../../components/location/statewiseLocationModal";
import DeletePartner from "../../components/partner/deletePartnerModal";
import AlertDialog from "../../components/reusableComponents/AlertDialog";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useLocation } from 'react-router-dom';

const MainGrid = styled(Grid)({
  flex: "1",
  backgroundColor: "white",
  // [theme.breakpoints.down("sm")]: {
  //   marginTop: "60px",
  // },
  marginTop: "80px",
});

const initialHeadCells = [
  {
    key: "action",
    label: "Actions",
    type: "editDelete",
    sort: false,
    sticky: true,
  },
  {
    key: "name",
    label: "Partner",
    type: "text",
    sort: true,
    capital:true
  },
  {
    key: "spoc_details",
    label: "SPOC",
    type: "user",
    sort: false,
  },
  {
    key: "protocol",
    label: "Protocol",
    type: "text",
    sort: true,
  },
  {
    key: "is_active",
    label: "Status",
    type: "boolean",
    sort: true,
  },
  // {
  //   key: "state_wise_location",
  //   label: "State(Villages)",
  //   type: "location",
  //   sort: false,
  // },
  {
    key: "created_at",
    label: "Date Added",
    type: "text",
    sort: true,
  },
];

export default function Partner({}) {
  const location = useLocation();
  const status = new URLSearchParams(location.search).get('status');
  const dispatch = useDispatch();
  const csvLink = useRef();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const rowsPerPage = 10;
  const [openAddModal, setopenAddModal] = React.useState(false);
  const [offset, setOffset] = useState(0);
  const [page, setPage] = React.useState(0);
  const [sortingType, setSortingType] = useState("desc");
  const [sortBy, setSortBy] = useState("created_at");
  const [applyFilter, setApplyFilter] = useState(false);
  const [searchBarData, setSearchBarData] = useState("");
  const [searchBarText, setSeachBarText] = useState("");
  const [exportData, setExportData] = useState("");
  const [exportedFileName, setExportedFileName] = useState("");
  const [partnerSnackBarType, setPartnerSnackBarType] = useState("success");
  const [partnerSnackBarMessage, setPartnerSnackBarMessage] = useState("");
  const [showPartnerSnackBar, setPartnerShowSnackBar] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openEditModal, setopenEditModal] = useState(false);
  const [openEditModalWithLocation, setopenEditModalWithLocation] = useState(false);
  const [editData, setEditData] = useState({});
  const [editDataWithLocation, setEditDataWithLocation] = useState({});
  const [deleteData, setDeleteData] = useState({});
  const [reload, setReload] = useState(false);
  const [filters, setFilters] = useState([]);
  const [apiCall, setApiCall] = useState(false);
  const [openLocationSubModal, setOpenLocationSubModal] = React.useState(false);
  const [openFilterPopOver, setOpenFilterPopOver] = React.useState(null);
  const [openAlertModal, setOpenAlertModal] = useState(false);
  const [selectedStates, setSelectedStates] = useState([]);
  const [selectedPartners, setSelectedPartners] = useState([]);
  const [selectedProtocols, setSelectedProtocols] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [clearFilter, setClearFilter] = useState(false);
  const [loading, setLoading] = useState(false);
  const loggedInUser = JSON.parse(window.localStorage.getItem("loggedInUser"));
  const ADMIN_PARTNER = process.env.REACT_APP_ADMIN_PARTNER

  const handleShowFilterPopOver = (event) => {
    setOpenFilterPopOver(event.currentTarget);
  };

  const handleCloseFilterPopOver = () => {
    setOpenFilterPopOver(null);
    setApplyFilter(false);
    setClearFilter(false);
  };

  const drawerWidth = useSelector(
    (state) => state.appBarSliceReducer.drawerWidth
  );

  const partnerDataResponse = useSelector(
    (state) => state.partnerSliceReducer.partnerData.data
  );
  const partnerDataErrorMessage = useSelector(
    (state) => state.partnerSliceReducer.partnerDataErrorMessage
  );

  const partnerDataIsSuccess = useSelector(
    (state) => state.partnerSliceReducer.partnerDataIsSuccess
  );

  const partnerDataLoading = useSelector(
    (state) => state.partnerSliceReducer.partnerDataLoading
  );

  const totalPartnerData = useSelector(
    (state) => state.partnerSliceReducer.partnerData.count
  );

  const exportedData = useSelector(
    (state) => state.partnerSliceReducer.exportedData
  );

  useEffect(()=>{
    if(loggedInUser.details.partner!==null){
      setFilters((oldArray) => [
        ...oldArray,
        { type: "name", value: [loggedInUser.details.partner.name] },
      ])
    }
  },[])

  useEffect(()=>{
    if(status!==null){
      setFilters((oldArray) => [
        ...oldArray,
        { type: "is_active", value: [status==="true"?true:false] },
      ])
    }
  },[status])

  useEffect(() => {
    if (clearFilter) {
      setPage(0);
      setOffset(0);
    }
  }, [clearFilter]);

  useEffect(() => {
    setPage(0);
    setOffset(0);
  }, [searchBarData]);

  useEffect(() => {
    if (applyFilter) {
      handleCloseFilterPopOver();
      setPage(0);
      setOffset(0);
    }
  }, [applyFilter]);

  useEffect(() => {
    setApiCall(true);
  }, [
    searchBarData,
    offset,
    sortingType,
    sortBy,
    filters
  ]);

  useEffect(() => {
    if (editData.id !== undefined && editData.id.toString() === ADMIN_PARTNER) {
      setopenEditModal(false);
    } else if (openEditModal) {
      setLoading(true);
      getEachPartnerData({
        filters: JSON.stringify([{ type: "id", value: [editData.id] }]),
      }).then((data) => {
        setLoading(false);
        try {
          setEditDataWithLocation(data.data[0]);
          setopenEditModalWithLocation(true);
          setopenEditModal(false);
        } catch (error) {
          // Handle the case where data.data[0] is undefined
          setPartnerShowSnackBar(true);
          setPartnerSnackBarMessage("Something went wrong on Partner server side ");
          setPartnerSnackBarType("error");
          setopenEditModal(false);
        }
      });
    }
  }, [openEditModal]);
  

  useEffect(() => {
    if (apiCall || reload) {
      setLoading(true)
      dispatch(
        getPartnerData({
          filters: JSON.stringify(filters),
          search: searchBarData,
          offset: offset,
          limit: rowsPerPage,
          sort_by: sortingType,
          sort_type: sortBy,
          internal:true
        })
      ).then(() => {
        setLoading(false)
        setApiCall(false);
        setReload(false);
      });
    }
  }, [dispatch, apiCall,reload,]);

  useEffect(() => {
    if (partnerSnackBarMessage !== "" && partnerSnackBarMessage !== undefined)
      setPartnerShowSnackBar(true);
  }, [partnerSnackBarMessage]);

  useEffect(() => {
    if (
      !partnerDataIsSuccess &&
      (partnerDataErrorMessage === undefined ||
        (partnerDataErrorMessage.message === undefined &&
          partnerDataErrorMessage.message !== ""))
    ) {
      setPartnerShowSnackBar(true);
      setPartnerSnackBarMessage("Something went wrong on Partner server side ");
      setPartnerSnackBarType("error");
    } else if (
      !partnerDataLoading &&
      !partnerDataIsSuccess &&
      partnerDataErrorMessage !== undefined &&
      partnerDataErrorMessage.message !== undefined &&
      partnerDataErrorMessage.message !== ""
    ) {
      setPartnerShowSnackBar(true);
      setPartnerSnackBarMessage(partnerDataErrorMessage.message);
      setPartnerSnackBarType("error");
    }
    //  else {
    //   setPartnerShowSnackBar(false);
    //   setPartnerSnackBarMessage("");
    // }
  }, [partnerDataErrorMessage, partnerDataLoading]);

  useEffect(() => {
    if (exportData){
      setLoading(true)
      dispatch(
        getExportedData({
          sort_by: sortingType,
          sort_type: sortBy,
          search: searchBarData,
          filters: JSON.stringify(filters),
          offset: offset,
          limit: rowsPerPage,
        })
      ).then((res) => {
        setLoading(false)
        setExportData(false);
        if(res.error!==undefined){
          setPartnerSnackBarMessage("Something went wrong!")
          setPartnerSnackBarType("error")
          setPartnerShowSnackBar(true)
        }
        else{
        setExportedFileName("partner_data" + ".csv");
        csvLink.current.link.click();
        }
      });
    }
  }, [exportData]);

  return (
    <MainGrid
      container
      id="main"
      sx={{ marginLeft: drawerWidth + "px", width: "auto" }}
      spacing={3}
    >
      <ThemeProvider theme={basicTheme}>
        <Grid item xs={12}>
          <TopFilterBar
            addtext="Add Partner"
            setAddOpen={setopenAddModal}
            searchBarData={searchBarData}
            setSearchBarData={setSearchBarData}
            searchBarText={searchBarText}
            setSeachBarText={setSeachBarText}
            handleShowFilterPopOver={handleShowFilterPopOver}
            setExportData={setExportData}
            searchBy="Search By Partner Name"
            loading={loading}
            filterSelected = {selectedStates.length!==0 || selectedPartners.length!==0 || selectedProtocols.length!==0 || selectedStatus!==null}
          />
          {openFilterPopOver && (
            <PartnerFilterPopOver
              handleClose={handleCloseFilterPopOver}
              openFilterPopOver={openFilterPopOver}
              setApplyFilter={setApplyFilter}
              filters={filters}
              setFilters={setFilters}
              selectedStates={selectedStates}
              setSelectedStates={setSelectedStates}
              selectedPartners={selectedPartners}
              setSelectedPartners={setSelectedPartners}
              selectedProtocols={selectedProtocols}
              setSelectedProtocols={setSelectedProtocols}
              selectedStatus={selectedStatus}
              setSelectedStatus={setSelectedStatus}
              clearFilter={clearFilter}
              setClearFilter={setClearFilter}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          {partnerDataLoading && !partnerDataIsSuccess && loading &&  (
            <Box>
              <LinearProgress color="success" />
            </Box>
          )}
          {isSmallScreen && partnerDataIsSuccess ? (
            <StackTable
              key={partnerDataResponse}
              headCells={initialHeadCells}
              tableResponse={partnerDataResponse}
              totalCount={totalPartnerData}
              setOffset={setOffset}
              rowsPerPage={rowsPerPage}
              setSortingType={setSortingType}
              sortingType={sortingType}
              setSortBy={setSortBy}
              sortBy={sortBy}
              setopenEditModal={setopenEditModal}
              setEditData={setEditData}
              setOpenDeleteModal={setOpenDeleteModal}
              setDeleteData={setDeleteData}
              setPage={setPage}
              page={page}
              setOpenLocationSubModal={setOpenLocationSubModal}
              openEditModal={openEditModal}
              openDeleteModal={openDeleteModal}
            />
          ) : (
            !partnerDataLoading &&
            partnerDataIsSuccess && !loading && (
              <DesktopTable
                key={partnerDataResponse}
                headCells={initialHeadCells}
                tableResponse={partnerDataResponse}
                totalCount={totalPartnerData}
                setOffset={setOffset}
                rowsPerPage={rowsPerPage}
                setSortingType={setSortingType}
                sortingType={sortingType}
                setSortBy={setSortBy}
                sortBy={sortBy}
                setopenEditModal={setopenEditModal}
                setEditData={setEditData}
                setOpenDeleteModal={setOpenDeleteModal}
                setDeleteData={setDeleteData}
                setPage={setPage}
                page={page}
                setOpenLocationSubModal={setOpenLocationSubModal}
                openEditModal={openEditModal}
                openDeleteModal={openDeleteModal}
              />
            )
          )}
        </Grid>
        {openEditModalWithLocation && (
          <EditPartnerModal
            openModal={openEditModalWithLocation}
            handleClose={() => setopenEditModalWithLocation(false)}
            data={editDataWithLocation}
            setEditData={setEditDataWithLocation}
            setPartnerSnackBarMessage={setPartnerSnackBarMessage}
            setPartnerSnackBarType={setPartnerSnackBarType}
            setReload={setReload}
          />
        )}
        {openAddModal && (
          <AddPartnerModal
            openAddModal={openAddModal}
            handleClose={() => setopenAddModal(false)}
            setPartnerSnackBarMessage={setPartnerSnackBarMessage}
            setReload={setReload}
          />
        )}
        {openAlertModal && (
          <AlertDialog
            handleClose={() => setOpenAlertModal(false)}
            open={openAlertModal}
            heading="Can't delete this Partner"
            text="Users and Supervisors are mapped with this partner.Please unmap them and then
            try deleting again!"
          />
        )}

        {openDeleteModal && (
          <DeletePartner
            handleClose={() => setOpenDeleteModal(false)}
            open={openDeleteModal}
            deleteData={deleteData}
            setSnackBarMessage={setPartnerSnackBarMessage}
            setReload={setReload}
            setOpenAlertModal={setOpenAlertModal}
          />
        )}
        <CSVLink
          data={exportedData}
          filename={exportedFileName}
          className="hidden"
          ref={csvLink}
          target="_blank"
          separator={";"}
        />
        {showPartnerSnackBar && (
          <SnackBars
            open={showPartnerSnackBar}
            handleClose={() => setPartnerShowSnackBar(false)}
            severity={partnerSnackBarType}
            message={partnerSnackBarMessage}
            autoHideDuration={6000}
            vertical="bottom"
            horizontal="center"
          />
        )}
        {openLocationSubModal && (
          <StateWiseLocationModal
            openModal={openLocationSubModal}
            handleClose={() => setOpenLocationSubModal(false)}
            data={editData.state_wise_location}
          />
        )}
        {loading && (
          <Backdrop
            sx={{
              color: "#fff",
              position:"absolute",
              zIndex: (theme) => theme.zIndex.drawer +1,
            }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
      </ThemeProvider>
    </MainGrid>
  );
}
