import React, { useState, useEffect } from "react";
import Popover from "@mui/material/Popover";
import { Grid, Button, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { displayDropdown, displayMultiSelectDropdown } from "../../utils/index";
import { useDispatch, useSelector } from "react-redux";
import {
  getPesticidesData,
  getPesticideTitleList,
  getPestData,
  getCropData,
  getFarmingTypeData,
} from "../../features/advisoryManagement/advisoryManagementSlice";
import { getPartnerNames } from "../../features/partner/partnerSlice";
import ClearIcon from "@mui/icons-material/Clear";
import _without from "lodash/without";
import AutoCompleteDropdown from "../reusableComponents/AutoCompleteDropDown";
import OptionalDropDown from "../reusableComponents/OptionalDropDown";
import AutoCompleteMultiDropdown from "../../components/advisoryManagement/AutoCompleteMultiDropDown";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const StyledGrid = styled(Grid)({
  display: "flex",
  width: "700px",
});

const ClearBtn = styled(Button)({
  backgroundColor: "#FFFFFF",
  color: "#2D6A4F",
  textTransform: "none",
  borderRadius: 10,
  fontSize: "18px",
  fontWeight: "600",
  marginLeft: 20,
  marginBottom: 20,
  marginTop: 30,
  width: "80%",
  height: "60%",
  borderRadius: 10,
  borderColor: "1px solid #2D6A4F",
});
const ApplyBtn = styled(Button)({
  backgroundColor: "#2D6A4F",
  color: "#FFFFFF",
  textTransform: "none",
  borderRadius: 10,
  fontSize: "18px",
  fontWeight: "600",
  marginLeft: 20,
  marginBottom: 20,
  marginTop: 30,
  width: "80%",
  height: "60%",
  borderRadius: 10,
  borderColor: "1px solid #2D6A4F",
});

const useStyles = makeStyles(() => ({
  closeBtnStyle: {
    display: "flex",
    justifyContent: "flex-end",
    zoom: "1.5",
    alignItems: "right",
    "&:hover": {
      color: "#081C15",
    },
    cursor: "pointer",
  },
}));

export default function PartnerPesticideFilterPopOver({
  handleClose = () => {},
  openFilterPopOver,
  setApplyFilter = () => {},
  filterBy,
  setFilterBy = () => {},
  selectedPest,
  setSelectedPest = () => {},
  selectedFarmingType,
  setSelectedFarmingType = () => {},
  selectedPartner,
  setSelectedPartner = () => {},
  selectedPesticides,
  setSelectedPesticides = () => {},
  clearFilter,
  setClearFilter = () => {},
}) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const open = Boolean(openFilterPopOver);
  const id = open ? "filter-popover" : undefined;
  const [partnerId, setPartnerId] = React.useState(selectedPartner);
  const [PestId, setPestId] = React.useState(selectedPest);
  const [FarmingTypeId, setFarmingTypeId] = React.useState(selectedFarmingType);
  const [pesticideId, setPesticideId] = React.useState(selectedPesticides);
  const [loading, setLoading] = useState(false);
  const loggedInUser = JSON.parse(window.localStorage.getItem("loggedInUser"));

    const handleApplyFilter = () => {
        setFilterBy([{
            type: "partner",
            value: partnerId!==null && partnerId.id!==null ? [partnerId.id] : []
        },
        {
            type: "pesticides",
            value: pesticideId.map((item) => item.code)
        },
        {
            type: "pest",
            value: PestId ? [PestId] : []
        },
        {
            type: "farming_type",
            value: FarmingTypeId ? [FarmingTypeId] : []
        }]
        );
        handleClose();
    };

  const handleChangePest = (event) => {
    setPestId(event.target.value);
    setSelectedPest(event.target.value);
  };

  const handleChangeFarmingType = (event) => {
    setFarmingTypeId(event.target.value);
    setSelectedFarmingType(event.target.value);
  };

  const handleChangePesticides = (value) => {
    setPesticideId(value);
    setSelectedPesticides(value);
  };

  const partnerNamesData = useSelector(
    (state) => state.partnerSliceReducer.partnerNames.data
  );
  const getPesticidesDataResponse = useSelector(
    (state) => state.pesticidesSliceReducer.pesticideTitleList
  );
  const getPestDataResponse = useSelector(
    (state) => state.pesticidesSliceReducer.pestData
  );
  const getFarmingTypeDataResponse = useSelector(
    (state) => state.pesticidesSliceReducer.farmingTypeData
  );

  useEffect(() => {
    if(loggedInUser.details.partner!==null)
      setPartnerId(loggedInUser.details.partner)
    if (getPesticidesDataResponse[0].code === "")
    {
      setLoading(true);
    
      dispatch(getPesticideTitleList({ fields: "code" })).then(()=>{
        if (getPestDataResponse[0].code === "")
          dispatch(getPestData({ fields: "code,name" })).then(()=>{
            if (getFarmingTypeDataResponse[0].name === "")
              dispatch(getFarmingTypeData({ fields: "name" })).then(()=>{
                if (partnerNamesData[0].id === "") {
                  
                  dispatch(
                    getPartnerNames({
                      fields: ["id", "name"].toString(),
                      filters: JSON.stringify([{ type: "is_active", value: [true] }]),
                      internal:true
                    })
                  ).then(() => setLoading(false));
                }
              })
          })
      })
    }
   
    
    
  }, [dispatch]);

  useEffect(() => {
    setSelectedPartner(partnerId);
  }, [partnerId]);

  useEffect(() => {
    if (clearFilter) {
      setClearFilter(false);
      setFarmingTypeId("");
      setPestId("");
      var tempFilter = [{type:"pesticides",value:[]},{type:"pest",value:[]},{type:"farming_type",value:[]}]
      if(loggedInUser.details.partner===null)
      {
        tempFilter.push({type:"partner",value:[]})
        setPartnerId(null);
      }
      else{
        tempFilter.push({type:"partner",value:[loggedInUser.details.partner.id]})
      }
      setFilterBy(tempFilter)
      setPesticideId([]);
      setSelectedFarmingType("");
      setSelectedPest("");
      setSelectedPartner(null);
      setSelectedPesticides([]);
    }
  }, [clearFilter]);

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={openFilterPopOver}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      sx={{
        "& .MuiPopover-paper": {
          padding: 2.5,
          borderRadius: "20px",
        },
      }}
    >
      <StyledGrid container spacing={2}>
        <Grid item xs={11} sx={{ color: "#78828C" }}>
          <Typography sx={{ fontSixe: "20px", color: "#999DA4" }}>
            Filter By
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <ClearIcon className={classes.closeBtnStyle} onClick={handleClose} />
        </Grid>
        <Grid container item sm={12} sx={{ color: "#5E6060" }}>
          <Grid item xs={12} sm={6}>
            <Typography sx={{ fontSixe: "18px", color: "#444444", margin: 1 }}>
              Partner
            </Typography>
            {/* <OptionalDropDown
                            text={"Select Partner"}
                            id={partnerId}
                            handleChange={handleChangePartner}
                            menuList={partnerNamesData}
                        /> */}
            <AutoCompleteDropdown
              width="auto"
              // selectedOptions={partnerId}
              selectedOptions={partnerNamesData[0].id!==""?partnerId:null}
              setSelectedOptions={setPartnerId}
              menuList={partnerNamesData}
              disable={loggedInUser.details.partner!==null}
              label={"Select Partner"}

            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography sx={{ fontSixe: "18px", color: "#444444", margin: 1 }}>
              Pest Name
            </Typography>
            <OptionalDropDown
              text={"Select Pest"}
              id={PestId}
              handleChange={handleChangePest}
              menuList={getPestDataResponse}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography sx={{ fontSixe: "18px", color: "#444444", margin: 1 }}>
              Farming Type
            </Typography>
            <OptionalDropDown
              text={"Select Farming Type"}
              id={FarmingTypeId}
              handleChange={handleChangeFarmingType}
              menuList={getFarmingTypeDataResponse}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography sx={{ fontSixe: "18px", color: "#444444", margin: 1 }}>
              Pesticide Code
            </Typography>
            <AutoCompleteMultiDropdown
              widht={230}
              menuList={getPesticidesDataResponse}
              selectedOptions={selectedPesticides}
              onHighlightChange={handleChangePesticides}
              label="Select Pesticide Code"
              disable={getPesticidesDataResponse.length === 0 ? true : false}
            />
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <ClearBtn
            variant="contained"
            name="Clear Filter"
            onClick={() => setClearFilter(true)}
          >
            Clear All
          </ClearBtn>
        </Grid>
        <Grid item xs={3}>
          <ApplyBtn
            variant="contained"
            name="Apply Filter"
            onClick={handleApplyFilter}
          >
            Apply
          </ApplyBtn>
        </Grid>
        {loading && (
          <Backdrop
            sx={{
              color: "#fff",
              position: "absolute",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
      </StyledGrid>
    </Popover>
  );
}
